import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Divider, Typography, TextField, Grid } from "@material-ui/core";
import LabelControl from "../LabelControls";
import ColouredToggleSwitch from "../ColoredToggleSwitch";
import InputBase from "@material-ui/core/InputBase";
import ChipSelect from "../ChipSelector";
import TextInputStyle from "../TextInputStyle";
import RequiredEditable from "../RequiredEditable";

const InputText = withStyles((theme) => ({
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 10,
    paddingLeft: 2,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {},
  sideHeading: {
    color: "#091540",
    fontWeight: 600,
    fontSize: 13,
    paddingLeft: 10,
  },
  section: {
    padding: 10,
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
  },
  sectionLabel: {
    color: "#999",
    fontSize: 10,
    marginRight: 5,
    marginTop: 5,
  },
  fullWidthText: {
    margin: "10px 0",
  },
  input: {
    color: "#091540",
    fontSize: 10,
  },
  center: {
    textAlign: "center",
  },
}));

export default function FileUploadSidebar(props) {
  const classes = useStyles();

  const { fileTooltipText } = props;

  return (
    <div>
      <Typography gutterBottom className={classes.sideHeading}>
        Name
      </Typography>
      <Divider />

      <div className={classes.section}>
        <TextInputStyle />
      </div>

      <Divider />

      <div className={classes.section}>
        <LabelControl />
      </div>

      <Divider />

      <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Preferences
        </Typography>
        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Show Prefix
          </Typography>
          <ColouredToggleSwitch />
        </div>
        <div>
          <div style={{ display: "inline-flex" }}>
            <Typography gutterBottom className={classes.sectionLabel}>
              Show Last Name
            </Typography>
            <ColouredToggleSwitch />
          </div>
        </div>

        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Prefix Placeholder
          </Typography>
          <InputText
            size="small"
            placeholder="Prefix"
            defaultValue="Prefix"
            inputProps={{
              className: classes.input,
            }}
          />
        </div>

        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            First Name Placeholder
          </Typography>
          <InputText
            size="small"
            placeholder="First Name"
            defaultValue="First Name"
            inputProps={{
              className: classes.input,
            }}
          />
        </div>

        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Last Name Placeholder
          </Typography>
          <InputText
            size="small"
            placeholder="Last Name"
            defaultValue="Last Name"
            inputProps={{
              className: classes.input,
            }}
          />
        </div>
        <div>
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            <Typography gutterBottom className={classes.sectionLabel}>
              Include tooltip
            </Typography>
            <ColouredToggleSwitch />
          </div>
        </div>
        <TextField
          variant="outlined"
          size="small"
          //value={selectedText}
          onChange={(e) => fileTooltipText(e.target.value)}
          fullWidth
          multiline
          rows={2}
          inputMode="text"
          className={classes.fullWidthText}
          placeholder="Enter tooltip text"
        />
      </div>
      <Divider />
      <div className={classes.section}>
        <RequiredEditable />
      </div>
    </div>
  );
}
