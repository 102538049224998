import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Collapse,
  InputAdornment,
  InputBase,
  Typography,
} from "@material-ui/core";
import ColorPicker from "../ColorPicker";
import Selector from "../Selector";
import { useSelector } from "react-redux";

const PageItemStyles = React.memo(({ screenStyles, onStyleChange }) => {
  const [showFormAppearance, setShowFormAppearance] = useState(false);

  const {
    activeScreen: { type: screenType },
  } = useSelector(({ screens }) => screens);

  const style = { ...screenStyles };

  const InputText = withStyles((theme) => ({
    input: {
      color: "#091540",
      borderRadius: 3,
      position: "relative",
      border: "1px solid #ABB3BF",
      fontSize: 11,
      padding: "5px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
    },
  }))(InputBase);

  const formRowSpacing = [
    ["10", "Thin"],
    ["15", "Normal"],
    ["20", "Large"],
    ["30", "Extra Large"],
  ];

  return (
    <div className="sidebar-section">
      <div
        className="sidebar-section-header"
        onClick={() => setShowFormAppearance((prev) => !prev)}
      >
        <Typography>Page appearance</Typography>
        <span>{`[${showFormAppearance ? "-" : "+"}]`}</span>
      </div>

      <Collapse in={showFormAppearance}>
        <div style={{ margin: 8 }}></div>
        <span
          className="section-form-subsection"
          style={{ display: "inline-block", marginTop: 10 }}
        >
          Dimensions
        </span>

        <div className="sidebar-section-itemgroup">
          <div className="sidebar-section-item">
            <Typography gutterBottom className="row-label">
              Width:
            </Typography>
            <InputText
              size="small"
              type="number"
              disabled={screenType === "app"}
              placeholder={
                style?.page?.dimensionMeasure === "px" ? "(in px)" : "(inches)"
              }
              defaultValue={style?.page?.width}
              endAdornment={
                <InputAdornment position="end">
                  {style?.page?.dimensionMeasure === "px" ? "px" : "in"}
                </InputAdornment>
              }
              onBlur={(e) =>
                onStyleChange({
                  value: e.target.value,
                  root: "page",
                  property: "width",
                })
              }
            />
          </div>

          <div className="sidebar-section-item">
            <Typography gutterBottom className="row-label">
              Height:
            </Typography>
            <InputText
              size="small"
              type="number"
              disabled={screenType === "app"}
              placeholder={
                style?.page?.dimensionMeasure === "px" ? "(in px)" : "(inches)"
              }
              defaultValue={style?.page?.height}
              endAdornment={
                <InputAdornment position="end">
                  {style?.page?.dimensionMeasure === "px" ? "px" : "in"}
                </InputAdornment>
              }
              onBlur={(e) =>
                onStyleChange({
                  value: e.target.value,
                  root: "page",
                  property: "height",
                })
              }
            />
          </div>

          <div className="sidebar-section-item">
            <Typography gutterBottom className="row-label">
              H.Margin:
            </Typography>
            <InputText
              size="small"
              type="number"
              placeholder={
                style?.page?.dimensionMeasure === "px" ? "(in px)" : "(inches)"
              }
              disabled={screenType === "app"}
              defaultValue={style?.page?.horizontalMargin}
              endAdornment={
                <InputAdornment position="end">
                  {style?.page?.dimensionMeasure === "px" ? "px" : "in"}
                </InputAdornment>
              }
              onBlur={(e) =>
                onStyleChange({
                  value: e.target.value,
                  root: "page",
                  property: "horizontalMargin",
                })
              }
            />
          </div>

          <div className="sidebar-section-item">
            <Typography gutterBottom className="row-label">
              V.Margin:
            </Typography>
            <InputText
              size="small"
              type="number"
              placeholder={
                style?.page?.dimensionMeasure === "px" ? "(in px)" : "(inches)"
              }
              disabled={screenType === "app"}
              defaultValue={style?.page?.verticalMargin}
              endAdornment={
                <InputAdornment position="end">
                  {style?.page?.dimensionMeasure === "px" ? "px" : "in"}
                </InputAdornment>
              }
              onBlur={(e) =>
                onStyleChange({
                  value: e.target.value,
                  root: "page",
                  property: "verticalMargin",
                })
              }
            />
          </div>
        </div>

        <span className="section-form-subsection">Background & Spacing</span>

        <div className="sidebar-section-itemgroup">
          <div className="sidebar-section-item">
            <Typography gutterBottom className="row-label">
              Bg.Color:
            </Typography>
            <ColorPicker
              color={style?.page?.backgroundColor}
              identity="backgroundColor"
              colorCallback={(e) =>
                onStyleChange({
                  value: e,
                  root: "page",
                  property: "backgroundColor",
                })
              }
            />
          </div>

          <div className="sidebar-section-item">
            <Typography gutterBottom className="row-label">
              Spacing:
            </Typography>
            <Selector
              items={formRowSpacing}
              onChange={(v) =>
                onStyleChange({
                  value: v,
                  root: "page",
                  property: "lineSpacing",
                })
              }
              selectedValue={style?.page?.lineSpacing}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
});
export default PageItemStyles;
