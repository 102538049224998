import { MenuItem } from "@material-ui/core";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { APP_DESIGN_MODES } from "./constants";
import Parser from "html-react-parser";

const getAnchorableText = (text) => {
  const validUrlRegex =
    /\b(https?:\/\/|www\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  const isAnchorTag = /<a\b[^>]*>(.*?)<\/a>/i.test(text);

  const formattedText = isAnchorTag
    ? text
    : text?.replace(validUrlRegex, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });

  return formattedText;
};

export const searchAndReplace = (
  value,
  dynamicContentObj,
  textOrField = "text"
) => {
  const text = value;

  if (text) {
    const newText =
      text?.replace(/(@[a-zA-Z0-9-_]+)/g, (match, offset, string) => {
        return `${dynamicContentObj?.[match]?.toString() || ""}`;
      }) || "";

    if (textOrField === "field" && text === newText) return "";

    //  display newlines accordingly
    const newLined = newText?.split("\n");

    const finalText =
      newLined?.length === 1
        ? getAnchorableText(newLined[0])
        : newLined?.map((text, index, theArray) => {
            // check if the text is inside an anchor tag
            const formattedText = getAnchorableText(text);

            return (
              <>
                {Parser(formattedText)}
                {index < theArray.length - 1 ? <br /> : null}
              </>
            );
          });

    return finalText;
  }
};

export const dropdownContent = (
  { values, name, appDesignMode, trackLookupVal },
  dynamicContentObj
) => {
  if (dynamicContentObj) {
    if (
      dynamicContentObj?.[name] &&
      typeof dynamicContentObj?.[name] === "object"
    ) {
      return dynamicContentObj?.[name]?.map((val, index) => (
        <MenuItem key={index} value={val}>
          {val}
        </MenuItem>
      ));
    } else if (name?.startsWith("@") && !dynamicContentObj?.[name]) {
      return null;
    } else {
      return values?.options?.map(({ dataText, dataValue }, i) => (
        <MenuItem
          key={i}
          value={values?.useValuesAttribute ? dataValue : dataText}
        >
          {dataText}
        </MenuItem>
      ));
    }
  } else {
    const duplicateLookup = values.options.find(
      (opt) => opt?.dataText === trackLookupVal?.[trackLookupVal?.length - 1]
    );
    if (!duplicateLookup) {
      values.options = [
        ...(values?.options || []),
        ...trackLookupVal?.map((lookupVal) => ({
          id: lookupVal,
          dataText: lookupVal,
        })),
      ];
    }
    return values?.options.map(({ dataText, dataValue }, i) => (
      <MenuItem
        key={i}
        value={values?.useValuesAttribute ? dataValue : dataText}
      >
        {dataText}
      </MenuItem>
    ));
  }
};

export const checkboxContent = (
  { values, name, appDesignMode, disabled, readOnly, reuseValue, _onChange },
  dynamicContentObj,
  trackLookupVal
) => {
  // if (dynamicContentObj) {
  if (
    dynamicContentObj?.[name] &&
    typeof dynamicContentObj?.[name] === "object"
  ) {
    return dynamicContentObj?.[name]?.map((val, index) => (
      <div key={index}>
        <FormControlLabel
          disabled={
            appDesignMode === APP_DESIGN_MODES.EDIT ||
            appDesignMode === APP_DESIGN_MODES.PREVIEW ||
            disabled ||
            readOnly
          }
          className={`${readOnly ? "read-only" : ""}`}
          control={
            <Checkbox
              value={val}
              onChange={_onChange}
              name={val}
              required={values?.required ?? false}
            />
          }
          label={
            <Typography className={""} style={{ textAlign: "left" }}>
              {val}
            </Typography>
          }
        />
      </div>
    ));
    // }
  } else {
    const duplicateLookup = values?.options?.find(
      (opt) => opt.dataText === trackLookupVal?.[trackLookupVal?.length - 1]
    );
    if (!duplicateLookup) {
      values.options = [
        ...(values?.options || []),
        ...trackLookupVal?.map((lookupVal) => ({
          id: lookupVal,
          dataText: lookupVal,
        })),
      ];
    }
    return values?.options?.map(
      ({ dataText, dataValue, id, classes }, index) => {
        const value = values?.useValuesAttribute ? dataValue : dataText;
        let defaultValues = [];
        if (reuseValue) {
          defaultValues = Array.isArray(reuseValue) ? reuseValue : [reuseValue];
        }
        if (trackLookupVal?.length) {
          defaultValues?.push(trackLookupVal?.[trackLookupVal?.length - 1]);
        }
        const checked = defaultValues?.includes(value);
        return (
          <div key={index}>
            <FormControlLabel
              disabled={
                appDesignMode === APP_DESIGN_MODES.EDIT ||
                appDesignMode === APP_DESIGN_MODES.PREVIEW ||
                disabled ||
                readOnly
              }
              control={
                <Checkbox
                  value={value}
                  onChange={_onChange}
                  name={id}
                  required={values?.required ?? false}
                  defaultChecked={checked}
                />
              }
              label={
                <Typography
                  className={`${classes?.checkbox} ${classes?.text}`}
                  style={{ textAlign: "left" }}
                >
                  {dataText}
                </Typography>
              }
            />
          </div>
        );
      }
    );
  }
};

export const convertValidUrlToClickableLink = (text) => {
  const arrayedText = Array.isArray(text) ? text : [text];

  // Function to validate if string  passed is a valid URL
  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (e) {
      return false;
    }
  };

  return arrayedText.map((text, index) => {
    const validUrl = isValidUrl(text);

    return validUrl ? (
      <a href={text} key={index} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    ) : (
      text
    );
  });
};

export const getDynamicImageSrc = (dynamicContentObj, name, values) => {
  if (dynamicContentObj?.[name]) {
    return dynamicContentObj?.[name];
  }

  return values?.src || "/images/image-component.png";
};
