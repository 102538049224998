import { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { makeStyles } from "@material-ui/core";
import { useStyles } from "../../utils/IntegrationsPanelStyle";
import PanelBody from "../PanelBody";
import { successToastify } from "../../../../common/Toastify";
import useCustomMutation from "../../../../common/CustomMutation";
import useCustomQuery from "../../../../common/CustomQuery";
import { getIntegrationResourcesListAPI } from "../../../../components/Query/Integration/integrationQuery";
import {
  newIntegrationAPI,
  updateIntegrationAPI,
} from "../../../../components/Mutation/Integration/IntegrationMutation";
import { handleDBData } from "../../utils/dataIntegration";
import APIForm from "./IntegrationForms/APIForm";
import { initEndpoint } from "../../utils/customStructures";

const APIIntegrationPanel = ({
  changeIntegrationPage,
  updatedData,
  updateList,
  integrations,
  type,
  // propertyType: authenticationType,
}) => {
  const { instance } = useMsal();

  const classes = useStyles(makeStyles);
  const steps = 2;

  const completedSteps = useRef(false);
  const location = useLocation();
  const queries = new URLSearchParams(location.search);
  const progress = queries.get("progress");

  const [step, setStep] = useState(1);
  const [activeId, setActiveId] = useState(null);
  const [availableResourcesList, setAvailableResourcesList] = useState([]);
  const [selectedResourcesList, setSelectedResourcesList] = useState([]);
  const [formCompleted, setFormCompleted] = useState(false);
  // const [disableDropDown, setDisableDropDown] = useState(false);
  // const [completedSteps, setCompletedSteps] = useState(false);
  const [loading, setLoading] = useState(false);
  const [overLookResourceSelectedList, setoverLookResourceSelectedList] =
    useState(true);

  const [fieldValues, setFieldValues] = useState({
    name: "",
    authenticationType: "",
    apiType: "",
    headerAuth: [],
    loginUsername: "",
    password: "",
    user: "",
    connectionString: "",
    url: "",
    token: "",
  });

  const [form2fieldValue, setForm2FieldValue] = useState([
    _.cloneDeep(initEndpoint),
  ]);

  const {
    integrationReducer: { editAccountFlag },
  } = useSelector((state) => state);

  useEffect(() => {
    if (progress === "proceed") {
      updateIntegrationAPISuccess({ data: null });
    }
  }, [progress]);

  const newIntAPISuccess = ({ data }) => {
    if (data?.data?.redirect) {
      const redirectUrl = data.data.redirect;
      window.location.href = redirectUrl;
      return;
    } else if (
      data?.data?.properties?.connectionCredentials?.authType === "OAuth2"
    ) {
      const authURLString =
        data?.data?.properties?.connectionCredentials?.authorizationURL;
      const clientIDString = `client_id=${fieldValues?.clientID}`;
      const promptString = "prompt=select_account consent";
      const accessTypeString = "access_type=offline";
      const responseTypeString = `response_type=code`;
      const stateString = `state=${JSON.stringify({
        platform: "oauth2",
        id: data?.data?.id,
        type: "custom",
        mode: "create",
      })}`;
      const redirectUrlString = `redirect_uri=${process.env.REACT_APP_ENDPOINT}/integrations/generate-tokens`;
      const scopeString = `scope=${data?.data?.properties?.connectionCredentials?.scopes?.join(
        " "
      )}`;

      const redirectUrl = `${authURLString}?${clientIDString}&${promptString}&${accessTypeString}&${responseTypeString}&${redirectUrlString}&${scopeString}&${stateString}`;

      window.location.href = redirectUrl;
      return;
    }

    if (step === 1 && !editAccountFlag) {
      updateList(data?.data);
      setActiveId(() => data?.data?.id);
      return setStep((prevStep) => prevStep + 1);
    }

    setLoading(false);
    return;
  };

  const updateIntegrationAPISuccess = ({ data }) => {
    !!data?._meta?.message && successToastify(data?._meta?.message);
    !!data?.data && updateList(data?.data);

    if (completedSteps.current) {
      // setCompletedSteps(() => false);
      completedSteps.current = true;
      setStep(() => 0);
      return;
    } else {
      setStep((prev) => prev + 1);
      // setCompletedSteps(() => false);
      completedSteps.current = false;
    }
  };

  /**
   * This function takes in an object with a data property, and returns an array of objects with name
   * and id properties.
   */
  const getIntegrationResourcesListAPISuccess = ({ data }) => {
    setAvailableResourcesList(() => data?.data || []);
  };

  // creating a new googel account integration
  const { mutate: newIntAPIMutate } = useCustomMutation({
    apiFunc: newIntegrationAPI,
    onSuccess: newIntAPISuccess,
    onError: () => {
      setLoading(false);
    },
    retries: 0,
  });

  // updating a google account integration
  const { mutate: updateIntegrationAPIMutate } = useCustomMutation({
    apiFunc: updateIntegrationAPI,
    onSuccess: updateIntegrationAPISuccess,
    onError: () => {
      setLoading(false);
    },
    retries: 0,
  });

  // fetching all user google sheet of the selected integrated google account
  useCustomQuery({
    apiFunc: getIntegrationResourcesListAPI,
    queryKey: ["getResources", { id: activeId }],
    enabled: step === 2 && true,
    onSuccess: getIntegrationResourcesListAPISuccess,
  });

  /* const getGoogleConsent = useGoogleLogin({
    onSuccess: (r) => {
      const data = handleDBData({
        type,
        propertyType: fieldValues?.authenticationType,
        fieldValues,
      });

      data.properties.connectionCredentials = {
        ...data.properties.connectionCredentials,
        scopes: r.scope?.split(" "),
        accessToken: r.access_token,
        expiresOn: r.expiresOn, //  this currently returns interval, in seconds
        tokenType: r.token_type,
        userInfo: {
          uniqueId: r.uniqueId, //  not returned
          email: r.account?.username, //  not returned
          name: r.account?.name, //  not returned
        },
      };

      newIntAPIMutate({
        data,
      });
    },
    onFailure: () => {
      setLoading(false);
      alert("BAD");
    },
    // clientId: process.env.REACT_APP_GOOGLE_CLIENTID,
    // isSignedIn: false,
    accessType: "offline",
    cookiePolicy: "single_host_origin",
    scope: [
      ...(fieldValues.googleScopes || []),
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
    ],
    // ?.map((scope) => `https://www.googleapis.com/auth/${scope}`)
    // ?.join(" "), // "https://www.googleapis.com/auth/admin.directory.user",
    prompt: "consent",
    select_account: true,
    // flow: "auth-code",
  }); */

  /* const getMicrosoftConsent = async (data) => {
    const result = await instance
      .loginPopup({
        ...consentRequest,
        scopes: fieldValues.microsoftScopes,
        redirectUri: `${process.env.REACT_APP_BASE_URL}/integrations`,
      })
      .then((r) => {
        // console.log(`***** >> ${JSON.stringify(r)}`);
        data.properties.connectionCredentials = {
          ...data.properties.connectionCredentials,
          authority: r.authority,
          tenantId: r.tenantId,
          scopes: r.scopes,
          accessToken: r.accessToken,
          expiresOn: r.expiresOn,
          tokenType: r.tokenType,
          userInfo: {
            uniqueId: r.uniqueId,
            email: r.account.username,
            name: r.account.name,
          },
        };

        newIntAPIMutate({
          data,
        });
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
    console.log(`resulttttt => ${JSON.stringify(result)}`);
    // instance
    //   .acquireTokenPopup({
    //     ...consentRequest,
    //     scopes: [...fieldValues.microsoftScopes, "offline_access"],
    //     redirectUri: `${process.env.REACT_APP_BASE_URL}/integrations`,
    //   })
    //   .then((tokenResponse) => {
    //     console.log(`tokenResponse >> ${JSON.stringify(tokenResponse)}`);
    //     return tokenResponse;
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  }; */

  /**
   * If the step is 1 and the editAccountFlag is false, then create a new integration, otherwise if the
   * step is 1 and the editAccountFlag is true, then update the selected integration, otherwise if the
   * step is equal to the steps, then update the integration.
   * @returns Nothing.
   */
  const progressStep = async (e) => {
    /* Preventing the default action of the event. */
    !!e.target && e.preventDefault();
    setLoading(true);

    if (e === 0) {
      setStep(0);
      setLoading(false);
    } else if (step === 1 && !editAccountFlag) {
      // create new integration

      if (fieldValues?.authenticationType === "GoogleAuth") {
        // getGoogleConsent();
        // data.scopes = [
        //   ...(fieldValues?.googleScopes || []),
        //   "https://www.googleapis.com/auth/userinfo.profile",
        //   "https://www.googleapis.com/auth/userinfo.email",
        // ].join(" ")
      } else if (fieldValues?.authenticationType === "MicrosoftAuth") {
        // getMicrosoftConsent(data);
        // data.scopes = (fieldValues?.microsoftScopes || [])?.join(" ");
      }

      const data = handleDBData({
        type,
        propertyType: fieldValues?.authenticationType,
        fieldValues,
      });

      /* newIntAPIMutate({
        data,
      }); */
      /* const redir = `${
        data.properties.connectionCredentials.authorizationURL
      }?client_id=${
        data.properties.connectionCredentials.clientID
      }&access_type=offline&prompt=select_account&response_type=token&redirect_uri=${"https://7459-102-216-32-7.ngrok-free.app/api/v1/integrations/redirect/oauth2"}&scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email`;
      console.log(`--> redir >> ${JSON.stringify(redir)}`);

      window.location.href = redir;
      // access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&prompt=consent&state=%7B"platform"%3A"google"%2C"type"%3A"Google%20Drive"%2C"user"%3A"65671054cca3a2af64c12dd9"%2C"account"%3A"615ae60a3b0d9011ce1aecc9"%2C"name"%3A"fhgh"%2C

      console.log(
        `=========>>> ${JSON.stringify(fieldValues?.microsoftScopes)}`
      );
      return; */
      // return setStep((prevStep) => prevStep + 1);
      newIntAPIMutate({
        data,
      });
    } else if (step === 1 && editAccountFlag) {
      // update selected integration
      const data = handleDBData({
        type,
        propertyType: fieldValues?.authenticationType,
        fieldValues,
      });

      updateIntegrationAPIMutate({ id: activeId, data });
      setLoading(false);
    } else if (steps === step) {
      const data = handleDBData({
        type,
        propertyType: fieldValues?.authenticationType,
        form2fieldValue,
        fieldValues,
      });

      completedSteps.current = true;
      updateIntegrationAPIMutate({ id: activeId, data });
    }

    return;
  };

  /* Checking if the step is 0, if it is, it will change the integration page to an empty string. */
  useEffect(() => {
    if (step === 0) {
      changeIntegrationPage("");
    }
  }, [step, changeIntegrationPage]);

  useEffect(() => {
    if (updatedData?.properties?.resources.length > 0) {
      setForm2FieldValue(() => updatedData?.properties?.resources);
    } else {
      setForm2FieldValue(() => [_.cloneDeep(initEndpoint)]);
    }

    if (updatedData) {
      /* setFieldValues({
        name: updatedData?.name,
        authenticationType: updatedData?.properties?.connectionCredentials?.authenticationType,
        apiType: updatedData?.properties?.,
        headerAuth: updatedData?.properties?.connectionCredentials?.,
        loginUsername: updatedData?.properties?.connectionCredentials?.,
        password: updatedData?.properties?.connectionCredentials?.,
        user: updatedData?.properties?.connectionCredentials?.,
        connectionString: updatedData?.properties?.connectionCredentials?.,
        url: updatedData?.properties?.connectionCredentials?.,
        token: updatedData?.properties?.connectionCredentials?.,
        scope: updatedData?.properties?.connectionCredentials?.scopes,
      }); */

      // console.log(">>>> updatedData", updatedData);
      setActiveId(updatedData?.id);
    }
    setSelectedResourcesList(() => updatedData?.properties?.resources);
  }, [updatedData, integrations, editAccountFlag]);

  return (
    <PanelBody
      title="REST API"
      mode={!!updatedData ? "Update" : "New"}
      step={step}
      setStep={progressStep}
      steps={steps}
      isResourceSelected={!!selectedResourcesList?.length}
      overLookResourceSelectedList={overLookResourceSelectedList}
      formCompleted={formCompleted}
      loading={loading}
    >
      <div className={classes.sideDialogMain}>
        <APIForm
          classes={classes}
          step={step}
          setFormCompleted={setFormCompleted}
          type={"RestApiIntegration"}
          integrations={integrations}
          availableResourcesList={availableResourcesList}
          selectedResourcesList={selectedResourcesList}
          // updateSelectedList={updateSelectedList}
          updatedData={updatedData}
          fieldValues={fieldValues}
          steps={steps}
          setFieldValues={setFieldValues}
          form2fieldValue={form2fieldValue}
          setForm2FieldValue={setForm2FieldValue}
        />
      </div>
    </PanelBody>
  );
};

export default APIIntegrationPanel;
