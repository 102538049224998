import React from "react";
import SidebarFieldPreferenceSection from "../components/SidebarFieldPreferenceSection";
import SidebarLabelPreferenceSection from "../components/SidebarLabelPreferenceSection";
import SidebarNameSection from "../components/SidebarNameSection";

export default function FileUploadSidebar(props) {
  const {
    style,
    values,
    name,
    title,
    id,
    type: itemType,
    index,
    containingChildIndex,
    isInsideContainer,
  } = props;

  const { selectedLayout } = props;

  const labelLayout = [
    ["filled", "filed"],
    ["outline", "outline"],
  ];

  return (
    <div className="sidebar-container">
      <SidebarNameSection
        itemId={id}
        itemType={itemType}
        name={name}
        title={title}
        index={index}
        isInsideContainer={isInsideContainer}
        containingChildIndex={containingChildIndex}
      />
      <div className="sidebar-container-scroll">
        <SidebarFieldPreferenceSection
          itemType={itemType}
          name={name}
          title={title}
          index={index}
          isInsideContainer={isInsideContainer}
          containingChildIndex={containingChildIndex}
          values={{ ...values }}
        />
        <SidebarLabelPreferenceSection
          itemType={itemType}
          name={name}
          title={title}
          index={index}
          isInsideContainer={isInsideContainer}
          containingChildIndex={containingChildIndex}
          values={{ ...values }}
        />
      </div>
    </div>
  );
}
