import { v4 } from "uuid";
import {
  UIEDITOR_SET_DROPZONE,
  UIEDITOR_LOAD_CANVAS_ITEMS,
  UIEDITOR_LOAD_SCREEN_STYLE,
  UIEDITOR_SET_CANVAS_ITEMS,
  UIEDITOR_LOAD_CUSTOM_ELEMENTS,
  UIEDITOR_REGISTER_ELEMENTS,
  UIEDITOR_DE_REGISTER_ELEMENTS,
  UIEDITOR_SET_ZOOM_LEVEL,
  UIEDITOR_SET_ACTIVE_COMPONENT,
  DISPLAY_TABLE_COLUMN,
  // Component Styles
  UIEDITOR_COMPONENT_STYLE_CHANGE,
  UIEDITOR_TOGGLE_FULLSCREEN,
} from "../actions/actionTypes";
import { manageAppLocalStorage } from "../../helpers/helperFunctions";

const currentScreenStyles = manageAppLocalStorage("get", null, "screenStyles");

export const uieditor = (
  state = {
    canvasActivity: {},
    activityHistory: [],
    undoMode: false,
    undoCursor: -1,
    uiEditorFullScreen: false,
    screenStyles: currentScreenStyles || {},
    displayTableColumn: false,
    dropZone: {},
    canvasItems: [],
    droppedPos: { id: null, pos: null },

    zoomLevel: 100,
    componentsSelection: { activeComponents: {}, mode: "none" },
  },
  action
) => {
  //console.log(`uieditor::: action.type::: ${action.type} ::: ${JSON.stringify(action.payload)}`);
  switch (action.type) {
    case UIEDITOR_SET_DROPZONE: {
      const dropZone = action.payload;
      return {
        ...state,
        dropZone,
      };
    }

    case UIEDITOR_LOAD_CANVAS_ITEMS: {
      const canvasItems = action.payload;
      return {
        ...state,
        canvasItems,
      };
    }

    case UIEDITOR_LOAD_SCREEN_STYLE: {
      const style = action.payload;
      return {
        ...state,
        screenStyles: style,
      };
    }

    case UIEDITOR_REGISTER_ELEMENTS: {
      const { undoMode, undoCursor, canvasActivity, activityHistory } =
        action.payload.pyld;
      return {
        ...state,
        undoMode,
        undoCursor,
        activityHistory,
        canvasActivity,
      };
    }

    case UIEDITOR_DE_REGISTER_ELEMENTS: {
      const canvasItems = action.payload;
      return {
        ...state,
        canvasItems,
      };
    }

    case UIEDITOR_SET_CANVAS_ITEMS: {
      console.log();
      const { canvasItems, droppedPos } = action.payload;
      return {
        ...state,
        canvasItems,
        droppedPos,
      };
    }

    case UIEDITOR_LOAD_CUSTOM_ELEMENTS: {
      const customElements = action.payload;
      return {
        ...state,
        customElements,
      };
    }

    case UIEDITOR_SET_ZOOM_LEVEL: {
      const zoomLevel = action.payload;
      return {
        ...state,
        zoomLevel,
      };
    }

    case UIEDITOR_SET_ACTIVE_COMPONENT: {
      const { componentsSelection } = action.payload;
      return {
        ...state,
        componentsSelection,
      };
    }

    case UIEDITOR_COMPONENT_STYLE_CHANGE: {
      const canvasItems = action.payload;
      return {
        ...state,
        canvasItems,
      };
    }

    case UIEDITOR_TOGGLE_FULLSCREEN: {
      const uiEditorFullScreen = action.payload;
      return {
        ...state,
        uiEditorFullScreen,
      };
    }

    case DISPLAY_TABLE_COLUMN: {
      return {
        ...state,
        displayTableColumn: action?.payload,
      };
    }

    default:
      return state;
  }
};
