import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Select from "../Selector";
import ColorPicker from "../ColorPicker";
import InputBase from "@material-ui/core/InputBase";
import { connect } from "react-redux";

const InputText = withStyles((theme) => ({
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 11,
    padding: "5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {},

  section: {
    padding: 10,
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
  },
  sectionLabel: {
    color: "#999",
    lineHeight: 3,
    fontSize: 10,
    marginRight: 15,
  },
  fullWidthText: {
    marginBottom: 10,
  },
  input: {
    color: "#091540",
    fontSize: 10,
  },
  center: {
    textAlign: "center",
  },
  box: {
    flex: 1,
  },
}));

function SimplePaper(props) {
  const classes = useStyles();
  const {
    labelHide,
    selectedSize,
    selectedWeight,
    selectedAlign,
    textColorCallback,
    fontSize,
    fontWeight,
    textAlign,
    color
  } = props;

  const labelSize = [
    ["12px", "12px"],
    ["14px", "14px"],
    ["16px", "16px"],
  ];

  const labelWeight = [
    ["light", "Light"],
    ["regular", "Regular"],
    ["bold", "Bold"],
  ];

  const labelAlignment = [
    ["left", "Left"],
    ["center", "Center"],
    ["right", "Right"],
    ["justify", "Justify"],
  ];

  return (
    <div>
      {labelHide ? (
        ""
      ) : (
        <div style={{ display: "flex", justifyContent: "space-between", }} className={classes.section} >
          <Select
            items={labelSize}
            key="labelSize"
            itemType={"labelSize"}
            selectedSize={selectedSize}
            selectedValue={fontSize}
          />
          <Select
            items={labelWeight}
            key="labelWeight"
            itemType={"labelWeight"}
            selectedWeight={selectedWeight}
            width="40%"
            selectedValue={fontWeight}
          />
          <Select
            items={labelAlignment}
            key="labelAlignment"
            itemType={"labelAlignment"}
            selectedAlign={selectedAlign}
            selectedValue={textAlign}
          />
          <ColorPicker
            identity="textColor"
            textColorCallback={textColorCallback}
            className={classes.box}
            color={color}
          />
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    hideLabel: state.reducers.hide_label,
  };
}

export default connect(mapStateToProps)(SimplePaper);
