import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import EditorLayoutPanel from "./EditorLayoutPanel";
import { allElements as uieditorTileElements } from "../Pages/UIEditor/utils/elementsList";
import { allElements as workflowTileElements } from "../Pages/Workflow/utils/elementsList";
import { panelData } from "../utils/editorLayoutPanelData";

import PanelActions from "../utils/PanelActions";

const EditorToolbars = (props) => {
  const location = useLocation();

  const drawerWidth =
    (props.drawerOpen ? 140 : 50) -
    (props.page === "data" ||
    (props.page === "uieditor" && props.uiEditorFullScreen)
      ? 235
      : props.page === "data" ||
        (props.page === "workflows" && props.workflowFullScreen)
      ? 235
      : 0);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      width: 240,
      // height: "100%",
      // backgroundColor: "yellow",
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      paddingTop: theme.spacing(3),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: 240, // drawerWidth,
        flexShrink: 0,
      },
    },
  }));
  const classes = useStyles();
  const [components, setcomponents] = useState([]);
  const [editorPage, setEditorPage] = useState(null);

  useEffect(() => {
    const PanelActionsView = new PanelActions();

    const displayEditorComponent = () => {
      const editor = location.pathname.split("/")[3];
      setEditorPage(editor);

      switch (editor) {
        case "uieditor":
          setcomponents(
            <EditorLayoutPanel
              page={editor}
              panelData={panelData(editor)}
              panelType="accordion"
              buttonCallback={props.callBackFromEditor}
              elements={uieditorTileElements}
            />
          );
          break;
        case "screens":
          // setcomponents(<ScreensPanel />);
          setcomponents(
            <EditorLayoutPanel
              page={editor}
              panelData={panelData(editor)}
              panelActions={PanelActionsView}
              panelType="list"
            />
          );
          // figma page 76
          break;
        case "workflows":
          setcomponents(
            /* props.workflowShowTasks ? (
              <EditorLayoutPanel
                page={editor}
                panelData={panelData(editor)}
                panelType="accordion"
                buttonCallback={props.callBackFromEditor}
                elements={workflowTileElements}
              />
            ) : */ <EditorLayoutPanel
              page={editor}
              panelData={panelData(editor)}
              panelType="list"
              panelActions={PanelActionsView}
            />
          );
          // figma page 76
          break;

        default:
          break;
      }
    };
    displayEditorComponent();

    return () => {
      displayEditorComponent();
    };
    // }, [location.pathname, props.callBackFromEditor, props.workflowShowTasks]);
  }, [location.pathname, props.callBackFromEditor]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Grid
          container
          style={{
            height: "100%",
          }}
        >
          {(editorPage !== "uieditor" || !props.uiEditorFullScreen) &&
            (editorPage !== "workflows" || !props.workflowFullScreen) &&
            editorPage !== "data" && (
              <div
                style={{
                  flex: 1,
                  backgroundColor: "transparent",
                  padding: "15px 11px 0",
                  marginLeft: 0, //props.drawerOpen ? 140 : 50,
                  position: "fixed",
                  height: "100%",
                  width: 240,
                  borderRight: "solid 0.5px #B8B8B8",
                  zIndex: 100,
                }}
              >
                {components}
                {/* <div style={{ position: "absolute", bottom: 0, right: 0, padding: 10, transform: "scaleX(-1)", cursor: "pointer" }}><DoubleArrow /></div> */}
              </div>
            )}
        </Grid>
      </nav>
    </div>
  );
};

EditorToolbars.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default connect((state) => {
  return {
    uiEditorFullScreen: state.uieditor.uiEditorFullScreen,
    workflowShowTasks: state.workflows.showTasks,
    workflowFullScreen: state.workflows.workflowFullScreen,
  };
})(withRouter(EditorToolbars));
