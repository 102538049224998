import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, InputBase } from "@material-ui/core";
import { v4 } from "uuid";
import validate from "validate.js";
import Required from "../Required";
import { APP_DESIGN_MODES } from "../../../../../../../utils/constants";
import { emptyEmailSchema } from "../../../../../../ForgotPassword/components/InputEmail/emailSchema";
import { searchAndReplace } from "../../../../../../../utils/dynamicContentReplace";

const InputText = ({
  style,
  onChange,
  appSequence,
  values,
  dataType,
  disabled: isDisabled,
  readOnly,
  dynamicData,
  screenId,
  taskId,
  returnedLookupObj,
  workflowInstanceId,
  ...props
}) => {
  const isAutoNumber = dataType === "autoNumber";
  const autoSubmitValueRef = useRef(false);

  const [autoDefault, setAutoDefault] = useState("");
  const [trackLookupVal, setTrackLookupVal] = useState(null);
  const [renderKey, setRenderKey] = useState(v4());
  const { autoNumConfig } = values;
  const dynamicContentObj = dynamicData?.[screenId];
  const useStyles = makeStyles((theme) => ({
    ...style,
    dimensions: {
      ...style?.dimensions,
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
  }));
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const classes = useStyles();
  const inputProps = {};
  if (values?.limitCharacter) {
    inputProps.minLength = values?.min;
    inputProps.maxLength = values?.max;
  }

  useEffect(() => {
    if (returnedLookupObj?.[props?.id]) {
      setTrackLookupVal(returnedLookupObj?.[props?.id]);
      onChange(returnedLookupObj?.[props?.id], props?.id);
      setRenderKey(v4());
    }
  }, [returnedLookupObj, props?.id]);

  useEffect(() => {
    if (isAutoNumber) {
      onChange(appSequence?.currentSequence, props.id || props.name);
      setRenderKey(v4());
    }
  }, [dataType]);

  useEffect(() => {
    if (autoNumConfig?.autoValue?.pre && autoNumConfig?.autoValue?.seqL)
      setAutoDefault(
        autoNumConfig?.autoValue?.pre ?? "" + autoNumConfig?.autoValue?.seqL
      );
  }, [autoNumConfig]);

  useEffect(() => {
    const errors = validate(formState.values, emptyEmailSchema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const textContent = (elem) => {
    if (!elem) {
      return "";
    }
    if (typeof elem === "string") {
      return elem;
    }

    const children = elem.props && elem.props.children;
    if (children instanceof Array) {
      return children.map(textContent).join("");
    }

    return textContent(children);
  };
  const doIsDynamicContent = () => {
    return (
      props?.val ||
      textContent(searchAndReplace(props.name, dynamicContentObj, "field"))
    );
  };

  const displayValue = () => {
    if (props?.appDesignMode === APP_DESIGN_MODES.LIVE) {
      if (isAutoNumber) {
        return appSequence?.currentSequence ?? "";
      } else {
        const isDynamicContent = doIsDynamicContent();
        if (isDynamicContent && !autoSubmitValueRef.current) {
          handleChange(
            { target: { value: isDynamicContent }, persist: () => {} },
            props.id
          );
          autoSubmitValueRef.current = isDynamicContent;
        }
        return isDynamicContent;
      }
    } else if (props?.appDesignMode === APP_DESIGN_MODES.PREVIEW) {
      return values?.value ?? "";
    }
  };
  const handleChange = (e) => {
    e.persist();

    if (dataType === "email") {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          ["email"]: e.target.value,
        },
        touched: {
          ...formState.touched,
          ["email"]: true,
        },
      }));
      !hasError("email") && onChange(e.target.value, props.id);
    } else {
      onChange(e.target.value, props.id);
    }
  };
  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div style={{ width: style?.field?.width }}>
      {!values?.labelHide && (
        <Typography gutterBottom className={classes?.label}>
          {values?.label}
          <Required required={values?.required && !isDisabled} />
        </Typography>
      )}
      <InputBase
        key={renderKey}
        name={values?.name}
        size="small"
        // inputProps={inputProps}
        inputProps={{
          ...inputProps,
          style: {
            // ...style?.field,
            color: style?.field?.color,
            fontSize: style?.field?.fontSize,
            InputtextAlign: style?.field?.InputtextAlign,
            fontWeight: style?.field?.fontWeight || 400,
            // height: style?.field?.height,
            borderStyle: "solid",
            borderWidth: 0,
          },
        }}
        className={`${classes?.dimensions_} ${classes?.field} ${
          values?.editable || autoNumConfig?.readOnly || readOnly
            ? "read-only"
            : ""
        }`}
        style={{
          padding: "0 10px",
          background: autoNumConfig?.background,
        }}
        placeholder={values?.placeholder}
        type={dataType?.toLowerCase()}
        variant="outlined"
        defaultValue={displayValue() || autoDefault || trackLookupVal}
        required={values?.required && !isDisabled}
        // disabled={props.appDesignMode === APP_DESIGN_MODES.EDIT || props.disabled}
        disabled={isDisabled || props?.name?.startsWith("@")}
        readOnly={values?.editable || autoNumConfig?.readOnly || readOnly}
        onChange={(e) => handleChange(e)}
      />
      {hasError("email") && (
        <p style={{ color: "red", marginTop: 5, fontSize: 10 }}>
          {formState.errors.email[0]}
        </p>
      )}
    </div>
  );
};

InputText.propTypes = {
  onChange: PropTypes.func,
  style: PropTypes.shape({
    dimensions: PropTypes.shape({
      height: PropTypes.any,
      width: PropTypes.any,
    }),
    appDesignMode: PropTypes.string,
    values: PropTypes.shape({
      label: PropTypes.any,
      labelHide: PropTypes.any,
      limitCharacter: PropTypes.any,
      max: PropTypes.any,
      min: PropTypes.any,
      name: PropTypes.any,
      placeholder: PropTypes.any,
      required: PropTypes.any,
      type: PropTypes.shape({
        toLowerCase: PropTypes.func,
      }),
    }),
  }),
};

InputText.defaultProps = {
  onChange: () => {},
};
export default InputText;
