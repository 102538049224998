import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ImMenu } from "react-icons/im";
import { IconButton } from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import { connect, useDispatch, useSelector } from "react-redux";
import IconLabelButtons from "./actualObjects/ActionButtons/ActionButtons";
import { rHideRightSideBar } from "../../../../../store/actions/properties";
import { APP_DESIGN_MODES } from "../../../../../utils/constants";

const useStyles = makeStyles((theme) => ({
  iconContainerForm: {
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    marginBottom: 2,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    marginBottom: 2,
    position: "absolute",
    // zIndex: 1,
  },
  iconWrap: {
    background: "#010A43",
    display: "flex",
  },
  icon: {
    color: "#FFF",
    padding: 5,
  },
}));

const ElementComponentWrapper = ({
  name,
  children,
  appDesignMode,
  parent,
  onDuplicate,
  onDelete,
  onClick,
  id,
  dragHandleProps,
  style,
  title,
  ...props
}) => {
  const [showIcons, setShowIcons] = useState(false);
  const isActiveComponent =
    props?.componentsSelection?.activeComponents?.id?.includes(id);

  const classes = useStyles();
  return (
    <React.Fragment>
      <Component
        appDesignMode={appDesignMode}
        isActiveComponent={isActiveComponent}
        showIcons={showIcons}
        setShowIcons={setShowIcons}
        type={props.type}
        classes={classes}
        children={children}
        onDuplicate={onDuplicate}
        onDelete={onDelete}
        onClick={onClick}
        parent={parent}
        id={id}
        style={style}
        dragHandleProps={dragHandleProps}
      />
      <FormContainer
        appDesignMode={appDesignMode}
        name={name}
        title={title}
        isActiveComponent={isActiveComponent}
        showIcons={showIcons}
        setShowIcons={setShowIcons}
        type={props.type}
        classes={classes}
        children={children}
        onDuplicate={onDuplicate}
        onDelete={onDelete}
        onClick={onClick}
        id={id}
        style={style}
        dragHandleProps={dragHandleProps}
      />
    </React.Fragment>
  );
};

const Component = ({
  parent,
  appDesignMode,
  setShowIcons,
  type,
  isActiveComponent,
  showIcons,
  children,
  onDuplicate,
  onDelete,
  onClick,
  id,
  dragHandleProps,
  classes,
  style,
  ...props
}) => {
  const { hiddenRightSideBar } = useSelector(({ reducers }) => reducers);
  const dispatch = useDispatch();

  if (type === "form") return null;

  if (type === "ScreenButtons")
    return (
      <React.Fragment>
        <div onClick={onClick}>{children}</div>
      </React.Fragment>
    );

  const _toggleRightSideBar = () => {
    if (!hiddenRightSideBar) return;
    dispatch(rHideRightSideBar(!hiddenRightSideBar));
  };

  return (
    <React.Fragment>
      {appDesignMode === APP_DESIGN_MODES.EDIT && (
        <div className={classes?.iconContainer}>
          <IconLabelButtons
            isActiveComponent={isActiveComponent}
            onDuplicate={onDuplicate}
            onDelete={onDelete}
            visible={isActiveComponent}
          />
        </div>
      )}
      <div
        {...dragHandleProps}
        onMouseEnter={() => setShowIcons(true)}
        onMouseLeave={() => setShowIcons(false)}
        onClick={onClick}
        onDoubleClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          _toggleRightSideBar();
        }}
      >
        <div
          style={
            appDesignMode === APP_DESIGN_MODES.EDIT
              ? {
                  border:
                    showIcons || isActiveComponent
                      ? "dashed 1px #008AFF"
                      : "dashed 1px transparent",
                }
              : // : { marginTop: 15 }
                {
                  // marginBottom: style?.form?.spacing || 15,
                }
          }
        >
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

const FormContainer = ({
  name,
  appDesignMode,
  setShowIcons,
  type,
  isActiveComponent,
  showIcons,
  children,
  onDuplicate,
  onDelete,
  onClick,
  id,
  dragHandleProps,
  classes,
  style,
  title,
  ...props
}) => {
  const dispatch = useDispatch();
  const { hiddenRightSideBar } = useSelector(({ reducers }) => reducers);

  if (type !== "form") return null;

  const _toggleRightSideBar = () => {
    if (!hiddenRightSideBar) return;
    dispatch(rHideRightSideBar(!hiddenRightSideBar));
  };

  return (
    <React.Fragment>
      <div
        onClick={onClick}
        onDoubleClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onClick(e);
          _toggleRightSideBar();
        }}
        className={classes?.iconContainerForm}
        style={{ justifyContent: "space-between" }}
      >
        <span
          {...dragHandleProps}
          style={{
            fontWeight: 200,
            fontSize: 13,
            paddingTop: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ImMenu fontSize={30} />
          <span style={{ marginLeft: 10, marginTop: 4, fontWeight: 500 }}>
            {title}:
          </span>
          <span style={{ marginLeft: 5, marginTop: 4, fontWeight: 500 }}>
            Double click here to add components
          </span>
          <span style={{ marginLeft: 5, marginTop: 8 }}>
            <AddCircle style={{ cursor: "pointer" }} />
          </span>
        </span>
        <IconLabelButtons
          isActiveComponent={isActiveComponent}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
          visible={isActiveComponent}
        />
      </div>
      <div
        // {...dragHandleProps}
        onMouseEnter={() => setShowIcons(true)}
        onMouseLeave={() => setShowIcons(false)}
      >
        <div
          style={{
            border:
              showIcons || isActiveComponent
                ? "dashed 1px #008AFF"
                : "dashed 1px transparent",
          }}
        >
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

ElementComponentWrapper.propTypes = {
  children: PropTypes.node,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.any,
  type: PropTypes.string,
  parent: PropTypes.string,
  appDesignMode: PropTypes.string,
};

ElementComponentWrapper.defaultProps = {
  type: "component",
  parent: null,
  appDesignMode: "",
  name: "",
};

export default connect((state) => {
  return {
    componentsSelection: state.uieditor.componentsSelection,
  };
})(ElementComponentWrapper);
