import { CustomAxios } from "../../common/CustomAxios";

export const getWorkflowInstance = async ({ page, id, perPage }) => {
  const per_page = perPage ?? 10;
  try {
    const populationQuery = [
      {
        path: "approvalHistory",
        populate: "task",
      },
      {
        path: "app",
        populate: {
          path: "category",
          select: "name",
        },
        select: "name",
      },
      {
        path: "user",
        select: "firstName lastName",
      },
      {
        path: "task",
        select: "name type",
      },
    ];

    const populationQueryString = JSON.stringify(populationQuery);

    const { data } = await CustomAxios().get(
      `${process.env.REACT_APP_ENDPOINT}/workflow-instances/?population=${populationQueryString}&page=${page}&per_page=${per_page}`
    );
    return { success: true, data };
  } catch (error) {
    return { success: false, data: "Error getting screens" };
  }
};

export const getflowInstanceStatus = async () => {
  try {
    const { data } = await CustomAxios().get(
      `${process.env.REACT_APP_ENDPOINT}/workflow-instances/analytics/status`
    );
    return data;
  } catch (error) {
    return { success: false, data: "Error getting screens" };
  }
};

export const filterWorkFlowInstance = async ({
  page,
  status,
  category,
  appName,
  perPage,
}) => {
  const per_page = perPage ?? 10;
  const _status = status && status !== "All" ? `status=${status}&` : "";
  const _category =
    category && category !== "All" ? `app.category=${category}&` : "";
  const _appName = appName ? `app.name=${appName}&` : "";
  try {
    const populationQuery = [
      {
        path: "approvalHistory",
        populate: "task",
      },
      {
        path: "app",
        populate: {
          path: "category",
          select: "name",
        },
        select: "name",
      },
      {
        path: "user",
        select: "firstName lastName",
      },
      {
        path: "task",
        select: "name type",
      },
    ];

    const populationQueryString = JSON.stringify(populationQuery);

    const { data } = await CustomAxios().get(
      `${process.env.REACT_APP_ENDPOINT}/workflow-instances?${_status}population=${populationQueryString}&${_category}${_appName}per_page=${per_page}&page=${page}`
    );
    return { success: true, data };
  } catch (error) {
    return { success: false, data: "Error getting screens" };
  }
};
export const getSingleWorkflowInstance = async ({ id }) => {
  try {
    const populationQuery = [
      {
        path: "taskStatus",
        populate: {
          path: "task",
          select: "name type",
        },
        select: "type status updatedAt",
      },
      {
        path: "approvalHistory",
        populate: {
          path: "user",
          select: "firstName lastName",
        },
      },
      // "workflow",
      {
        path: "workflow",
        select: "name tasks",
      },
      {
        path: "user",
        select: "firstName lastName",
      },
      {
        path: "app",
        select: "name description",
        populate: {
          path: "category",
          select: "name",
        },
      },
      {
        path: "task",
        select: "name type assignedTo",
      },
    ];

    const populationQueryString = JSON.stringify(populationQuery);

    const { data } = await CustomAxios().get(
      `${process.env.REACT_APP_ENDPOINT}/workflow-instances/${id}?population=${populationQueryString}`
    );
    return { success: true, data };
  } catch (error) {
    return { success: false, data: "Error getting screens" };
  }
};

export const reminderMail = async (data) => {
  const { taskId, workflowInstanceId } = data;
  try {
    const results = await CustomAxios().post(
      `${process.env.REACT_APP_ENDPOINT}/tasks/send-reminder-mail`,
      { taskId, workflowInstanceId }
    );
    return results;
  } catch (error) {
    return { error, msg: "Error sending mails" };
  }
};

export const ressignTask = async (data) => {
  const { taskId, workflowInstanceId } = data;
  try {
    const results = await CustomAxios().post(
      `${process.env.REACT_APP_ENDPOINT}/tasks/reassign`,
      data
    );
    return results;
  } catch (error) {
    return { error, msg: "Error sending mails" };
  }
};
