import React, { useState } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// fake data generator
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}-${new Date().getTime()}`,
    content: `item ${k + offset}`,
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const insert = (source, destination, droppableSource, droppableDestination) => {
  const destClone = Array.from(destination);
  const inserted = source[droppableSource.index];

  destClone.splice(droppableDestination.index, 0, inserted);

  const result = {};
  const drop2 = +droppableDestination.droppableId.split("-")[2];
  result[drop2] = destClone;

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  const drop1 = +droppableSource.droppableId.split("-")[0];
  const drop2 = +droppableDestination.droppableId.split("-")[0];
  result[drop1] = sourceClone;
  result[drop2] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 500,
});

const Playground = () => {
  const [state, setState] = useState([[[]], [[]]]);
  const [isInserting, setIsInserting] = useState(false);
  const [isShifting, setIsShifting] = useState(false);
  const [isRearranging, setIsRearranging] = useState(false);
  const [draggingFrom, setDraggingFrom] = useState(null);

  const elements = [{ id: 1 }, { id: 2 }, { id: 3 }];

  const onDragStart = (result) => {
    const level = result?.source?.droppableId.split("-")[0];

    if (result?.source?.droppableId === "12345") {
      setIsInserting(true);
    } else if (level === "plevel1") {
      setIsShifting(true);
    } else {
      setIsRearranging(true);
      setDraggingFrom(result?.source?.droppableId);
    }
  };

  const onDragEnd = (result) => {
    setIsInserting(false);
    setIsShifting(false);
    setIsRearranging(false);
    setDraggingFrom(null);

    const { source, destination } = result;

    if (source.droppableId === "12345") {
      onInsert(result);
      return;
    }

    if (
      source.droppableId === "my-droppable-id" &&
      destination.droppableId === "my-droppable-id"
    ) {
      onShift(result);
      return;
    }

    // dropped outside the list

    if (!destination) {
      return;
    }

    const [sTag, sColumn, sRow] = source.droppableId.split("-");
    const [dTag, dColumn, dRow] = destination.droppableId.split("-");

    const sInd = +source.droppableId.split("-")[0];
    const dInd = +destination.droppableId.split("-")[0];

    if (sColumn !== dColumn) {
      // delete from source
      const newState = [...state];
      const article = newState[sColumn][sRow].splice(source.index, 1)[0];

      // append to destination
      newState[dColumn][dRow].splice(destination.index, 0, article);

      finalizeState([sColumn, dColumn], newState);
      return;
    }

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      // setState(newState.filter((group) => group.length));
      // finalizeState(newState);
      setState(newState);
    }
  };

  const onInsert = (data) => {
    const { source, destination } = data;
    console.log(`-----onInsert >> ${JSON.stringify(data)}`);

    // dropped outside the list
    if (!destination || destination.droppableId === "12345") {
      return;
    }

    // const sInd = +source.droppableId.split("-");
    const dFlow = destination.droppableId.split("-");
    const dpInd = +dFlow[1];
    const dInd = +dFlow[2];

    const payload = insert(elements, state[dpInd][dInd], source, destination);
    const newState = [...state];
    newState[dpInd][dInd] = payload[dInd];

    finalizeState([dpInd], newState);
    // setState(newState);
  };

  const onShift = (data) => {
    const { source, destination } = data;

    const newState = [...state];

    console.log(`-----shift >> ${JSON.stringify(data)}`);

    const fromIndex = source.index;
    const toIndex = destination.index;

    const from = newState.splice(fromIndex, 1)[0];
    newState.splice(toIndex, 0, from);

    // finalizeState(newState);
    setState(newState);
  };

  const finalizeState = (columns, incomingState) => {
    const newState = [...incomingState];

    columns.forEach((column) => {
      const workingState = [...newState][column];
      let formerState = null;

      console.log(`A ----> workingState >> ${JSON.stringify(workingState)}`);
      for (let index = 0; index < workingState.length; index++) {
        console.log(
          `| ----> index >> ${JSON.stringify(index)} | ${workingState.length}`
        );
        const eachState = workingState[index];

        if (!eachState?.length) {
          if (formerState) {
            if (!formerState?.length) {
              workingState.splice(index, 1);
              index--;
            }
          }
        } else {
          if (formerState) {
            if (formerState?.length) {
              workingState.splice(index, 0, []);
              index++;
            }
          } else {
            workingState.splice(index, 0, []);
            index++;
          }
        }
        formerState = workingState[index];
      }
      console.log(`B ----> workingState >> ${JSON.stringify(workingState)}`);

      if (workingState[workingState.length - 1].length) {
        workingState.push([]);
      }
      console.log(`C ----> workingState >> ${JSON.stringify(workingState)}`);

      newState[column] = workingState;
    });
    setState(newState);
  };

  return (
    <div>
      <button
        type="button"
        onClick={() => {
          finalizeState([...state, []]);
        }}
      >
        Add new group
      </button>
      <button
        type="button"
        onClick={() => {
          finalizeState([...state, getItems(1)]);
        }}
      >
        Add new item
      </button>
      <div>
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <div style={{ margin: 20, backgroundColor: "#fefefe" }}>
            <Droppable droppableId={"12345"} isDropDisabled={true}>
              {(provided0, snapshot1) => (
                <div ref={provided0.innerRef}>
                  {elements.map((item, index) => (
                    <Draggable
                      key={`${item.id}-keyID`}
                      draggableId={`${item.id}-keyID`}
                      index={index}
                    >
                      {(provided0, snapshot) => (
                        <>
                          <div
                            key={index}
                            ref={provided0.innerRef}
                            {...provided0.draggableProps}
                            {...provided0.dragHandleProps}
                          >
                            <span>Item-{index}</span>
                          </div>
                          {snapshot.isDragging && (
                            <div>
                              <span>Item-{index}</span>
                            </div>
                          )}
                        </>
                      )}
                    </Draggable>
                  ))}
                  {provided0.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <Droppable key={90807} droppableId={"plevel0"} isDropDisabled={true}>
            {(providedg, snapshotg) => (
              <div
                ref={providedg.innerRef}
                {...providedg.droppableProps}
                style={{ display: "flex", gap: 10 }}
              >
                {[null, null].map((el, ind0) => (
                  <Draggable
                    key={ind0}
                    draggableId={`glevel0-${ind0}`}
                    index={ind0}
                  >
                    {(provided2gd, snapshotgd) => (
                      <div
                        ref={provided2gd.innerRef}
                        {...provided2gd.draggableProps}
                        // {...provided2gd.dragHandleProps}
                        style={{
                          ...getItemStyle(
                            snapshotgd.isDragging,
                            provided2gd.draggableProps.style
                          ),
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#eee",
                        }}
                      >
                        <Droppable
                          key={454554 * (ind0 + 1)}
                          droppableId={`plevel1-${ind0}`}
                          isDropDisabled={!isShifting}
                        >
                          {(provided1, snapshot) => (
                            <div
                              ref={provided1.innerRef}
                              {...provided1.droppableProps}
                            >
                              {state[ind0].map((el, ind1) => (
                                <Draggable
                                  key={`${ind0}-${ind1}`}
                                  draggableId={`glevel1-${ind0}-${ind1}`}
                                  index={ind1}
                                >
                                  {(provided2, snapshot2) => (
                                    <div
                                      ref={provided2.innerRef}
                                      {...provided2.draggableProps}
                                      // {...provided2.dragHandleProps}
                                      style={{
                                        ...getItemStyle(
                                          snapshot2.isDragging,
                                          provided2.draggableProps.style
                                        ),
                                        flex: 1,
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: 0,
                                        margin: 0,
                                        ...(!el.length
                                          ? {
                                              background: "#ececce",
                                              border: "dashed 1px",
                                              padding: "4px 16px",
                                            }
                                          : { background: "#808080" }),
                                      }}
                                    >
                                      {!!el.length && (
                                        <div {...provided2.dragHandleProps}>
                                          [X]
                                        </div>
                                      )}
                                      <Droppable
                                        key={ind1}
                                        droppableId={`plevel2-${ind0}-${ind1}`}
                                        direction="horizontal"
                                        isDropDisabled={
                                          (el.length > 2 &&
                                            draggingFrom !==
                                              `plevel2-${ind0}-${ind1}`) ||
                                          isShifting
                                        }
                                      >
                                        {(provided3, snapshot3) => (
                                          <div
                                            ref={provided3.innerRef}
                                            style={{
                                              ...getListStyle(
                                                snapshot3.isDraggingOver
                                              ),
                                              display: "flex",
                                              // minHeight: 5,
                                              ...(!el.length
                                                ? {
                                                    padding: "10px 16px",
                                                  }
                                                : {}),
                                            }}
                                            {...provided3.droppableProps}
                                          >
                                            {el.map((item, index) => (
                                              <Draggable
                                                key={item.id}
                                                draggableId={`glevel2-${ind0}-${ind1}-${item.id}`}
                                                index={index}
                                              >
                                                {(provided4, snapshot4) => (
                                                  <div
                                                    ref={provided4.innerRef}
                                                    {...provided4.draggableProps}
                                                    {...provided4.dragHandleProps}
                                                    style={{
                                                      ...getItemStyle(
                                                        snapshot4.isDragging,
                                                        provided4.draggableProps
                                                          .style
                                                      ),
                                                      flex: 1,
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-around",
                                                      }}
                                                    >
                                                      {item.content}
                                                      <button
                                                        type="button"
                                                        onClick={() => {
                                                          const newState = [
                                                            ...state,
                                                          ];
                                                          newState[ind1].splice(
                                                            index,
                                                            1
                                                          );
                                                          finalizeState(
                                                            newState
                                                          );
                                                        }}
                                                      >
                                                        delete
                                                      </button>
                                                    </div>
                                                  </div>
                                                )}
                                              </Draggable>
                                            ))}
                                            {provided3.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided1.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    )}
                  </Draggable>
                ))}
                {providedg.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

const rootElement = document.getElementById("root");
ReactDOM.render(<Playground />, rootElement);

export default Playground;
