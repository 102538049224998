import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateScreenStyles } from "../../../../Pages/UIEditor/utils/canvasHelpers";
import TextItemStyles from "./TextItemStyles";
import InputItemStyles from "./InputItemStyles";
import ButtonItemStyles from "./ButtonItemStyles";
import LabelItemStyles from "./LabelItemStyles";
import HeadingTextItemStyles from "./HeadingTextItemStyles";
import FormItemStyles from "./FormItemStyles";
import HeaderItemStyles from "./HeaderItemStyles";
import PageItemStyles from "./PageItemStyles";

const SidebarStyleSection = React.memo(
  ({ activeSelection, screenStyles, screenHasHeader }) => {
    const {
      activeScreen: { type: screenType },
    } = useSelector(({ screens }) => screens);

    const { type } = activeSelection || {};

    const dispatch = useDispatch();
    const onStyleChange = ({ value, root, property }) =>
      dispatch(
        updateScreenStyles({
          value,
          root,
          property,
          type,
        })
      );

    const restoreDefaultsStyles = () => {
      dispatch(updateScreenStyles({ type: "reset_defaults" }));
    };

    return (
      <>
        <div
          className="sidebar-section _header"
          style={{ textTransform: "uppercase" }}
        >
          Styles:
          <span className="in-link" onClick={restoreDefaultsStyles}>
            Restore defaults
          </span>
        </div>

        <PageItemStyles
          activeSelection={activeSelection}
          screenStyles={screenStyles}
          onStyleChange={onStyleChange}
        />

        {screenHasHeader && (
          <HeaderItemStyles
            activeSelection={activeSelection}
            screenStyles={screenStyles}
            onStyleChange={onStyleChange}
          />
        )}

        {screenType === "app" && (
          <FormItemStyles
            activeSelection={activeSelection}
            screenStyles={screenStyles}
            onStyleChange={onStyleChange}
          />
        )}

        <TextItemStyles
          activeSelection={activeSelection}
          screenStyles={screenStyles}
          onStyleChange={onStyleChange}
        />

        <HeadingTextItemStyles
          activeSelection={activeSelection}
          screenStyles={screenStyles}
          onStyleChange={onStyleChange}
        />

        {screenType === "app" && (
          <>
            <LabelItemStyles
              activeSelection={activeSelection}
              screenStyles={screenStyles}
              onStyleChange={onStyleChange}
            />

            <InputItemStyles
              activeSelection={activeSelection}
              screenStyles={screenStyles}
              onStyleChange={onStyleChange}
            />

            <ButtonItemStyles
              activeSelection={activeSelection}
              screenStyles={screenStyles}
              onStyleChange={onStyleChange}
            />
          </>
        )}
      </>
    );
  }
);
export default SidebarStyleSection;
