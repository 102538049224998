import { errorToastify } from "../../../common/Toastify";
import { retrieveApp } from "../../../components/Mutation/Apps/AppsMutation";
import { rSelectApp } from "../../../store/actions/properties";
import { mainNavigationUrls } from "../../../utils/lists";

//these are the dialog flag to watch out for
export const operations = ["create", "duplicate", "update"];
export const appDialogModes = {
  NEW: "New",
  CLONE: "Clone", //  to create app from template
  DUPLICATE: "Duplicate",
  PROPERTIES: "Properties",
};

/**
 * It takes in an object with a bunch of properties and sets them to...
 */
export const openApp = ({ id, history }) => {
  history.push(`/editor/${id}/screens`);
};

export const registerApp = (appId, history) => async (dispatch) => {
  if (!appId) return;

  const selectedApp = await retrieveApp({ id: appId });

  if (selectedApp?.data?._meta?.success) {
    dispatch(rSelectApp(selectedApp?.data?.data));
    return selectedApp?.data?.data;
  } else {
    if (
      !(selectedApp.message || selectedApp)?.toLowerCase()?.includes("network")
    ) {
      history.push(mainNavigationUrls.APPS);
      errorToastify("Invalid URL. Kindly check and try again.");
    }
    return false;
  }
};

/**
 * It takes an object with an id and props, and returns a component based on the id
 * @returns A function that takes an object with an id, props, Welcome, and Tour.
 */
export const renderOnboarder = ({ id, props, Welcome, Tour }) => {
  switch (id) {
    case "welcome":
      return <Welcome {...props} />;
    case "tour":
      return <Tour {...props} />;
    default:
      return null;
  }
};

/**
 * If hasUpdate is true, set a timeout to set hasUpdate to false after 3 seconds.
 */
export const removeUpdateFlag = ({
  hasUpdate,
  timeout,
  subscribe,
  setHasUpdate,
}) => {
  if (hasUpdate === true) {
    timeout = setTimeout(() => {
      subscribe && setHasUpdate(false);
    }, 3000);
    //console.log("timeout updated");
  }
};
