import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Required from "../Required";
import { APP_DESIGN_MODES } from "../../../../../../../utils/constants";
import { useEffect, useState } from "react";
import { v4 } from "uuid";

const TextArea = ({
  style,
  onChange,
  returnedLookupObj,
  values,
  readOnly,
  ...props
}) => {
  const [renderKey, setRenderKey] = useState(v4());
  const [trackLookupVal, setTrackLookupVal] = useState(null);
  const useStyles = makeStyles((theme) => ({
    ...style,
    dimensions: {
      ...style?.dimensions,
      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
      },
    },
    myStyle: {
      width: style?.field?.width,
    },
  }));
  const classes = useStyles();
  const inputProps = {};
  if (values?.limitCharacter) {
    inputProps.minLength = values?.min;
    inputProps.maxLength = values?.max;
  }

  useEffect(() => {
    if (returnedLookupObj?.[props?.id]) {
      setTrackLookupVal(returnedLookupObj?.[props?.id]);
      onChange(returnedLookupObj?.[props?.id], props?.id);
      setRenderKey(v4());
    }
    if (props.val) {
      setTrackLookupVal(props.val);
      onChange(props.val, props?.id);
    }
  }, [returnedLookupObj, props?.id]);

  const displayValue = () => {
    if (props?.appDesignMode === APP_DESIGN_MODES.LIVE) return props?.val;
    if (props?.appDesignMode === APP_DESIGN_MODES.PREVIEW) return values?.value;
  };
  return (
    <div style={{ width: style?.field?.width }}>
      {!values?.labelHide && (
        <Typography gutterBottom className={classes?.label}>
          {values?.label}
          <Required required={values?.required} />
        </Typography>
      )}
      <TextField
        key={renderKey}
        inputProps={{
          ...inputProps,
          readOnly: values?.editable || readOnly,
          style: {
            ...style?.field,
            borderStyle: "solid",
            height: null,
            minHeight: style?.field?.height,
          },
        }}
        rows={values?.rows}
        size="small"
        className={`${classes?.dimensions_} ${classes?.field_}`}
        classes={{
          root: classes?.myStyle,
        }}
        multiline
        required={values?.required}
        variant="outlined"
        type={"text"}
        inputMode="text"
        defaultValue={displayValue() || trackLookupVal}
        placeholder={values?.placeholder}
        readOnly={values?.editable || readOnly}
        // disabled={props.appDesignMode === APP_DESIGN_MODES.EDIT || props.disabled}
        onChange={(e) => onChange(e.target.value, props.id)}
      />
    </div>
  );
};

export default TextArea;
