import { TextField } from "@material-ui/core";
import Address from "./actualObjects/Address";
import SubmitButton from "./actualObjects/SubmitButton";
import Currency from "./actualObjects/Currency";
import DateTime from "./actualObjects/DateTime";
import DecisionButton from "./actualObjects/DecisionButton";
import FileUpload from "./actualObjects/FileUpload";
import Image from "./actualObjects/Image";
import InputText from "./actualObjects/InputText";
import InputTable from "./actualObjects/InputTable";
import HeadingText from "./actualObjects/HeadingText";
import PageBreak from "./actualObjects/PageBreak";
import PhoneNumber from "./actualObjects/PhoneNumber";
import Signature from "./actualObjects/Signature";
import UserPicker from "./actualObjects/UserPicker";
// import DisplayTable from "./actualObjects/DisplayTable";
import Text from "./actualObjects/Text";
import Video from "./actualObjects/Video";
import Custom from "./actualObjects/Custom";
import Dropdown from "./actualObjects/Dropdown";
import CheckBox from "./actualObjects/Checkbox";
import Radio from "./actualObjects/Radio";
import Toggle from "./actualObjects/Toggle";
import Slider from "./actualObjects/Slider";
import TextArea from "./actualObjects/TextArea";
import ActionButton from "./actualObjects/ActionButton";
import Header from "./actualObjects/Header";
import Form from "./actualObjects/Form";
import ScreenButtons from "./actualObjects/ScreenButtons";
import DisplayTable from "./actualObjects/DisplayTable/DisplayTable";
import { APP_DESIGN_MODES } from "../../../../../utils/constants";

const RealComponents = ({ ...props }) => {
  //  basic elements
  switch (props.type) {
    case "submitButton":
      return <SubmitButton {...props} />;
    case "ScreenButtons":
      return <ScreenButtons {...props} />;
    case "actionButton":
      return <ActionButton {...props} />;
    case "decisionButton":
      return <DecisionButton {...props} />;
    case "textBox":
      return (
        <TextField
          style={props.style}
          variant="outlined"
          size="small"
          placeholder="type here"
          disabled={
            props.appDesignMode === APP_DESIGN_MODES.EDIT || props.disabled
          }
          {...props}
        />
      );
    case "text":
      return <Text {...props} />;
    case "video":
      return <Video {...props} />;
    case "image":
      return <Image {...props} />;
    case "displayTable":
      return <DisplayTable {...props} />;
    case "pageBreak":
      return <PageBreak {...props} />;
    case "signature":
      return <Signature {...props} />;
    case "userPicker":
      return <UserPicker {...props} />;
    case "fileUpload":
      return <FileUpload {...props} />;
    case "form":
      return <Form {...props} />;
    case "inputText":
      return <InputText {...props} />;
    case "textArea":
      return <TextArea {...props} />;
    case "heading":
      return <HeadingText {...props} />;
    case "dual":
      return (
        <TextField
          variant="outlined"
          size="small"
          placeholder="type here"
          disabled={props.appDesignMode === APP_DESIGN_MODES.EDIT}
        />
      );
    case "dateTime":
      return <DateTime {...props} />;
    case "phoneNumber":
      return <PhoneNumber {...props} />;
    case "address":
      return <Address {...props} />;
    case "currency":
      return <Currency {...props} />;

    //  advanced elements
    case "inputTable":
      return <InputTable {...props} />;

    //  custom elements
    case "custom":
      return (
        <Custom
          contents={props.contents}
          style={props.style}
          customId={props.customId}
          {...props}
        />
      );
    case "dropdown":
      return <Dropdown {...props} />;
    case "checkbox":
      return <CheckBox {...props} />;
    case "radio":
      return <Radio {...props} />;
    case "toggle":
      return <Toggle {...props} />;
    case "slider":
      return <Slider {...props} />;

    // Navigation
    case "header":
      return <Header {...props} />;

    default:
      return <Text {...props} />;
  }

  // inputTable:
  //   <InputTable />,
  // inputTable: <TextField
  //   variant="outlined"
  //   size="small"
  //   placeholder="type here"
  //   disabled
  // />,

  // Select: <Select
  //   // labelId="demo-controlled-open-select-label"
  //   // id="demo-controlled-open-select"
  //   disabled
  //   value={1}
  // >
  //   <MenuItem key={1} value={1}>One</MenuItem>
  //   <MenuItem key={2} value={2}>Two</MenuItem>
  //   <MenuItem key={3} value={3}>Three</MenuItem>
  // </Select>,
};

export default RealComponents;
