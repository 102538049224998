import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Collapse, InputBase } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateItemStyles } from "../../../../Pages/UIEditor/utils/canvasHelpers";
import InputItemStyles from "./InputItemStyles";
import PageItemStyles from "./PageItemStyles";
import HeaderItemStyles from "./HeaderItemStyles";
import FormItemStyles from "./FormItemStyles";
import HeadingTextItemStyles from "./HeadingTextItemStyles";
import TextItemStyles from "./TextItemStyles";
import LabelItemStyles from "./LabelItemStyles";
import ButtonItemStyles from "./ButtonItemStyles";
import Switch from "../PlainToggleSwitch";

const CustomStyleSection = React.memo(
  ({
    activeSelection,
    screenHasHeader,
    items,
    id,
    itemType,
    index,
    isInsideContainer,
    containingChildIndex,
    styles,
    stylesOverride,
  }) => {
    const InputText = withStyles((theme) => ({
      input: {
        color: "#091540",
        borderRadius: 3,
        position: "relative",
        border: "1px solid #ABB3BF",
        fontSize: 11,
        padding: "5px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
      },
    }))(InputBase);

    const dispatch = useDispatch();
    const onItemStyleChange = ({ value, root, property }) => {
      console.log(
        `. . . . onItemStyleChange . . . . ${JSON.stringify(
          value
        )} . ${JSON.stringify(root)} . ${JSON.stringify(property)}`
      );
      dispatch(
        updateItemStyles({
          value,
          root,
          property,
          index,
          isInsideContainer,
          containingChildIndex,
          itemType,
        })
      );
    };

    const formRowSpacing = [
      ["10", "Thin"],
      ["15", "Normal"],
      ["20", "Large"],
      ["30", "Extra Large"],
    ];
    const fontWeightSelection = [
      ["100", "Thin"],
      ["200", "Medium"],
      ["300", "Normal"],
      ["500", "Bold"],
      ["700", "Bolder"],
    ];
    const textAlignSelection = [
      ["left", "Left"],
      ["center", "Center"],
      ["right", "Right"],
    ];
    const lineHeightSelection = [
      [1, "Small"],
      [1.5, "Normal"],
      [2, "More"],
    ];

    return (
      <>
        <div
          className="sidebar-section _header"
          style={{ marginTop: 20, padding: "0 10px" }}
        >
          Appearance: override screen defaults
          <Switch
            itemType="togglehide"
            value={stylesOverride}
            checked={stylesOverride}
            toggleHide={(v) =>
              onItemStyleChange({ value: v, property: "overrideDefault" })
            }
          />
        </div>

        <Collapse in={stylesOverride}>
          {items.includes("page") && (
            <PageItemStyles
              activeSelection={activeSelection}
              screenStyles={styles}
              onStyleChange={onItemStyleChange}
            />
          )}

          {items.includes("header") && (
            <HeaderItemStyles
              activeSelection={activeSelection}
              screenStyles={styles}
              onStyleChange={onItemStyleChange}
            />
          )}

          {items.includes("form") && (
            <FormItemStyles
              activeSelection={activeSelection}
              screenStyles={styles}
              onStyleChange={onItemStyleChange}
            />
          )}

          {items.includes("heading") && (
            <HeadingTextItemStyles
              activeSelection={activeSelection}
              screenStyles={styles}
              onStyleChange={onItemStyleChange}
            />
          )}

          {items.includes("text") && (
            <TextItemStyles
              activeSelection={activeSelection}
              screenStyles={styles}
              onStyleChange={onItemStyleChange}
            />
          )}

          {items.includes("input") && (
            <InputItemStyles
              activeSelection={activeSelection}
              screenStyles={styles}
              onStyleChange={onItemStyleChange}
            />
          )}

          {items.includes("button") && (
            <ButtonItemStyles
              activeSelection={activeSelection}
              screenStyles={styles}
              onStyleChange={onItemStyleChange}
            />
          )}

          {items.includes("label") && (
            <LabelItemStyles
              activeSelection={activeSelection}
              screenStyles={styles}
              onStyleChange={onItemStyleChange}
            />
          )}
        </Collapse>
      </>
    );
  }
);
export default CustomStyleSection;
