import React, { useState } from "react";
import { Collapse, MenuItem, Select, Typography } from "@material-ui/core";
import DataActivitiesModule from "./common/DataActivitiesModule";

const DynamicContentComponent = ({
  classes,
  currentIdx,
  currentVal,
  dynamicContentsStructure,
  dynamicContents,
  lookupContents,
  updateDynamicContentsData,
  updateLookupContentsData,
}) => {
  const lookupContentsIndexData = lookupContents[currentIdx];
  const [show, setShow] = useState(true);

  const getSelectedIndex = (value) => {
    const theKey =
      value?.target?.name === "lookupField"
        ? value?.target?.value
        : currentVal?.matching?.valueSourceInput;

    const position = lookupContents?.findIndex(
      (content) => content?.matching?.valueSourceInput === theKey
    );

    return position;
  };

  const updateLookconIndexData = (dataValue, ppty, isGrouped) => {
    console.log(dataValue);
    const index = getSelectedIndex(dataValue);
    const newData = {
      ...lookupContents[index],
      ...(isGrouped
        ? {
            ...dataValue,
          }
        : {
            [dataValue?.target?.name]: dataValue?.target?.value,
          }),
      activeSelection: true,
    };
    if (
      dataValue?.target?.name === "lookupField" &&
      currentVal?.activeSelection
    ) {
      const oldIndex = lookupContents.findIndex(
        (lookVal) =>
          lookVal?.matching?.valueSourceInput ===
          currentVal?.matching?.valueSourceInput
      );
      lookupContents[oldIndex].activeSelection = false;
    }

    updateLookupContentsData(newData, index);
  };

  const shouldBeDisabled = (lID) => {
    return (
      lookupContents?.find(
        (lookcon) =>
          !!lookcon?.activeSelection &&
          lookcon?.matching?.valueSourceInput === lID
      ) && currentVal?.matching?.valueSourceInput !== lID
    );
  };

  return (
    <>
      <div className={classes.sectionEntry} style={{ flex: 1 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography gutterBottom className={classes.sectionTitle}>
            Select Lookup Field
          </Typography>
          <span
            onClick={() => setShow((prev) => !prev)}
            style={{ cursor: "pointer" }}
          >
            {show ? "[-]" : "[+]"}
          </span>
        </div>
        <Select
          name="lookupField"
          variant="outlined"
          size="small"
          fullWidth
          placeholder="Select form screen"
          classes={{
            root: classes.select,
          }}
          value={currentVal?.matching?.valueSourceInput || "choose"}
          onChange={updateLookconIndexData}
        >
          <MenuItem value="choose" disabled>
            <em>select lookup field</em>
          </MenuItem>
          {lookupContents?.map((lc, idx) => {
            return (
              <MenuItem
                value={lc?.matching?.valueSourceInput}
                selected
                key={idx}
                disabled={shouldBeDisabled(lc?.matching?.valueSourceInput)}
              >
                {lc?.name}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <Collapse in={!!show && !!currentVal?.activeSelection}>
        <DataActivitiesModule
          moduleSource={"screenLookupContent"}
          data={{ ...currentVal, isLookupField: true }}
          updateData={updateLookconIndexData}
        />
      </Collapse>
    </>
  );
};

export default DynamicContentComponent;
