import React from "react";
import { useDispatch } from "react-redux";
import SidebarFieldPreferenceSection from "../components/SidebarFieldPreferenceSection";
import SidebarLabelPreferenceSection from "../components/SidebarLabelPreferenceSection";
import SidebarNameSection from "../components/SidebarNameSection";

export default function DateTimeSidebar(props) {
  const {
    style,
    values,
    name,
    title,
    id,
    type: itemType,
    index,
    containingChildIndex,
    isInsideContainer,
  } = props;

  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    checkedBtn: true,
  });

  const dateFormatsList = [
    ["dmy", "DD-MM-YYYY"],
    ["ymd", "YYYY-MM-DD"],
    ["Mdyr", "Month D, Yr"],
    ["mdy", "MM-DD-YY"],
  ];
  const timeFormatsList = [
    ["12", "12 Hours"],
    ["24", "24 Hours"],
  ];

  return (
    <>
      <div className="sidebar-container">
        <SidebarNameSection
          id={id}
          itemType={itemType}
          name={name}
          title={title}
          index={index}
          isInsideContainer={isInsideContainer}
          containingChildIndex={containingChildIndex}
        />

        <div className="sidebar-container-scroll">
          <SidebarFieldPreferenceSection
            itemType={itemType}
            name={name}
            title={title}
            index={index}
            isInsideContainer={isInsideContainer}
            containingChildIndex={containingChildIndex}
            values={{ ...values }}
            dateFormatsList={dateFormatsList}
            timeFormatsList={timeFormatsList}
          />
          <SidebarLabelPreferenceSection
            itemType={itemType}
            name={name}
            title={title}
            index={index}
            isInsideContainer={isInsideContainer}
            containingChildIndex={containingChildIndex}
            values={{ ...values }}
          />
        </div>
      </div>
    </>
  );
}
