import { CustomAxios } from "../../../../common/CustomAxios";
import { catchErr } from "../../../../common/util/catchErr";
import { sortByIndexPpty } from "../UIEditor/utils/canvasHelpers";

export const getScreens = async (appId) => {
  try {
    const { data } = await CustomAxios().get(
      `${process.env.REACT_APP_ENDPOINT}/screens?per_page=100&app=${appId}`
    );
    return { success: data._meta?.success, data };
  } catch (error) {
    return { success: false, data: "Error getting screens" };
  }
};

export const createScreen = async (screen) => {
  try {
    const { data } = await CustomAxios().post(
      `${process.env.REACT_APP_ENDPOINT}/screens`,
      screen
    );
    return { success: data._meta?.success, data };
  } catch (error) {
    console.log(`***** ${JSON.stringify(error.response)}`);
    return {
      success: false,
      data: `Error creating screen (${
        error?.response?.data
          ? error?.response?.data?._meta?.error?.message
          : "(Network)"
      })`,
    };
  }
};

export const updateScreen = async ({ id, ...property }) => {
  try {
    const { data } = await CustomAxios().put(
      `${process.env.REACT_APP_ENDPOINT}/screens/${id}`,
      property
    );
    return { success: data._meta?.success, data };
  } catch (error) {
    return { success: false, data: "Error creating screen" };
  }
};

export const saveAsset = async (file) => {
  try {
    const {
      data: { data },
    } = await CustomAxios().post(
      `${process.env.REACT_APP_ENDPOINT}/files/asset`,
      file,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    return {
      data,
      success: data._meta?.success,
    };
  } catch (error) {
    catchErr({
      err: error,
      alertResMessage: error?.response?.data?._meta?.error?.message,
    });
    return {
      success: false,
      data: error.response,
    };
  }
};

export const deleteScreen = async (id) => {
  const response = await CustomAxios()
    .delete(`${process.env.REACT_APP_ENDPOINT}/screens/${id}`)
    .catch((err) => {
      console.log(`RRRRRREEEEEEE`);
    });
  return { success: response?.data?._meta?.success, data: response?.data };
};

export const getSingleScreenItems = async (id) => {
  try {
    const { data } = await CustomAxios().get(
      `${process.env.REACT_APP_ENDPOINT}/items?screen=${id}&per_page=500`
    );
    return { success: data._meta?.success, data: sortByIndexPpty(data.data) };
  } catch (error) {
    return { success: false, data: "Error getting items" };
  }
};

export const duplicateScreen = async (body) => {
  try {
    const { data } = await CustomAxios().post(
      `${process.env.REACT_APP_ENDPOINT}/screens/duplicate`,
      body
    );
    return { success: data._meta?.success, data };
  } catch (error) {
    return { success: false, data: "Error duplicating screens" };
  }
};

export const getDocumentScreenInfo = async (id) => {
  try {
    const { data } = await CustomAxios().get(
      `${process.env.REACT_APP_ENDPOINT}/screens/${id}`
    );
    return { success: data._meta?.success, data: data.data };
  } catch (error) {
    return { success: false, data: "Error getting items" };
  }
};

export const clearScreenItemsAPI = async ({ id }) => {
  try {
    const { data } = await CustomAxios().put(
      `${process.env.REACT_APP_ENDPOINT}/screens/${id}/clear-items`
    );
    return { success: true, data };
  } catch (error) {
    return { success: false, data: "Error clearing canvas" };
  }
};
