import { v4 } from "uuid";
import _ from "lodash";
import {
  rSelectActiveTask,
  rSetupBackDrop,
  rHideRightSideBar,
  rUpdateWorkflowCanvas,
  rLoadWorkflowTasks,
  rSetWorkflowVariables,
  rToggleWorkflowFullScreen,
  updateBackendErrorBank,
  rSetWorkflowsList,
  rSetActiveWorkflow,
  rSetWorkflowIntegrations,
  rSetWorkflowCrashed,
  rSetLoadingRightSideBar,
  rSetErrorLoadingRightSideBar,
  rWorkflowVariablesWarning,
} from "../../../../../store/actions/properties";
import {
  getWorkflowTaskAPI,
  createWorkflowTaskAPI,
  updateWorkflowAPI,
  updateWorkflowTaskAPI,
  clearWorkflowTasksAPI,
  updateWorkflowNodesAPI,
  getWorkflowsListAPI,
  createWorkflowNodesAPI,
  deleteWorkflowTaskAPI,
} from "./workflowAPIs";
import {
  WORKFLOWS_TASK_MAIL,
  WORKFLOWS_TASK_DATA,
  WORKFLOWS_TASK_APPROVAL,
  WORKFLOWS_TASK_SCREEN,
  WORKFLOWS_TASK_CALENDAR,
  WORKFLOWS_TASK_COMPUTATION,
  WORKFLOWS_TASK_CUSTOM,
  WORKFLOWS_TASK_END,
} from "../components/utils/taskTypes";
import {
  SetAppStatus,
  wrapAPI,
  wrapDebounceAPI,
} from "../../../../../helpers/helperFunctions";
import { errorToastify } from "../../../../../common/Toastify";
import { getIntegrationDataAPI } from "../../../../Integrations/utils/integrationsAPIs";
import { groupIntegrations } from "./tasksHelpers";
import { mainNavigationUrls } from "../../../../../utils/lists";
import { VARIABLES_LENGTH_THRESHOLD } from "../components/utils/constants";
import { trimVariablesAppWorkflowAPI } from "../../../../../components/Mutation/Apps/AppsMutation";

const preAPIs = async (dispatch, func, ...args) => {
  dispatch(SetAppStatus({ type: "info", msg: "..." }));

  const resp = await func(...args);

  // const resp = await func(...args);
  if (resp?.data?._meta?.success || resp?._meta?.success) {
    dispatch(SetAppStatus({ type: "info", msg: "" }));

    if (!args?.[0]?.noLogError) {
      dispatch(
        updateEndpointError("Workflow Editor", "update", "workflows", ...args)
      );
    }

    return resp;
  } else {
    dispatch(
      SetAppStatus({ type: "error", msg: "An error occured (Network)" })
    );

    if (!args?.[0]?.noLogError) {
      dispatch(
        logEndpointError(
          "Workflow Editor",
          "update",
          "workflows",
          preAPIs,
          func,
          "argument",
          "",
          null,
          ...args
        )
      );
    }

    return "error";
  }
};

const reformatWorkflowData = (workflowData) => {
  const outData = {};
  workflowData?.tasks
    ?.filter((task) => !task.source)
    ?.forEach((task) => {
      outData[task.id] = {
        name: task.name,
        id: task.id,
        type: task.type,
        variables: task.variables,
        ...(task.hasDecision
          ? {
              properties: {
                ...(outData?.[task.id]?.properties || {}),
                hasDecision: task.hasDecision,
              },
            }
          : {}),
      };
    });
  return outData;
};

export const getAllWorkflows =
  (appId, refresh, history) => async (dispatch, getState) => {
    const { workflowsList, activeWorkflow } = getState().workflows;
    let returnedWorkflowsList = workflowsList;

    if (!workflowsList?.length || refresh) {
      //  get workflows from backend
      try {
        const data = await dispatch(
          wrapAPI(getWorkflowsListAPI, "Workflows loaded", {
            query: {
              app: appId,
              selection: [
                "id",
                "name",
                "app",
                "variables",
                "tasks",
                "createdAt",
              ],
            },
          })
        );
        if (data?._meta?.success) {
          returnedWorkflowsList = data?.data;
          //  load data into state for canvas
          if (
            !returnedWorkflowsList?.length ||
            !returnedWorkflowsList?.[0]?.tasks?.length
          ) {
            const heavyTasks = [
              {
                id: `{ unknown }`,
                name: `{ unknown }`,
                type: `{ unknown }`,
                variables: `{ unknown }`,
              },
            ];
            dispatch(rWorkflowVariablesWarning(heavyTasks));
          } else {
            dispatch(rSetWorkflowsList(returnedWorkflowsList));
            //  load data into state for tasks
            const reformattedWorkflowData = reformatWorkflowData(
              returnedWorkflowsList?.[0]
            );
            dispatch(rLoadWorkflowTasks(reformattedWorkflowData));
          }
        } else {
          if (data?.toLowerCase()?.includes("network")) return;
          errorToastify("Invalid URL. Kindly check and try again.");
          !!history && history.push(mainNavigationUrls.APPS);
          return;
        }

        const newActive = returnedWorkflowsList?.[0] || {};
        let activeWF;

        if (refresh) {
          const updatedActive = returnedWorkflowsList.find(
            (screen) => screen.id === activeWorkflow?.id
          );
          activeWF = updatedActive || newActive;
          dispatch(rSetActiveWorkflow(activeWF));
        } else if (
          !activeWorkflow ||
          !returnedWorkflowsList.find(
            (workflow) => workflow.id === activeWorkflow?.id
          )
        ) {
          activeWF = newActive;
          dispatch(rSetActiveWorkflow(activeWF));
        }

        //  load task details from db
        const allVariables = {};
        if (activeWF?.tasks?.length >= 2) {
          dispatch(rSetWorkflowCrashed(false));
          activeWF?.tasks.forEach((task) => {
            if (!!task.type && !["EndTask"].includes(task.type)) {
              const { id, type, variables } = task;
              allVariables[id] = variables;
              // dispatch(loadTask({ id, type, selected: false }));
            }
          });
        } else {
          //  workflow has crashed
          dispatch(rSetWorkflowCrashed(true));
        }
        dispatch(rSetWorkflowVariables(allVariables || {}));
      } catch (err) {
        // console.log(`E R R R O R`);
      }
    }
    return returnedWorkflowsList || [];
  };

export const getAllWorkflowIntegrations =
  (refresh) => async (dispatch, getState) => {
    const { workflowIntegrations } = getState().workflows;
    let returnWorkflowIntegrations = workflowIntegrations;

    if (!Object.keys(workflowIntegrations)?.length || refresh) {
      //  get integrations from backend
      const data = await dispatch(
        wrapAPI(getIntegrationDataAPI, "Integrations loaded", {
          query: {
            active: true,
            disabled: false,
            per_page: 1000,
          },
        })
      );

      if (data?._meta?.success) {
        returnWorkflowIntegrations = groupIntegrations(data?.data);
        //  load data into state
        dispatch(rSetWorkflowIntegrations(returnWorkflowIntegrations));
      }
    }
    return returnWorkflowIntegrations || [];
  };

export const setupActiveWorkflow = () => async (dispatch, getState) => {
  const state = getState().workflows;
  const { id: workflowId } = getState().workflows.activeWorkflow;
  const { workflows } = state;

  const activeWF = workflows.find((wf) => wf.id === workflowId);

  // !!activeWF?.tasks?.length && dispatch(rUpdateWorkflowCanvas(activeWF.tasks));

  //  load task details from db
  const allVariables = {};
  activeWF?.tasks.forEach((task) => {
    if (!!task.type && !["EndTask"].includes(task.type)) {
      const { id, type, variables } = task;
      allVariables[id] = variables;
      dispatch(loadTask({ id, type, selected: false }));
    }
  });
  dispatch(rSetWorkflowVariables(allVariables || {}));
};

export const addWorkflowTask =
  (added, data, inf) => async (dispatch, getState) => {
    const state = getState().workflows;
    const { workflowTasks } = state;

    const { id: appId } = getState().appsReducer.selectedApp;
    const { id: workflowId } = getState().workflows.activeWorkflow;

    const response = await preAPIs(dispatch, createWorkflowNodesAPI, {
      ...added,
      id: workflowId,
      nodeId: added.id,
    });

    if (response === "error") return;

    const { task, workflowTask } = response?.data;

    const activeWorkflowTasks_ = { ...workflowTasks, [added.id]: task };

    dispatch(rLoadWorkflowTasks(activeWorkflowTasks_));
    dispatch(rUpdateWorkflowCanvas(data));
    dispatch(rSelectActiveTask(added));
    dispatch(rHideRightSideBar(false));
    dispatch(
      rSetupBackDrop({
        show: true,
        clickToHideBackdrop: true,
      })
    );

    if (task?.variables?.length) {
      const directValue = {
        taskId: added.id,
        taskVariables: task?.variables,
      };
      dispatch(updateTaskVariables({ directValue, saveToDB: true }));
    }
    return;

    //  save task in Tasks... don't remove yet... be sure
    const taskInf = {
      taskId: added.id,
      // name: "[unspecified]",  //  to be returned to ""...once backend fixes the issue
      app: appId,
      workflow: workflowId,
      description: "",
      type: added.type,
      triggerType: "default",
      useCustomTrigger: false,
      triggeredByWorkflow: true,
      triggeredByWebhook: false,

      properties: {
        ...(added.type === "MailTask" ? { actionType: "email" } : {}),
        ...(added.type === "DataTask"
          ? { dataMatching: [{}], dataMethod: "new" }
          : {}),
        ...(added.type === "ApprovalTask"
          ? {
              approvalActions: [
                { sourceHandle: "b" },
                { sourceHandle: "c" },
                { sourceHandle: "d" },
              ],
            }
          : {}),
        ...(added.type === "ComputationTask"
          ? {
              functions: [],
              decisionActions: [{ sourceHandle: "b" }, { sourceHandle: "c" }],
            }
          : {}),
      },
      new: true,
      configured: false,
    };

    const newWorkflowTask = await preAPIs(
      dispatch,
      createWorkflowTaskAPI,
      taskInf
    );
    // const newWorkflowTask = await createWorkflowTaskAPI(taskInf);
    if (newWorkflowTask?._meta?.success) {
      //  process new variables structure into data
      const varStruct = await dispatch(
        updateTaskVariables({
          linkedValues: {
            action: "new",
            target: { id: added.id, type: added.type },
          },
        })
      );

      const processedData = processDataVariables(data, varStruct);

      /* //  add data for new node
      processedData.push({
        ...added,
        variables: varStruct[added?.id],
      }); */

      const payload = {
        id: workflowId,
        tasks: processedData,
      };

      //  update db with new structure
      /* preAPIs(dispatch, updateWorkflowNodesAPI, {
        nodeId: added.id,
        ...added,
        id: workflowId,
      }); */
      preAPIs(dispatch, updateWorkflowAPI, payload);

      //  update state with task info
      const activeWorkflowTasks_ = { ...workflowTasks };
      delete taskInf.app;
      delete taskInf.workflow;
      taskInf.id = newWorkflowTask.data.id;
      taskInf.variables = varStruct[added?.id];

      activeWorkflowTasks_[added.id] = taskInf;
    }
  };

export const loadTask =
  ({ id, type, selected = true }) =>
  async (dispatch, getState) => {
    let {
      workflowTasks: { ...currentValue },
    } = getState().workflows;
    const { id: appId } = getState().appsReducer.selectedApp;

    dispatch(rSetErrorLoadingRightSideBar(false));
    dispatch(rSetLoadingRightSideBar(true));

    //  if task is not already (fully) loaded into global state
    if (!currentValue?.[id]?.app) {
      //  fetch from API
      const task = await preAPIs(dispatch, getWorkflowTaskAPI, {
        query: {
          taskId: id,
          app: appId,
        },
        noLogError: true,
      });
      // const task = await getWorkflowTaskAPI({
      //     query: {
      //         taskId: id,
      //     },
      // });

      const state_ = getState().workflows;
      const workflowTasks = state_.workflowTasks;

      const selectedTaskDetails = task?.data?.[0];
      currentValue = { ...workflowTasks };

      if (selectedTaskDetails) {
        currentValue[id] = selectedTaskDetails;
      } else {
        dispatch(rSetErrorLoadingRightSideBar(true));
      }
    }

    dispatch(rLoadWorkflowTasks(currentValue));
    !!selected && dispatch(rSelectActiveTask({ id, type }));
    !!selected && dispatch(rHideRightSideBar(false));
    dispatch(rSetLoadingRightSideBar(false));
  };

export const updateWorkflowCanvas =
  (changed, data, inf, canvasAction) => async (dispatch, getState) => {
    const { id: workflowId } = getState().workflows.activeWorkflow;

    if (changed) {
    }

    //  process new variables structure into data
    if (canvasAction === "move") {
      await preAPIs(dispatch, updateWorkflowNodesAPI, {
        nodeId: changed.id,
        ...changed,
        //  this is so we can use workflow id as url param
        id: workflowId,
      });
    } else {
      if (!data) return;

      const varStruct = await dispatch(
        updateTaskVariables({
          linkedValues: {
            action: canvasAction,
            source: { id: inf?.source?.id },
            target: { id: inf?.target?.id },
          },
        })
      );

      const processedData = processDataVariables([...(data || [])], varStruct);

      const payload = {
        id: workflowId,
        tasks: processedData,
      };

      if (canvasAction === "connect")
        preAPIs(dispatch, updateWorkflowAPI, payload);
    }

    dispatch(rUpdateWorkflowCanvas([...(data || [])]));
  };

export const setActiveWorkflowCanvas = (data) => (dispatch, getState) => {
  const state = getState().workflows;
  const { activeWorkflow, pos, subworkflow } = state;

  const value = { ...activeWorkflow };

  value.tasks = data;

  dispatch(rSelectActiveTask(data));
  dispatch(rUpdateWorkflowCanvas(value));
  dispatch(
    rSetupBackDrop({
      show: true,
      clickToHideBackdrop: true,
    })
  );
};

export const updateWorkflowTask =
  (update, data, saveToDB) => async (dispatch, getState) => {
    const state = getState().workflows;
    const { workflowTasks, variables, workflowCanvas } = state;

    const currentValue = { ...workflowTasks };

    const oldData = { ...currentValue[data.taskId] };
    currentValue[data.taskId] = {
      ...oldData,
      ...data,
      variables: variables[data.taskId],
      new: false,
    };

    //  check if updated fields include 'name' or 'configure' or 'properties.screenType'
    //  and then save in canvas for quick access
    let workflowCanvas_,
      toChange = false;
    Object.entries(update).forEach((entry) => {
      if (
        [
          "name",
          "configured",
          "properties.screenType",
          "properties.hasDecision",
        ].includes(entry[0])
      ) {
        workflowCanvas_ = workflowCanvas?.map((element) => {
          if (element?.id === data.taskId) {
            element[entry[0]?.replace("properties.", "")] = entry[1];
            toChange = true;
          }
          return element;
        });
      }
    });

    if (toChange) {
      dispatch(rUpdateWorkflowCanvas(workflowCanvas_));
    }

    dispatch(rLoadWorkflowTasks(currentValue));

    //  save to DB
    if (saveToDB) {
      update.id = oldData.id;

      // await preThisAPI(dispatch, updateWorkflowTaskAPI, update);
      await dispatch(
        wrapDebounceAPI("task", updateWorkflowTaskAPI, "", null, update)
      );
    }
  };

export const logEndpointError =
  (
    section,
    action,
    resource,
    hostFunc,
    func,
    dispatcher,
    msg,
    callback,
    { id: resourceId, ...data }
  ) =>
  async (dispatch, getState) => {
    const { plugBackendUpdateErrorBank } = getState().reducers;

    const newUpdates = { ...data };

    const updatedErrorBank = {
      ...plugBackendUpdateErrorBank,
      [section]: {
        ...(plugBackendUpdateErrorBank?.[section] || {}),
        [resource]: {
          ...(plugBackendUpdateErrorBank?.[section]?.[resource] || {}),
          [resourceId]: {
            ...(plugBackendUpdateErrorBank?.[section]?.[resource]?.[
              resourceId
            ] || {}),
            payload: {
              ...(plugBackendUpdateErrorBank?.[section]?.[resource]?.[
                resourceId
              ]?.payload || {}),
              ...newUpdates,
            },
            hostFn: hostFunc,
            fn: func,
            dispatcher,
            msg,
            callback,
            ts: new Date(),
          },
        },
      },
    };

    !!Object.keys(data)[0] &&
      dispatch(updateBackendErrorBank(updatedErrorBank));
  };

export const updateEndpointError =
  (section, action, resource, { id: resourceId, ...data }) =>
  async (dispatch, getState) => {
    const { plugBackendUpdateErrorBank: currentStatus } = getState().reducers;

    // delete currentStatus[`${resource}.${resourceId}.${Object.keys(data)[0]}`];
    Object.keys(data).forEach((key) => {
      delete currentStatus?.[section]?.[resource]?.[resourceId]?.payload?.[key];
    });

    if (
      !Object.keys(
        currentStatus?.[section]?.[resource]?.[resourceId]?.payload || {}
      ).length
    ) {
      delete currentStatus?.[section]?.[resource]?.[resourceId];
      if (!Object.keys(currentStatus?.[section]?.[resource] || {}).length) {
        delete currentStatus?.[section]?.[resource];
        if (!Object.keys(currentStatus?.[section] || {}).length) {
          delete currentStatus?.[section];
        }
      }
    }

    !!Object.keys(data)[0] && dispatch(updateBackendErrorBank(currentStatus));
  };

export const removeWorkflowTask =
  (removed, canvasData, clearingTask = true) =>
  async (dispatch, getState) => {
    const {
      workflowTasks: { ...currentValue },
      activeWorkflow: { id: workflowId },
      activeTask,
    } = getState().workflows;

    //  deprecated code, since all tasks are still being sent across
    /* preAPIs(dispatch, deleteWorkflowNodesAPI, {
      removed,
      id: workflowId,
    }); */

    const isNodeDeletion = removed.find(
      (element) => !element.source && !element.target
    );
    if (isNodeDeletion) {
      preAPIs(dispatch, deleteWorkflowTaskAPI, {
        id: currentValue[activeTask.id]?.id,
      });
    }

    // dispatch(rHideRightSideBar(true));
    clearingTask && delete currentValue[activeTask.id];

    dispatch(rLoadWorkflowTasks(currentValue));
    dispatch(rUpdateWorkflowCanvas([...(canvasData || [])]));
    // dispatch(rSelectActiveTask({}));
    clearingTask && dispatch(clearActiveTask());

    //  ALSO CLEAR VARIABLES ...BRB

    //  process new variables structure into data
    const varStruct = await dispatch(
      updateTaskVariables({
        linkedValues: {
          action: "delete",
          target: removed,
        },
      })
    );

    const processedData = processDataVariables(
      [...(canvasData || [])],
      varStruct
    );
    if (!processedData?.length) {
      dispatch(
        SetAppStatus({
          type: "error",
          msg: "Sorry an error occured. Please try again.",
        })
      );
      return;
    }

    const payload = {
      id: workflowId,
      tasks: processedData,
    };

    //  update db with new structure
    preAPIs(dispatch, updateWorkflowAPI, payload);
    // updateWorkflowAPI(payload);
  };

const getFreshWorkflowVariables = (data) => {
  const variables = {};
  data.forEach((tsk) => {
    variables[tsk.id] = tsk.variables;
  });
  return variables;
};

export const clearWorkflowCanvas = () => async (dispatch, getState) => {
  const { id: workflowId } = getState().workflows.activeWorkflow;
  const saved = await preAPIs(dispatch, clearWorkflowTasksAPI, workflowId);

  dispatch(rLoadWorkflowTasks({}));
  // dispatch(rSelectActiveTask({}));
  dispatch(clearActiveTask());

  if (!saved?.data?.tasks) return;

  dispatch(rUpdateWorkflowCanvas(saved?.data?.tasks));
  dispatch(
    rSetWorkflowVariables(getFreshWorkflowVariables(saved?.data?.tasks))
  );
};

export const clearActiveTask = () => (dispatch, getState) => {
  dispatch(rSelectActiveTask({}));
  dispatch(rHideRightSideBar(true)); //  display rightsidebar
};

export const toggleWorkflowFullScreen = () => (dispatch, getState) => {
  const { workflowFullScreen } = getState().workflows;
  dispatch(rToggleWorkflowFullScreen(!workflowFullScreen));
};

export const updateTaskVariables =
  ({
    directValue: { taskId, taskVariables } = {},
    linkedValues: { source: source_, target: target_, action: action_ } = {},
    saveToDB,
  }) =>
  async (dispatch, getState) => {
    const {
      variables: { ...currentVariables },
      workflowCanvas: [...currentCanvas],
    } = getState() && getState()?.workflows;

    let source = source_;
    let target = target_;
    let action = action_;
    let targetHasTarget;
    let counter = 0; //  this should be removed eventually

    if (taskId) {
      currentVariables[taskId] = taskVariables;
      target = { id: taskId };
      action = "update";
    }

    recursivelySetVariables();

    function recursivelySetVariables() {
      counter++;
      targetHasTarget = false;

      switch (action) {
        case "new":
          currentVariables[target.id] = getDefaultVariables(target);
          break;

        case "connect":
          const sourceNodeVariables = currentVariables[source.id] || [];
          const targetNodeVariables = currentVariables[target.id] || [];

          const mergedSourceToTarget = targetNodeVariables;

          /* 
            update the parent in source variables...
            and add to target (next) variables 
          */
          sourceNodeVariables.forEach((variable) => {
            /* 
              first skip variables that already exists in target 
              (perhaps from another link) 
            */
            const _variable = { ...variable }; //  !important
            if (
              !targetNodeVariables.find(
                (targetVariable) => _variable.id === targetVariable.id
              )
            ) {
              _variable.parent = target.id;
              mergedSourceToTarget.push(_variable);
            }
          });

          currentVariables[target.id] = mergedSourceToTarget;

          /* 
            finally, remove orphan variables: 
            that is variables that nolonger exist in their parents 
            (for now, just immediate source vars) 
          */
          currentVariables[target.id] = [
            ...(currentVariables[target.id] || []).filter(
              (variable) =>
                variable.matching?.valueSourceId !== source.id ||
                !!(sourceNodeVariables || []).find(
                  (sourceNodeVariable) => variable.id === sourceNodeVariable.id
                )
            ),
          ];

          break;

        // case "reconnect":
        //   recursivelySetVariables("delete");
        //   recursivelySetVariables("connect");
        //   break;

        case "delete":
          const foundNode = target_.find((el) => !!el.position);
          const foundLinks =
            target_.filter((el) => {
              if (!!foundNode) return el.source === foundNode.id;
              else return !!el.target;
            }) || [];

          foundLinks?.forEach((foundLink) => {
            target = {
              id: foundLink.target,
              type:
                getCanvasTaskDetails(foundLink.target)?.type ||
                WORKFLOWS_TASK_SCREEN,
            };

            if (currentVariables[target.id]) {
              const sourceVariables = currentVariables[foundLink.source];
              const targetVariables = currentVariables[foundLink.target];

              //  then remove source vars from current/target var:
              //  retain vars that are generated from current node OR don't exist in source node

              const resultantVariables = [
                ...(targetVariables || []).filter((variable) => {
                  // const condition_1 = variable.parent === target.id;

                  //  check if variable originates from current task
                  const condition_1 =
                    variable.matching.valueSourceId === target.id;

                  //  check if variable is in any of the other sources
                  const condition_2 = checkIfVariableIsFromOtherSources(
                    foundLink,
                    variable.id
                  );

                  return condition_1 || condition_2;
                }),
              ];

              currentVariables[target.id] = resultantVariables;
            }
          });

          if (!!foundNode) {
            delete currentVariables[foundNode.id];
            target = {
              id: foundNode.id,
              type:
                getCanvasTaskDetails(foundNode.id)?.type ||
                WORKFLOWS_TASK_SCREEN,
            };
          }
          break;

        default:
          break;
      }

      const founds = !!target?.id
        ? currentCanvas.filter((itm) => itm.source === target?.id)
        : null;

      !!founds &&
        founds
          .filter((found) => found.type !== WORKFLOWS_TASK_END)
          .forEach((found) => {
            source = target;
            target = {
              id: found.target,
              type:
                getCanvasTaskDetails(found.target)?.type ||
                WORKFLOWS_TASK_SCREEN,
            };
            action = "connect";
            targetHasTarget = true;
            if (targetHasTarget && counter < 1000) recursivelySetVariables();
          });
    } //  counter condition to be removed

    const workflowEndTaskId = currentCanvas.find(
      (task) => task.type === WORKFLOWS_TASK_END
    )?.id;
    delete currentVariables[workflowEndTaskId];

    dispatch(rSetWorkflowVariables(currentVariables || {}));

    if (saveToDB) {
      // alert("BINGO!")
      const { id: workflowId } = getState().workflows.activeWorkflow;
      const processedData = processDataVariables(
        currentCanvas,
        currentVariables
      );

      const payload = {
        id: workflowId,
        tasks: processedData,
      };

      const returnedResponse = await preAPIs(
        dispatch,
        updateWorkflowAPI,
        payload
      );

      dispatch(generateVariablesWarning(returnedResponse?.data || []));
    }

    return currentVariables;

    function checkIfVariableIsFromOtherSources(foundLink, variableId) {
      let checkResult = false;
      //  get variables of other sources
      const otherSourceNodes = currentCanvas
        .filter(
          (link) =>
            link.target === foundLink.target && link.source !== foundLink.source
        )
        .map((link) => ({ variables: currentVariables[link.source] }));

      //  loop through other source variables for existence of variable
      for (let index = 0; index < otherSourceNodes.length; index++) {
        const otherVariables = otherSourceNodes[index].variables;

        const varFound = otherVariables?.find(
          (otherVariable) => variableId === otherVariable.id
        );

        if (!!varFound) {
          checkResult = true;
          break;
        }
      }

      return checkResult;
    }

    function getDefaultVariables(obj) {
      // alert(obj.type)
      // alert(JSON.stringify(elements))
      switch (obj.type) {
        case WORKFLOWS_TASK_SCREEN:
          return [];

        case WORKFLOWS_TASK_MAIL:
          return [];

        case WORKFLOWS_TASK_DATA:
          return [];
        case WORKFLOWS_TASK_CUSTOM:
          return [];

        case WORKFLOWS_TASK_APPROVAL:
          return [
            {
              id: v4(),
              name: "Approver (Name)",
              parent: obj.id,
              nodeType: obj.type,
              dataType: ["name", "value"],
              group: "Approval",
              matching: {
                valueSourceType: "ApprovalTask",
                valueSourceId: obj.id,
                valueSourceInput: "approverName",
              },
            },
            {
              id: v4(),
              name: "Approver (Email)",
              parent: obj.id,
              nodeType: obj.type,
              dataType: ["email"],
              group: "Approval",
              matching: {
                valueSourceType: "ApprovalTask",
                valueSourceId: obj.id,
                valueSourceInput: "approverEmail",
              },
            },
            {
              id: v4(),
              name: "Approver (ID)",
              parent: obj.id,
              nodeType: obj.type,
              dataType: ["employeeId"],
              group: "Approval",
              matching: {
                valueSourceType: "ApprovalTask",
                valueSourceId: obj.id,
                valueSourceInput: "approverId",
              },
            },
            {
              id: v4(),
              name: "Time of Approval",
              parent: obj.id,
              nodeType: obj.type,
              dataType: ["datetime"],
              group: "Approval",
              matching: {
                valueSourceType: "ApprovalTask",
                valueSourceId: obj.id,
                valueSourceInput: "approvalTimestamp",
              },
            },
            {
              id: v4(),
              name: "Approval Action",
              parent: obj.id,
              nodeType: obj.type,
              dataType: ["text"],
              group: "Approval",
              matching: {
                valueSourceType: "ApprovalTask",
                valueSourceId: obj.id,
                valueSourceInput: "approverAction",
              },
            },
            {
              id: v4(),
              name: "Comment",
              parent: obj.id,
              nodeType: obj.type,
              dataType: ["text"],
              group: "Approval",
              matching: {
                valueSourceType: "ApprovalTask",
                valueSourceId: obj.id,
                valueSourceInput: "approverComment",
              },
            },
          ];

        case WORKFLOWS_TASK_COMPUTATION:
          return [
            /* {
                        id: v4(),
                        name: 'Computation Output',
                        parent: obj.id,
                        nodeType: obj.type,
                        dataType: ['name', 'value', 'email', 'text'],
                        group: 'Computation',
                        matching: {
                            valueSourceType: 'ComputationTask',
                            valueSourceId: obj.id,
                            valueSourceInput: "name",
                        }
                    }, */
          ];

        case WORKFLOWS_TASK_CALENDAR:
          return [];

        default:
          break;
      }
    }

    function getCanvasTaskDetails(taskId) {
      return currentCanvas.find((task) => task.id === taskId);
    }
  };

const processDataVariables = (data, varys) => {
  const processed = data
    ? data.map((d) => {
        if (!!varys[d.id]) d.variables = varys[d.id];
        return d;
      })
    : null;
  return processed;
};

const generateVariablesWarning = (workflowData) => (dispatch) => {
  const workflowTasks = workflowData?.tasks;
  const heavyTasks = workflowTasks
    .filter((task) => task.variable?.length > VARIABLES_LENGTH_THRESHOLD)
    .map((task) => ({
      id: task.id,
      name: task.name,
      type: task.type,
      variables: task.variables?.length,
    }));
  dispatch(rWorkflowVariablesWarning(heavyTasks));
};

export const triggerHeavyVariablesBackendFix =
  () => async (dispatch, getState) => {
    const { id } = getState().appsReducer.selectedApp;

    const payload = { id };

    const response = await preAPIs(
      dispatch,
      trimVariablesAppWorkflowAPI,
      payload
    );
    console.log("------response >> ", response);

    window.location.reload();
  };
