import React, { useState, useEffect, useRef } from "react";
import {
  updateComponentAttribute,
  updateStylesAndValues,
  updateValues,
} from "../../../../Pages/UIEditor/utils/canvasHelpers";
import { useDispatch } from "react-redux";
import SidebarNameSection from "../components/SidebarNameSection";
import TableColumnsPreferences from "../components/TableColumnsPreferences";
import SidebarFieldPreferenceSection from "../components/SidebarFieldPreferenceSection";

export default function DisplayTableSidebar(props) {
  const {
    style,
    id,
    type: itemType,
    title,
    parent: container,
    index,
    name,
    values,
    data,
    dataType,
    updateData,
    isInsideContainer,
    containingChildIndex,
    isSaved,
  } = props;

  const [expanded, setExpanded] = useState(null);
  const [isDynamic, setIsDynamic] = useState(!!name?.startsWith("@"));

  useEffect(() => {
    if (!style) return;
    if (!values?.columns?.length) {
      const newColumnData = [
        {
          dataText: "Title-1",
          id: getRandomId(),
          relWidth: 1,
        },
        {
          dataText: "Title-2",
          id: getRandomId(),
          relWidth: 1,
        },
      ];
      // onStyleChangeOrValues(newColumnData, "values", "columns");
      if (isSaved)
        dispatch(
          updateValues({
            value: newColumnData,
            property: "columns",
            index,
            isInsideContainer,
            containingChildIndex,
          })
        );
    }

    const newRow = getRandomId();
    if (!values?.rows?.length) {
      const newRowData = [
        {
          title: "1.",
          id: newRow,
          relWidth: 1,
        },
      ];
      onStyleChangeOrValues(newRowData, "values", "rows");
    }

    if (!values?.values) {
      const newData = {
        [newRow]: [null, null],
      };
      onStyleChangeOrValues(newData, "values", "values");
    }
  }, [values]);

  const dispatch = useDispatch();
  const onStyleChangeOrValues = (value, parent, property, changeType) => {
    dispatch(
      updateStylesAndValues({
        value,
        parent,
        property,
        id,
        type: itemType,
        changeType,
        container,
        index,
      })
    );
  };
  const onNameChange = ({ target: { name, value } }) =>
    dispatch(
      updateComponentAttribute({
        attrib: "name",
        value,
        index,
        isInsideContainer,
        containingChildIndex,
      })
    );

  const handleToggleFixed = (e) => {
    // if(!e.target.checked) {
    //   const confm = window?.confirm(`Any data in this table will be cleared. Continue?`);
    //   if(!confm) return;
    //   const newData = {
    //     [newRow]: [ null, null ],
    //   }
    //   onStyleChangeOrValues(newData, "values", "values");
    // }
    onStyleChangeOrValues(e.target.checked, "values", "fixed");
  };

  const _addLine = (sel, id) => {
    const field = [...values[sel]] || [];

    const indx = field.findIndex((i) => i.id === id);
    // const indx = field.indexOf(id);
    const newId = getRandomId();

    if (indx < field.length - 1)
      field.splice(indx + 1, 0, {
        title: `Title-${indx + 1}x`,
        id: newId,
        relWidth: 1,
      });
    else field.push({ title: `Title-${indx + 1}x`, id: newId, relWidth: 1 });

    onStyleChangeOrValues(field, "values", sel);

    const data = { ...values?.values } || {};

    if (sel === "columns") {
      Object.keys(data).forEach((dt, i) => {
        if (indx < data[dt].length - 1) data[dt].splice(indx + 1, 0, null);
        else data[dt].push(null);
      });
    } else if (sel === "rows") {
      const newArray = new Array(data[Object.keys(data)[0]].length).fill(null);
      data[newId] = newArray;
    }

    onStyleChangeOrValues(data, "values", "values");
  };

  const _removeLine = (sel, id) => {
    const field = [...values[sel]] || [];
    const indx = field.findIndex((i) => i.id === id);
    // const indx = field.indexOf(id);
    const data = { ...values?.values } || {};

    field.splice(indx, 1);

    onStyleChangeOrValues(field, "values", sel);

    if (sel === "columns") {
      Object.keys(data).forEach((dt, i) => {
        data[dt].splice(indx, 1);
      });
    } else if (sel === "rows") {
      delete data[id];
    }

    onStyleChangeOrValues(data, "values", "values");
  };

  const handleChangeAttribute = (sel, attrib, indx, e) => {
    const fields = style.values[sel];
    fields[indx][attrib] = e.target.value;

    onStyleChangeOrValues(fields, "values", sel);
  };

  function getRandomArray(length) {
    const arr = [];
    for (let itr = 0; itr < length; itr++) {
      arr.push(getRandomId());
    }
    return arr;
  }
  function getRandomId() {
    return `${new Date().getTime()}-${Math.round(Math.random() * 1000)}`;
  }

  const _expanded = (status, catg) => {
    const expnd = expanded === catg ? null : catg;
    setExpanded(expnd);
  };

  return (
    <div className="sidebar-container">
      <SidebarNameSection
        itemId={id}
        itemType={itemType}
        name={name}
        title={title}
        index={index}
        isInsideContainer={isInsideContainer}
        containingChildIndex={containingChildIndex}
        isDynamic={isDynamic}
        setIsDynamic={setIsDynamic}
      />
      <SidebarFieldPreferenceSection
        itemType={itemType}
        name={name}
        title={title}
        index={index}
        isInsideContainer={isInsideContainer}
        containingChildIndex={containingChildIndex}
        values={{ ...values }}
        dataType={dataType}
        isDynamic={isDynamic}
        setIsDynamic={setIsDynamic}
        updateData={updateData}
      />

      <div className="sidebar-container-scroll">
        <TableColumnsPreferences
          itemType={itemType}
          name={name}
          title={title}
          index={index}
          isInsideContainer={isInsideContainer}
          containingChildIndex={containingChildIndex}
          values={{ ...(values || {}) }}
          setIsDynamic={setIsDynamic}
          isDynamic={isDynamic}
        />
      </div>

      {/* <div>
        <Checkbox
          checked={values?.fixed}
          onChange={(e) => handleToggleFixed(e)}
        />
        Fixed Table
      </div>
      <Divider /> */}
      {/* <div
        className={classes.section}
        style={{ display: "flex", flexDirection: "column", paddingBottom: 0 }}
      >
        <Accordion
          expanded={expanded === "columns"}
          square={false}
          onChange={(e, ex) => _expanded(ex, "columns")}
          style={{ boxShadow: "none" }}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon className={classes.summaryIcon} />}
            aria-controls="visual-elements"
            id="visual-elements"
            // style={{ padding: 0 }}
            // className={classes.elementButton}
            classes={{
              root: classes.normalHeight,
              content: classes.keepMargin,
              expandIcon: classes.elementButton,
            }}
          >
            <Typography gutterBottom className={classes.sectionTitle}>
              Table Columns
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className={classes.expansionDetails}
            classes={{
              root: classes.detailsPadding,
            }}
          >
            <div style={{ width: "100%" }}>
              {_columns_.map((col, index) => (
                <div style={{ display: "flex", flex: 1, marginBottom: 3 }}>
                  <InputText
                    variant="outlined"
                    size="small"
                    placeholder={`Enter Column ${index + 1} Title`}
                    style={{ flex: 1 }}
                    defaultValue={col.title || `Title-${index}`}
                    onChange={(e) =>
                      handleChangeAttribute("columns", "title", index, e)
                    }
                    rows={2}
                    inputProps={{
                      min: 0,
                      className: classes.input,
                    }}
                  />
                  <IconButton
                    size="small"
                    aria-label="Add"
                    className={classes.margin}
                    onClick={() => _addLine("columns", col.id)}
                  >
                    <AddCircle />
                  </IconButton>
                  {_columns_.length > 1 && (
                    <IconButton
                      size="small"
                      aria-label="Close"
                      className={classes.margin}
                      onClick={() => _removeLine("columns", col.id)}
                    >
                      <CancelIcon />
                    </IconButton>
                  )}
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <Divider /> */}
      {/* <div
        className={classes.section}
        style={{ display: "flex", flexDirection: "column", paddingBottom: 0 }}
      >
        <Accordion
          expanded={expanded === "rows"}
          square={false}
          onChange={(e, ex) => _expanded(ex, "rows")}
          style={{ boxShadow: "none" }}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon className={classes.summaryIcon} />}
            aria-controls="visual-elements"
            id="visual-elements"
            // style={{ padding: 0 }}
            // className={classes.elementButton}
            classes={{
              root: classes.normalHeight,
              content: classes.keepMargin,
              expandIcon: classes.elementButton,
            }}
          >
            <Typography gutterBottom className={classes.sectionTitle}>
              Table Rows
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className={classes.expansionDetails}
            classes={{
              root: classes.detailsPadding,
            }}
          >
            <div style={{ width: "100%" }}>
              {_rows_.map((row, index) => (
                <div style={{ display: "flex", flex: 1, marginBottom: 3 }}>
                  <InputText
                    variant="outlined"
                    size="small"
                    placeholder={`Enter Row ${index + 1} Title`}
                    style={{ flex: 1 }}
                    // defaultValue={"2px"}
                    rows={2}
                    inputProps={{
                      min: 0,
                      className: classes.input,
                    }}
                  />
                  <IconButton
                    size="small"
                    aria-label="Add"
                    className={classes.margin}
                    onClick={() => _addLine("rows", row.id)}
                  >
                    <AddCircle />
                  </IconButton>
                  {_rows_.length > 1 && (
                    <IconButton
                      size="small"
                      aria-label="Close"
                      className={classes.margin}
                      onClick={() => _removeLine("rows", row.id)}
                    >
                      <CancelIcon />
                    </IconButton>
                  )}
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <Divider /> */}
      {/* <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Cell Content
        </Typography>
        <Grid container spacing={1} className={classes.colTitle}>
          <Grid item xs={3}>
            <div style={{ display: "inline-flex" }}>
              <Typography gutterBottom className={classes.sectionRow}>
                Column 1 Row 2
              </Typography>
            </div>
          </Grid>
          <Grid item xs={9} style={{ display: "inline-flex" }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Enter title"
              // defaultValue={"2px"}
              rows={2}
              style={{ width: "90%" }}
              inputProps={{
                min: 0,
                className: classes.input,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.colTitle}>
          <Grid item xs={3}>
            <div style={{ display: "inline-flex" }}>
              <Typography gutterBottom className={classes.sectionRow}>
                Column 2 Row 2
              </Typography>
            </div>
          </Grid>
          <Grid item xs={9} style={{ display: "inline-flex" }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Enter title"
              // defaultValue={"2px"}
              rows={2}
              style={{ width: "90%" }}
              inputProps={{
                min: 0,
                className: classes.input,
              }}
            />
          </Grid>
        </Grid>
      </div> */}
    </div>
  );
}
