import { composeQueryString } from "../../../common/composeQueryString";
import { CustomAxios } from "../../../common/CustomAxios";

/**
 * It's a function that takes in an object with a queryKey property that is an array of objects with a
 * type and propertyType property. It then uses those properties to make a GET request to an endpoint
 * and returns the data from the response.
 * @returns {
 *   "data": [
 *     {
 *       "id": "5d8f8f8f8f8f8f8f8f8f8f8f",
 *       "type": "type1",
 *       "properties": {
 *         "type": "propertyType1",
 *         "name": "name1",
 *         "
 */
export const getAllIntegration = async ({
  queryKey: [, { type, propertyType }],
  queryKey,
}) => {
  console.log("querykey :>> ", queryKey);
  const result = await CustomAxios().get(
    `${process.env.REACT_APP_ENDPOINT}/integrations?type=${type}&properties.type=${propertyType}&active=true&per_page=100`
  );
  return result.data;
};
/**
 * It takes an object of options, composes a query string, and then makes a GET request to an endpoint.
 * @param options - {
 * @returns An object with a data property that is an array.
 */

export const getIntegrationDataAPI = async (options) => {
  const queryStr = composeQueryString(options);
  if (queryStr === false) return { data: [] };

  const result = await CustomAxios().get(
    `${process.env.REACT_APP_ENDPOINT}/integrations${queryStr}`
  );

  return result.data;
};
/**
 * It's a function that makes a GET request to an endpoint, and returns the data from the response.
 * @returns An array of objects.
 */

export const getIntegrationResourcesListAPI = async ({
  queryKey: [, { id }],
}) => {
  const result = await CustomAxios().get(
    `${process.env.REACT_APP_ENDPOINT}/integrations/resources/${id}`
  );

  return result.data;
};

export const getDataSheetsAPI = async (options) => {
  const queryStr = composeQueryString(options);
  if (queryStr === false) return { data: [] };

  const result = await CustomAxios().get(
    `${process.env.REACT_APP_ENDPOINT}/datasheets${queryStr}`
  );

  return result.data;
};

export const getGoogleSheetSheet = async (options) => {
  console.log(`1&&& >> getGoogleSheetSheet >>> ${JSON.stringify(options)}`);
  const queryStr = composeQueryString(options);
  console.log(`2&&& >> getGoogleSheetSheet >>> ${JSON.stringify(queryStr)}`);
  if (queryStr === false) return { data: [] };

  const result = await CustomAxios().get(
    `${process.env.REACT_APP_ENDPOINT}/google-apis/sheets${queryStr}`
  );

  return result.data;
};
