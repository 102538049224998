import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 } from "uuid";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  setActiveItem,
  reorderContainerContent,
  deleteContainerElement,
  duplicateContainerElement,
} from "../../../../../Pages/UIEditor/utils/canvasHelpers";
import ElementComponentWrapper from "../../ElementComponentWrapper";
import RealComponents from "../../ElementsComponent";
import {
  APP_DESIGN_MODES,
  SCREEN_REUSE_ATTRIBUTES,
} from "../../../../../../../utils/constants";
import { manageLogicalOperators } from "../../../../../../Run/comparison";

const Form = ({
  style,
  screenStyles,
  onFormSubmit,
  returnedLookupObj,
  children,
  name,
  capturedData,
  appDesignMode,
  ...props
}) => {
  const useStyles = makeStyles((theme) => style);
  const classes = useStyles();
  const dispatch = useDispatch();
  //const [renderedItems, setRenderedItems] = useState(children);
  const activeItem = (data) => dispatch(setActiveItem({ ...data }));
  const onDelete = (data) => dispatch(deleteContainerElement({ ...data }));

  const onDuplicate = (data) =>
    dispatch(duplicateContainerElement({ ...data }));

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    switch (source.draggableId) {
      case destination.draggableId:
        return dispatch(
          reorderContainerContent({
            index: props.containerIndex,
            parent: props.parent,
            source,
            destination,
          })
        );
      default:
        return null;
    }
  };

  const getReuseValue = (item, meta) => {
    return !meta
      ? props?.screenReuseAttributes?.[item.id]?.value
      : props?.screenReuseAttributes?.[item.id]
      ? props.metadata?.[
          `${props?.screenReuseAttributes?.[item.id]?.sourceVariable}_meta`
        ]
      : "";
  };

  return (
    <div className={classes?.form} /*  style={{ overflow: "auto" }} */>
      {/* <form onSubmit={onFormSubmit} > */}
      {appDesignMode !== APP_DESIGN_MODES.EDIT &&
        children
          ?.filter(
            (item) =>
              appDesignMode !== APP_DESIGN_MODES.LIVE ||
              props?.screenReuseAttributes?.[item.id]?.attribute !==
                SCREEN_REUSE_ATTRIBUTES.HIDDEN
          )
          .map(
            (item, index) =>
              manageLogicalOperators(
                item?.values?.conditionalSymbol,
                capturedData?.[item?.values?.conditionalElement],
                item?.values?.conditionalValue,
                item?.values?.conditionals,
                item?.type
              ) && (
                <div
                  key={index}
                  style={{
                    marginBottom: style?.page?.lineSpacing,
                    ...(item.type !== "userPicker"
                      ? { overflow: "hidden" }
                      : {}),
                  }}
                >
                  <ElementComponentWrapper
                    // key={index}
                    parent={"form"}
                    appDesignMode={appDesignMode}
                    type={item.type}
                    id={item.id}
                    style={style}
                    /* style={{
                ...screenStyles,
                ...(item.style?.overrideDefault === true ? item.style : {}),
              }} */
                    onClick={(e) =>
                      activeItem({
                        item,
                        index: props.containerIndex,
                        containerType: "form",
                        parent: props.parent,
                        containingChildIndex: index,
                        isInsideContainer: true,
                      })
                    }
                  >
                    <RealComponents
                      {...props}
                      type={item.type}
                      customId={item.customId || null}
                      customName={item.customName || null}
                      onChange={props.onChange}
                      action={props.action}
                      returnedLookupObj={
                        returnedLookupObj &&
                        Object?.values(returnedLookupObj)?.some(
                          (value) => value != null
                        ) &&
                        returnedLookupObj
                      }
                      // style={item.style}
                      {...item}
                      // style={{ ...style, ...item.style }}
                      style={{
                        ...screenStyles,
                        ...(item.style?.overrideDefault === true
                          ? item.style
                          : {}),
                      }}
                      // reuseAttribute={props?.screenReuseAttributes?.[item.id]}
                      // disabled={props?.screenReuseAttributes?.[item.id]}
                      {...(props?.screenReuseAttributes?.[item.id]
                        ? {
                            readOnly:
                              props?.screenReuseAttributes?.[item.id]
                                ?.attribute ===
                              SCREEN_REUSE_ATTRIBUTES.READONLY,
                          }
                        : {})}
                      // val={props.metadata?.[`${name}_${item.name}`]}
                      val={getReuseValue(item)}
                      valMeta={getReuseValue(item, "_meta")}
                      id={item.id}
                      name={item?.name}
                      value={item.values}
                      appDesignMode={appDesignMode}
                    />
                  </ElementComponentWrapper>
                </div>
              )
          )}
      {appDesignMode === APP_DESIGN_MODES.EDIT && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={name || v4()}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} className={classes?.drop}>
                {children
                  ?.filter((item) => item.type !== "ScreenButtons")
                  ?.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          // className="uieditor-form-child"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            marginBottom: style?.page?.lineSpacing,
                            // marginBottom: style?.form?.spacing || 15,
                          }}
                        >
                          <ElementComponentWrapper
                            parent={"form"}
                            appDesignMode={appDesignMode}
                            type={item.type}
                            id={item.id}
                            onClick={(e) =>
                              activeItem({
                                item,
                                index: props.containerIndex,
                                containerType: "form",
                                parent: props.parent,
                                containingChildIndex: index,
                                isInsideContainer: true,
                              })
                            }
                            onDelete={() =>
                              onDelete({
                                containerIndex: props.containerIndex,
                                parent: props.parent,
                                containingChildId: item.id,
                                containingChildIndex: index,
                              })
                            }
                            onDuplicate={() =>
                              onDuplicate({
                                containerIndex: props.containerIndex,
                                parent: props.parent,
                                containingChildIndex: index,
                              })
                            }
                          >
                            <RealComponents
                              onChange={(val, name) => {}}
                              type={item.type}
                              style_={item.style}
                              customId={item.customId || null}
                              customName={item.customName || null}
                              {...item}
                              // style={style}
                              style={{
                                ...screenStyles,
                                ...(item.style?.overrideDefault === true
                                  ? item.style
                                  : {}),
                              }}
                              val={props.metadata?.[`${name}_${item.name}`]}
                              appDesignMode={appDesignMode}
                              name={item?.name}
                              id={item.id}
                              index={index}
                            />
                          </ElementComponentWrapper>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {!children?.length && appDesignMode === APP_DESIGN_MODES.EDIT && (
        <div
          className={classes?.empty}
          style={{
            color: "#AAA",
            fontWeight: 200,
            fontStyle: "italic",
            marginTop: 10,
          }}
        >
          [ Configure from properties panel... ]
        </div>
      )}
      {/* </form> */}
    </div>
  );
};

Form.propTypes = {
  onFormSubmit: PropTypes.func,
  style: PropTypes.shape({
    containerIndex: PropTypes.any,
    appDesignMode: PropTypes.string,
    parent: PropTypes.any,
    values: PropTypes.shape({
      children: PropTypes.shape({
        length: PropTypes.any,
        map: PropTypes.func,
      }),
      name: PropTypes.func,
    }),
  }),
};

Form.defaultProps = {
  onFormSubmit: () => {},
};
export default Form;
