import ScreensPanelActions from "../Pages/Screens/utils/ScreensPanelActions";
import WorkflowPanelActions from "../Pages/Workflow/utils/WorkflowPanelActions";

const Workflow = new WorkflowPanelActions();
const Screen = new ScreensPanelActions();

export default class PanelActions {
  async handleNewEntity(args) {
    const page = args.page;
    delete args.page;
    switch (page) {
      case "screens":
        args.dispatch(Screen.handleNewScreen(args));
        break;

      case "workflows":
        args.dispatch(Workflow.handleNewWorkflow(args));
        break;

      default:
        break;
    }
  }

  async handleDisplayOption(args) {
    const page = args.page;
    delete args.page;

    switch (page) {
      case "screens":
        Screen.handleDisplayOption(args);
        break;

      case "workflows":
        Workflow.handleDisplayOption(args);
        break;

      default:
        break;
    }
  }

  handleRename(args) {
    const page = args.page;
    delete args.page;

    switch (page) {
      case "screens":
        Screen.handleRename(args);
        break;

      case "workflows":
        Workflow.handleRename(args);
        break;

      default:
        break;
    }
  }

  // var el = document.getElementById("foo");
  async saveEntityNameChange(args) {
    const page = args.page;
    delete args.page;

    switch (page) {
      case "screens":
        args.dispatch(Screen.saveScreenNameChange(args));
        break;

      case "workflows":
        Workflow.saveWorkflowNameChange(args);
        break;

      default:
        break;
    }
  }

  async handleEntityDelete(args) {
    const page = args.page;
    delete args.page;

    switch (page) {
      case "screens":
        Screen.handleScreenDelete(args);
        break;

      case "workflows":
        Workflow.handleWorkflowDelete(args);
        break;

      default:
        break;
    }
  }

  handleEditEntity(args) {
    const page = args.page;
    delete args.page;

    switch (page) {
      case "screens":
        Screen.handleEditScreen(args);
        break;

      case "workflows":
        Workflow.handleEditEntity(args);
        break;

      default:
        break;
    }
  }

  async handleDuplicateEntity(args) {
    const page = args.page;
    delete args.page;

    switch (page) {
      case "screens":
        args.dispatch(Screen.handleDuplicateScreen(args));
        break;

      case "workflows":
        Workflow.handleDuplicateEntity(args);
        break;

      default:
        break;
    }
  }

  handleEntityFilter(args) {
    const page = args.page;
    delete args.page;
    switch (page) {
      case "screens":
        return Screen.handleScreensFilter(args);

      case "workflows":
        const res = Workflow.handleWorkflowsFilter(args);
        return res; //  FIX THIS!

      default:
        break;
    }
  }

  handlePreviewEntity(args) {
    const { page } = args;
    delete args.page;

    switch (page) {
      case "screens":
        Screen.handlePreviewScreen(args);
        break;

      case "workflows":
        Workflow.handleEditWorkflow(args);
        break;

      default:
        break;
    }
  }

  async fetchAllAndSetDefaultEntity(...args) {
    // alert(JSON.stringify(arguments))
    // const page = args[0]; args.splice(0, 1);
    // alert(JSON.stringify(args))

    // var copy = [].slice.call(arguments);
    // const page = copy.splice(0, 1);

    const page = args[0];
    args.splice(0, 1);
    // myFunc.apply(this, copy);
    // alert(`${JSON.stringify(arguments)}`)
    switch (page) {
      case "screens":
        Screen.fetchAllAndSetDefaultScreen(...args);
        break;

      case "workflows":
        Workflow.fetchAllAndSetDefaultWorkflow(...args); // FIX THIS!!!
        break;

      default:
        break;
    }
  }

  removeUpdateFlag(args) {
    const page = args.page;
    delete args.page;
    switch (page) {
      case "screens":
        Screen.removeUpdateFlag(args);
        break;

      case "workflows":
        Workflow.removeUpdateFlag(args);
        break;

      default:
        break;
    }
  }
}
