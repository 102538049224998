import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, InputBase, TextField, Typography } from "@material-ui/core";
import Required from "../Required";
import { APP_DESIGN_MODES } from "../../../../../../../utils/constants";
import moment from "moment";
import { v4 } from "uuid";

export default function DateTime({
  style,
  values,
  onChange,
  readOnly,
  returnedLookupObj,
  val: reuseValue,
  ...props
}) {
  const [trackDateLookupVal, setTrackDateLookupVal] = useState(null);
  const [trackTimeLookupVal, setTrackTimeLookupVal] = useState(null);
  const [dateKey, setDateKey] = useState(v4());
  const [timeKey, setTimeKey] = useState(v4());

  const useStyles = makeStyles((theme) => ({
    ...style,
    dimensions: {
      // ...style?.dimensions,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    const initValue = reuseValue || returnedLookupObj?.[props?.id];

    if (initValue) {
      const timeFormat = ["HH:mm", "HH:mm:ss", "hh:mm A", "hh:mm:ss A"];
      const hasTime = /\b(\d{1,2}:\d{2}(:\d{2})?(\s?[APMapm]{2})?)\b/;
      const matchTme = initValue?.match(hasTime);
      const timeVal = matchTme ? matchTme[0] : null;

      setTrackDateLookupVal(moment(initValue).format("YYYY-MM-DD"));
      setTrackTimeLookupVal(moment(timeVal, timeFormat, true).format("HH:mm"));

      const finalValue = moment(initValue).format("YYYY-MM-DDTHH:mm");
      onChange(finalValue, props?.id);
      setDateKey(v4());
      setTimeKey(v4());
    }
  }, [returnedLookupObj, props?.id, reuseValue]);

  return (
    <div className={classes?.root} style={{ width: style?.field?.width }}>
      {!values?.labelHide && (
        <Typography gutterBottom className={classes?.label}>
          {values?.label}
          <Required required={values?.required} />
        </Typography>
      )}
      <Grid container spacing={1} className={`${readOnly ? "read-only" : ""}`}>
        {values?.showDate && (
          <Grid item xs={!!values?.stacked || !values.showTime ? 12 : 6}>
            <InputBase
              key={dateKey}
              variant="outlined"
              size="small"
              id="date"
              type="date"
              className={classes?.field}
              style={{ width: "100%", padding: "0 10px" }}
              placeholder={values?.datePlaceholder}
              inputProps={{
                style: {
                  color: style?.field?.color,
                  textAlign: style?.field?.textAlign,
                  borderStyle: "solid",
                },
              }}
              disabled={
                props.appDesignMode === APP_DESIGN_MODES.EDIT ||
                props.appDesignMode === APP_DESIGN_MODES.PREVIEW ||
                props.disabled
              }
              required={values?.required}
              defaultValue={reuseValue?.split("T")?.[0] || trackDateLookupVal}
              readOnly={readOnly}
              onChange={(e) => onChange(e.target.value, props.id)}
            />
          </Grid>
        )}
        {values?.showTime && (
          <Grid item xs={values?.stacked || !values.showDate ? 12 : 6}>
            <InputBase
              key={timeKey}
              variant="outlined"
              size="small"
              placeholder={values?.timePlaceholder}
              className={classes?.field}
              id="input-with-icon-textfield"
              style={{ width: "100%", padding: "0 10px" }}
              type="time"
              inputProps={{
                style: {
                  color: style?.field?.color,
                  textAlign: style?.field?.textAlign,
                  // height: style?.field?.height,
                  borderStyle: "solid",
                },
              }}
              disabled={props.appDesignMode !== APP_DESIGN_MODES.LIVE}
              defaultValue={reuseValue?.split("T")?.[1] || trackTimeLookupVal}
              readOnly={readOnly}
              onChange={(e) => onChange(e.target.value, props.id)}
            />
          </Grid>
        )}
      </Grid>
      {values?.showTooltip && (
        <div className={classes?.component}>
          <Typography className={classes?.toolTip}>
            {values?.toolTip}
          </Typography>
        </div>
      )}
    </div>
  );
}
