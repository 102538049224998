import { useEffect, useState } from "react";
import CustomStyleSection from "../components/CustomStyleSection";
import SidebarFieldPreferenceSection from "../components/SidebarFieldPreferenceSection";
import SidebarLabelPreferenceSection from "../components/SidebarLabelPreferenceSection";
import SidebarNameSection from "../components/SidebarNameSection";

export default function InputTextSidebar(props) {
  const {
    values,
    name,
    id,
    title,
    styles,
    stylesOverride,
    type: itemType,
    dataType,
    index,
    containingChildIndex,
    isInsideContainer,
  } = props;

  const [isDynamic, setIsDynamic] = useState(props?.name?.startsWith("@"));

  useEffect(() => {
    console.log(`_____values: ${JSON.stringify(values)}`);
  }, []);
  return (
    <div className="sidebar-container">
      <SidebarNameSection
        itemId={id}
        itemType={itemType}
        name={name}
        title={title}
        index={index}
        isInsideContainer={isInsideContainer}
        containingChildIndex={containingChildIndex}
        isDynamic={isDynamic}
        setIsDynamic={setIsDynamic}
      />

      <div className="sidebar-container-scroll">
        <SidebarFieldPreferenceSection
          itemType={itemType}
          name={name}
          title={title}
          index={index}
          isInsideContainer={isInsideContainer}
          containingChildIndex={containingChildIndex}
          values={{ ...values }}
          dataType={dataType}
          isDynamic={isDynamic}
          setIsDynamic={setIsDynamic}
        />
        <SidebarLabelPreferenceSection
          itemType={itemType}
          name={name}
          title={title}
          index={index}
          isInsideContainer={isInsideContainer}
          containingChildIndex={containingChildIndex}
          values={{ ...values }}
          dataType={dataType}
        />
        <CustomStyleSection
          itemType={itemType}
          index={index}
          name={name}
          title={title}
          isInsideContainer={isInsideContainer}
          containingChildIndex={containingChildIndex}
          values={values}
          dataType={dataType}
          items={["input", "label"]}
          styles={styles}
          stylesOverride={stylesOverride}
        />
      </div>
    </div>
  );
}
