import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Divider, Typography, TextField } from "@material-ui/core";
import LabelControl from "../LabelControls";
import ColouredToggleSwitch from "../ColoredToggleSwitch";
import InputBase from "@material-ui/core/InputBase";
import ChipSelect from "../ChipSelector";
import TextInputStyle from "../TextInputStyle";
import RequiredEditable from "../RequiredEditable";
import { useDispatch } from "react-redux";
import {
  updateStylesAndValues,
  updateAddressPreferences,
} from "../../../../Pages/UIEditor/utils/canvasHelpers";
import titleCase from "../../../../../../helpers/titleCase";

const InputText = withStyles((theme) => ({
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 10,
    paddingLeft: 2,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {},
  sideHeading: {
    color: "#091540",
    fontWeight: 600,
    fontSize: 13,
    padding: "10px 5px 0px 5px",
    display: "flex",
    justifyContent: "space-between",
    "& span": {
      textAlign: "right",
      "& input": {
        paddingLeft: 10,
      },
    },
  },
  section: {
    padding: 10,
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
  },
  sectionLabel: {
    color: "#999",
    fontSize: 10,
    marginRight: 5,
    marginTop: 5,
  },
  fullWidthText: {
    margin: "10px 0",
  },
  input: {
    color: "#091540",
    fontSize: 10,
  },
  center: {
    textAlign: "center",
  },
}));

export default function FileUploadSidebar(props) {
  const { style, id, type, parent: container, index } = props;
  const dispatch = useDispatch();
  const onStyleChangeOrValues = (value, parent, property, changeType) => {
    dispatch(
      updateStylesAndValues({
        value,
        parent,
        property,
        id,
        type,
        changeType,
        container,
        index,
      })
    );
  };
  const onUpdateAddressPreferences = (value, name, changeType, extra) => {
    dispatch(
      updateAddressPreferences({ value, name, id, type, changeType, extra })
    );
  };
  const classes = useStyles();

  const { fileTooltipText } = props;

  const items = [
    { name: "Street", value: "street" },
    { name: "Country", value: "country" },
    { name: "State", value: "state" },
    { name: "Area", value: "area" },
    { name: "Region", value: "region" },
    { name: "Zip code", value: "zip_code" },
    { name: "P.O.Box", value: "poBox" },
  ];

  return (
    <div>
      <Typography gutterBottom className={classes.sideHeading}>
        Address Name:{" "}
        <InputText
          variant="outlined"
          size="small"
          onChange={(e) =>
            onStyleChangeOrValues(e.target.value, "values", "name")
          }
          value={style?.values?.name}
          placeholder="Name"
          style={{ width: "60%" }}
          inputProps={{
            min: 0,
            style: { textAlign: "center" },
            className: classes.input,
          }}
        />
      </Typography>
      <Divider />

      <div className={classes.section}>
        {/* <TextInputStyle /> */}

        <Typography gutterBottom className={classes.sectionTitle}>
          Width
        </Typography>
        <InputText
          size="small"
          placeholder="Enter width"
          value={style?.dimensions?.width}
          onChange={(e) =>
            onStyleChangeOrValues(e.target.value, "dimensions", "width")
          }
          inputProps={{
            className: classes.input,
          }}
        />
      </div>

      <Divider />

      <div className={classes.section}>
        <LabelControl
          labelValue={style?.values?.label}
          fontSize={style?.label?.fontSize}
          fontWeight={titleCase(style?.label?.fontWeight)}
          textAlign={titleCase(style?.label?.textAlign)}
          labelName={(e) => onStyleChangeOrValues(e, "values", "label")}
          color={style?.label?.color}
          selectedSize={(e) => onStyleChangeOrValues(e, "label", "fontSize")}
          selectedWeight={(e) =>
            onStyleChangeOrValues(e, "label", "fontWeight")
          }
          selectedAlign={(e) => onStyleChangeOrValues(e, "label", "textAlign")}
          textColorCallback={(e) => onStyleChangeOrValues(e, "label", "color")}
          labelToggleHide={(e) =>
            onStyleChangeOrValues(e, "values", "labelHide")
          }
          labelHide={style?.values?.labelHide}
        />
      </div>

      <Divider />

      <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Preferences
        </Typography>

        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Address Element
          </Typography>
          <ChipSelect
            items={items}
            data={style?.values?.data}
            handleChange={(value) =>
              onUpdateAddressPreferences(value, null, "select", null)
            }
            handleDelete={(value) =>
              onUpdateAddressPreferences(value, null, "delete", null)
            }
          />
        </div>

        {style?.values?.data?.map((value, index) => (
          <div key={index} style={{ display: "inline-flex" }}>
            <Typography gutterBottom className={classes.sectionLabel}>
              {value.name} Placeholder
            </Typography>
            <InputText
              onChange={(e) =>
                onUpdateAddressPreferences(
                  e.target.value,
                  value.name,
                  "change",
                  index
                )
              }
              size="small"
              placeholder={value.placeholder}
              value={value.placeholder}
              inputProps={{
                className: classes.input,
              }}
            />
          </div>
        ))}

        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Include tooltip
          </Typography>
          <ColouredToggleSwitch />
        </div>
        <TextField
          variant="outlined"
          size="small"
          //value={selectedText}
          onChange={(e) => fileTooltipText(e.target.value)}
          fullWidth
          inputMode="text"
          multiline
          rows={2}
          className={classes.fullWidthText}
          placeholder="Enter tooltip text"
        />
        <Divider />
        <RequiredEditable />
      </div>
    </div>
  );
}
