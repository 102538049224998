import { CustomAxios } from "../../../common/CustomAxios";
import { composeQueryString } from "../../../common/composeQueryString";

export const getIntegrationDataAPI = async (options) => {
  // console.log("option", options);
  const queryStr = composeQueryString(options);
  if (queryStr === false) return { data: [] };

  const result = await CustomAxios().get(
    `${process.env.REACT_APP_ENDPOINT}/integrations${queryStr}`
  );

  // console.log("result.data", result.data);
  return result.data;
};

export const getIntegrationResourcesListAPI = async (id) => {
  // const queryStr = composeQueryString(options);
  // if(queryStr === false) return { data: [] };

  const result = await CustomAxios().get(
    `${process.env.REACT_APP_ENDPOINT}/integrations/resources/${id}`
  );

  return result.data;
};

export const newIntegrationAPI = async ({ data }) => {
  const result = await CustomAxios().post(
    `${process.env.REACT_APP_ENDPOINT}/integrations`,
    data
  );
  return result.data;
};

export const updateIntegrationAPI = async ({ id, data }) => {
  const result = await CustomAxios().put(
    `${process.env.REACT_APP_ENDPOINT}/integrations/${id}`,
    data
  );
  return result.data;
};

export const deleteIntegrationAPI = async ({ id }) => {
  const result = await CustomAxios().delete(
    `${process.env.REACT_APP_ENDPOINT}/integrations/${id}`
  );
  return result.data;
};

export const getGoogleSheetSheet = async (options) => {
  const queryStr = composeQueryString(options);
  if (queryStr === false) return { data: [] };

  const result = await CustomAxios().get(
    `${process.env.REACT_APP_ENDPOINT}/google-apis/sheets${queryStr}`
  );

  return result.data;
};
