import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Typography,
  Select,
  MenuItem,
  Collapse,
} from "@material-ui/core";
import {
  ErrorOutlineOutlined,
  CheckCircleOutlineOutlined,
  SwapHoriz,
} from "@material-ui/icons";
import MyWindowsDimensions from "../../../../../utils/windowsDimension";
import MailActions from "./sidebarActions/MailActions";
import VariablesMenuItems from "../../../utils/VariablesMenuItems";
import useCustomMutation from "../../../../../../../common/CustomMutation";
import useCustomQuery from "../../../../../../../common/CustomQuery";
import {
  getUserIntegrationsAPI,
  requestUrlForDocuSignAPI,
  getUserTemplatesAPI,
  getUserRecipientsAPI,
  getUserDocumentsAPI,
  getUserTabsAPI,
} from "../../../../../../../utils/DocuSignAPIConnect/DocuSignAPIConnect";
import DataMatchingPair from "./sidebarActions/common/DataMatchingPair";
import SelectOnSteroids from "./sidebarActions/common/SelectOnSteroids";
import { globalSetTaskInfo, isConnectedTo } from "../../utils/funcs";
import { NameDescription } from "./sidebarActions/common/NameDescription";

const useStyles = makeStyles((theme) => ({
  sideHeading: {
    color: "#091540",
    // fontWeight: 600,
    fontSize: 11,
    paddingLeft: 10,
    paddingTop: 10,
    textTransform: "capitalize",
    display: "inline-flex",
    alignItems: "center",
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
  section: {
    padding: 10,
    paddingBottom: 20,
    // marginBottom: 100,
  },
  container: {
    overflow: "hidden",
    height: "100%",
    "&:hover": {
      overflow: "overlay",
    },
  },
  actionsListItem: {
    width: "30%",
    height: 80,
    margin: 5,
    display: "inline-block",
    textAlign: "center",
    backgroundColor: "white",
    border: "solid 1px white",
    boxShadow: "1px 1px 3px #ccc",
    borderRadius: 10,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#fafafa",
      border: "solid 1px #eaeaea",
      boxShadow: "none",
      color: "#1846c8",
    },
  },
  closeIcon: {
    fontSize: 16,
    color: "#AAAAAA",
    marginRight: 10,
    cursor: "pointer",
    "&:hover": {
      color: "#091540",
    },
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
    marginBottom: 5,
  },
  input: {
    color: "#091540",
    fontSize: 12,
    marginBottom: 12,
  },
  select: {
    color: "#091540",
    fontSize: 12,
    padding: 10,
  },
  switchLabel: {
    width: "100%",
    justifyContent: "space-between",
    margin: 0,
  },
  matchingFields: {
    borderRadius: 5,
    border: "dashed 1.5px #999",
    padding: "10px 5px 10px 10px",
    backgroundColor: "#f8f8f8",
    marginTop: 7,
  },
  mappingTitle: {
    fontSize: 12,
    flex: 1,
    color: "#f7a712",
    display: "flex",
    justifyContent: "space-between",
  },
  pair: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
    "& > div, & > p": {
      flex: 1,
      "&:first-child": {
        marginRight: 5,
      },
    },
  },
  multiSelect: {
    display: "flex",
    flexWrap: "wrap",
  },
  selectedFields: {
    backgroundColor: "#f8f8f8",
    marginRight: 5,
    marginBottom: 5,
    padding: 5,
    border: "dashed 1px #999",
    borderRadius: 8,
    color: "#888",
  },
  sideHeadingBar: {
    backgroundColor: "#f8f8f8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    height: 29,
    "&:hover": {
      opacity: 0.7,
    },
  },
  sectionEntry: {
    marginBottom: 13,
  },
  addMatch: {
    fontSize: 20,
    boxShadow: "0px 2px 3px #aaa",
    borderRadius: "14%",
    marginTop: 10,
  },
  googleConnect: {
    fontSize: 13,
    margin: "0 8px 16px 0",
    backgroundColor: "#d1241c",
    color: "#ffffff",
    textTransform: "none",
    borderRadius: 3,
  },
  dateTimeFields: {
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
      width: "48%",
    },
  },
}));

const PaymentTaskSidebar = ({
  activeTaskId,
  workflowTasks,
  workflowCanvas,
  allVariables,
  dispatch,
}) => {
  const activeTask = workflowTasks[activeTaskId];
  const variables =
    allVariables[activeTaskId]?.filter(
      (v) => v.parent !== activeTaskId || v.group !== "Approval"
    ) || [];

  const classes = useStyles();
  const [windowDimensions, setWindowDimensions] = useState({});
  const [showSection, setShowSection] = useState({
    settings: true,
    action: false,
  });
  const [taskUpdated, setTaskUpdated] = useState(false);
  const [settingsComplete, setSettingsComplete] = useState(false);
  const [actionComplete, setActionComplete] = useState(false);
  const [fetchConnectionsLoading, setFetchConnectionsLoading] = useState(false);
  const [fetchTemplatesLoading, setFetchTemplatesLoading] = useState(false);
  const [fetchDocumentsLoading, setFetchDocumentsLoading] = useState(false);
  const [docuSignConnections, setDocuSignConnections] = useState([]);
  const [templatesList, setTemplatesList] = useState([]);
  const [loadDTOptions, setLoadDTOptions] = useState({});
  const [documentsList, setDocumentsList] = useState([]);
  const [recipientsList, setRecipientsList] = useState([]);
  const [loadTDOptions, setLoadTDOptions] = useState({});
  const [tabsList, setTabsList] = useState([]);
  const [loadTDTOptions, setLoadTDTOptions] = useState({});
  const [matchingPairs, setMatchingPairs] = useState([{}]);
  const [nodeIsConnectedToAny, setNodeIsConnectedToAny] = useState(false);
  const [nodeIsConnectedToStart, setNodeIsConnectedToStart] = useState(false);
  const reverseMatchingRows = false;

  useEffect(() => {
    setFetchConnectionsLoading(true);
    fetchDocuSignConnections();
  }, []);

  useEffect(() => {
    if (
      !!activeTask?.properties?.docusignAccountId &&
      !!docuSignConnections.length
    ) {
      const { email, account_id } =
        docuSignConnections.find(
          (d) => d.account_id === activeTask?.properties?.docusignAccountId
        ) || {};

      !!account_id &&
        setLoadDTOptions({
          param: {
            email,
            account_id,
          },
        });
    }
  }, [activeTask?.properties?.docusignAccountId, docuSignConnections]);

  useEffect(() => {
    if (!!activeTask?.properties?.templateId && !!templatesList.length) {
      const { email, account_id } =
        docuSignConnections.find(
          (d) => d.account_id === activeTask?.properties?.docusignAccountId
        ) || {};

      !!account_id &&
        setLoadTDOptions({
          param: {
            email,
            account_id,
            templateId: activeTask?.properties?.templateId,
          },
        });
    }
  }, [activeTask?.properties?.templateId, templatesList]);

  useEffect(() => {
    if (!!activeTask?.properties?.documentId && !!documentsList.length) {
      const { email, account_id } =
        docuSignConnections.find(
          (d) => d.account_id === activeTask?.properties?.docusignAccountId
        ) || {};

      !!account_id &&
        setLoadTDTOptions({
          param: {
            email,
            account_id,
            templateId: activeTask?.properties?.templateId,
            documentId: activeTask?.properties?.documentId,
          },
        });
    }
  }, [activeTask?.properties?.documentId, documentsList]);

  useEffect(() => {
    checkSetupStatus(activeTask);
  }, [activeTask]);

  useEffect(() => {
    const isConn = isConnectedTo(activeTaskId, workflowCanvas, "startNode");
  }, [workflowCanvas]);

  useEffect(() => {
    const isConnectedToAnySource = isConnectedTo(
      activeTaskId,
      workflowCanvas,
      "anyNode"
    );
    const isConnectedToStartNode = isConnectedTo(
      activeTaskId,
      workflowCanvas,
      "startNode"
    );
    setNodeIsConnectedToAny(isConnectedToAnySource);
    setNodeIsConnectedToStart(isConnectedToStartNode);
  }, [workflowCanvas, activeTask]);

  const _toggleSection = (e, sect) => {
    e && e.stopPropagation();

    const makeTrue = !showSection[sect];
    const showSection_ = { ...showSection };
    Object.keys(showSection_).forEach((p) => (showSection_[p] = false));
    if (makeTrue) showSection_[sect] = true;
    setShowSection(showSection_);
  };

  const _setTaskInfo = (e, ppty, isGrouped) => {
    !!e.persist && e.persist();

    globalSetTaskInfo(dispatch, e, ppty, isGrouped, activeTask, setTaskUpdated);
  };

  const checkSetupStatus = (info, auto) => {
    // auto && _toggleSection(null, 'action');
    let sC, aC;

    if (!!info?.name) {
      sC = true;
      setSettingsComplete(true);
    } else {
      sC = false;
      setSettingsComplete(false);
    }

    if (
      !!info?.properties?.docusignapi &&
      !!info?.properties?.docusignAccountId &&
      !!info?.properties?.templateId &&
      !!info?.properties?.documentId &&
      // (!tabsList.length || (!!tabsList.length && !!info?.properties?.customTabs?.length)) &&
      recipientsList.length === info?.properties?.recipients?.length
    ) {
      aC = true;
      setActionComplete(true);
    } else {
      aC = false;
      setActionComplete(false);
    }

    return sC && aC;
  };

  const { mutate: fetchDocuSignConnections } = useCustomMutation({
    apiFunc: getUserIntegrationsAPI,
    onSuccess: ({ data }) => {
      const conns = data.data;
      const connections = [];

      conns.forEach((cc) => {
        const { userInfo } = cc;
        userInfo.accounts.forEach((ac) => {
          connections.push({
            email: userInfo.email,
            name: userInfo.name,
            account_id: ac.account_id,
            account_name: ac.account_name,
            docusignapi: cc._id,
          });
        });
      });

      console.log("gcons >> ", JSON.stringify(connections));
      // _preSetTaskInfo(conns._id, "docusignapi");
      setDocuSignConnections(connections);
      setFetchConnectionsLoading(false);
    },
    retries: 0,
  });

  const { mutate: handleDocuSignConnect } = useCustomMutation({
    apiFunc: requestUrlForDocuSignAPI,
    onSuccess: ({ data }) => {
      const url = data?.data?.url;
      window.location.href = decodeURIComponent(decodeURIComponent(url));
    },
    retries: 0,
  });

  //  fetch account templates
  const { isDTLoading, isDTFetching } = useCustomQuery({
    queryKey: ["userDocuSignTemplates", loadDTOptions],
    apiFunc: getUserTemplatesAPI,
    onSuccess: ({ data }) => {
      // window.location.href = url;
      const tList =
        data?.data.map((r) => {
          return { templateId: r.templateId, name: r.name || "[ Template ]" };
        }) || [];

      setTemplatesList(tList);
      setFetchTemplatesLoading(false);
    },
    onError: ({ data }) => {
      //  if authentication expired
      if (data?._meta?.error?.message === "invalid_grant") {
        _handleDocuSignConnect();
      }
    },
  });

  //  fetch template documents
  const { isTDLoading, isTDFetching } = useCustomQuery({
    queryKey: ["userDocuSignTemplates", loadTDOptions],
    apiFunc: getUserDocumentsAPI,
    onSuccess: ({ data }) => {
      const dList =
        data?.data.map((r) => {
          return { documentId: r.documentId, name: r.name || "[ Document ]" };
        }) || [];
      // alert(JSON.stringify(cList))
      setDocumentsList(dList);
      setFetchDocumentsLoading(false);
    },
  });
  //  fetch template recipients
  const { isTRLoading, isTRFetching } = useCustomQuery({
    queryKey: ["userDocuSignRecipients", loadTDOptions],
    apiFunc: getUserRecipientsAPI,
    onSuccess: ({ data }) => {
      const rList = data?.data;
      const recipients = [];
      Object.keys(rList).forEach((r) => {
        Array.isArray(rList[r]) && recipients.push(...rList[r]);
      });
      // alert(JSON.stringify(cList))
      // recipients.sort((a, b) => a.routingOrder - b.routingOrder);
      // console.log(`recipients >>> ${JSON.stringify(recipients)}`)
      setRecipientsList(
        recipients.map((r, i) => {
          return {
            orderOrder: i,
            recipientId: r.recipientId,
            recipientType: r.recipientType,
            roleName: r.roleName,
            routingOrder: r.routingOrder,
          };
        })
      );
      // setFetchDocumentsLoading(false);
    },
  });

  //  fetch documents tabs
  const { isTDTLoading, isTDTFetching } = useCustomQuery({
    queryKey: ["userDocuSignTabs", loadTDTOptions],
    apiFunc: getUserTabsAPI,
    onSuccess: ({ data }) => {
      const tList = data?.data;
      const tabs = [];

      Object.keys(tList).forEach((tab) => {
        tList[tab].forEach((t) => {
          tabs.push({
            tabType: tab,
            tabLabel: t.tabLabel,
            //  ...and for display purposes
            id: t.tabLabel,
            name: t.tabLabel,
          });
        });
      });
      console.log(`>>> tabs >> ${JSON.stringify(tabs)}`);
      setTabsList(tabs);
      // setFetchDocumentsLoading(false);
    },
  });

  const _handleDocuSignConnect = () => {
    const redirectUrl = {
      // "redirectUrl": "https://plug-dev.netlify.app/editor/workflow"
      redirectUrl: `${process.env.REACT_APP_BASE_URL}/editor/workflows`,
    };
    handleDocuSignConnect(redirectUrl);
  };

  const _preSetTaskInfo = (e, ppty, aux) => {
    if (ppty === "docusignAccountId") {
      if (e.target.value === "add") {
        _handleDocuSignConnect();
        return;
      } else {
        const api = docuSignConnections.find(
          (c) => c.account_id === e.target.value
        ).docusignapi;
        _setTaskInfo(api, "docusignapi");
      }
    } else if (ppty === "recipients") {
      const val = [
        ...(activeTask?.properties?.recipients ||
          new Array(recipientsList.length).fill(null)),
      ];
      const ordr = recipientsList.find((r) => r.roleName === aux).orderOrder;
      const rordr = recipientsList.find((r) => r.roleName === aux).routingOrder;
      console.log(
        `1activeTask?.properties?.recipients >>> ${JSON.stringify(
          activeTask?.properties?.recipients
        )}`
      );
      console.log(
        `2activeTask?.properties?.recipients >>> ${JSON.stringify(val)}`
      );
      console.log(
        `3activeTask?.properties?.recipients >>> ${JSON.stringify(ordr)}`
      );
      console.log(
        `4activeTask?.properties?.recipients >>> ${JSON.stringify(rordr)}`
      );

      val[ordr] = {
        routingOrder: rordr,
        recipientName: e[0].name,
        recipientRoleName: aux,
        recipientEmail: e,
      };
      console.log(
        `4activeTask?.properties?.recipients >>> ${JSON.stringify(val)}`
      );
      e = [...val];
    }

    _setTaskInfo(e, ppty);
  };

  const _setMatchingPairs = (index, obj) => {
    console.log(`1_setMatchingPairs >>> ${JSON.stringify(obj)} >>> ${index}`);

    const tabLabel = Object.keys(obj)[0];
    const value = obj[tabLabel];
    const tab = tabsList.find((t) => t.tabLabel === tabLabel);

    const valObj = {
      tabType: tab.tabType,
      tabLabel: tab.tabLabel,
      value,
    };

    // console.log(`activeTask?.properties?.customTabs >>> ${JSON.stringify(activeTask?.properties?.customTabs)}`);
    const fromState = [...(activeTask?.properties?.customTabs || [])];
    fromState[index] = valObj;
    console.log(`fromState >>> ${JSON.stringify(fromState)}`);
    // setMatchingPairs(fromState);
    _setTaskInfo(fromState, "customTabs");
    // const mF = fromStet
  };

  const MenuItems = () =>
    VariablesMenuItems({ variables, classes, workflowTasks });

  const _reformPairs = (plist) => {
    return plist.map((pl) => {
      return {
        [pl.tabLabel]: pl.value,
      };
    });
  };

  return (
    <div className={classes.container}>
      <MyWindowsDimensions setWinDim={setWindowDimensions} />
      <div
        className={classes.sideHeadingBar}
        onClick={(e) => _toggleSection(e, "settings")}
      >
        <Typography gutterBottom className={classes.sideHeading}>
          {activeTask?.type || ""} information
          {settingsComplete && (
            <CheckCircleOutlineOutlined
              style={{ fontSize: 16, marginLeft: 10, color: "green" }}
            />
          )}
          {!settingsComplete && (
            <ErrorOutlineOutlined
              style={{ fontSize: 16, marginLeft: 10, color: "red" }}
            />
          )}
        </Typography>
        {/* <Tooltip title="Close"><CancelIcon className={classes.closeIcon} onClick={cancelNewTask} /></Tooltip> */}
      </div>
      <Collapse in={!!showSection.settings}>
        <Divider style={{ marginBottom: 10 }} />
        <div className={classes.section}>
          <NameDescription
            activeTask={activeTask}
            _setTaskInfo={_setTaskInfo}
            nodeIsConnectedToAny={nodeIsConnectedToAny}
            nodeIsConnectedToStart={nodeIsConnectedToStart}
          />
        </div>
      </Collapse>
      <Divider />
      <div
        className={classes.sideHeadingBar}
        onClick={(e) => _toggleSection(e, "action")}
      >
        <Typography gutterBottom className={classes.sideHeading}>
          Action details
          {actionComplete && (
            <CheckCircleOutlineOutlined
              style={{ fontSize: 16, marginLeft: 10, color: "green" }}
            />
          )}
          {!actionComplete && (
            <ErrorOutlineOutlined
              style={{ fontSize: 16, marginLeft: 10, color: "red" }}
            />
          )}
        </Typography>
        {/* <Tooltip title="Close"><CancelIcon className={classes.closeIcon} onClick={cancelNewTask} /></Tooltip> */}
      </div>
      <Divider style={{ marginBottom: 10 }} />

      <Collapse in={!!showSection.action}>
        <div className={classes.section}>
          <div className={classes.sectionEntry}>
            <Typography gutterBottom className={classes.sectionTitle}>
              Select DocuSign account*
            </Typography>
            <Select
              key={
                !!docuSignConnections.length
                  ? activeTask?.properties?.docusignAccountId || "choose"
                  : "choose"
              }
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Select form screen"
              classes={{
                root: classes.select,
              }}
              defaultValue={
                !!docuSignConnections.length
                  ? activeTask?.properties?.docusignAccountId || "choose"
                  : "choose"
              }
              onChange={(e) => _preSetTaskInfo(e, "docusignAccountId")}
            >
              <MenuItem value="choose" disabled>
                {fetchConnectionsLoading
                  ? "please wait..."
                  : !!docuSignConnections.length
                  ? "Select Account"
                  : "No connections added"}
              </MenuItem>
              <MenuItem value="add">Add new DocuSign account</MenuItem>
              {docuSignConnections.map((dc, indx) => (
                <MenuItem value={dc.account_id} key={indx}>
                  {dc.account_name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.sectionEntry}>
            <Typography gutterBottom className={classes.sectionTitle}>
              Select template*
            </Typography>
            <Select
              key={
                !!templatesList.length
                  ? activeTask?.properties?.templateId || "choose"
                  : "choose"
              }
              disabled={!templatesList.length}
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Select template"
              classes={{
                root: classes.select,
              }}
              defaultValue={
                !!templatesList.length
                  ? activeTask?.properties?.templateId || "choose"
                  : "choose"
              }
              onChange={(e) => _preSetTaskInfo(e, "templateId")}
            >
              <MenuItem value="choose" disabled>
                {isDTLoading || isDTFetching
                  ? "please wait..."
                  : !!templatesList.length
                  ? "Select one"
                  : "[ First select DocuSign account ]"}
              </MenuItem>
              {templatesList.map((tp, indx) => (
                <MenuItem value={tp.templateId} key={indx}>
                  {tp.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.sectionEntry}>
            <Typography gutterBottom className={classes.sectionTitle}>
              Template document*
            </Typography>
            <Select
              key={
                !!documentsList.length
                  ? activeTask?.properties?.documentId || "choose"
                  : "choose"
              }
              disabled={!documentsList.length}
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Select template document"
              classes={{
                root: classes.select,
              }}
              defaultValue={
                !!documentsList.length
                  ? activeTask?.properties?.documentId || "choose"
                  : "choose"
              }
              onChange={(e) => _preSetTaskInfo(e, "documentId")}
            >
              <MenuItem value="choose" disabled>
                {isTDLoading || isTDFetching
                  ? "please wait..."
                  : !!documentsList.length
                  ? "Select one"
                  : "[ First select DocuSign account ]"}
              </MenuItem>
              {documentsList.map((dc, indx) => (
                <MenuItem value={dc.documentId} key={indx}>
                  {dc.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className={classes.sectionEntry}>
            <Typography gutterBottom className={classes.sectionTitle}>
              Map document tabs
            </Typography>
            <div className={classes.matchingFields}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography
                    gutterBottom
                    className={classes.sectionTitle}
                    style={{ color: "#666666", marginBottom: 0, lineHeight: 1 }}
                  >
                    Map variables to document tabs:
                  </Typography>
                  <div
                    style={{
                      fontSize: "0.9em",
                      color: "#aaa",
                      fontWeight: "normal",
                    }}
                  >
                    Only form inputs with set Name attribute in UI Editor appear
                    here
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: reverseMatchingRows ? "row-reverse" : "row",
                  marginTop: 10,
                }}
              >
                <div className={classes.mappingTitle}>
                  <Typography
                    gutterBottom
                    style={{ fontSize: 12, color: "#f7a712" }}
                  >
                    Variable
                  </Typography>
                  {!reverseMatchingRows && <SwapHoriz />}
                </div>
                <div className={classes.mappingTitle}>
                  <Typography
                    gutterBottom
                    style={{ fontSize: 12, color: "#f7a712" }}
                  >
                    Document tab
                  </Typography>
                  {reverseMatchingRows && <SwapHoriz />}
                </div>
              </div>

              {tabsList.map((value, index) => (
                <DataMatchingPair
                  key={index}
                  mapv={{ value, index }}
                  valuesData={tabsList}
                  pairsList={_reformPairs(
                    activeTask?.properties?.customTabs || []
                  )}
                  setMatchingPairs={_setMatchingPairs}
                  variables={variables}
                  removePair={() => false}
                  reverseRow={reverseMatchingRows}
                  setCanAdd={() => {}}
                  // MenuItems={MenuItems}
                  counter={activeTask?.properties?.customTabs?.length || 1}
                  selText="Tabs"
                  fixed
                />
              ))}
            </div>
          </div>

          <div className={classes.sectionEntry}>
            <Typography gutterBottom className={classes.sectionTitle}>
              Document recipients (in order)*
            </Typography>
            <div className={classes.matchingFields}>
              {recipientsList.map((r, i) => (
                <div key={i} className={classes.sectionEntry}>
                  <div style={{ marginBottom: 5 }}>
                    {i + 1}. {r.roleName}{" "}
                    <span
                      style={{
                        color: "#999",
                        textTransform: "capitalize",
                        fontStyle: "italic",
                      }}
                    >
                      - {r.recipientType} {r.routingOrder}
                    </span>
                  </div>
                  <SelectOnSteroids
                    // disabled={!activeTask?.properties?.calendarId}
                    source="email"
                    variables={variables}
                    onChange={(v) =>
                      _preSetTaskInfo(v, "recipients", r.roleName)
                    }
                    value={
                      activeTask?.properties?.recipients?.[i]?.recipientEmail
                    }
                    type="email"
                    multiple={false}
                  />
                </div>
              ))}
            </div>
          </div>

          <MailActions
            show={true}
            activeTask={activeTask}
            setTaskInfo={_setTaskInfo}
            properties={activeTask?.properties}
            variables={variables}
            workflowTasks={workflowTasks}
            hideTarget
            hideCC
            hideFrom
            optionalSubject
            optionalBody
          />
        </div>
      </Collapse>
      <Divider style={{ marginBottom: 100 }} />
    </div>
  );
};

export default connect((state) => {
  return {
    activeTaskId: state.workflows.activeTask?.id,
    workflowTasks: state.workflows.workflowTasks,
    workflowCanvas: state.workflows.workflowCanvas,
    allVariables: state.workflows.variables,
    taskPos: state.workflows.pos,
  };
})(PaymentTaskSidebar);
