import { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useStyles } from "../../utils/IntegrationsPanelStyle";
import PanelBody from "../PanelBody";
import { successToastify } from "../../../../common/Toastify";
import useCustomMutation from "../../../../common/CustomMutation";
import useCustomQuery from "../../../../common/CustomQuery";
import { getIntegrationResourcesListAPI } from "../../../../components/Query/Integration/integrationQuery";
import {
  newIntegrationAPI,
  updateIntegrationAPI,
} from "../../../../components/Mutation/Integration/IntegrationMutation";
import { useSelector } from "react-redux";

const SheetIntegrationPanel = ({
  changeIntegrationPage,
  updatedData,
  updateList,
  integrations,
}) => {
  const classes = useStyles(makeStyles);
  const steps = 2;
  const [fieldValue, setFieldValue] = useState({
    name: "",
    selectedSheetInt: "",
  });

  const [step, setStep] = useState(1);
  const [activeId, setActiveId] = useState(null);
  const [availableResourcesList, setAvailableResourcesList] = useState([]);
  const [selectedResourcesList, setSelectedResourcesList] = useState([]);
  const [formCompleted, setFormCompleted] = useState(false);
  const [allIntegration, setIntegrationEmails] = useState([]);
  const [disableDropDown, setDisableDropDown] = useState(false);
  const [completedStep, setCompletedStep] = useState(false);

  /* Destructuring the state object and assigning the value of editAccountFlag to the variable
  editAccountFlag. */
  const {
    integrationReducer: { editAccountFlag },
  } = useSelector((state) => state);

  /**
   * If the step is 1 and the fieldValue?.selectedSheetInt.toLowerCase() is not "add_new" and the
   * editAccountFlag is false, then set the activeId to data?.data?.id and set the step to the previous
   * step plus 1. Otherwise, set the window.location.href to data.data?.googleAuthUrl.
   * </code>
   * @returns The return value is the result of the function.
   */
  const newIntGoogleSheetSuccess = ({ data }) => {
    console.log(`eeeeeeeeeeeeeeeeeeeeeeeee >> ${data?.data?.googleAuthUrl}`);

    if (
      step === 1 &&
      fieldValue?.selectedSheetInt.toLowerCase() !== "add_new" &&
      !editAccountFlag
    ) {
      console.log(`3333333333333333333`);

      updateList(data?.data);
      setActiveId(() => data?.data?.id);
      return setStep((prevStep) => prevStep + 1);
    } else {
      console.log(`666666666666666666`);

      window.location.href = data.data?.googleAuthUrl;
    }
  };

  /**
   * If the completedStep variable is true, then set the completedStep variable to false and set the
   * step variable to 0, otherwise, set the step variable to the previous value plus 1 and set the
   * completedStep variable to false.
   */
  const updateIntegrationAPISuccess = ({ data }) => {
    successToastify(data?._meta?.message);
    console.log(`kk >>> ${JSON.stringify(data?.data)}`);
    updateList(data?.data);

    if (completedStep) {
      setCompletedStep(() => false);
      setStep(() => 0);
      return;
    } else {
      setStep((prev) => prev + 1);
      setCompletedStep(() => false);
    }
  };

  /**
   * This function takes in an object with a data property, and returns an array of objects with name
   * and id properties.
   */
  const getIntegrationResourcesListAPISuccess = ({ data }) => {
    const sheetsList = data?.data.map((sheets) => ({
      name: sheets.name,
      id: sheets.id,
    }));
    setAvailableResourcesList(sheetsList);
  };

  // creating a new googel account integration
  const { mutate: newIntSheetMutate } = useCustomMutation({
    apiFunc: newIntegrationAPI,
    onSuccess: newIntGoogleSheetSuccess,
    retries: 0,
  });

  // updating a google account integration
  const { mutate: updateIntegrationAPIMutate } = useCustomMutation({
    apiFunc: updateIntegrationAPI,
    onSuccess: updateIntegrationAPISuccess,
    retries: 0,
  });

  // fetching all user google sheet of the selected integrated google account

  useCustomQuery({
    apiFunc: getIntegrationResourcesListAPI,
    queryKey: ["getResources", { id: activeId }],
    enabled: step === 2 && true,
    onSuccess: getIntegrationResourcesListAPISuccess,
  });

  /**
   * If the step is 1 and the selectedSheetInt is 'add_new', then create a new integration, otherwise, if
   * the step is 1 and the selectedSheetInt is not 'add_new', then update the integration, otherwise, if
   * the step is equal to the number of steps, then update the integration.
   * @returns Nothing.
   */

  /**
   * If the step is 1 and the selectedSheetInt is "add_new" and the editAccountFlag is false, then
   * create a new integration.
   *
   * If the step is 1 and the selectedSheetInt is not "add_new" and the editAccountFlag is false, then
   * create a new integration from an existing account.
   *
   * If the step is 1 and the selectedSheetInt is not "add_new" and the editAccountFlag is true, then
   * update the selected account.
   *
   * If the step is equal to the number of steps, then update the integration.
   *
   * If the step is 0, then do nothing.
   * @returns Nothing.
   */
  const progressStep = async (e) => {
    /* Preventing the default action of the event. */
    !!e.target && e.preventDefault();
    console.log(`aaaaaaaaaaaaaaaaaaa`);

    if (e === 0) {
      console.log(`bbbbbbbbbbbbbbbbbbbbbbb`);
      setStep(0);
      return;
    } else if (
      step === 1 &&
      ["add_new"].includes(fieldValue?.selectedSheetInt.toLowerCase()) &&
      !editAccountFlag
    ) {
      // create new integration
      console.log(`ccccccccccccccccccccccccc`);
      const data = {
        name: fieldValue.name,
        type: "GoogleApiIntegration",
        group: "data",
        redirectUrl: `${process.env.REACT_APP_BASE_URL}/integrations`,
        properties: {
          type: "Google Sheet",
        },
      };
      console.log(`111111111111111111111`);
      newIntSheetMutate({
        data,
      });
    } else if (
      step === 1 &&
      fieldValue?.selectedSheetInt.toLowerCase() !== "add_new" &&
      !editAccountFlag
    ) {
      // create integration from existing account
      const data = {
        name: fieldValue.name,
        email: fieldValue.selectedSheetInt,
        type: "GoogleApiIntegration",
        group: "data",
        redirectUrl: window.location.href,
        properties: {
          type: "Google Sheet",
        },
      };

      console.log(`22222222222222222222`);
      newIntSheetMutate({
        data,
      });
    } else if (
      step === 1 &&
      fieldValue?.selectedSheetInt.toLowerCase() !== "add_new" &&
      editAccountFlag
    ) {
      // update selected integration
      const data = {
        name: fieldValue.name,
        email: fieldValue?.selectedSheetInt,
        type: "GoogleApiIntegration",
        group: "data",
        properties: {
          type: "Google Sheet",
        },
      };
      updateIntegrationAPIMutate({ id: activeId, data });
    } else if (steps === step) {
      const data = {
        name: fieldValue.name,
        properties: {
          resources: selectedResourcesList,
        },
      };

      setCompletedStep(() => true);
      updateIntegrationAPIMutate({ id: activeId, data });
      setDisableDropDown(() => false);
      setStep(0);
      return;
    }

    return;
  };

  /**
   * If the sheetProps.name is not in the selectedResourcesList, add it. If it is, remove it.
   */
  const updateSelectedList = ({ sheetProps }) => {
    /* Creating a copy of the selectedResourcesList array. */
    const sheetLists = [...selectedResourcesList];
    /* Finding the index of the sheetProps.name in the sheetLists array. */
    const sheetIdx = sheetLists.findIndex(
      (sheets) => sheets?.name === sheetProps?.name
    );

    if (sheetIdx === -1) {
      /* Pushing the sheetProps object into the sheetLists array. */
      sheetLists.push(sheetProps);
    } else {
      /* Removing the item at index sheetIdx from the array sheetLists. */
      sheetLists.splice(sheetIdx, 1);
    }
    /* Setting the state of the selectedResourcesList to the sheetLists. */
    setSelectedResourcesList(sheetLists);
  };

  /* Creating a custom input component. */
  const BootstrapInput = withStyles((theme) => ({
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  /**
   * When the user types in the input field, the value of the input field is set to the value of the
   * field that the user is typing in.
   */
  const handleChange = (e) => {
    setFieldValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  /* Checking if the step is 0, if it is, it will change the integration page to an empty string. */
  useEffect(() => {
    if (step === 0) {
      changeIntegrationPage("");
    }
  }, [step, changeIntegrationPage, fieldValue.selectedSheetInt]);

  /* Setting the activeId to the updatedData.id. */
  useEffect(() => {
    /* Filtering the integrations array and returning the email addresses of the users. */
    const accountsEmail = integrations.length > 0 && [
      ...new Set(
        integrations
          .filter(
            ({ type, properties: { type: propertyType } }) =>
              type === "GoogleApiIntegration" && propertyType === "Google Sheet"
          )
          .map(({ properties: { userInfo } }) => userInfo && userInfo?.email)
      ),
    ];

    /* Setting the state of the component. */
    setIntegrationEmails(() => accountsEmail);

    /* Setting the activeId to the id of the updatedData. */
    setActiveId(updatedData?.id);

    /* Setting the value of the field. */
    setFieldValue((data) => ({
      ...data,
      name: updatedData?.name,
      selectedSheetInt: updatedData?.properties?.userInfo?.email,
    }));

    /* Setting the state of the selectedResourcesList to the updatedData.properties.resources or an empty
array. */
    setSelectedResourcesList(() => updatedData?.properties?.resources || []);
    /* Setting the disableDropDown state to true if the editAccountFlag is true. */
    editAccountFlag && setDisableDropDown(() => true);
  }, [updatedData, integrations, editAccountFlag]);

  /* Checking if the fieldValue.name is empty or not. If it is empty, it will set the formCompleted to
false. If it is not empty, it will set the formCompleted to true. */
  useEffect(() => {
    setFormCompleted(!!fieldValue.name);
  }, [fieldValue]);

  return (
    <PanelBody
      title="Google Sheet"
      mode={!!updatedData ? "Update" : "New"}
      step={step}
      setStep={progressStep}
      steps={steps}
      isResourceSelected={!!selectedResourcesList?.length}
      formCompleted={formCompleted}
    >
      <div className={classes.sideDialogMain}>
        {step === 1 && (
          <>
            <Typography className={classes.formLabels} gutterBottom>
              Name
            </Typography>
            <TextField
              className={classes.FormTextField}
              size="small"
              name="name"
              inputMode="text"
              onChange={(e) => handleChange(e)}
              fullWidth
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              required
              value={fieldValue.name || ""}
              placeholder={`Enter here`}
              type="text"
              variant="outlined"
              inputProps={{
                className: classes.inputColor,
                autoComplete: "new-int",
                form: {
                  autoComplete: "off",
                },
              }}
            />

            <div className="selectResources">
              <div style={{ marginTop: 15, marginBottom: 10 }}>
                <Typography className={classes.formLabels} gutterBottom>
                  Account to integrate
                </Typography>
                <FormGroup>
                  {!disableDropDown ? (
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      classes={{
                        root: classes.selectPadding,
                      }}
                      placeholder={"Select from the list"}
                      name="selectedSheetInt"
                      value={fieldValue && fieldValue?.selectedSheetInt}
                      onChange={(e) => handleChange(e)}
                      disabled={disableDropDown}
                    >
                      <MenuItem value="add_new">Add Google account</MenuItem>
                      {allIntegration.length > 0 &&
                        allIntegration.map((emails, idx) => {
                          return (
                            <MenuItem value={emails} key={idx}>
                              {emails}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  ) : (
                    <TextField
                      className={classes.FormTextField}
                      size="small"
                      name="selectedSheetInt"
                      inputMode="email"
                      onChange={(e) => handleChange(e)}
                      fullWidth
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                      required
                      value={fieldValue.selectedSheetInt || ""}
                      placeholder={`Enter here`}
                      type="email"
                      disabled={disableDropDown}
                      variant="outlined"
                      inputProps={{
                        className: classes.inputColor,
                        autoComplete: "new-int",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />
                  )}
                </FormGroup>
              </div>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <Typography className={classes.formLabels} gutterBottom>
              Select the collections to integrate
            </Typography>
            <div className="selectResources">
              <div style={{ marginTop: 15, marginBottom: 10 }}>
                <FormGroup>
                  {availableResourcesList?.map((sheetProps, idx) => {
                    return (
                      <FormControlLabel
                        key={idx}
                        control={
                          <Checkbox
                            onChange={() => updateSelectedList({ sheetProps })}
                            checked={selectedResourcesList
                              .map(({ name }) => name)
                              ?.includes(sheetProps.name)}
                          />
                        }
                        label={sheetProps.name}
                      />
                    );
                  })}
                </FormGroup>
              </div>
            </div>
          </>
        )}
      </div>
    </PanelBody>
  );
};

export default SheetIntegrationPanel;
