import { CustomAxios } from "../../../common/CustomAxios";

// create an app request
export const createApp = async (data) => {
  console.log(data);
  const appResult = await CustomAxios().post(
    `${process.env.REACT_APP_ENDPOINT}/apps`,
    data
  );

  return appResult;
};

// create an app request
export const retrieveApp = async (data) => {
  try {
    const appResult = await CustomAxios().get(
      `${process.env.REACT_APP_ENDPOINT}/apps/${data.id}`
    );
    return appResult;
  } catch (err) {
    return err;
  }
};

// delete an app request
export const deleteApp = async (appId) => {
  const result = await CustomAxios().delete(
    `${process.env.REACT_APP_ENDPOINT}/apps/${appId}`
  );
  return result;
};

// duplicate an app request
export const duplicateApps = async (data) => {
  const result = await CustomAxios().post(
    `${process.env.REACT_APP_ENDPOINT}/apps/duplicate`,
    data
  );
  return result;
};

// update an app request
export const updateApps = async ({ app, ...data }) => {
  const result = await CustomAxios().put(
    `${process.env.REACT_APP_ENDPOINT}/apps/${app}`,
    data
  );
  return result;
};

// publish an app
export const publishApp = async ({ id, active }) => {
  const result = await CustomAxios({ overrideNotification: true }).put(
    `${process.env.REACT_APP_ENDPOINT}/apps/${id}/active`,
    { active }
  );
  return result;
};

// trim app workflow variables
export const trimVariablesAppWorkflowAPI = async ({ id }) => {
  try {
    const result = await CustomAxios().put(
      `${process.env.REACT_APP_ENDPOINT}/apps/${id}/trim-variables`,
      {}
    );
    return result?.data;
  } catch (e) {
    return e;
  }
};
