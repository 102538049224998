import { useEffect, useState } from "react";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import { useStyles } from "../../utils/IntegrationsPanelStyle";
import PanelBody from "../PanelBody";
import { successToastify } from "../../../../common/Toastify";
import useCustomMutation from "../../../../common/CustomMutation";
import {
  newIntegrationAPI,
  updateIntegrationAPI,
} from "../../../../components/Mutation/Integration/IntegrationMutation";
import { useDispatch, useSelector } from "react-redux";
import { SET_EDIT_INTEGRATION_FLAG } from "../../../../store/actions/integrationActions";

const SendGridIntegrationPanel = ({
  changeIntegrationPage,
  updatedData,
  updateList,
  integrations,
}) => {
  const classes = useStyles(makeStyles);
  const steps = 1;
  const [fieldValue, setFieldValue] = useState({
    name: "",
    apiKey: "",
    templateId: "",
  });

  const [step, setStep] = useState(1);
  const [activeId, setActiveId] = useState(null);
  const [formCompleted, setFormCompleted] = useState(false);

  /* Destructuring the state object and assigning the value of editAccountFlag to the variable
  editAccountFlag. */
  const {
    integrationReducer: { editAccountFlag },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  /**
   * If step is 1 and editAccountFlag is false, setActiveId to data.data.id and setStep to 0. Otherwise,
   * setActiveId to data.data.id and setStep to 0.
   * @returns the value of the setActiveId and setStep functions.
   */
  const newIntSendGridSuccess = ({ data }) => {
    if (step === 1 && !editAccountFlag) {
      setActiveId(() => data?.data?.id);
      setStep(() => 0);
      return;
    } else {
      setActiveId(() => data?.data?.id);
      setStep(() => 0);
      return;
    }
  };

  /**
   * If the data is not null, and the data's meta is not null, and the data's meta's message is not
   * null, then show a success toast, and update the list, and set the step to 0.
   * @returns The return value is the return value of the function passed to setStep.
   */

  const updateIntegrationAPISuccess = ({ data }) => {
    successToastify(data?._meta?.message);
    updateList(data?.data);
    dispatch({ type: SET_EDIT_INTEGRATION_FLAG, payload: false });

    setStep(() => 0);
    return;
  };

  // creating a new googel account integration
  const { mutate: newIntSendGridMutate } = useCustomMutation({
    apiFunc: newIntegrationAPI,
    onSuccess: newIntSendGridSuccess,
    retries: 0,
  });

  // updating a google account integration
  const { mutate: updateIntegrationAPIMutate } = useCustomMutation({
    apiFunc: updateIntegrationAPI,
    onSuccess: updateIntegrationAPISuccess,
    retries: 0,
  });

  /**
   * If the step is 1 and the editAccountFlag is false, then create a new integration. If the step is 1
   * and the editAccountFlag is true, then update the selected integration.
   * @returns the value of the setStep function.
   */
  const progressStep = async (e) => {
    /* Preventing the default action of the event. */
    !!e.target && e.preventDefault();

    if (e === 0) {
      setStep(0);
      return;
    } else if (step === 1 && !editAccountFlag) {
      // create new integration
      const data = {
        name: fieldValue.name,
        type: "EmailServiceIntegration",
        group: "mail",
        properties: {
          apiKey: fieldValue?.apiKey,
          templateId: fieldValue?.templateId,
          type: "SendGrid",
        },
      };

      newIntSendGridMutate({
        data,
      });
    } else if (step === 1 && editAccountFlag) {
      // update selected integration

      const data = {
        name: fieldValue.name,
        type: "EmailServiceIntegration",
        properties: {
          apiKey: fieldValue?.apiKey,
          templateId: fieldValue?.templateId,
          type: "SendGrid",
        },
      };
      updateIntegrationAPIMutate({ id: activeId, data });
    }
  };

  /**
   * When the user types in the input field, the value of the input field is set to the value of the
   * field that the user is typing in.
   */
  const handleChange = (e) => {
    setFieldValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  /* Checking if the step is 0, if it is, it will change the integration page to an empty string. */
  useEffect(() => {
    if (step === 0) {
      changeIntegrationPage("");
    }
  }, [step, changeIntegrationPage]);

  /* Setting the activeId to the updatedData.id. */
  useEffect(() => {
    setActiveId(updatedData?.id);

    /* Setting the value of the field. */
    setFieldValue((data) => ({
      ...data,
      name: updatedData?.name,
    }));
  }, [updatedData, integrations, editAccountFlag]);

  /* Checking if the fieldValue.name is empty or not. If it is empty, it will set the formCompleted to
false. If it is not empty, it will set the formCompleted to true. */
  useEffect(() => {
    setFormCompleted(
      !!fieldValue.name && !!fieldValue.apiKey && !!fieldValue.templateId
    );
  }, [fieldValue]);

  return (
    <PanelBody
      title="Send Grid"
      mode={!!updatedData ? "Update" : "New"}
      step={step}
      setStep={progressStep}
      overLookResourceSelectedList
      steps={steps}
      formCompleted={formCompleted}
    >
      <div className={classes.sideDialogMain}>
        {step === 1 && (
          <>
            <Typography className={classes.formLabels} gutterBottom>
              Name
            </Typography>
            <TextField
              className={classes.FormTextField}
              size="small"
              name="name"
              inputMode="text"
              onChange={(e) => handleChange(e)}
              fullWidth
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              required
              value={fieldValue.name || ""}
              placeholder={`Enter here`}
              type="text"
              variant="outlined"
              inputProps={{
                className: classes.inputColor,
                autoComplete: "new-int",
                form: {
                  autoComplete: "off",
                },
              }}
            />

            <Typography className={classes.formLabels} gutterBottom>
              ApiKey
            </Typography>
            <TextField
              className={classes.FormTextField}
              size="small"
              name="apiKey"
              inputMode="text"
              onChange={(e) => handleChange(e)}
              fullWidth
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              required
              value={fieldValue.apiKey || ""}
              placeholder={`Enter here`}
              type="text"
              variant="outlined"
              inputProps={{
                className: classes.inputColor,
                autoComplete: "new-int",
                form: {
                  autoComplete: "off",
                },
              }}
            />

            <Typography className={classes.formLabels} gutterBottom>
              Template Id
            </Typography>
            <TextField
              className={classes.FormTextField}
              size="small"
              name="templateId"
              inputMode="text"
              onChange={(e) => handleChange(e)}
              fullWidth
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              required
              value={fieldValue.templateId || ""}
              placeholder={`Enter here`}
              type="text"
              variant="outlined"
              inputProps={{
                className: classes.inputColor,
                autoComplete: "new-int",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </>
        )}
      </div>
    </PanelBody>
  );
};

export default SendGridIntegrationPanel;
