import { CustomAxios } from "../../../common/CustomAxios";
import { errorToastify } from "../../../common/Toastify";

/**
 * It creates a template, then creates a screen, then creates a component.
 * @param data - {
 * @returns The return value is the result of the last expression in the function.
 */
export const createTemplate = async (data) => {
  const templateResult = await CustomAxios().post(
    `${process.env.REACT_APP_ENDPOINT}/templates`,
    data
  );

  return templateResult;
};

/**
 * It deletes a template from the database.
 * @param data - {
 * @returns The result of the delete request.
 */
export const deleteTemplate = async (id) => {
  const result = await CustomAxios().delete(
    `${process.env.REACT_APP_ENDPOINT}/templates/${id}`
  );
  return result;
};

export const createAppFromTemplate = async (data) => {
  if (!data?.templateId) {
    errorToastify("Sorry, there's an error with this request.");
    return false;
  }
  const result = await CustomAxios().post(
    `${process.env.REACT_APP_ENDPOINT}/apps/from-template`,
    data
  );
  return result;
};

/**
 * It takes in a data object, and sends it to the server.
 * @param data - {
 * @returns The result of the post request.
 */
export const duplicateTemplates = async (data) => {
  const { app: template, ...datas } = data;
  const result = await CustomAxios().post(
    `${process.env.REACT_APP_ENDPOINT}/templates/duplicate`,
    { template, ...datas }
  );
  return result;
};

export const updateTemplates = async ({ app, ...data }) => {
  const result = await CustomAxios().put(
    `${process.env.REACT_APP_ENDPOINT}/templates/${app}`,
    data
  );
  return result;
};

/**
 * It takes an id and an active boolean and updates the active boolean in the database.
 * @returns The result of the request.
 */
export const publishTemplates = async ({ id, active }) => {
  const result = await CustomAxios().put(
    `${process.env.REACT_APP_ENDPOINT}/templates/${id}/active`,
    { active }
  );
  return result;
};
