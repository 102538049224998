import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NativeSelect from "@material-ui/core/NativeSelect";
import { useDispatch, useSelector } from "react-redux";
/* import {
  SELECTED_CATEGORY,
  SELECTED_SORT,
} from "../../store/actions/appsActions"; */
// import { handleSelectedSort } from "./util/logics";

const BootstrapInput = withStyles((theme) => ({
  input: {
    color: "#091540",
    borderRadius: "0 4px 4px 0",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ABB3BF",
    fontSize: 12,
    padding: "9px 30px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRight: "none",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    // marginRight: theme.spacing(1)
  },
  filterLabel: {
    background: "#E7E9EE",
    padding: "6.5px 10px 6px",
    border: "1px solid #ABB3BF",
    borderRight: "none",
    borderRadius: "3px 0 0 3px",
    color: "#091540",
    fontSize: 12,
    whiteSpace: "nowrap",
  },
  selectMenu: {
    border: "none",
    boxShadow: "none",
  },
}));

const CustomizedSelects = ({ filters, doFilter, appCategories }) => {
  //console.log(`>>>>>>>>>>>>> ${JSON.stringify(appCategories)}`);
  const classes = useStyles();
  // const userGroups = appCategories;
  // const [ status, setStatus ] = useState("");
  // const [ role, setRole ] = useState("");
  // const [ filters, setFilters ] = useState({ status: 'All', role: 'All', group: 'All', search: '' });

  const [sort, setSort] = useState("");

  // useEffect(() => {
  //   console.log(appCategories);
  // });

  // useEffect(() => {
  //   doFilter({  })
  // }, [ status, role, group ])

  const statuses = [
    ["All", "All Status"],
    ["Active", "Active"],
    ["Inactive", "Inactive"],
  ];

  const roles = [
    ["All", "All Roles"],
    ["Admin", "Admin"],
    // ["Customer", "Customer"],
    ["Designer", "Designer"],
    ["Employee", "Employee"],
  ];

  /* const groups = [
    ["All", "All Groups"],
    ["PlugDev", "Plug Dev"],
    ["Finance", "HR"],
    ["SeniorMgt", "Senior Mgt"],
    ["OlamideFans", "Olamine's Fans"],
  ]; */

  /* useEffect(() => {
    
    return () => {
      
    }
  }, [ status, role, group ]) */

  /* const handleCategory = (event) => {
    event.persist();
    setCategory(() => event.target.value);
    // dispatch({ type: SELECTED_CATEGORY, payload: event.target.value });
  }; */

  return (
    <div>
      <Grid container alignItems="flex-end" style={{ flexWrap: "nowrap" }}>
        <Grid item>
          <Typography className={classes.filterLabel}>Filter by:</Typography>
        </Grid>
        <Grid item>
          <NativeSelect
            id="categories"
            variant="outlined"
            value={filters.status}
            input={<BootstrapInput />}
            classes={{
              root: classes.root1,
              selectMenu: classes.selectMenu,
            }}
            // style={{ borderRight: "none" }}
            onChange={(v) => doFilter({ status: v.target.value })}
          >
            {statuses.map(([value, name]) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </NativeSelect>
        </Grid>
        {/* <Grid item>
          <NativeSelect
            id="categories"
            variant="outlined"
            value={filters.role}
            input={<BootstrapInput />}
            classes={{
              root: classes.root1,
              selectMenu: classes.selectMenu,
            }}
            // style={{ borderRight: "none" }}
            onChange={(v) => doFilter({ role: v.target.value })}
          >
            {roles.map(([value, name]) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </NativeSelect>
        </Grid> */}
        <Grid item>
          <NativeSelect
            id="categories"
            variant="outlined"
            value={filters.name}
            input={<BootstrapInput />}
            classes={{
              root: classes.root,
              selectMenu: classes.selectMenu,
            }}
            style={{ marginRight: 10 }}
            onChange={(v) => doFilter({ name: v.target.value })}
          >
            {!!appCategories.length && (
              <>
                <option key={"all"} value={"All"}>
                  App Categories
                </option>
                {!!appCategories &&
                  appCategories.map((grp) => (
                    <option key={grp.id} value={grp.name}>
                      {grp.name}
                    </option>
                  ))}
              </>
            )}
            {!appCategories.length && (
              <>
                <option key={"all"} value={"All"} disabled>
                  No categories created yet
                </option>
              </>
            )}
          </NativeSelect>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomizedSelects;
