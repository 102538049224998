import { InputBase } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { updateValues } from "../../../../Pages/UIEditor/utils/canvasHelpers";
import SidebarFieldPreferenceSection from "../components/SidebarFieldPreferenceSection";
import SidebarNameSection from "../components/SidebarNameSection";
import CustomStyleSection from "../components/CustomStyleSection";

const InputText = withStyles((theme) => ({
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 11,
    padding: "5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const HeaderSidebar = (props) => {
  const {
    values,
    name,
    id,
    title,
    styles,
    stylesOverride,
    type: itemType,
    dataType,
    index,
    containingChildIndex,
    isInsideContainer,
  } = props;

  const dispatch = useDispatch();
  const onValuesChange = ({ value, property }) =>
    dispatch(
      updateValues({
        value,
        property,
        index,
        isInsideContainer,
        containingChildIndex,
        type: itemType,
      })
    );

  const iconLabels = [
    ["select", "Select"],
    ["home", "Home"],
    ["back", "Back"],
    ["settings", "Settings"],
    ["message", "Message"],
    ["profile", "Profile"],
  ];

  const onExternalLinkChange = ({ value, property }) => {
    onValuesChange({
      value: { name: "External", link: value, value: "external" },
      property,
    });
  };

  return (
    <>
      <div className="sidebar-container">
        <SidebarNameSection
          id={id}
          itemType={itemType}
          name={name}
          title={title}
          index={index}
          isInsideContainer={isInsideContainer}
          containingChildIndex={containingChildIndex}
        />

        <div className="sidebar-container-scroll">
          <SidebarFieldPreferenceSection
            itemType={itemType}
            name={name}
            title={title}
            index={index}
            isInsideContainer={isInsideContainer}
            containingChildIndex={containingChildIndex}
            values={{ ...values }}
            dataType={dataType}
          />
          <CustomStyleSection
            itemType={itemType}
            index={index}
            name={name}
            title={title}
            isInsideContainer={isInsideContainer}
            containingChildIndex={containingChildIndex}
            values={values}
            dataType={dataType}
            items={["header"]}
            styles={styles}
            stylesOverride={stylesOverride}
          />
        </div>
      </div>
    </>
  );
};

export default HeaderSidebar;
