import _ from "lodash";
import { CustomAxios } from "../../../../../common/CustomAxios";
import { SetAppStatus } from "../../../../../helpers/helperFunctions";

export const createItem = async (item) => {
  try {
    const { data } = await CustomAxios().post(
      `${process.env.REACT_APP_ENDPOINT}/items`,
      item
    );
    return { success: true, data };
  } catch (error) {
    return { success: false, data: "Error creating item" };
  }
};

export const deleteItem = async (id) => {
  try {
    const { data } = await CustomAxios().delete(
      `${process.env.REACT_APP_ENDPOINT}/items/${id}`
    );
    return { success: true, data };
  } catch (error) {
    return { success: false, data: "Error deleting item" };
  }
};

export const reorderItems = async ({ id, body }) => {
  try {
    const { data } = await CustomAxios().put(
      `${process.env.REACT_APP_ENDPOINT}/items/${id}`,
      body
    );
    return { success: true, data };
  } catch (error) {
    console.log(1, JSON.stringify(error));
    return { success: false, data: "Error updating item" };
  }
};

export const reorderElementsOnCanvas = async ({ id, body }) => {
  try {
    const { data } = await CustomAxios().post(
      `${process.env.REACT_APP_ENDPOINT}/items/reorder`,
      body
    );
    return { success: true, data };
  } catch (error) {
    console.log(1, JSON.stringify(error));
    return { success: false, data: "Error updating item" };
  }
};

export const updateItem = _.debounce(async ({ dispatch, id, ...item }) => {
  if (!id) return null;

  try {
    const { data } = await CustomAxios().put(
      `${process.env.REACT_APP_ENDPOINT}/items/${id}`,
      item
    );
    // this added cos debounce doesn't return values
    if (dispatch) {
      console.log(`* . * . * . *`);
    }
    dispatch &&
      dispatch(SetAppStatus({ type: "success", msg: "Item updated" }));

    return { success: true, data };
  } catch (error) {
    // this added cos debounce doesn't return values
    dispatch &&
      dispatch(SetAppStatus({ type: "error", msg: "Error updating item" }));

    return { success: false, data: "Error updating item" };
  }
}, 1000);

export const handleExplicitUpdate = async ({ id, ...item }) => {
  try {
    const { data } = await CustomAxios().put(
      `${process.env.REACT_APP_ENDPOINT}/items/${id}`,
      item
    );
    return { success: true, data };
  } catch (error) {
    return { success: false, data: "Error updating item" };
  }
};
