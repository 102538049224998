import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useStyles } from "../../utils/IntegrationsPanelStyle";
import PanelBody from "../PanelBody";
import { successToastify } from "../../../../common/Toastify";
import useCustomMutation from "../../../../common/CustomMutation";
import useCustomQuery from "../../../../common/CustomQuery";
import { getIntegrationResourcesListAPI } from "../../../../components/Query/Integration/integrationQuery";
import {
  newIntegrationAPI,
  updateIntegrationAPI,
} from "../../../../components/Mutation/Integration/IntegrationMutation";
import { useSelector } from "react-redux";
import { mainNavigationUrls } from "../../../../utils/lists";

const GoogleDriveIntegrationPanel = ({
  changeIntegrationPage,
  updatedData,
  updateList,
  integrations,
}) => {
  const classes = useStyles(makeStyles);
  const history = useHistory();

  const steps = 2;

  const completedStep = useRef("in-process");
  const [fieldValue, setFieldValue] = useState({
    name: "",
    selectedFolderInt: "",
  });
  const [step, setStep] = useState(1);
  const [activeId, setActiveId] = useState(null);
  const [availableResourcesList, setAvailableResourcesList] = useState([]);
  const [selectedResourcesList, setSelectedResourcesList] = useState([]);
  const [formCompleted, setFormCompleted] = useState(false);
  const [allIntegration, setIntegrationEmails] = useState([]);
  const [disableDropDown, setDisableDropDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAvailableResourcesList, setLoadingAvailableResourcesList] =
    useState(true);

  /* Destructuring the state object and assigning the value of editAccountFlag to the variable
  editAccountFlag. */
  const {
    integrationReducer: { editAccountFlag },
  } = useSelector((state) => state);

  /* Checking if the step is 0, if it is, it will change the integration page to an empty string. */
  useEffect(() => {
    console.log(`^^^^^^^^^^^^^^^^^^^^^^^^`);
  }, []);
  useEffect(() => {
    console.log(`**** * ** * ** *** ** * ** ** *1`);
    if (step === 0) {
      console.log(`**** * ** * ** *** ** * ** ** *2`);
      changeIntegrationPage("");
    }
  }, [step, changeIntegrationPage, fieldValue.selectedFolderInt]);

  /* Setting the activeId to the updatedData.id. */
  useEffect(() => {
    /* Filtering the integrations array and returning the email addresses of the users. */
    const accountsEmail = integrations.length > 0 && [
      ...new Set(
        integrations
          .filter(
            ({ type, properties: { type: propertyType } }) =>
              type === "GoogleApiIntegration" && propertyType === "Google Drive"
          )
          .map(({ properties: { userInfo } }) => userInfo && userInfo?.email)
      ),
    ];

    /* Setting the state of the component. */
    setIntegrationEmails(() => accountsEmail);

    /* Setting the activeId to the id of the updatedData. */
    setActiveId(updatedData?.id);

    /* Setting the value of the field. */
    setFieldValue((data) => ({
      ...data,
      name: updatedData?.name,
      selectedFolderInt: updatedData?.properties?.userInfo?.email,
    }));

    /* Setting the state of the selectedResourcesList to the updatedData.properties.resources or an empty
array. */
    setSelectedResourcesList(() => updatedData?.properties?.resources || []);
    /* Setting the disableDropDown state to true if the editAccountFlag is true. */
    editAccountFlag && setDisableDropDown(() => true);
  }, [updatedData, integrations, editAccountFlag]);

  /* Checking if the fieldValue.name is empty or not. If it is empty, it will set the formCompleted to
false. If it is not empty, it will set the formCompleted to true. */
  useEffect(() => {
    setFormCompleted(!!fieldValue.name && !!fieldValue.selectedFolderInt);
  }, [fieldValue]);

  /**
   * If the step is 1 and the fieldValue?.selectedFolderInt.toLowerCase() is not "add_new" and the
   * editAccountFlag is false, then set the activeId to data?.data?.id and set the step to the previous
   * step plus 1. Otherwise, set the window.location.href to data.data?.googleAuthUrl.
   * </code>
   * @returns The return value is the result of the function.
   */
  const newIntGoogleFolderSuccess = ({ data }) => {
    console.log(`|-> 777`);
    // setLoading(false);

    if (
      step === 1 &&
      fieldValue?.selectedFolderInt.toLowerCase() !== "add_new" &&
      !editAccountFlag
    ) {
      console.log(`|-> 888`);

      updateList(data?.data);
      setActiveId(() => data?.data?.id);
      return setStep((prevStep) => prevStep + 1);
    } else {
      console.log(`|-> 999`);

      window.location.href = data.data?.googleAuthUrl;
    }
  };

  /**
   * If the completedStep variable is true, then set the completedStep variable to false and set the
   * step variable to 0, otherwise, set the step variable to the previous value plus 1 and set the
   * completedStep variable to false.
   */
  const updateIntegrationAPISuccess = ({ data }) => {
    successToastify(data?._meta?.message);
    console.log(`|-> aaa`);
    updateList(data?.data);
    setLoading(false);
    setDisableDropDown(() => false);
    // setStep(() => 0);

    if (completedStep.current === "finish") {
      console.log(`|-> ZZZ`);
      // setCompletedStep(() => false);
      setStep(() => 0);
      return history.push(mainNavigationUrls.INTEGRATIONS);
    } else {
      console.log(`|-> YYY`);
      setStep((prev) => prev + 1);
      // setCompletedStep(() => false);
    }
  };

  /**
   * This function takes in an object with a data property, and returns an array of objects with name
   * and id properties.
   */
  const getIntegrationResourcesListAPISuccess = ({ data }) => {
    console.log(`|-> bbb`);

    const foldersList = data?.data.map((folders) => ({
      name: folders.name,
      id: folders.id,
    }));
    setLoadingAvailableResourcesList(false);
    setAvailableResourcesList(foldersList);
  };

  // creating a new googel account integration
  const { mutate: newIntFolderMutate } = useCustomMutation({
    apiFunc: newIntegrationAPI,
    onSuccess: newIntGoogleFolderSuccess,
    onError: () => setLoading(false),
    retries: 0,
  });

  // updating a google account integration
  const { mutate: updateIntegrationAPIMutate } = useCustomMutation({
    apiFunc: updateIntegrationAPI,
    onSuccess: updateIntegrationAPISuccess,
    onError: () => setLoading(false),
    retries: 0,
  });

  // fetching all user google folder of the selected integrated google account

  useCustomQuery({
    apiFunc: getIntegrationResourcesListAPI,
    queryKey: ["getResources", { id: activeId }],
    enabled: step === 2 && true,
    onSuccess: getIntegrationResourcesListAPISuccess,
    onError: () => setLoading(false),
  });

  const progressStep = async (e) => {
    /* Preventing the default action of the event. */
    !!e.target && e.preventDefault();

    console.log(`|-> 000`);
    setLoading(true);

    if (e === 0) {
      setLoading(false);
      setStep(0);
      console.log(`|-> 111`);
      return;
    } else if (
      step === 1 &&
      ["add_new"].includes(fieldValue?.selectedFolderInt.toLowerCase()) &&
      !editAccountFlag
    ) {
      console.log(`|-> 222`);

      // create new integration
      const data = {
        name: fieldValue.name,
        type: "GoogleApiIntegration",
        group: "data",
        redirectUrl: `${process.env.REACT_APP_BASE_URL}/integrations`,
        properties: {
          type: "Google Drive",
        },
      };

      await newIntFolderMutate({ data });
    } else if (
      step === 1 &&
      fieldValue?.selectedFolderInt.toLowerCase() !== "add_new" &&
      !editAccountFlag
    ) {
      console.log(`|-> 333`);
      // create integration from existing account
      const data = {
        name: fieldValue.name,
        email: fieldValue.selectedFolderInt,
        type: "GoogleApiIntegration",
        group: "data",
        redirectUrl: window.location.href,
        properties: {
          type: "Google Drive",
        },
      };

      await newIntFolderMutate({ data });
    } else if (
      step === 1 &&
      fieldValue?.selectedFolderInt.toLowerCase() !== "add_new" &&
      editAccountFlag
    ) {
      console.log(`|-> 444`);
      // update selected integration
      const data = {
        name: fieldValue.name,
        email: fieldValue?.selectedFolderInt,
        type: "GoogleApiIntegration",
        group: "document",
        properties: {
          type: "Google Drive",
        },
      };

      await updateIntegrationAPIMutate({ id: activeId, data });
    } else if (steps === step) {
      console.log(`|-> 555`);
      const data = {
        name: fieldValue.name,
        properties: {
          resources: selectedResourcesList,
        },
      };

      completedStep.current = "finish";
      await updateIntegrationAPIMutate({ id: activeId, data });
      console.log(`|-> 000`);
      return;
    }
    console.log(`|-> 666`);

    return;
  };

  /**
   * If the folderProps.name is not in the selectedResourcesList, add it. If it is, remove it.
   */
  const updateSelectedList = ({ folderProps }) => {
    /* Creating a copy of the selectedResourcesList array. */
    const folderLists = [...selectedResourcesList];
    /* Finding the index of the folderProps.name in the folderLists array. */
    const folderIdx = folderLists.findIndex(
      (folders) => folders?.name === folderProps?.name
    );

    if (folderIdx === -1) {
      /* Pushing the folderProps object into the folderLists array. */
      folderLists.push(folderProps);
    } else {
      /* Removing the item at index folderIdx from the array folderLists. */
      folderLists.splice(folderIdx, 1);
    }
    /* Setting the state of the selectedResourcesList to the folderLists. */
    setSelectedResourcesList(folderLists);
  };

  /* Creating a custom input component. */
  const BootstrapInput = withStyles((theme) => ({
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  /**
   * When the user types in the input field, the value of the input field is set to the value of the
   * field that the user is typing in.
   */
  const handleChange = (e) => {
    setFieldValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <PanelBody
      title="Google Drive"
      mode={!!updatedData ? "Update" : "New"}
      step={step}
      setStep={progressStep}
      steps={steps}
      isResourceSelected={!!selectedResourcesList?.length}
      formCompleted={formCompleted}
      loading={loading}
    >
      <div className={classes.sideDialogMain}>
        {step === 1 && (
          <>
            <Typography className={classes.formLabels} gutterBottom>
              Name
            </Typography>
            <TextField
              className={classes.FormTextField}
              size="small"
              name="name"
              inputMode="text"
              onChange={(e) => handleChange(e)}
              fullWidth
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              required
              value={fieldValue.name || ""}
              placeholder={`Enter here`}
              type="text"
              variant="outlined"
              inputProps={{
                className: classes.inputColor,
                autoComplete: "new-int",
                form: {
                  autoComplete: "off",
                },
              }}
            />

            <div className="selectResources">
              <div style={{ marginTop: 15, marginBottom: 10 }}>
                <Typography className={classes.formLabels} gutterBottom>
                  Account to integrate
                </Typography>
                <FormGroup>
                  {!disableDropDown ? (
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      classes={{
                        root: classes.selectPadding,
                      }}
                      placeholder={"Select from the list"}
                      name="selectedFolderInt"
                      value={fieldValue && fieldValue?.selectedFolderInt}
                      onChange={(e) => handleChange(e)}
                      disabled={disableDropDown}
                    >
                      <MenuItem value="add_new">Add Google account</MenuItem>
                      {allIntegration.length > 0 &&
                        allIntegration.map((emails, idx) => {
                          return (
                            <MenuItem value={emails} key={idx}>
                              {emails}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  ) : (
                    <TextField
                      className={classes.FormTextField}
                      size="small"
                      name="selectedFolderInt"
                      inputMode="email"
                      onChange={(e) => handleChange(e)}
                      fullWidth
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                      required
                      value={fieldValue.selectedFolderInt || ""}
                      placeholder={`Enter here`}
                      type="email"
                      disabled={disableDropDown}
                      variant="outlined"
                      inputProps={{
                        className: classes.inputColor,
                        autoComplete: "new-int",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />
                  )}
                </FormGroup>
              </div>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <Typography className={classes.formLabels} gutterBottom>
              Select the folders to integrate
            </Typography>
            <div className="selectResources">
              <div style={{ marginTop: 15, marginBottom: 10 }}>
                {loadingAvailableResourcesList ? (
                  <div style={{ fontWeight: 700 }}>Loading, Please wait...</div>
                ) : (
                  <>
                    {!availableResourcesList.length ? (
                      <div>
                        No folders found. You'll need at least one folder to
                        integrate
                      </div>
                    ) : (
                      <FormGroup>
                        {availableResourcesList?.map((folderProps, idx) => {
                          return (
                            <FormControlLabel
                              key={idx}
                              control={
                                <Checkbox
                                  onChange={() =>
                                    updateSelectedList({ folderProps })
                                  }
                                  checked={selectedResourcesList
                                    .map(({ name }) => name)
                                    ?.includes(folderProps.name)}
                                />
                              }
                              label={folderProps.name}
                            />
                          );
                        })}
                      </FormGroup>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </PanelBody>
  );
};

export default GoogleDriveIntegrationPanel;
