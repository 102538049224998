import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Typography,
  Select,
  MenuItem,
  Collapse,
  FormControlLabel,
  Switch,
  IconButton,
  Grid,
  Tooltip,
} from "@material-ui/core";
import {
  ErrorOutlineOutlined,
  CheckCircleOutlineOutlined,
  Add,
  SwapHoriz,
  Refresh,
} from "@material-ui/icons";
import { v4 } from "uuid";
import useCustomQuery from "../../../../../../../common/CustomQuery";
import MyWindowsDimensions from "../../../../../utils/windowsDimension";
import RichTextEditor from "./sidebarActions/common/RichTextEditor";
import { updateTaskVariables } from "../../../utils/workflowHelpers";
import { getScreensAPI } from "../../utils/temporaryScreensAPI";
import { getScreenItemsAPI } from "../../utils/temporaryScreenItemsAPI";
import SelectOnSteroids from "./sidebarActions/common/SelectOnSteroids";
import CustomConfirmBox from "../../../../../../../common/Helper/CustomConfirmBox";
import ScreenTaskActions from "./sidebarActions/ScreenAction";
import ScheduleActivity from "./Helpers/ScheduleActivity";
import Escalation from "./Helpers/Escalation";
import ExecutionVariables from "./Helpers/ExecutionVariables";
import {
  doManageVariables,
  globalSetTaskInfo,
  isConnectedTo,
  validFormSelfReferenceFields,
} from "../../utils/funcs";
import { NameDescription } from "./sidebarActions/common/NameDescription";
import InitiatorMatchingPair from "./Helpers/InitiatorMatchingPair";
import {
  INVALID_FIELD_LABEL_STYLE,
  variableGroups,
} from "../../utils/constants";
import { setStateTimeOut } from "../../../../../../../helpers/helperFunctions";
import { useStyles } from "./Helpers/rightSidebarStyles";
import { getReusedScreenDynamicContents } from "../../../utils/workflowAPIs";
import { WORKFLOWS_TASK_SCREEN } from "../../utils/taskTypes";
import AutoReminder from "./Helpers/AutoReminder";

const REUSABLE_VARIABLE_GROUPS = [
  variableGroups.FORM,
  variableGroups.INPUT_TABLE,
];

const screenVariableFieldTypes = [
  "inputText",
  "textArea",
  "field", //  ??
  "phoneNumber",
  "fileUpload",
  "dateTime",
  "signature",
  "dropdown",
  "checkbox",
  "userPicker",
  "inputTable",
];
const screenDynamicTypes = [
  "text",
  "heading",
  "inputText",
  "dropdown",
  "checkbox",
  "displayTable",
  "image",
];
const screenUnitTextTypes = ["text", "heading"];
const screenUnitImageType = ["image"];
const screenUnitFieldTypes = [
  "inputText",
  "textArea",
  "phoneNumber",
  "fileUpload",
  "dateTime",
  "signature",
];
const screenListTypes = ["dropdown", "checkbox", "userPicker"];
const screenTableTypes = ["displayTable", "inputTable"];

const ScreenTaskSidebar = ({
  activeTaskId,
  activeTaskType,
  workflowTasks,
  workflowCanvas,
  allVariables,
  selectedApp,
  dispatch,
}) => {
  const activeTask = workflowTasks[activeTaskId];
  const variables = allVariables?.[activeTaskId];
  //console.log(activeTask);

  const classes = useStyles(makeStyles);

  const [properties, setProperties] = useState(activeTask?.properties || {});
  const [screensList, setScreensList] = useState([]);
  const [selectFormFields, setSelectFormFields] = useState([]);
  const [showSection, setShowSection] = useState({
    settings: true,
    action: false,
  });
  const [isInfoConfigured, setIsInfoConfigured] = useState(false);
  const [isActionsConfigured, setIsActionsConfigured] = useState(false);
  const [initiatorPairs, setInitiatorPairs] = useState([]);
  const [canAdd, setCanAdd] = useState(true);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [hideAssignEmailBody, setHideAssignEmailBody] = useState(true);
  const [hideAutoRemindEmailBody, setHideAutoRemindEmailBody] = useState(true);
  const [hideEscalateEmailBody, setHideEscalateEmailBody] = useState(true);
  const [nodeIsConnectedToAny, setNodeIsConnectedToAny] = useState(false);
  const [nodeIsConnectedToStart, setNodeIsConnectedToStart] = useState(false);

  const [selectedScreen, setSelectedScreen] = useState("");
  const [isOriginalScreen, setIsOriginalScreen] = useState(false);
  const [heldAssignBody, setHeldAssignBody] = useState(
    activeTask?.properties?.emailBody
  );
  const [heldAutoRemindBody, setHeldAutoRemindBody] = useState(
    activeTask?.properties?.automaticReminder?.emailBody
  );
  const [heldEscalateBody, setHeldEscalateBody] = useState(
    activeTask?.properties?.escalation?.emailBody
  );
  const [screenFieldsVariables, setScreenFieldVariables] = useState([]);
  const [listOfReusableScreen, setListOfReusableScreen] = useState([]);
  const [indicator, setIndicator] = useState(false);
  const [selectScreenDropdownKey, setSelectScreenDropdownKey] = useState(v4());
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);

  let accumulatedDynamicContents = [];

  let SCREEN_INFO;
  let SCREEN_ID;

  let selectedScreenInfo = {};
  let formFieldArray = [];
  let tableColumnArray = [];

  //  TEMPORARILY DISABLED - CAUSING INFINITE LOOP - CONFIRM UTILIY
  useEffect(() => {
    !!properties?.screen &&
      handleScreenSelect({ target: { value: properties?.screen } }, true);
  }, []);

  useEffect(() => {
    checkSetupStatus(activeTask);
    if (selectedApp?.id) refetch(); //  consider adding selectedApp to dependencies
  }, []);

  useEffect(() => {
    updateSelectedScreenInfo(properties?.screen);
    setIsOriginalScreen(!properties?.screenReuse?.isReusingScreen);
  }, [screensList, properties?.screen]);

  // clo
  useEffect(() => {
    if (!!properties?.initiatorProfile?.length) {
      const vl = validFormSelfReferenceFields(variables, activeTaskId);
      const pl = properties?.initiatorProfile;
      setCanAdd(vl.length > pl.length);
      setInitiatorPairs(properties?.initiatorProfile);
    }
  }, [properties?.initiatorProfile]);

  useEffect(() => {
    setProperties({ ...activeTask?.properties });
    checkSetupStatus(activeTask);

    const screenVariables = (variables || [])?.filter(
      (variable) =>
        REUSABLE_VARIABLE_GROUPS.includes(variable.group) &&
        variable.matching?.valueSourceId === activeTaskId
    );

    setIsOriginalScreen(!activeTask?.properties?.screenReuse?.isReusingScreen);
    setScreenFieldVariables(screenVariables);
  }, [activeTask]);

  useEffect(() => {
    const isConnectedToAnySource = isConnectedTo(
      activeTaskId,
      workflowCanvas,
      "anyNode"
    );
    const isConnectedToStartNode = isConnectedTo(
      activeTaskId,
      workflowCanvas,
      "startNode"
    );
    setNodeIsConnectedToAny(isConnectedToAnySource);
    setNodeIsConnectedToStart(isConnectedToStartNode);
  }, [workflowCanvas, activeTaskId]);

  useEffect(() => {
    const resuseableScreens = (variables || []).filter(
      (variable) =>
        variable?.matching?.valueSourceId !== activeTaskId && //  exclude that of current screen, if any
        variable?.dataType === "screen"
    );

    setListOfReusableScreen(resuseableScreens);
  }, [variables]);

  useEffect(() => {
    setHeldAssignBody(activeTask?.properties?.emailBody);
    setHeldAutoRemindBody(activeTask?.properties?.automaticReminder?.emailBody);
    setHeldEscalateBody(activeTask?.properties?.escalation?.emailBody);
  }, [activeTask]);

  useEffect(() => {
    setSelectScreenDropdownKey(v4());
  }, [properties.screen, properties.screenReuse]);

  const updateSelectedScreenInfo = (screenId) => {
    const screenInfo = screensList.find((sc) => sc.id === screenId) || {};
    selectedScreenInfo = screenInfo;
    setSelectedScreen(() => screenInfo);
    return screenInfo;
  };

  //  remove any existing key in dynamicContents that's
  //  no longer in the structure
  const reinitializeDynamicContents = (contents, structure) => {
    const filteredContent = contents.filter((content) =>
      structure.find((item) => item.key === content.key)
    );
    return filteredContent;
  };

  const saveScreenAttributesUpdate = (
    dynamicContentsStructure,
    screenVariables,
    initialize = true
  ) => {
    let screenFieldsAttributes = {};
    const lookupVariables = screenVariables?.filter((variable) => {
      variable.activeSelection = variable?.activeSelection ?? false;
      return (
        variable?.group?.toLowerCase() === "form" &&
        variable.matching.valueSourceType === "form"
      );
    });
    if (!initialize) {
      screenVariables.forEach((variable) => {
        screenFieldsAttributes = {
          ...screenFieldsAttributes,
          [variable.matching.valueSourceInput]: {
            name: variable.name,
            attribute: variable?.name?.startsWith("@")
              ? "readonly"
              : properties?.screenReuse?.isReusingScreen
              ? "readonly"
              : "editable",
            sourceVariable: variable.id,
          },
        };
      });
    }

    _setTaskInfo(
      {
        dynamicContents: reinitializeDynamicContents(
          properties?.dynamicContents,
          dynamicContentsStructure
        ),
        _dynamicContentsStructure: dynamicContentsStructure,
        lookupContents: lookupVariables,
        automaticReminder: {
          autoMailTask: false,
          emailBody: "",
          autoReminder: {
            delay: { value: [] },
            timing: { value: [] },
            type: "time",
            recurring: { frequencyMeasure: "", frequencyCount: "" },
          },
        },
        escalation: {
          escalateTask: false,
          emailBody: "",
          escalateTo: [],
          escalateTaskDueDate: {
            delay: { value: [] },
            timing: { value: [] },
            type: "time",
          },
        },
        ...(initialize
          ? {
              initiatorProfile: [],
              screenType: SCREEN_INFO?.type,
              screen: SCREEN_ID,
              screenReuse: {
                isReusingScreen: false,
                isReusableScreen: false,
                reusableScreenVariableId: "",
                reusableScreenTaskId: "",
                fieldsAttributes: [screenFieldsAttributes],
              },
            }
          : { "screenReuse.fieldsAttributes": [screenFieldsAttributes] }),
      },
      null,
      true
    );
  };

  const _toggleSection = (e, sect) => {
    e && e.stopPropagation();
    !!e.persist && e.persist();

    const makeTrue = !showSection[sect];
    const showSection_ = { ...showSection };
    Object.keys(showSection_).forEach((p) => (showSection_[p] = false));
    if (makeTrue) showSection_[sect] = true;
    setShowSection(showSection_);
  };

  const _setTaskInfo = (e, ppty, isGrouped, aux) => {
    !!e?.persist && e.persist();

    if (
      ppty === "useInitiatorProfile" &&
      e?.target?.checked &&
      aux !== "confirmed" &&
      initiatorProfileAlreadyExists()
    ) {
      return setOpenConfirmBox(() => true);
    }

    const additionalOuterProperties = [
      "assignTask",
      "assignedTo",
      "escalateTask",
      "escalateTo",
      "escalateTaskDueDate",
    ];

    globalSetTaskInfo(
      dispatch,
      e,
      ppty,
      isGrouped,
      activeTask,
      () => {},
      additionalOuterProperties,
      checkSetupStatus
    );
  };

  const checkSetupStatus = (info) => {
    const infoStatus =
      !!info?.name &&
      !!info?.properties?.screen &&
      (!!info?.triggeredByWebhook || !!info?.triggeredByWorkflow) &&
      (!info?.assignTask || !!info?.assignedTo?.length);

    const actionsStatus = true;
    const status = infoStatus && actionsStatus;

    const preStatus = isInfoConfigured && isActionsConfigured;

    setIsInfoConfigured(infoStatus);
    setIsActionsConfigured(actionsStatus);

    if (status === preStatus) return null;
    return status;
  };

  const joinInitiatorsToVariables = (data) => {
    const currentVariables = [...variables];
    const nvarys = currentVariables.filter(
      (variable) =>
        !(
          variable.matching?.valueSourceId === activeTaskId &&
          !!variable.group &&
          variable.group.toLowerCase() === "initiator"
        )
    );

    data.forEach((dataElement) => {
      const inputKeyName = Object.keys(dataElement)[0];
      const inputKeyId = dataElement[inputKeyName];
      const formInputId = currentVariables.find((v) => v.id === inputKeyId)
        .matching.valueSourceInput;

      nvarys.push({
        id: v4(),
        name: `Initiator (${inputKeyName.replace("initiator_", "")})`,
        parent: activeTaskId,
        nodeType: WORKFLOWS_TASK_SCREEN,
        dataType: [inputKeyName.replace("initiator_", "")],
        group: "Initiator",
        matching: {
          valueSourceType: "form",
          valueSourceId: "activeFormID", //  checkagain
          valueSourceInput: formInputId,
        },
      });
    });

    const directValue = {
      taskId: activeTaskId,
      taskVariables: nvarys,
    };
    dispatch(updateTaskVariables({ directValue, saveToDB: true }));
  };

  const joinExecutorVarsToVariables = (data, passedVariables, clear = null) => {
    const data_ = [...(data || [])];

    const validFields = data_.filter(
      (dataElement) =>
        !!dataElement.dataType &&
        dataElement.dataType !== "none" &&
        !!dataElement.name
    );

    const toVariablesLists = {
      task: validFields,
    };

    const validCurrentVars = doManageVariables(
      variableGroups.EXECUTOR,
      toVariablesLists,
      passedVariables || variables || [],
      activeTaskId,
      activeTaskType,
      clear
    );

    const directValue = {
      taskId: activeTaskId,
      taskVariables: validCurrentVars,
    };

    dispatch(updateTaskVariables({ directValue, saveToDB: true }));
  };

  const joinFormInputsToVariables = (data, passedVariables, clear = null) => {
    // if (!variables) return;    //  reconsider the commenting of this
    const data_ = [...(data || [])];

    const validFields = data_.filter(
      (dataElement) =>
        !!dataElement.dataType &&
        dataElement.dataType !== "none" &&
        !!dataElement.name
    );

    setSelectFormFields(validFields);

    const toVariablesLists = {
      form: validFields,
    };

    const validCurrentVars = doManageVariables(
      variableGroups.FORM,
      toVariablesLists,
      passedVariables || variables || [], //  as well as reconsider the addition of the || []
      activeTaskId,
      activeTaskType,
      clear
    );

    return validCurrentVars;
  };

  //  just for inputTables...for now
  const joinTableColumnsToVariables = (data, passedVariables, clear = null) => {
    const data_ = [...(data || [])];

    const allColumns = [];
    const allAggregateCells = [];

    /* populate list of columns/aggregateCells to be added as 
      new variables */
    data_?.forEach((eachTable) => {
      const columns = eachTable.values?.columns;
      columns?.forEach((column) => {
        allColumns.push({
          ...column,
          name: `${eachTable.name} >> ${column.header}`,
          parentTableId: eachTable.id,
          parentTableName: eachTable.name,
          dataType: [column.dataType?.toLowerCase() || "text", "array"],
        });
      });

      const aggregateCells = eachTable.values?.aggregateCells;
      aggregateCells?.forEach((cell) => {
        allAggregateCells.push({
          ...cell,
          name: `${eachTable.name} >> ${cell.label}`,
          parentTableId: eachTable.id,
          parentTableName: eachTable.name,
          dataType: [cell.dataType?.toLowerCase() || "text"],
        });
      });
    });

    const toVariablesLists = {
      column: allColumns,
      aggregateCell: allAggregateCells,
    };
    const validCurrentVars = doManageVariables(
      variableGroups.INPUT_TABLE,
      toVariablesLists,
      passedVariables || variables || [],
      activeTaskId,
      activeTaskType,
      clear
    );

    return validCurrentVars;
  };

  const documentScreenVariables = (name, passedVariables, clear = null) => {
    // if (!variables) return;
    if (!name) {
      //console.log(`DOC SCREEN NAME NOT AVAILABLE YET ON THIS LINE`);
      return;
    }

    //console.log(`DOC SCREEN THINGS`);

    const toVariablesLists = {
      document: [
        {
          id: v4(),
          name: `${name} (doc)`,
          parent: activeTaskId,
          dataType: ["file"],
        },
      ],
    };

    const validCurrentVars = doManageVariables(
      variableGroups.DOCUMENT,
      toVariablesLists,
      passedVariables || variables,
      activeTaskId,
      activeTaskType,
      clear
    );

    return validCurrentVars;
  };

  const _setMatchingPairs = (index, obj) => {
    const fromState = [...initiatorPairs];
    fromState[index] = obj;
    joinInitiatorsToVariables(fromState);
    setInitiatorPairs(fromState);
    _setTaskInfo(fromState, "initiatorProfile");
  };

  const _addPair = () => {
    if (!canAdd) return;
    const fromState = [...initiatorPairs];
    fromState.push({});
    setInitiatorPairs(fromState);
    setCanAdd(false);
  };

  const _removePair = (index) => {
    const fromState = [...initiatorPairs];
    fromState.splice(index, 1);
    setInitiatorPairs(fromState);
    _setTaskInfo(fromState, "initiatorProfile");
  };

  const getDynamicIdentifier = (screenItem) => {
    if (screenUnitTextTypes.includes(screenItem.type)) {
      return screenItem?.values?.value;
    }
    return screenItem?.name;
  };

  const getDynamicType = ({ type: itemType }) => {
    if (screenUnitTextTypes.includes(itemType)) {
      return "text";
    } else if (screenUnitFieldTypes.includes(itemType)) {
      return "field";
    } else if (screenListTypes.includes(itemType)) {
      return "list";
    } else if (screenTableTypes.includes(itemType)) {
      return "table";
    } else if (screenUnitImageType.includes(itemType)) {
      return "text";
    }
  };

  /**
   * It takes an array of objects, and if the object has a children property, it calls itself with the
   * children array as the argument. If the object doesn't have a children property, it checks the type
   * property and does something with it.
   */
  const handleScreenItems = (
    { screenItems, parentId },
    root = true,
    initialize
  ) => {
    const match = /(@[a-zA-Z0-9-_]+)/g;
    screenItems instanceof Array &&
      screenItems.forEach((screenItem) => {
        if (!!screenItem?.children?.length) {
          handleScreenItems(
            {
              screenItems: screenItem?.children,
              parentId: screenItem?.id,
            },
            false
          );
        } else {
          //  retrieve dynamic fields
          if (screenDynamicTypes.includes(screenItem.type)) {
            const dynamicIdentifier = getDynamicIdentifier(screenItem);

            (dynamicIdentifier?.match(match) || []).forEach((dynamicText) => {
              accumulatedDynamicContents.push({
                key: dynamicText,
                dynamicType: getDynamicType(screenItem),
                fieldType: screenItem?.type,
                ...(screenItem.type === "displayTable"
                  ? { tableColumns: screenItem.values?.columns }
                  : {}),
              });
            });
          }
          //  retrieve variable fields
          if (screenVariableFieldTypes.includes(screenItem.type)) {
            if (screenItem.type === "inputTable") {
              tableColumnArray = [
                ...tableColumnArray,
                { ...screenItem, parentId },
              ];
            } else {
              formFieldArray = [...formFieldArray, { ...screenItem, parentId }];
            }
          }
        }
      });

    //  once all items are completed scanned...
    if (root) {
      let allUpdatedVariables = [];

      //  bring in form inputs into screen node variables
      allUpdatedVariables =
        joinFormInputsToVariables(
          formFieldArray,
          null,
          !formFieldArray.length
        ) || allUpdatedVariables;

      //  bring in Display Table columns into screen node variables
      // if (tableColumnArray.length) { //  commented out so existing table vars can be cleared
      allUpdatedVariables =
        joinTableColumnsToVariables(
          tableColumnArray,
          allUpdatedVariables,
          !tableColumnArray.length
        ) || allUpdatedVariables;
      // }

      //  bring in doc screen vars into screen node variables

      if (selectedScreenInfo.type === "document") {
        allUpdatedVariables =
          documentScreenVariables(
            selectedScreenInfo?.name,
            allUpdatedVariables
          ) || allUpdatedVariables;
      }

      const screenVariables = allUpdatedVariables.filter((variable) => {
        return (
          REUSABLE_VARIABLE_GROUPS.includes(variable.group) &&
          variable.matching.valueSourceId === activeTaskId
        );
      });

      saveScreenAttributesUpdate(
        accumulatedDynamicContents,
        screenVariables,
        initialize
      );

      setScreenFieldVariables(screenVariables);

      //  finally, clear any existingscreen variable if initialize
      if (initialize) {
        allUpdatedVariables = allUpdatedVariables.filter((variable) => {
          return !(
            variable.parent === activeTaskId &&
            variable.dataType === "screen" &&
            variable.group === variableGroups.SCREENS
          );
        });
      }

      const directValue = {
        taskId: activeTaskId,
        taskVariables: allUpdatedVariables,
      };

      dispatch(updateTaskVariables({ directValue, saveToDB: true }));
    }
  };

  const onLoadItemsSuccess = ({ data }, initialize) => {
    if (!data?.length) {
      return;
    }

    handleScreenItems({ screenItems: data }, true, initialize);
  };

  const handleScreenSelect = async (e, isSelected = true, initialize) => {
    if (!screensList.length) return;

    let screenId = e.target.value;
    if (!screenId) return;
    updateSelectedScreenInfo(screenId);
    setIsLoadingScreen(true);

    let isReusingScreen = false;
    let reusableScreenVariableId = "";
    let reuseTaskId = "";
    let fieldsAttributes = [{}];
    let resusedDynamicContentsStructure = null;

    accumulatedDynamicContents = [];
    formFieldArray = [];

    const lookupVariables = activeTask?.variables?.filter((variable) => {
      variable.activeSelection = variable?.activeSelection ?? false;
      return (
        variable?.group?.toLowerCase() === "form" &&
        variable.matching.valueSourceType === "form"
      );
    });

    let screenInfo = screensList.find((sc) => sc.id === screenId) || {};

    //  check if it's reused screen
    if (!screenInfo.id) {
      // reused screen
      const {
        screenVariableId,
        screenTaskId,
        reScreenId,
        screenFieldsAttributes,
        screenDynamicContentsStructure,
        updatedVariablesSet,
      } = await handleReusableScreenSelection(screenId);

      screenId = reScreenId;
      screenInfo = screensList.find((sc) => sc.id === reScreenId) || {};
      reusableScreenVariableId = screenVariableId;
      reuseTaskId = screenTaskId;
      isReusingScreen = true;
      isSelected = false;
      fieldsAttributes = [screenFieldsAttributes || {}];
      resusedDynamicContentsStructure = screenDynamicContentsStructure;
      setIsOriginalScreen(false);

      //  load variables into state... if no reusedScreenVariable is passed in
      const directValue = {
        taskId: activeTaskId,
        taskVariables: updatedVariablesSet,
      };

      dispatch(updateTaskVariables({ directValue, saveToDB: true }));
    } else {
      // original screen
      setIsOriginalScreen(true);
      setScreenFieldVariables([{}]);
    }

    SCREEN_INFO = screenInfo;
    SCREEN_ID = screenId;

    /* Setting the taskInfo object. */
    // documentScreenVariables(screenInfo?.type, screenInfo?.name); // PLEASE CONFIRM THE USE OF THIS, PERHAPS WITH A DOC SCREEN TEST

    if (isSelected) {
      await loadScreenItems(
        {
          query: {
            screen: screenId,
          },
        },
        initialize
      );
    } else {
      _setTaskInfo(
        {
          initiatorProfile: [],
          _dynamicContentsStructure: resusedDynamicContentsStructure,
          screenType: screenInfo?.type,
          screen: screenId,
          lookupContents: lookupVariables,
          automaticReminder: {
            autoMailTask: true,
            autoReminder: {
              delay: { value: [] },
              timing: { value: [] },
              type: "time",
              recurring: { frequencyMeasure: "", frequencyCount: "" },
            },
            emailBody: "",
          },
          escalation: {
            escalateTask: true,
            escalateTo: [],
            escalateTaskDueDate: {
              delay: { value: [] },
              timing: { value: [] },
              type: "time",
            },
            emailBody: "",
          },
          screenReuse: {
            isReusingScreen,
            isReusableScreen: false,
            reusableScreenVariableId,
            reusableScreenTaskId: reuseTaskId,
            fieldsAttributes,
          },
        },
        null,
        true
      );
    }

    /* Checking the setup status of the active task. */
    // checkSetupStatus(activeTask);
    setIsLoadingScreen(false);
  };

  const handleReusableScreenSelection = async (
    screenIdentity,
    newScreenVariable,
    isSelected = true
  ) => {
    const [screenVariableId, reScreenId, reuseTaskId] =
      screenIdentity.split("|");

    //  fetch reused screen dynamicContents...if any
    let screenDynamicContentsStructure = null;
    if (isSelected) {
      const reusedDynamicContents = await getReusedScreenDynamicContents(
        reuseTaskId
      );

      screenDynamicContentsStructure =
        reusedDynamicContents?.data?.[0]?.properties
          ?._dynamicContentsStructure || null;

      if (screenDynamicContentsStructure) {
        screenDynamicContentsStructure = screenDynamicContentsStructure.filter(
          (eachDynamicContent) => eachDynamicContent.fieldType !== "inputText"
        );
      }
    }
    //  first confirm id is that of valide 'screen variable', then...

    //  get variables of reused screen
    const existingScreenVariable = [...variables].find(
      (variable) =>
        variable?.dataType === "screen" &&
        variable.matching.valueSourceInput === reScreenId
    );

    //  initialize attributes list object
    let screenFieldsAttributes = {};

    //  clear any former variables created for screen
    const updatedVariables = [...variables].filter(
      (variable) =>
        ![
          variableGroups.FORM,
          variableGroups.INPUT_TABLE,
          variableGroups.DOCUMENT,
        ].includes(variable?.group) ||
        variable.matching.valueSourceId !== activeTaskId
    );

    //  get cloned variables from screen/node
    const reusedScreenFieldVariables = [...variables]
      .filter(
        (variable) =>
          [
            variableGroups.FORM,
            variableGroups.INPUT_TABLE,
            variableGroups.DOCUMENT,
          ].includes(variable?.group) &&
          variable.matching.valueSourceId ===
            (newScreenVariable || existingScreenVariable).matching.valueSourceId
      )
      .map((variable, index) => {
        const newVar = {
          ...variable,
          id: v4(),
          parent: activeTaskId,
          matching: {
            ...variable.matching,
            valueSourceId: activeTaskId,
          },
        };

        screenFieldsAttributes = {
          ...screenFieldsAttributes,
          [variable.matching.valueSourceInput]: {
            name: variable.name,
            attribute: existingScreenVariable ? "readonly" : "editable",
            sourceVariable: variable.id,
          },
        };
        return newVar;
      });

    const updatedVariablesSet = [
      ...updatedVariables,
      ...reusedScreenFieldVariables,
    ];

    return {
      screenVariableId,
      screenTaskId: reuseTaskId,
      reScreenId,
      screenFieldsAttributes,
      screenDynamicContentsStructure,
      updatedVariablesSet,
    };
  };

  const loadScreenItems = async (options, initialize) => {
    const returnedItems = await getScreenItemsAPI(options);

    if (returnedItems?._meta?.success) {
      onLoadItemsSuccess(returnedItems, initialize);
    }
  };

  const onLoadScreensSuccess = async (data) => {
    setScreensList(data.data.data);

    let screenVariables = (variables || [])?.filter(
      (variable) =>
        REUSABLE_VARIABLE_GROUPS.includes(variable.group) &&
        variable.matching?.valueSourceId === activeTaskId
    );

    setScreenFieldVariables(screenVariables);
    setIsOriginalScreen(
      !!data?.data?.data?.find((screen) => screen.id === properties?.screen)
    );
  };

  const { refetch } = useCustomQuery({
    queryKey: [
      "allScreens",
      {
        query: {
          app: selectedApp?.id,
          per_page: 100,
        },
      },
    ],
    apiFunc: getScreensAPI,
    onSuccess: onLoadScreensSuccess,
    enabled: false, // disable this query from automatically running
  });

  const _convertBody = (content, wh, taskType) => {
    console.log(content, wh, taskType);
    if (wh === "to-id") {
      if (
        !!content &&
        content.slice(0, 3) === "<p>" &&
        content.slice(-4) === "</p>"
      ) {
        content = !!content ? content.slice(3, -4) : "";
      }
      setStateTimeOut(setIndicator, true, false);

      if (taskType === "assignScreen") {
        _setTaskInfo(content, "emailBody");
      } else if (taskType === "escalation") {
        _setTaskInfo(
          { ...activeTask?.properties?.escalation, emailBody: content },
          "escalation"
        );
      } else if (taskType === "automaticReminder") {
        _setTaskInfo(
          { ...activeTask?.properties?.automaticReminder, emailBody: content },
          "automaticReminder"
        );
      }
    } else if (wh === "from-id") {
      return !!content ? content + "&nbsp;" : "&nbsp;";
    }
  };

  /**
   * If there are any ScreenTasks in the workflow, and any of them have the property useInitiatorProfile
   * set to true, then return false, otherwise return true.
   * @returns a boolean value.
   */
  const initiatorProfileAlreadyExists = () => {
    for (const _task in workflowTasks) {
      if (
        workflowTasks[_task]?.type === WORKFLOWS_TASK_SCREEN &&
        workflowTasks[_task]?.properties?.useInitiatorProfile &&
        workflowTasks[_task]?.id !== activeTaskId
      ) {
        return true;
      }
    }

    return false;
  };

  /**
   * If the action is true, set the showInitorProfile state to true and set the openConfirmBox state to
   * false.
   * If the action is false, set the openConfirmBox state to false.
   * @param open - boolean
   * @param action - true or false
   */
  const confirmAction = () => {
    _setTaskInfo(
      { target: { checked: true } },
      "useInitiatorProfile",
      null,
      "confirmed"
    );
    setOpenConfirmBox(() => false);
  };

  const reloadScreens = async () => {
    accumulatedDynamicContents = [];
    await refetch();

    const reusedScreenId = `${properties?.screenReuse?.reusableScreenVariableId}|${properties?.screen}|${properties?.screenReuse?.reusableScreenTaskId}`;
    const usedScreenId = properties?.screenReuse?.isReusingScreen
      ? reusedScreenId
      : properties?.screen;

    handleScreenSelect({ target: { value: usedScreenId } }, true, false);
  };

  const makeScreenReusable = async (e) => {
    e.persist();

    let currentVariables = [...variables];
    let fieldsAttributes = [{}];
    let reusableScreenVariableId = v4();

    const otherVariables = currentVariables.filter(
      (variable) =>
        variable.matching.valueSourceId !== activeTaskId ||
        variable.dataType !== "screen"
    );

    if (!!e.target.checked) {
      if (otherVariables.length < currentVariables.length) {
        //  screenVariable(s) found
        return;
      }

      const newScreenVariable = {
        id: reusableScreenVariableId,
        name: selectedScreen?.name,
        parent: activeTaskId,
        nodeType: activeTaskType,
        dataType: "screen",
        group: variableGroups.SCREENS,
        matching: {
          valueSourceType: properties?.screenType,
          valueSourceId: activeTaskId,
          valueSourceInput: properties?.screen,
        },
      };

      const { screenFieldsAttributes } = await handleReusableScreenSelection(
        `${reusableScreenVariableId}|${properties?.screen}|${activeTaskId}`,
        newScreenVariable,
        false
      );

      fieldsAttributes = [screenFieldsAttributes || {}];

      currentVariables.push(newScreenVariable);
    } else {
      reusableScreenVariableId = "";

      currentVariables = otherVariables;
    }

    _setTaskInfo(
      {
        screenReuse: {
          isReusableScreen: e.target.checked,
          isReusingScreen: false,
          reusableScreenVariableId,
          reusableScreenTaskId: activeTaskId,
          fieldsAttributes,
        },
      },
      null,
      true
    );

    const directValue = {
      taskId: activeTaskId,
      taskVariables: currentVariables,
    };
    dispatch(updateTaskVariables({ directValue, saveToDB: true }));
  };

  const updateFieldsAttributes = (newFieldAttributes) => {
    _setTaskInfo(
      {
        screenReuse: {
          ...properties?.screenReuse,
          fieldsAttributes: [newFieldAttributes],
        },
      },
      null,
      true
    );
  };

  return (
    <div className={classes.container}>
      <MyWindowsDimensions setWinDim={() => {}} />
      <div
        className={classes.sideHeadingBar}
        onClick={(e) => _toggleSection(e, "settings")}
      >
        <Typography gutterBottom className={classes.sideHeading}>
          {activeTask?.type || ""} Information
          {isInfoConfigured ? (
            <CheckCircleOutlineOutlined
              style={{ fontSize: 16, marginLeft: 10, color: "green" }}
            />
          ) : (
            <ErrorOutlineOutlined
              style={{ fontSize: 16, marginLeft: 10, color: "red" }}
            />
          )}
        </Typography>
      </div>
      <Collapse in={!!showSection.settings}>
        <Divider style={{ marginBottom: 10 }} />
        <div className={classes.section}>
          <NameDescription
            selectedApp={selectedApp}
            activeTask={activeTask}
            _setTaskInfo={_setTaskInfo}
            nodeIsConnectedToAny={nodeIsConnectedToAny}
            nodeIsConnectedToStart={nodeIsConnectedToStart}
            classes={classes}
          />
          <hr style={{ margin: "20px 0" }} />
          <div
            className={classes.sectionEntry}
            style={{ borderBottom: "solid 1px #EEE" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                gutterBottom
                className={classes.sectionTitle}
                style={!properties?.screen ? INVALID_FIELD_LABEL_STYLE : {}}
              >
                Select screen*
              </Typography>
              <Tooltip title="Reload screens">
                <Refresh
                  className={isLoadingScreen ? "icon-spin" : ""}
                  style={{
                    fontSize: 16,
                    ...(isLoadingScreen
                      ? { color: "#06188f", cursor: "default" }
                      : { color: "#999", cursor: "pointer" }),
                  }}
                  onClick={isLoadingScreen ? null : reloadScreens}
                />
              </Tooltip>
            </div>
            <Select
              key={`${selectScreenDropdownKey}${
                properties?.screenReuse?.isReusingScreen
                  ? `${properties?.screenReuse?.reusableScreenVariableId}|${properties?.screen}`
                  : properties?.screen || "choose"
              }`}
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Select screen"
              classes={{
                root: classes.select,
                outlined: classes.selected,
              }}
              defaultValue={
                properties?.screenReuse?.isReusingScreen
                  ? `${properties?.screenReuse?.reusableScreenVariableId}|${properties?.screen}|${properties?.screenReuse?.reusableScreenTaskId}`
                  : properties?.screen || "choose"
              }
              onChange={(e) => handleScreenSelect(e, "screen")}
            >
              <MenuItem value="choose" selected disabled>
                <em>Select screen</em>
              </MenuItem>
              {listOfReusableScreen.map((reusableScreen, index) => (
                <MenuItem
                  key={`re-select-screen-${reusableScreen.id}-${index}`}
                  value={`${reusableScreen.id}|${reusableScreen.matching.valueSourceInput}|${reusableScreen.matching.valueSourceId}`}
                  style={{ justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className={classes.reuseableScreenBadge}>RE</div>
                    {reusableScreen.name}
                  </div>
                  <span
                    style={{
                      color: "#0c7b93",
                      fontStyle: "italic",
                      textTransform: "capitalize",
                    }}
                  >
                    [ Reusable ]
                  </span>
                </MenuItem>
              ))}

              {!!listOfReusableScreen.length && <hr />}

              {screensList.map((el, i) => (
                <MenuItem
                  key={`select-screen-${el.id}-${i}`}
                  value={el.id}
                  style={{ justifyContent: "space-between" }}
                >
                  <div>{el.name}</div>
                  <span
                    style={{
                      color: "#0c7b93",
                      fontStyle: "italic",
                      textTransform: "capitalize",
                    }}
                  >
                    [ {el.type} ]
                  </span>
                </MenuItem>
              ))}
            </Select>
          </div>

          {/* 
            - show this section if screen has fields and 
            if selected screen is original screen 
            - once this is activated, an extra variable
            is added to the vairables list for node
          */}

          {!!properties?.screen &&
            isOriginalScreen &&
            !!screenFieldsVariables.length && (
              <Collapse
                in={!!properties?.screen}
                style={{ borderBottom: "solid 1px #EEE" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "10px 0 5px",
                  }}
                >
                  <FormControlLabel
                    classes={{
                      root: classes.switchLabel,
                      label: classes.sectionTitle,
                    }}
                    control={
                      <Switch
                        key={properties?.screenReuse?.isReusableScreen}
                        defaultChecked={
                          properties?.screenReuse?.isReusableScreen
                        }
                        onChange={makeScreenReusable}
                        name="checkedC"
                        color="primary"
                        size="small"
                      />
                    }
                    label="Make screen reusable"
                    labelPlacement="start"
                  />
                </div>
              </Collapse>
            )}
          {selectedApp?.isPublic && (
            <Collapse
              in={!!properties?.screen}
              style={{ borderBottom: "solid 1px #EEE" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "10px 0 5px",
                }}
              >
                <FormControlLabel
                  classes={{
                    root: classes.switchLabel,
                    label: classes.sectionTitle,
                  }}
                  control={
                    <Switch
                      key={properties?.useInitiatorProfile}
                      defaultChecked={properties?.useInitiatorProfile}
                      onChange={(e) => _setTaskInfo(e, "useInitiatorProfile")}
                      name="checkedC"
                      color="primary"
                      size="small"
                    />
                  }
                  label="Create initiator profile"
                  labelPlacement="start"
                />
              </div>

              <Collapse in={!!properties?.useInitiatorProfile}>
                <div
                  className={classes.matchingFields}
                  style={{ marginTop: 0, marginBottom: 5 }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 3,
                    }}
                  >
                    <div className={classes.mappingTitle}>
                      <Typography
                        gutterBottom
                        style={{ fontSize: 12, color: "#f7a712" }}
                      >
                        Reference name
                      </Typography>
                      <SwapHoriz />
                    </div>
                    <Typography gutterBottom className={classes.mappingTitle}>
                      Matching form field
                    </Typography>
                  </div>

                  {initiatorPairs.map((value, index) => (
                    <InitiatorMatchingPair
                      key={index}
                      map={{ value, index }}
                      selectFormFields={selectFormFields}
                      setInitiatorPairs={_setMatchingPairs}
                      initiatorPairs={initiatorPairs}
                      pairsList={properties?.initiatorProfile || [{}]}
                      removePair={_removePair}
                      setCanAdd={setCanAdd}
                      count={initiatorPairs}
                      variables={validFormSelfReferenceFields(
                        variables,
                        activeTaskId
                      )}
                    />
                  ))}

                  <IconButton
                    onClick={_addPair}
                    aria-label="Add pair"
                    size="small"
                    className={classes.addMatch}
                    disabled={!canAdd}
                  >
                    <Add style={{ fontSize: 20 }} />
                  </IconButton>
                </div>
              </Collapse>
            </Collapse>
          )}
          <Collapse in={properties?.screenType === "document"}>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "10px 0px 5px",
              }}
            >
              <FormControlLabel
                classes={{
                  root: classes.switchLabel,
                  label: classes.sectionTitle,
                }}
                control={
                  <Switch
                    key={properties?.previewDownload}
                    defaultChecked={properties?.previewDownload}
                    onChange={(e) => _setTaskInfo(e, "previewDownload")}
                    name="checkDoc"
                    color="primary"
                    size="small"
                  />
                }
                label={
                  <>
                    Show download button
                    <Tooltip
                      title="This option will automatically open the document preview and the
                user can download thereafter."
                    >
                      <div className="info-icon-tooltip-1">i</div>
                    </Tooltip>
                  </>
                }
                labelPlacement="start"
              />
            </Grid>
            {/* <Grid container>
              <Typography
                variant="p"
                component="p"
                className={classes.sectionTitle}
                style={{ color: "#7c9baf"}}
              >
                This option will automatically open the document preview and the
                user can download thereafter.
              </Typography>
            </Grid> */}
          </Collapse>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0px 5px",
            }}
          >
            <FormControlLabel
              classes={{
                root: classes.switchLabel,
                label:
                  !activeTask?.assignTask || !!activeTask?.assignedTo?.length
                    ? classes.sectionTitle
                    : classes.sectionTitleError,
              }}
              control={
                <Switch
                  key={!!activeTask?.assignTask}
                  defaultChecked={!!activeTask?.assignTask}
                  onChange={(e) => _setTaskInfo(e, "assignTask")}
                  name="checkedC"
                  color="primary"
                  size="small"
                />
              }
              label="Assign screen"
              labelPlacement="start"
            />
          </div>
          <Collapse in={!!activeTask?.assignTask}>
            <div style={{ marginBottom: "9px" }}>
              <div className={classes.sectionEntry}>
                <SelectOnSteroids
                  source="email"
                  variables={variables}
                  onChange={(e) => _setTaskInfo(e, "assignedTo")}
                  value={activeTask?.assignedTo || []}
                  type="email"
                  multiple={true}
                />
              </div>
              <div className={classes.sectionEntry}>
                <Typography gutterBottom className={classes.sectionTitle}>
                  Message (Optional)
                  <span
                    className="email-collapse-body"
                    onClick={() => {
                      setHideAutoRemindEmailBody(true);
                      setHideEscalateEmailBody(true);
                      setHideAssignEmailBody(!hideAssignEmailBody);
                    }}
                  >
                    {!hideAssignEmailBody ? "Collapse" : "Expand"}
                  </span>
                  {!hideAssignEmailBody ? (
                    <>
                      <span
                        className="email-body-save-btn"
                        onClick={() =>
                          _convertBody(heldAssignBody, "to-id", "assignScreen")
                        }
                      >
                        Save message
                      </span>
                      {indicator ? (
                        <span
                          style={{
                            fontSize: "11px",
                            fontWeight: 500,
                            marginRight: 10,
                            float: "right",
                          }}
                          id="screen-task-message-saved"
                        >
                          Message saved
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Typography>
                <Collapse in={!hideAssignEmailBody}>
                  {" "}
                  {!hideAssignEmailBody && (
                    <RichTextEditor
                      variables={variables}
                      emailBody={_convertBody(heldAssignBody, "from-id")}
                      holdBody={(content) => setHeldAssignBody(content)}
                    />
                  )}
                </Collapse>
              </div>
            </div>
          </Collapse>
          {/* <Collapse in={!!activeTask?.assignTask}> */}
          <ExecutionVariables
            selectedScreen={selectedScreen}
            activeTask={activeTask}
            setTaskInfo={_setTaskInfo}
            activeTaskId={activeTaskId}
            variables={variables}
            properties={properties}
            classes={classes}
            joinExecutorVarsToVariables={joinExecutorVarsToVariables}
          />
          {/* </Collapse> */}
          <Collapse in={!!activeTask?.assignTask}>
            <div style={{ marginBottom: "9px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "10px 0px 5px",
                }}
              >
                <FormControlLabel
                  classes={{
                    root: classes.switchLabel,
                    label: classes.sectionTitle,
                  }}
                  control={
                    <Switch
                      key={!!activeTask?.useCustomTrigger}
                      defaultChecked={!!activeTask?.useCustomTrigger}
                      onChange={(e) => _setTaskInfo(e, "useCustomTrigger")}
                      name="checkedC"
                      color="primary"
                      size="small"
                    />
                  }
                  label="Schedule screen"
                  labelPlacement="start"
                />
              </div>

              {!!activeTask?.useCustomTrigger ? (
                <ScheduleActivity
                  activeTask={activeTask}
                  classes={classes}
                  saveScheduleInfo={_setTaskInfo}
                  variables={variables}
                  from="screenTask"
                />
              ) : null}
            </div>
            <div
              style={{
                paddingBottom: "9px",
                borderBottom: "1px solid darkgray",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "10px 0px 5px",
                }}
              >
                <FormControlLabel
                  classes={{
                    root: classes.switchLabel,
                    label: classes.sectionTitle,
                  }}
                  control={
                    <Switch
                      key={!!properties?.automaticReminder?.autoMailTask}
                      defaultChecked={
                        !!properties?.automaticReminder?.autoMailTask
                      }
                      onChange={(e) =>
                        _setTaskInfo(
                          {
                            ...properties.automaticReminder,
                            autoMailTask: e.target.checked,
                          },
                          "automaticReminder"
                        )
                      }
                      name="checkedC"
                      color="primary"
                      size="small"
                    />
                  }
                  label="Auto Reminder"
                  labelPlacement="start"
                />
              </div>

              {!!properties?.automaticReminder?.autoMailTask ? (
                <AutoReminder
                  activeTask={activeTask}
                  classes={classes}
                  properties={properties}
                  _setTaskInfo={(data) =>
                    _setTaskInfo(data, "automaticReminder")
                  }
                  variables={variables}
                  from="screenTask"
                />
              ) : null}

              <div
                className={classes.sectionEntry}
                style={{ marginTop: "9px" }}
              >
                <Typography gutterBottom className={classes.sectionTitle}>
                  Message (Optional)
                  <span
                    className="email-collapse-body"
                    onClick={() => {
                      setHideAssignEmailBody(true);
                      setHideEscalateEmailBody(true);
                      setHideAutoRemindEmailBody(!hideAutoRemindEmailBody);
                    }}
                  >
                    {!hideAutoRemindEmailBody ? "Collapse" : "Expand"}
                  </span>
                  {!hideAutoRemindEmailBody ? (
                    <>
                      <span
                        className="email-body-save-btn"
                        onClick={() =>
                          _convertBody(
                            heldAutoRemindBody,
                            "to-id",
                            "automaticReminder"
                          )
                        }
                      >
                        Save message
                      </span>
                      {indicator ? (
                        <span
                          style={{
                            fontSize: "11px",
                            fontWeight: 500,
                            marginRight: 10,
                            float: "right",
                          }}
                          id="screen-task-message-saved"
                        >
                          Message saved
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Typography>
                <Collapse in={!hideAutoRemindEmailBody}>
                  {" "}
                  {!hideAutoRemindEmailBody && (
                    <RichTextEditor
                      variables={variables}
                      emailBody={_convertBody(heldAutoRemindBody, "from-id")}
                      holdBody={(content) => setHeldAutoRemindBody(content)}
                    />
                  )}
                </Collapse>
              </div>
            </div>
            <div style={{ marginBottom: "9px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "10px 0px 5px",
                }}
              >
                <FormControlLabel
                  classes={{
                    root: classes.switchLabel,
                    label: classes.sectionTitle,
                  }}
                  control={
                    <Switch
                      key={!!properties?.escalation?.escalateTask}
                      defaultChecked={!!properties?.escalation?.escalateTask}
                      onChange={(e) =>
                        _setTaskInfo(
                          {
                            ...properties.escalation,
                            escalateTask: e.target.checked,
                          },
                          "escalation"
                        )
                      }
                      name="checkedC"
                      color="primary"
                      size="small"
                    />
                  }
                  label="Escalation"
                  labelPlacement="start"
                />
              </div>

              {!!properties?.escalation?.escalateTask ? (
                <Escalation
                  activeTask={activeTask}
                  properties={properties}
                  classes={classes}
                  _setTaskInfo={(data, type) =>
                    _setTaskInfo(data, "escalation")
                  }
                  variables={variables}
                  from={"screenTask"}
                />
              ) : null}
              <div className={classes.sectionEntry}>
                <Typography gutterBottom className={classes.sectionTitle}>
                  Message (Optional)
                  <span
                    className="email-collapse-body"
                    onClick={() => {
                      setHideAssignEmailBody(true);
                      setHideAutoRemindEmailBody(true);
                      setHideEscalateEmailBody(!hideEscalateEmailBody);
                    }}
                  >
                    {!hideEscalateEmailBody ? "Collapse" : "Expand"}
                  </span>
                  {!hideEscalateEmailBody ? (
                    <>
                      <span
                        className="email-body-save-btn"
                        onClick={() =>
                          _convertBody(heldEscalateBody, "to-id", "escalation")
                        }
                      >
                        Save message
                      </span>
                      {indicator ? (
                        <span
                          style={{
                            fontSize: "11px",
                            fontWeight: 500,
                            marginRight: 10,
                            float: "right",
                          }}
                          id="screen-task-message-saved"
                        >
                          Message saved
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Typography>
                <Collapse in={!hideEscalateEmailBody}>
                  {" "}
                  {!hideEscalateEmailBody && (
                    <RichTextEditor
                      variables={variables}
                      emailBody={_convertBody(heldEscalateBody, "from-id")}
                      holdBody={(content) => setHeldEscalateBody(content)}
                    />
                  )}
                </Collapse>
              </div>
            </div>
          </Collapse>
        </div>
      </Collapse>
      <div
        className={classes.sideHeadingBar}
        onClick={(e) => _toggleSection(e, "action")}
      >
        <Typography gutterBottom className={classes.sideHeading}>
          Action details
          {isActionsConfigured && (
            <CheckCircleOutlineOutlined
              style={{ fontSize: 16, marginLeft: 10, color: "green" }}
            />
          )}
          {!isActionsConfigured && (
            <ErrorOutlineOutlined
              style={{ fontSize: 16, marginLeft: 10, color: "red" }}
            />
          )}
        </Typography>
      </div>

      <Collapse in={!!showSection.action}>
        <div className={classes.section}>
          <ScreenTaskActions
            activeTaskId={activeTaskId}
            isReusableScreen={properties?.screenReuse?.isReusableScreen}
            isReusingScreen={properties?.screenReuse?.isReusingScreen}
            fieldsAttributes={
              properties?.screenReuse?.fieldsAttributes?.[0] || {}
            }
            updateFieldsAttributes={updateFieldsAttributes}
            dynamicContents={properties?.dynamicContents}
            lookupContents={properties?.lookupContents}
            dynamicContentsStructure={properties?._dynamicContentsStructure}
            updateDynamicContents={(data) =>
              _setTaskInfo(data, "dynamicContents")
            }
            updateLookupContents={(data) =>
              _setTaskInfo(data, "lookupContents")
            }
          />
        </div>
      </Collapse>

      {openConfirmBox && (
        <CustomConfirmBox
          closeConfirmBox={() => setOpenConfirmBox(false)}
          text={`You have already performed this action. Do you want 
        to replace Initiator Profile on the other screen task?`}
          open={openConfirmBox}
          confirmAction={confirmAction}
        />
      )}
      <Divider />
    </div>
  );
};

export default connect((state) => {
  return {
    activeTaskId: state.workflows.activeTask?.id,
    activeTaskType: state.workflows.activeTask?.type,
    workflowTasks: state.workflows.workflowTasks,
    workflowCanvas: state.workflows.workflowCanvas,
    allVariables: state.workflows.variables,
    integrations: state.workflows.workflowIntegrations,
    selectedApp: state.appsReducer.selectedApp,
  };
})(ScreenTaskSidebar);
