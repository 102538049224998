import React from "react";

import InputElements from "../Pages/UIEditor/components/pickComponents/InputElements";
import BasicElements from "../Pages/UIEditor/components/pickComponents/BasicElements";
import ButtonElements from "../Pages/UIEditor/components/pickComponents/ButtonElements";
import AdvancedElements from "../Pages/UIEditor/components/pickComponents/AdvancedElements";
import CustomElements from "../Pages/UIEditor/components/pickComponents/CustomElements";
import SelectionElements from "../Pages/UIEditor/components/pickComponents/SelectionElements";
import NavigationElements from "../Pages/UIEditor/components/pickComponents/NavigationElements";
import SearchedElements from "../Pages/UIEditor/components/pickComponents/SearchedElements";

import TaskElements from "../Pages/Workflow/components/pickComponents/TaskElements";

export function panelData(page) {
  let elements, searchBarText, newText, panelHeader;

  switch (page) {
    case "uieditor":
      elements = [
        {
          title: "Basic Elements",
          component: <BasicElements />,
        },
        // {
        //   title: "Form",
        //   component: <InputElements />,
        // },
        // {
        //     title: "Form Inputs",
        //     component: <InputElements />
        // },
        // {
        //     title: "Selection Controls",
        //     component: <SelectionElements />
        // },
        // {
        //     title: "Button",
        //     component: <ButtonElements />
        // },
        {
          title: "Navigation",
          component: <NavigationElements />,
        },
        {
          title: "Advanced",
          component: <AdvancedElements />,
        },
        // {
        //     title: "Custom",
        //     component: <CustomElements />
        // },
      ];
      searchBarText = "Search components...";
      newText = "New Template";
      panelHeader = "UI Editor";

      break;

    case "workflows":
      elements = [
        {
          title: "Tasks",
          component: <TaskElements />,
        },
        {
          title: "Other Things",
          component: <InputElements />,
        },
      ];
      searchBarText = "Search tasks...";
      newText = "New workflow";
      panelHeader = "Workflow Editor";

      break;

    case "screens":
      elements = [
        {
          title: "Tasks",
          component: <TaskElements />,
        },
        {
          title: "Other Things",
          component: <InputElements />,
        },
      ];
      searchBarText = "Search screens...";
      newText = "New screen";
      panelHeader = "Screens";

      break;

    default:
      break;
  }

  return {
    panelHeader,
    newText,
    searchBarText,
    elements,
  };
}
