import { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useStyles } from "../../utils/IntegrationsPanelStyle";
import PanelBody from "../PanelBody";
import { errorToastify, successToastify } from "../../../../common/Toastify";
import useCustomMutation from "../../../../common/CustomMutation";
import useCustomQuery from "../../../../common/CustomQuery";
import { getIntegrationResourcesListAPI } from "../../../../components/Query/Integration/integrationQuery";
import {
  newIntegrationAPI,
  updateIntegrationAPI,
} from "../../../../components/Mutation/Integration/IntegrationMutation";
import { useSelector } from "react-redux";

const GMailIntegrationPanel = ({
  changeIntegrationPage,
  updatedData,
  updateList,
  integrations,
}) => {
  const classes = useStyles(makeStyles);
  const steps = 1;
  const [fieldValue, setFieldValue] = useState({
    name: "",
    googleAccount: "",
  });

  const [step, setStep] = useState(1);
  const [activeId, setActiveId] = useState(null);
  const [availableResourcesList, setAvailableResourcesList] = useState([]);
  const [selectedResourcesList, setSelectedResourcesList] = useState([]);
  const [formCompleted, setFormCompleted] = useState(false);
  const [allIntegration, setIntegrationEmails] = useState([]);
  const [disableDropDown, setDisableDropDown] = useState(false);
  const [completedStep, setCompletedStep] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFieldValue({
      name: updatedData?.name,
      googleAccount: updatedData?.properties?.userInfo?.email,
    });
    setActiveId(updatedData?.id);

    /* Filtering the integrations array and returning the email addresses of the users. */
    const accountsEmail = integrations.length > 0 && [
      ...new Set(
        integrations
          .filter(
            ({ type, properties: { type: propertyType } }) =>
              type === "GoogleApiIntegration" && propertyType === "Google Mail"
          )
          .map(({ properties: { userInfo } }) => userInfo && userInfo?.email)
      ),
    ];

    /* Setting the state of the component. */
    setIntegrationEmails(() => accountsEmail);

    setStep(1);
  }, [updatedData, integrations]);

  /* Checking if the step is 0, if it is, it will change the integration page to an empty string. */
  useEffect(() => {
    if (step === 0) {
      changeIntegrationPage("");
    }
  }, [step, changeIntegrationPage]);

  /* Checking if the fieldValue.name is empty or not. If it is empty, it will set the formCompleted to
  false. If it is not empty, it will set the formCompleted to true. */
  useEffect(() => {
    setFormCompleted(!!fieldValue.name);
  }, [fieldValue]);

  /* Destructuring the state object and assigning the value of editAccountFlag to the variable
  editAccountFlag. */
  const {
    integrationReducer: { editAccountFlag },
  } = useSelector((state) => state);

  const progressStep = async (e) => {
    !!e.target && e.preventDefault();

    if (e === 0) {
      //  if integration terminated
      setStep(0);
    } else if (step === 1) {
      //  if integration is still progressing... make connection... first restructure payload
      if (!fieldValue.name) {
        errorToastify("Enter name for new integration");
        return;
      }

      setLoading(true);

      const payload = {
        name: fieldValue.name,
        type: "GoogleApiIntegration",
        group: "mail",
        ...(!!activeId || fieldValue.googleAccount !== "_new_"
          ? { email: fieldValue.googleAccount }
          : {}),
        redirectUrl: `${process.env.REACT_APP_BASE_URL}/integrations`,
        properties: {
          type: "Google Mail",
        },
      };

      let sendData,
        _id = activeId;
      try {
        if (!activeId) {
          //  if NEW integration
          sendData = await newIntegrationAPI({ data: payload });
          if (sendData?._meta?.success) {
            _id = sendData?.data.id;
            setActiveId(_id);
          }

          // if (!fieldValue.account) {
          if (!!sendData?.data?.googleAuthUrl) {
            //  redirect to docusign
            const redirectUrl = sendData?.data?.googleAuthUrl;
            console.log(`====> redirectUrl >> ${redirectUrl}`);
            window.location.href = redirectUrl;
            return;
          }
        } else {
          //  if UPDATE integration
          sendData = await updateIntegrationAPI({
            id: activeId,
            data: payload,
          }); //  send to backend
        }

        if (sendData?._meta?.success) {
          successToastify(sendData?._meta?.message);

          //  update master list on main UI
          updateList(sendData.data);

          //  go to home
          setStep(0);
        } else {
          errorToastify(sendData?._meta?.message);
        }
      } catch (e) {
        errorToastify(e.response?.data?._meta?.error?.message || e.message);
      }
      setLoading(false);
    }
  };

  const selectAccount = (e) => {
    setFieldValue({ ...fieldValue, googleAccount: e.target.value });
    /* if (e.target.value === "_new_") {
      // progressStep(null);
    } else {
      setFieldValue({ ...fieldValue, googleAccount: e.target.value });
    } */
  };

  return (
    <PanelBody
      title="GMail"
      mode={!!updatedData ? "Update" : "New"}
      step={step}
      setStep={progressStep}
      steps={steps}
      isResourceSelected={!!selectedResourcesList?.length}
      formCompleted={formCompleted}
      overLookResourceSelectedList
      loading={loading}
    >
      <div className={classes.sideDialogMain}>
        {step === 1 && (
          <>
            <Typography className={classes.formLabels} gutterBottom>
              Name
            </Typography>
            <TextField
              className={classes.FormTextField}
              size="small"
              name="name"
              inputMode="text"
              onChange={(e) =>
                setFieldValue({ ...fieldValue, name: e.target.value })
              }
              fullWidth
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              required
              value={fieldValue.name || ""}
              placeholder={`Enter here`}
              type="text"
              variant="outlined"
              autoFocus
              focused
              inputProps={{
                className: classes.inputColor,
                autoComplete: "new-int",
                form: {
                  autoComplete: "off",
                },
              }}
            />

            <div className="selectResources">
              <div style={{ marginTop: 15, marginBottom: 10 }}>
                <Typography className={classes.formLabels} gutterBottom>
                  Google account
                </Typography>
                <FormGroup>
                  <Select
                    variant="outlined"
                    size="small"
                    fullWidth
                    classes={{
                      root: classes.selectPadding,
                    }}
                    placeholder={"Select from the list"}
                    name="googleAccount"
                    value={fieldValue?.googleAccount || "choose"}
                    onChange={selectAccount}
                    disabled={!!activeId}
                  >
                    <MenuItem value="choose" disabled>
                      <em>Select Google account</em>
                    </MenuItem>
                    <MenuItem value="_new_">Add Google account</MenuItem>
                    {allIntegration.length > 0 &&
                      allIntegration.map((emails, idx) => {
                        return (
                          <MenuItem value={emails} key={idx}>
                            {emails}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormGroup>
              </div>
            </div>
          </>
        )}
      </div>
    </PanelBody>
  );
};

export default GMailIntegrationPanel;
