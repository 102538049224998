import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useGoogleLogin } from "react-google-login";
import { googleLogin } from "../../store/actions";
import { refreshTokenSetUp } from "../refreshGoogleLoginToken";
import useCustomMutation from "../../common/CustomMutation";
import { socialAuthSignUp } from "../../components/Mutation/Registration/registrationMutation";
import { useDispatch } from "react-redux";
import { errorToastify } from "../../common/Toastify";
import { unprotectedUrls } from "../lists";

const GoogleSignup = ({ classes, ...props }) => {
  const { history } = props;
  const dispatch = useDispatch();
  const btnMessage = props.btnMessage;

  const onLoginSuccess = ({ data }) => {
    const userId = data?.data?.id;
    const accountId = data?.data?.account;
    const accessToken = data?._meta?.accessToken;
    const userInfo = data?.data;

    if (!userId || !accountId)
      return errorToastify(
        "Sorry, an error occured with your Inputs. Kindly restart the registration."
      );

    localStorage.setItem("userId", userId);
    localStorage.setItem("accountId", accountId);
    localStorage.setItem("accessToken", accessToken);
    sessionStorage.setItem("accessToken", accessToken);
    localStorage.setItem("onboardingmode", "googlesignup");
    localStorage.setItem("userInfo", JSON.stringify(userInfo));

    history.push(unprotectedUrls.SIGNUP_DETAIL);
  };

  const { mutate: googleSignInMutate } = useCustomMutation({
    apiFunc: socialAuthSignUp,
    onSuccess: onLoginSuccess,
    retries: 0,
  });

  const onSuccess = (res) => {
    const socialAuthId = res?.profileObj?.googleId;
    const accessToken = res?.tokenId;
    const socialAuthType = "google";
    const email = res?.profileObj?.email;

    localStorage.setItem("status", "old");
    dispatch(googleLogin(res?.profileObj));
    refreshTokenSetUp({
      res,
      googleSignInMutate,
      data: {
        socialAuthId,
        accessToken,
        email,
        socialAuthType,
      },
    });
  };

  const onFailure = (res) => {
    //console.log(`failed login`, res);
    if (
      res.error !== "popup_closed_by_user" &&
      res.error !== "idpiframe_initialization_failed"
    )
      errorToastify(res?.error);
    // setMessage(error.message);
    // setSeverity("error");
    // setOpen(true);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId: process.env.REACT_APP_GOOGLE_CLIENTID,
    prompt: "consent",
    cookiePolicy: "single_host_origin",
    scope: "profile email",
  });

  return (
    <>
      <Button
        className={classes.signUpButton}
        style={{ backgroundColor: "#ffffff", color: "#464D72" }}
        size="large"
        type="submit"
        variant="outlined"
        onClick={signIn}
      >
        <img alt="G" className={classes.googleIcon} src="/images/google.png" />
        {btnMessage}
      </Button>
      {/* <div>
        <MessageSnackbar
          close={handleClose}
          message={message}
          open={open}
          severity={severity}
        />
      </div> */}
    </>
  );
};

GoogleSignup.propTypes = {
  history: PropTypes.object,
};

export default withRouter(GoogleSignup);
