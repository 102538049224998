import { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ReactSpinnerTimer from "react-spinner-timer";
import SkeletonCard from "../../components/SkeletonCard";
import useStyles from "./components/style";
import useCustomQuery from "../../../../common/CustomQuery";
import useCustomMutation from "../../../../common/CustomMutation";
import {
  getUserGroupsAPI,
  newUserGroupAPI,
  updateUserGroupAPI,
  removeUserGroupAPI,
} from "./utils/usergroupsAPIs";
// import { handleUserGroupActions, handleAssignUserGroup } from "../../../../utils/userServices";
import SingleUserGroup from "./components/SingleUserGroup";
import NewUserGroupModal from "./components/NewUserGroupModal";
import { rTriggerNewUserGroupDialog } from "../../../../store/actions/properties";

const UserGroups = () => {
  const dispatch = useDispatch();
  const { triggerNewUserGroup } = useSelector(({ users }) => users);

  const [isLap, setIsLap] = useState(true);
  const [modalMode, setModalMode] = useState("add");
  const [modalData, setModalData] = useState();
  const [isNewUserGroupModalVisible, setIsNewUserGroupModalVisible] =
    useState(false);
  const [newAdds, setNewAdds] = useState([]);
  const [allUserGroups, setAllUserGroups] = useState([]);
  const [filteredUserGroups, setFilteredUserGroups] = useState([]);
  const [filters, setFilters] = useState({ status: "All", search: "" });

  const classes = useStyles(); // ok

  useEffect(() => {
    document.title = "Settings | User Groups";

    return () => {
      dispatch(rTriggerNewUserGroupDialog(""));
    };
  }, []);

  useEffect(() => {
    const filtr = { ...filters };
    //console.log(`vals = ${JSON.stringify(filtr)}`);
    // let srch = (filtr.search.length > 2) ? new RegExp(filtr.search.replace(/[^\w\s]/gi, ''), "g") : null;
    let srch = new RegExp(filtr.search.replace(/[^\w\s]/gi, ""), "gi");

    const filtered = allUserGroups.filter(
      (f) =>
        (filtr.status === "All" || f.active === (filtr.status === "Active")) &&
        (!srch || (f.name && f.name.search(srch) !== -1))
    );

    setFilteredUserGroups(filtered);
  }, [allUserGroups, filters]);

  useEffect(() => {
    if (!!triggerNewUserGroup) {
      setIsNewUserGroupModalVisible(true);
    }
  }, [triggerNewUserGroup]);

  const onGetUserGroupsSuccess = ({ data }) => {
    //console.log(data.data);
    setAllUserGroups(data.data);
  };

  // fetch usergroups
  const options = {
    query: {
      // population: ["users"],
      population: [{ path: "users", select: "id firstName lastName" }],
    },
  };
  const { isLoading, isFetching } = useCustomQuery({
    queryKey: ["allUserGroups", options],
    apiFunc: getUserGroupsAPI,
    onSuccess: onGetUserGroupsSuccess,
  });

  const onAddUserGroupSuccess = ({ data }) => {
    //console.log(`>>>>>>>>>>>> data = ${JSON.stringify(data)}`);
    const newData = {
      ...data.data,
      id: data.data.id,
      status: "Inactive",
    };

    let updatedGroups = [...allUserGroups];

    updatedGroups.unshift(newData);
    // //console.log(`list length = ${updatedUsers.length} = ${JSON.stringify(updatedUsers)}`);
    setAllUserGroups(updatedGroups);

    setModalMode("add");
    setModalData("");
    setIsNewUserGroupModalVisible(false);
  };

  const onUpdateUserGroupSuccess = ({ data }) => {
    //console.log('@@@@@@@@', JSON.stringify(data.data));
    const dat = data?.data;

    const updatedUserGroups = allUserGroups.filter((u) => {
      return u.id === dat.id ? data.data : u;
    });

    setAllUserGroups(updatedUserGroups);

    // setFilteredUsers(data.data);
    // dispatch({ type: FETCH_SPECIFIED_SHEET, payload: data?.data });
    // alert('SUCCESS')

    setModalMode("add");
    setModalData("");
    setIsNewUserGroupModalVisible(false);
  };

  const onDeleteUserGroupSuccess = ({ data }) => {
    //console.log('@@@@@@@@', JSON.stringify(data));
    const dat = data?.data;

    const updatedUserGroups = allUserGroups.filter((g) => g.id !== dat._id);

    setAllUserGroups(updatedUserGroups);

    // setFilteredUsers(data.data);
    // dispatch({ type: FETCH_SPECIFIED_SHEET, payload: data?.data });
    // alert('SUCCESS')

    setModalMode("add");
    setModalData("");
    setIsNewUserGroupModalVisible(false);
  };

  const { mutate: addUserGroup } = useCustomMutation({
    apiFunc: newUserGroupAPI,
    onSuccess: onAddUserGroupSuccess,
    retries: 0,
  });

  const { mutate: updateUserGroup } = useCustomMutation({
    apiFunc: updateUserGroupAPI,
    onSuccess: onUpdateUserGroupSuccess,
    retries: 0,
  });

  const { mutate: deleteUserGroup } = useCustomMutation({
    apiFunc: removeUserGroupAPI,
    onSuccess: onDeleteUserGroupSuccess,
    retries: 0,
  });

  const _doFilter = (filt) => {
    let filtr = { ...filters, ...filt };
    setFilters(filtr);
  };

  const handleChange = (lap) => {
    if (lap.isFinish) {
      setIsLap(false);
    }
  };

  const _handleSaveNewUserGroup = async (info) => {
    const resp = { status: "failed" }; // await handleUserGroupActions(info, 'add');
    //console.log(`resp: ${JSON.stringify(resp)}`);
    if (resp.status === "success") {
      //console.log(`pre= ${JSON.stringify(newAdds)}`);
      info.id = resp.data.id;
      const upd = newAdds;
      upd.unshift(info);
      //console.log(`pre= ${JSON.stringify(upd)}`);
      setNewAdds(upd);
      setIsNewUserGroupModalVisible(false);
    }
  };

  const _handleModalDone = ({ mode, data }) => {
    //console.log(`1111111111111111 -- ${mode} -- ${JSON.stringify(data)}`);
    if (mode === "add") {
      // //console.log(`${mode} >> 222222222222222 >> ${JSON.stringify(data)}`);

      addUserGroup({ data });
    } else if (mode === "update") {
      updateUserGroup({ data });
    } else {
      //  just a normal close modal
      setModalMode("add");
      setModalData("");
      setIsNewUserGroupModalVisible(false);
    }
  };

  const _deleteMe = (id) => {
    deleteUserGroup({ id });
  };

  return !isLoading && !isFetching ? (
    <div style={{ width: "100%" }}>
      <Grid container item xs={12} sm={12} direction="row" spacing={3}>
        {!!filteredUserGroups?.length &&
          filteredUserGroups.map((data) => (
            <SingleUserGroup
              key={data.id}
              item={data}
              deleteMe={() => _deleteMe(data.id)}
            />
          ))}
      </Grid>

      {isNewUserGroupModalVisible && (
        // <NewUserGroupModal closeModal={() =>setIsNewUserGroupModalVisible(false)} saveNewUserGroup={_handleSaveNewUserGroup} />
        <NewUserGroupModal closeModal={_handleModalDone} mode={modalMode} />
      )}

      {/* { isRolePermissionsVisible && rolePermissionsData &&
        <RolePermissions showModal={setIsRolePermissionsVisible} item={ activeRole } data={ rolePermissionsData } /> 
      }
      { isRoleUsersVisible && roleUsersData &&
        <RoleUsers showModal={setIsRoleUsersVisible} item={ activeRole } data={ roleUsersData } /> 
      } */}
    </div>
  ) : (
    <div>
      {isLap ? (
        <div className={classes.loadingPage}>
          <ReactSpinnerTimer
            timeInSeconds={3}
            totalLaps={1}
            isRefresh={false}
            onLapInteraction={handleChange}
          />
        </div>
      ) : (
        <SkeletonCard />
      )}
    </div>
  );
};

export default UserGroups;
