import { useState } from "react";
import CustomStyleSection from "../components/CustomStyleSection";
import SidebarFieldPreferenceSection from "../components/SidebarFieldPreferenceSection";
import SidebarNameSection from "../components/SidebarNameSection";

export default function ImageSidebar(props) {
  const {
    id,
    type: itemType,
    title,
    index,
    styles,
    stylesOverride,
    values,
    isInsideContainer,
    containingChildIndex,
    name,
  } = props;
  const [isDynamic, setIsDynamic] = useState(name?.startsWith("@"));

  // const onStyleChange = ({ value, root, property }) => dispatch(updateStyles({ value, root, property, id, index, isInsideContainer, containingChildIndex, type }))
  // const onNameChange = ({ target: { name, value } }) => dispatch(updateComponentAttribute({ attrib: "name", value, index, isInsideContainer, containingChildIndex }))
  // const onValuesChange = ({ value, property }) => dispatch(updateValues({ value, property, index, isInsideContainer, containingChildIndex, type }))
  // const classes = useStyles();
  // const { updateProp } = props;

  // function updateData(key, value) {
  //   updateProp(key, value);
  // }
  // const layout = [
  //   ["fill", "Filled"],
  //   ["outline", "Outlined"],
  // ];

  // const fileType = [
  //   ["any", "Any"],
  //   ["image", "Image"],
  //   ["audio", "Audio"],
  //   ["pdf", "PDF"],
  //   ["zip", "Zip"],
  // ];

  // const uploadFrom = [
  //   ["any", "File"],
  //   ["image", "Camera"],
  //   ["audio", "Both Camera & File"],
  // ];

  return (
    <div className="sidebar-container">
      <SidebarNameSection
        itemId={id}
        itemType={itemType}
        name={name}
        title={title}
        index={index}
        isInsideContainer={isInsideContainer}
        containingChildIndex={containingChildIndex}
      />

      <div className="sidebar-container-scroll">
        <SidebarFieldPreferenceSection
          itemType={itemType}
          name={name}
          title={title}
          index={index}
          isInsideContainer={isInsideContainer}
          containingChildIndex={containingChildIndex}
          values={{ ...values }}
          isDynamic={isDynamic}
          setIsDynamic={setIsDynamic}
        />
        <CustomStyleSection
          itemType={itemType}
          index={index}
          name={name}
          title={title}
          isInsideContainer={isInsideContainer}
          containingChildIndex={containingChildIndex}
          values={values}
          items={["image"]}
          styles={styles}
          stylesOverride={stylesOverride}
        />
      </div>
      {/* consider adding border radius and other stylings to image */}
    </div>
  );
}
