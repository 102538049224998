import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Divider, Typography, TextField, Grid } from "@material-ui/core";
import LabelControls from "../LabelControls";
import InputBase from "@material-ui/core/InputBase";
import ColorPicker from "../ColorPicker";

const InputText = withStyles((theme) => ({
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 11,
    padding: "5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {},
  sideHeading: {
    color: "#091540",
    fontWeight: 600,
    fontSize: 13,
    paddingLeft: 10,
  },
  section: {
    padding: 10,
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
  },
  sectionLabel: {
    color: "#999",
    fontSize: 10,
    marginRight: 12,
    marginTop: 5,
  },
  fullWidthText: {
    margin: "10px 0",
  },
  input: {
    color: "#091540",
    fontSize: 10,
  },
  center: {
    textAlign: "center",
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
  input: {
    "&::placeholder": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 12,
      color: "#091540!important",
      LineHeight: 15.6,
    },
  },
}));

export default function FileUploadSidebar(props) {
  const classes = useStyles();
  const colorCall = props.colorCallback;
  const { textColorCallback } = props;

  return (
    <div>
      <Typography gutterBottom className={classes.sideHeading}>
        Line
      </Typography>
      <Divider />
      <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Style
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <div style={{ display: "inline-flex" }}>
              <Typography gutterBottom className={classes.sectionLabel}>
                W
              </Typography>
              <InputText
                variant="outlined"
                size="small"
                placeholder="12px"
                defaultValue={"100%"}
                style={{ width: "40%" }}
                inputProps={{
                  min: 0,
                  style: { textAlign: "center" },
                  className: classes.input,
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={{ display: "inline-flex" }}>
            <Typography gutterBottom className={classes.sectionLabel}>
              H
            </Typography>
            <InputText
              variant="outlined"
              size="small"
              placeholder="100%"
              defaultValue={"100%"}
              style={{ width: "40%" }}
              inputProps={{
                min: 0,
                style: { textAlign: "center" },
                className: classes.input,
              }}
            />
            <div>
              <ColorPicker
                identity="textColor"
                textColorCallback={textColorCallback}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.section}>
        <div style={{ display: "inline-flex" }}>
          <InputText
            variant="outlined"
            size="small"
            placeholder="12px"
            defaultValue={"Solid"}
            style={{ width: "60%" }}
            inputProps={{
              min: 0,
              style: { textAlign: "center" },
              className: classes.input,
            }}
          />
        </div>
      </div>
    </div>
  );
}
