import React, { useState } from "react";
import {
  Collapse,
  Divider,
  IconButton,
  InputBase,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddCircle from "@material-ui/icons/AddCircle";
import { Fragment } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch } from "react-redux";
import { updateContainerChildren } from "../../../../Pages/UIEditor/utils/canvasHelpers";
import SidebarNameSection from "../components/SidebarNameSection";
import CustomStyleSection from "../components/CustomStyleSection";
// import Cancel from "@material-ui/icons/Cancel";

const InputText = withStyles((theme) => ({
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 11,
    padding: "5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);
const useStyles = makeStyles((theme) => ({
  root: {},
  addSection: {
    width: "100%",
    alignSelf: "flex-end",
    textAlign: "right",
  },
  sideHeading: {
    color: "#091540",
    fontWeight: 600,
    fontSize: 13,
    padding: "10px 5px 8px 10px",
    borderBottom: "solid 1px #eeeeee",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& span": {
      textAlign: "right",
      "& input": {
        paddingLeft: 10,
      },
    },
  },
  section: {
    padding: "0 10px",
    display: "flex",
    flexDirection: "row",
    paddingBottom: 0,
    justifyContent: "space-between",
    alignItems: "center",
  },
  sectionOfForm: {
    paddingLeft: 20,
    display: "flex",
    flexDirection: "row",
    paddingBottom: 0,
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 50,
    boxShadow: "2px 2px 7px #eee",
    // margin: 5,
    fontWeight: 300,
    width: "49%",
    fontSize: 11,
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
  },
  sectionLabel: {
    color: "#999",
    fontSize: 10,
    marginRight: 12,
    marginTop: 5,
  },
  sectionRow: {
    color: "#999",
    fontSize: 10,
    marginTop: 5,
  },
  fullWidthText: {
    margin: "10px 0",
  },
  input: {
    color: "#091540",
    fontSize: 10,
  },
  center: {
    textAlign: "center",
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
  input: {
    "&::placeholder": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 12,
      color: "#091540!important",
      LineHeight: 15.6,
    },
  },
  colTitle: {
    paddingTop: 10,
  },

  normalHeight: {
    minHeight: "0 !important",
    padding: 0,
    // marginTop: "0 !important",
    // marginBottom: "10px !important",
  },
  normalMargin: {
    marginTop: "0 !important",
    marginBottom: "10px !important",
  },
  keepMargin: {
    margin: "0 !important",
  },
  elementButton: {
    padding: "0 12px",
  },
  detailsPadding: {
    paddingRight: 0,
    paddingLeft: 0,
  },
}));

const FormSidebar = (props) => {
  const {
    index,
    isInsideContainer,
    containingChildIndex,
    name,
    title,
    id,
    type: itemType,
    styles,
    stylesOverride,
  } = props;

  const [showFormComponents, setShowFormComponents] = useState(false);
  const elements = [
    {
      type: "text",
      title: "Paragraph Text",
      elementsCategory: "basicElements",
    },
    {
      type: "heading",
      title: "Heading Text",
      elementsCategory: "basicElements",
    },
    {
      type: "pageBreak",
      title: "Page Break",
      elementsCategory: "basicElements",
    },
    {
      elementsCategory: "inputElements",
      type: "inputText",
      title: "Text Input",
    },
    {
      elementsCategory: "inputElements",
      type: "textArea",
      title: "Text Area",
    },
    {
      elementsCategory: "selectionElements",
      type: "dropdown",
      title: "Select Dropdown",
    },

    {
      elementsCategory: "inputElements",
      type: "fileUpload",
      title: "File Upload",
    },
    {
      elementsCategory: "inputElements",
      type: "phoneNumber",
      title: "Phone Number",
    },
    {
      elementsCategory: "selectionElements",
      title: "Checkbox",
      type: "checkbox",
    },
    // {
    //   elementsCategory: "inputElements",
    //   type: "currency",
    //   title: "Currency",
    // },
    {
      elementsCategory: "inputElements",
      type: "dateTime",
      title: "Date Time",
    },
    // {
    //     type: "address",
    //     title: "Address",
    //     elementsCategory: "inputElements"
    // },
    {
      type: "signature",
      title: "Signature",
      elementsCategory: "inputElements",
    },
    {
      elementsCategory: "inputElements",
      type: "userPicker",
      title: "User Picker",
    },
    // {
    //     elementsCategory: "buttonElements",
    //     type: "actionButton",
    //     title: "Action Button",
    // },
    // {
    //     elementsCategory: "buttonElements",
    //     type: "decisionButton",
    //     title: "Decision Button",
    // },
  ];

  const classes = useStyles();
  const dispatch = useDispatch();

  const onUpdateChildren = ({ type, elementsCategory }) =>
    dispatch(updateContainerChildren({ type, elementsCategory, index }));

  return (
    <div className="sidebar-container">
      <SidebarNameSection
        itemId={id}
        itemType={itemType}
        name={name}
        title={title}
        index={index}
        isInsideContainer={isInsideContainer}
        containingChildIndex={containingChildIndex}
      />

      <div className="sidebar-container-scroll">
        <div className="sidebar-section">
          {/* <div
            className="sidebar-section-header"
            onClick={() => setShowFormComponents((prev) => !prev)}
          >
            <Typography>[ Form Items ]</Typography>
            <span>{`[${showFormComponents ? "-" : "+"}]`}</span>
          </div> */}

          {/* <Collapse in={showFormComponents}> */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 5,
              marginTop: 10,
            }}
          >
            {elements.map(({ title, type, elementsCategory }, index) => (
              <Fragment key={index}>
                <div className={classes.sectionOfForm}>
                  <div style={{ lineHeight: 1.3 }}>{title}</div>
                  <IconButton
                    onClick={() => onUpdateChildren({ type, elementsCategory })}
                    style={{ color: "#0c7b93ab" }}
                    aria-label="fingerprint"
                  >
                    <AddCircle />
                  </IconButton>
                </div>
              </Fragment>
            ))}
          </div>
          {/* </Collapse> */}
        </div>
        <CustomStyleSection
          itemType={itemType}
          index={index}
          name={name}
          title={title}
          isInsideContainer={isInsideContainer}
          containingChildIndex={containingChildIndex}
          items={["form"]}
          styles={styles}
          stylesOverride={stylesOverride}
        />
      </div>
    </div>
  );
};

export default FormSidebar;
