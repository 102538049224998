import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Cancel from "@material-ui/icons/Cancel";
import Backdrop from "@material-ui/core/Backdrop";
import { InfoOutlined } from "@material-ui/icons";
import { Button, Typography } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },

  pwaBanner: {
    //zIndex: 9999999999999,
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    position: "fixed",
    height: "200px",
    bottom: "0px",
    color: "#999",
    borderRadius: "5px",
    boxShadow: "0px 0px 4.582096576690674px 0px #8C8C8C40",
    backgroundColor: "white",
    paddingLeft: "1.5rem",
  },
  backdrop: {
    zIndex: 9999999999999,
    color: "#fff",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  installInfo: {
    display: "flex",
    alignItems: "center",
    color: "#2457C1",
    marginTop: "1rem",
  },
  installBtn: {
    background: "#2457C1",
    color: "white",
    marginRight: "2rem",
    fontSize: "14px",
    width: "7rem",
    height: "2.6rem",
    textTransform: "none",
    "&:hover": {
      background: "#2457C1",
    },
  },
}));

export default function PwaBanner() {
  const classes = useStyles();
  const [showBanner, setShowBanner] = useState(true);
  const [prompt, setPrompt] = useState(null);
  const windowsWidth = window.outerWidth < 790;
  const LOCAL_STORAGE_PWA_KEY = "nextPWAInstallationReminder";

  useEffect(() => {
    if (window.outerWidth < 790) {
      window.addEventListener("beforeinstallprompt", (event) => {
        event.preventDefault();
        setShowBanner(false);
        setPrompt(event);
      });
    }
  }, []);

  const hidePrompt = () => {
    setShowBanner(false);
    setNextInstallationReminder();
  };

  const setNextInstallationReminder = () => {
    const currentDate = moment(Date.now());
    const oneMonthFromNow = currentDate.add(1, "M");
    localStorage.setItem(LOCAL_STORAGE_PWA_KEY, oneMonthFromNow);
  };

  const clientIsDueForAReminder = () => {
    const dateFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_PWA_KEY);
    if (!dateFromLocalStorage) return true;
    const nextReminderDate = moment(dateFromLocalStorage);
    const currentDate = moment(Date.now());
    return currentDate.isSameOrAfter(nextReminderDate);
  };

  const showPrompt = () => {
    if (prompt) {
      prompt.prompt().then((result) => {
        if (result.outcome === "accepted") {
          hidePrompt();
          localStorage.removeItem(LOCAL_STORAGE_PWA_KEY);
          return;
        } else {
          setNextInstallationReminder();
          return hidePrompt();
        }
      });
    }
  };

  return (
    <>
      {showBanner && clientIsDueForAReminder() && windowsWidth && (
        <Backdrop
          className={classes.backdrop}
          open={showBanner}
          //onClick={hidePrompt}
        >
          <div className={classes.pwaBanner}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className={classes.installInfo}>
                <InfoOutlined fontSize="large" />
                <h2
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  Installation
                </h2>
              </div>
              <Cancel
                onClick={() => hidePrompt()}
                style={{ cursor: "pointer", margin: "1rem" }}
                fontSize="large"
              />
            </div>
            <div
              style={{
                margin: "0 0.8rem 2rem 0.4rem",
              }}
            >
              <Typography variant="body1">
                Click the button below to install Plug on your device for easy
                accessibility and prompt notifications.
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="filled"
                onClick={() => showPrompt()}
                className={classes.installBtn}
              >
                Install
              </Button>
            </div>
          </div>
        </Backdrop>
      )}
    </>
  );
}
