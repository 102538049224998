import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ComponentTile from "./ComponentTile";
import DraggableTile from "./DraggableTile";
import { allElements } from "../../utils/elementsList";

const CustomElements = ({ navigation, buttonCallback, ...props }) => {
  // const { next } = navigation;

  return (
    <div>HELLO</div>
    // <DraggableTile elements={allElements("customElements", {}, false, "", props.customElements)} adjustHeight={454} />
  );
};

export default connect((state) => {
  return {
    customElements: state.uieditor.customElements,
  };
})(CustomElements);
