import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import RealComponents from "../../Pages/UIEditor/components/ElementsComponent";
import PageWrapper from "../../Pages/UIEditor/components/PageWrapper";

import { loadScreenProperties } from "./utils/screenLoadProperties";
import { APP_DESIGN_MODES } from "../../../../utils/constants";

const Screens = ({ dispatch, screenStyles, ...props }) => {
  const location = useLocation();

  useEffect(() => {
    document.title = "Editor | Screens";
  }, []);

  const [isScreenLoading, setIsScreenLoading] = useState(false);

  /* useEffect(() => {
    const init = async () => {
      setIsScreenLoading(true);
      if (!props.screensList?.length) return;
      const { loadedStyle } = await dispatch(loadScreenProperties());
      setIsScreenLoading(!!loadedStyle);
      // setScreenStyle(loadedStyle);
    };
    init();
  }, [props.activeScreen?.id, props.screensList]); */

  const determineStyle = (item) => {
    return {
      // ...defaultStyles,
      ...screenStyles,
      ...(item?.style?.overrideDefault ? item.style : {}),
    };
  };

  return (
    // <ErrorBoundary render={() => <div>This is me</div>}>
    <PageWrapper
      zoomLevel={props.zoomLevel}
      margin="100px auto 50px auto"
      screenStyles={screenStyles}
      screenLoading={isScreenLoading}
    >
      {props.canvasItems?.map((item, index) => (
        <div
          key={item?.id}
          style={{
            marginBottom: determineStyle(item)?.page?.lineSpacing,
          }}
        >
          <RealComponents
            onChange={(val, name) => {}}
            key={item?.id}
            id={item?.id}
            name={item?.name}
            type={item?.type}
            parent={item?.parent}
            disabled={true}
            values={item?.values}
            children={item?.children}
            containerIndex={index}
            style={determineStyle(item)}
            screenStyles={screenStyles}
            customId={item?.customId || null}
            customName={item?.customName || null}
            appDesignMode={APP_DESIGN_MODES.PREVIEW}
          />
        </div>
      ))}
    </PageWrapper>
    // </ErrorBoundary>
  );
};
export default connect((state) => {
  return {
    activeScreen: state.screens.activeScreen,
    screensList: state.screens.screensList,
    screenStyles: state.uieditor.screenStyles,
    zoomLevel: state.uieditor.zoomLevel,
    canvasItems: state.uieditor.canvasItems,
  };
})(Screens);
