import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";

export default function IconLabelButtons(props) {
  const { onDelete, onDuplicate, isActiveComponent } = props;

  const useStyles = makeStyles((theme) => ({
    icons: {
      color: "#F4EEFF",
      padding: 5,
    },
    root: {
      background: isActiveComponent ? "#008AFF" : "#010A43", // checkout this blue: #2979ff
      display: "inline-block",
      borderRadius: 3,
      visibility: props.visible ? "visible" : "hidden",
      maxHeight: 24,
      alignSelf: "end",
      zIndex: 1,
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes?.root}>
      <Tooltip title="Duplicate">
        <IconButton className={classes?.icons} onClick={onDuplicate}>
          <img src="../../../../images/icons/copy.svg" alt="Clone" width={13} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Remove">
        <IconButton className={classes?.icons} onClick={onDelete}>
          <img
            src="../../../../images/icons/trash.svg"
            alt="Delete"
            width={13}
          />
        </IconButton>
      </Tooltip>
    </div>
  );
}
