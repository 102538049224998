import { CustomAxios } from "../../../common/CustomAxios";

/**
 * It takes in an object with a data property, and then it makes a post request to the endpoint with
 * the data property as the body of the request.
 * @returns The result of the API call.
 */
export const newIntegrationAPI = async ({ data }) => {
  const result = await CustomAxios().post(
    `${process.env.REACT_APP_ENDPOINT}/integrations`,
    // `${process.env.REACT_APP_ENDPOINT}/integrations/example/cb`,
    data
  );
  return result.data;
};

/**
 * This function takes an id and data as arguments, and returns the data from the result of a PUT
 * request to the API.
 * @returns The result of the API call.
 */
export const updateIntegrationAPI = async ({ id, data }) => {
  //console.log("data", data);
  const result = await CustomAxios().put(
    `${process.env.REACT_APP_ENDPOINT}/integrations/${id}`,
    data
  );
  return result.data;
};

/**
 * It makes a DELETE request to the API endpoint, and returns the data from the response.
 * @returns The result of the delete request.
 */
export const deleteIntegrationAPI = async ({ id }) => {
  const result = await CustomAxios().delete(
    `${process.env.REACT_APP_ENDPOINT}/integrations/${id}`
  );
  return result.data;
};
