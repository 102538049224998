import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import {
  SignUp as SignUpView,
  SignupDetail as SignUpDetailView,
  Welcome as WelcomeView,
  ForgotPassword as ForgotPasswordView,
  Login as LoginView,
  VerifyOTP as VerifyOTPView,
  Administration as AdministrationView,
  Apps as AppsView,
  AppTemplates as AppTemplatesView,
  ResetPassword as ResetPasswordView,
  Download as DownloadView,
  DashboardEditor as DashboardEditorView,
  DashboardsList as DashboardsListView,
  Playground as PlaygroundView,
  // Documents as AllItemsView
} from "./views";
import AccountDetails from "./views/Administration/AccountDetails";
import UIEditorPreview from "./views/EditorLayout/Pages/UIPreview";
import UIEditorPublish from "./views/EditorLayout/Pages/Publish";
import EditorLayout from "./views/EditorLayout/EditorLayout";
import SettingsLayout from "./views/SettingsLayout/SettingsLayout";
// import TesterScreen from "./views/EditorLayout/Pages/Workflow/components/RightSidebar/components/actions/SelectOnSteroids1.js";
import Datasheets from "./views/Datasheets";
import Datasheet from "./views/Datasheets/Pages/Datasheet";
import RunAppView, { DisplayScreen } from "./views/Run";
import ApprovalNode from "./views/Run/ApprovalScreen";
import Appendage from "./components/AppendageForm/Appendage";
import RunAppCompleted from "./views/Run/Completed";
import CreatePasswordHome from "./views/CreatePasswordHome/CreatePasswordHome";
import Analytics from "./views/Analytics";
import Integrations from "./views/Integrations";
import SingleWorkAnalytics from "./views/Analytics/SingleWorkflowAnalytics";
import InAppNotification from "./views/InAppNotification";
// // import DragDrop from "./common/Helper/DragDrop";
// import UploadedSuccessLayout from "./common/Helper/DragDrop/UploadedSuccessLayout";
import SignatureModal from "./views/Run/SignatureModal";
import VerifySSO from "./utils/SSOLogin/VerifySSO";
import {
  mainNavigationUrls,
  otherProtectedUrls,
  unprotectedUrls,
} from "./utils/lists";
import ErrorPage from "./components/Errorpage/Error";
import LoginWithSSO from "./views/LoginWithSSO/index";

const Routes = (props) => {
  const { isAuthenticated, isVerifying } = props;

  return (
    <>
      <Switch>
        <ProtectedRoute
          exact
          path={`${mainNavigationUrls.ADMINISTRATION}/:slug`}
          component={AccountDetails}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path={`${mainNavigationUrls.ADMINISTRATION}`}
          component={AdministrationView}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        {/* )} */}
        <ProtectedRoute
          exact
          path={`${mainNavigationUrls.APPS}`}
          component={AppsView}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path={`${mainNavigationUrls.TEMPLATES}`}
          component={AppTemplatesView}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path={`${mainNavigationUrls.ANALYTICS}/:id`}
          component={SingleWorkAnalytics}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />

        {/* <ProtectedRoute
          path="/custom"
          component={CustomDialogBox}
          exact
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        /> */}
        <ProtectedRoute
          exact
          path={`${mainNavigationUrls.ANALYTICS}`}
          component={Analytics}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        {/* <ProtectedRoute
          exact
          path="/apps/uploaded"
          component={DragDrop}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        /> */}

        <ProtectedRoute
          exact
          path="/appendage"
          component={Appendage}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path={`${mainNavigationUrls.NOTIFICATIONS}`}
          component={InAppNotification}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path={`${mainNavigationUrls.DATASHEETS}/:id`}
          component={Datasheet}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path={`${mainNavigationUrls.DATASHEETS}`}
          component={Datasheets}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />

        <ProtectedRoute
          exact
          path={`${mainNavigationUrls.INTEGRATIONS}`}
          component={Integrations}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />

        <ProtectedRoute
          exact
          path={`${otherProtectedUrls.DASHBOARDS_EDITOR}/:slug`}
          component={DashboardEditorView}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path={`${mainNavigationUrls.DASHBOARDS}`}
          component={DashboardsListView}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />

        <Route exact path={`${unprotectedUrls.ROOT}`} component={LoginView} />
        <Route exact path={`${unprotectedUrls.LOGIN}`} component={LoginView} />
        <Route
          exact
          path={`${unprotectedUrls.LOGIN_WITH_SSO}`}
          component={LoginWithSSO}
        />
        <Route
          exact
          path={`${unprotectedUrls.VERIFY_SSO}`}
          component={VerifySSO}
        />
        <Route
          exact
          path={`${unprotectedUrls.SIGN}`}
          component={SignatureModal}
        />
        <Route
          exact
          path={`${unprotectedUrls.SIGNUP_DETAIL}`}
          component={SignUpDetailView}
        />
        <Route
          exact
          path={`${unprotectedUrls.WELCOME}`}
          component={WelcomeView}
        />
        <Route
          exact
          path={`${unprotectedUrls.FORGOT_PASSWORD}`}
          component={ForgotPasswordView}
        />
        <Route
          exact
          path={`${unprotectedUrls.RESET_PASSWORD}`}
          component={ResetPasswordView}
        />
        <Route
          exact
          path={`${unprotectedUrls.CREATE_PASSWORD}`}
          component={CreatePasswordHome}
        />
        <Route
          exact
          path={`${unprotectedUrls.VERIFY}`}
          component={VerifyOTPView}
        />
        <Route
          exact
          path={`${unprotectedUrls.RUN_COMPLETED}`}
          component={RunAppCompleted}
        />
        <Route
          exact
          path={`${unprotectedUrls.RUN}/:accountSlug/:appSlug`}
          component={RunAppView}
        />
        <Route
          exact
          path={`${unprotectedUrls.RUN}/:accountSlug/:appSlug/:screenSlug`}
          component={DisplayScreen}
        />

        <ProtectedRoute
          exact
          path={`${otherProtectedUrls.EDITOR_UIEDITOR_PREVIEW}/:screenId`}
          component={UIEditorPreview}
        />
        <ProtectedRoute
          exact
          path={`${otherProtectedUrls.EDITOR_UIEDITOR_PUBLISH}`}
          component={UIEditorPublish}
        />
        <ProtectedRoute
          exact
          path={`${otherProtectedUrls.DOWNLOAD}/:id`}
          component={DownloadView}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <Route
          exact
          path={`${unprotectedUrls.DOWNLOAD_PUBLIC}/:id`}
          component={DownloadView}
        />
        <Route exact path={`/thinktank`} component={PlaygroundView} />
        <Route path={`${unprotectedUrls.ERROR}`} component={ErrorPage} />
        <ProtectedRoute
          exact
          path={`${otherProtectedUrls.APPROVAL}/:accountSlug/:appSlug`}
          component={ApprovalNode}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          path={`${otherProtectedUrls.EDITOR}`}
          component={EditorLayout}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          path={`${mainNavigationUrls.SETTINGS}`}
          component={SettingsLayout}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        >
          <Route
            exact
            path={`${unprotectedUrls.SIGNUP}`}
            component={SignUpView}
          />
        </GoogleReCaptchaProvider>
      </Switch>
    </>
  );
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
  };
}

export default withRouter(connect(mapStateToProps)(Routes));
