import React, { useEffect, useMemo, useState, useRef } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import EditorNavBar from "./components/EditorNavBar";
import AppLayout from "../../components/AppLayout";
import EditorPageSwitch from "./components/EditorPageSwitch";
import EditorNavigations from "./components/EditorNavigations";
import EditorToolbars from "./components/EditorToolbars";

import "./styles.scss";

import { DragDropContext } from "react-beautiful-dnd";
import { canvasRegisterElement } from "./Pages/UIEditor/utils/canvasHelpers";
import EditorFooter from "./components/EditorFooter";
import ErrorBoundary from "../../components/ProtectedRoute/ErrorBoundary";
import GeneralError from "../../components/ProtectedRoute/components/GeneralError";
import {
  activateElement,
  loadCanvasItems,
  loadScreenStyle,
  rAddToWorkflow,
  rLoadWorkflowTasks,
  rRemoteUpdateCanvasElements,
  rSelectActiveTask,
  rSetActiveWorkflow,
  rSetWorkflowIntegrations,
  rSetWorkflowVariables,
  rSetWorkflowsList,
  rSetupBackDrop,
  rToggleUIEditorFullScreen,
  rToggleWorkflowFullScreen,
  rUpdateWorkflowCanvas,
  registerElements,
  saveActiveScreen,
  setCanvasItems,
  setCanvasZoomLevel,
  saveDisplayTableColumn,
  setDropZone,
  setScreensList,
  toggleWorkflowPanel,
  rOpenAppDetailsDialog,
  rSelectApp,
  rSetAppsControlMode,
} from "../../store/actions/properties";
import { manageAppLocalStorage } from "../../helpers/helperFunctions";
import MainPageLayout from "../../components/AppLayout/MainPageLayout";
import { CssBaseline } from "@material-ui/core";
import { editorNavigationList, otherProtectedUrls } from "../../utils/lists";
import { registerApp } from "../Apps/util/appLogics";
import { APPS_CONTROL_MODES } from "./Pages/Workflow/components/utils/constants";
import PanelActions from "./utils/PanelActions";
import CustomAlertBox from "../../common/Helper/CustomAlertBox";
const drawerWidth = 376;

const EditorLayout = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [currentAppId, setCurrentAppId] = useState("");
  const lastAppId = useRef();

  const [page, setPage] = useState(null);
  const [open, setOpen] = useState(true);
  const [pageHeaderTitle, setPageHeaderTitle] = useState("");
  const [isAppRegistered, setIsAppRegistered] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);

  const PanelActionsView = new PanelActions();

  useEffect(() => {
    return () => {
      //  clear uieditor states
      dispatch(
        registerElements({
          canvasActivity: [],
          activityHistory: [],
          undoMode: false,
          undoCursor: -1,
        })
      );
      dispatch(rToggleUIEditorFullScreen(false));
      dispatch(loadScreenStyle({}));
      dispatch(saveDisplayTableColumn(false));
      dispatch(setDropZone({}));
      dispatch(loadCanvasItems([]));
      dispatch(setCanvasItems([], { id: null, pos: null }));
      dispatch(setCanvasZoomLevel(100));
      dispatch(
        activateElement({
          componentsSelection: { activeComponents: {}, mode: "none" },
        })
      );

      //  clear screen states
      dispatch(setScreensList([]));
      dispatch(saveActiveScreen({}));

      // //  clear workflow states
      dispatch(toggleWorkflowPanel(false));
      dispatch(rSetWorkflowsList([]));
      dispatch(rToggleWorkflowFullScreen(false));
      dispatch(rUpdateWorkflowCanvas([]));
      dispatch(rLoadWorkflowTasks({}));
      dispatch(rSetActiveWorkflow({}));
      dispatch(rSelectActiveTask({}));
      dispatch(rAddToWorkflow(0));
      dispatch(
        rSetupBackDrop({
          show: false,
          clickToHideBackdrop: true,
        })
      );
      dispatch(rSetWorkflowVariables({}));
      dispatch(rRemoteUpdateCanvasElements([]));
      dispatch(rSetWorkflowIntegrations({}));

      // //  clear app/templates states
      dispatch(rSelectApp({}));
      dispatch(rOpenAppDetailsDialog(null));

      //  clear app-centric localstorage
      manageAppLocalStorage("clear", lastAppId.current, "app");
    };
  }, []);

  useEffect(() => {
    if (!!currentAppId) lastAppId.current = currentAppId;
    const page = location.pathname.split("/")[3];
    if (currentAppId) {
      const verifyNewApp = manageAppLocalStorage("get", currentAppId, "isNew");
      if (verifyNewApp) {
        setShowPrompt(true);
      }
    }
  }, [currentAppId]);

  useEffect(() => {
    const page = location.pathname.split("/")[3];
    const title = editorNavigationList.find(
      (item) => item.url === `/${page}`
    )?.title;
    setPageHeaderTitle(title);

    const appId = location.pathname.split("/")[2];
    setCurrentAppId(appId);
  }, [location.pathname]);

  useEffect(() => {
    const currentAppId = location.pathname.split("/")[2];
    setCurrentAppId(currentAppId);
  }, [location]);

  useEffect(() => {
    if (!currentAppId) return;

    initializeAppRegistration();
  }, [currentAppId]);

  const initializeAppRegistration = async () => {
    if (!currentAppId) return;
    const registeredApp = await dispatch(registerApp(currentAppId, history));
    if (registeredApp) {
      dispatch(
        rSetAppsControlMode(
          registeredApp.template
            ? APPS_CONTROL_MODES.TEMPLATE
            : APPS_CONTROL_MODES.APP
        )
      );
      setIsAppRegistered(true);
    }
  };

  useEffect(() => {
    if (!isAppRegistered) return;

    if (page && !!currentAppId) {
      PanelActionsView.fetchAllAndSetDefaultEntity(
        page,
        dispatch,
        currentAppId,
        false,
        history
      );
    }
  }, [page, isAppRegistered]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    switch (source.droppableId) {
      case "basicElements":
      case "inputElements":
      case "selectionElements":
      case "buttonElements":
      case "searchedElements":
      case "advancedElements":
      case "navigationElements":
        dispatch(canvasRegisterElement({ source, destination, type: "drop" }));
        break;
      case destination.droppableId:
        dispatch(
          canvasRegisterElement({ source, destination, type: "reorder" })
        );
        break;

      default:
        break;
    }
  };
  const toggleSideBar = () => setOpen((prev) => !prev);
  const memoizedNavigation = useMemo(() => {
    return (
      <ErrorBoundary render={() => <GeneralError section="editors" />}>
        <Route
          exact
          path={`${otherProtectedUrls.EDITOR}/:appId/:editor`}
          component={() => (
            <EditorPageSwitch setPage={(pg) => setPage(pg)} page={page} />
          )}
        />
      </ErrorBoundary>
    );
  }, [page]);

  const getMarginLeft = () => {
    // if (!open && page === "uieditor" && props.uiEditorFullScreen) return 4;

    // if (!open && page === "workflows" && props.workflowFullScreen) return 4;
    if (page === "uieditor") return props.uiEditorFullScreen ? 4 : 234;
    else if (page === "workflows" && props.workflowFullScreen) return 4;
    // return open ? 234 : 4;
    return 234;
  };
  // localStorage.setItem("sideNavigationWidth", 290);

  const sideToolbarVisibility = () => {
    const hideSideToolbarFor = ["workflows"];

    if (hideSideToolbarFor.includes(page)) return false;
    return true;
  };

  return (
    <ErrorBoundary render={() => <GeneralError />}>
      <div
        className={`editor-pages ${
          sideToolbarVisibility() ? "" : "hideSideToolbar"
        }`}
        style={{ height: "100vh" }}
      >
        <CssBaseline />
        <DragDropContext onDragEnd={onDragEnd}>
          <AppLayout
            headerTitle={pageHeaderTitle}
            hideSearbar
            extraToolBar={<EditorNavBar page={page} />}
            CustomSideNavigation={
              <>
                <div
                  style={{
                    display: "flex",
                    minWidth: sideToolbarVisibility() ? 290 : 50,
                  }}
                >
                  {/* <div style={{ display: "contents" }}> */}
                  <div
                    style={{
                      position: "fixed",
                      display: "flex",
                      // display: "contents",
                      backgroundColor: "#fff",
                      zIndex: 2,
                    }}
                  >
                    {/* THE EXTREME LEFT VERTICAL BAR */}
                    <EditorNavigations
                      drawerOpen={open}
                      toggleSideBar={toggleSideBar}
                      page={page}
                      appId={currentAppId}
                    />

                    {/* THE SECOND LEFT VERTICAL BAR/PANEL */}
                    {sideToolbarVisibility() && (
                      <EditorToolbars
                        drawerOpen={open}
                        drawerWidth={drawerWidth}
                        page={page}
                      />
                    )}
                  </div>
                </div>
              </>
            }
          >
            <div
              className="allZone"
              style={{ height: "100%", flex: 1, backgroundColor: "#FCFCFC" }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  marginLeft: 0, // getMarginLeft(),
                  // marginLeft: 419,
                  overflowY:
                    page === "uieditor" || page === "screens"
                      ? "auto"
                      : "hidden",
                }}
              >
                {memoizedNavigation}
                <EditorFooter drawerOpen={open} page={page} />
              </div>
              {/* </DragDropContext> */}
            </div>
          </AppLayout>
        </DragDropContext>
        {showPrompt && (
          <CustomAlertBox
            open={showPrompt}
            type="success"
            closeConfirmBox={() => {
              manageAppLocalStorage("clear", currentAppId, "isNew");
              setShowPrompt(false);
            }}
            text={`Your app was created successfully, click OK to start designing the app interface`}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default connect((state) => {
  return {
    uiEditorFullScreen: state.uieditor.uiEditorFullScreen,
    workflowFullScreen: state.workflows.workflowFullScreen,
  };
})(EditorLayout);
