import svg from "./EmptyCanvas.svg";

const EmptyCanvas = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: 860,
      }}
    >
      <img
        style={{ marginTop: "25%" }}
        src={svg}
        alt={"Drag and drop components here"}
      />
    </div>
  );
};

export default EmptyCanvas;
