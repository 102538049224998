import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PublishIcon from "@mui/icons-material/Publish";
import PreviewIcon from "@mui/icons-material/Preview";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MoreIcon from "@material-ui/icons/MoreVert";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Button from "@material-ui/core/Button";
import { Menu, MenuItem, Tooltip } from "@material-ui/core";
import { connect, useDispatch, useSelector } from "react-redux";
import { clearWorkflowCanvas } from "../Pages/Workflow/utils/workflowHelpers";
import Dialog from "@material-ui/core/Dialog";
import CancelIcon from "@material-ui/icons/Cancel";
import { blue } from "@material-ui/core/colors";
import useCustomMutation from "../../../common/CustomMutation";
import { publishApp } from "../../../components/Mutation/Apps/AppsMutation";
import { publishTemplates } from "../../../components/Mutation/Templates/TemplateMutation";
import {
  getUserRole,
  userManagementPermission,
  tempPermissions,
} from "../../../common/util/userRoleEvaluation";
import { useHistory } from "react-router-dom";
import { clearUIEditorCanvas } from "../Pages/UIEditor/utils/canvasHelpers";
import { successToastify, errorToastify } from "../../../common/Toastify";
import { SetAppStatus } from "../../../helpers/helperFunctions";
import { APPS_CONTROL_MODES } from "../Pages/Workflow/components/utils/constants";
import clearEditorIcon from "../../../assets/images/editor-clear-icon.svg";
import editEditorIcon from "../../../assets/images/editor-edit-icon.svg";
import previewEditorIcon from "../../../assets/images/editor-preview-icon.svg";
import { mainNavigationUrls } from "../../../utils/lists";
import CustomConfirmBox from "../../../common/Helper/CustomConfirmBox";
import CustomAlertBox from "../../../common/Helper/CustomAlertBox";

const EditorNavBar = (props) => {
  const drawerWidth = props.drawerWidth;

  const appType =
    props.appsControlMode === APPS_CONTROL_MODES.APP ? "app" : "template";

  const useStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    title: {
      color: "#424874",
      fontSize: 11,
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      boxShadow: "0px 1px 1px -1px rgba(0,0,0,0.2)",
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
      paddingTop: 10,
      paddingBottom: 10,
    },
    navIcons: {
      color: "#999",
      fontSize: 18,
    },
    dot: {
      background: "#0C7B93",
    },
    changes: {
      textAlign: "center",
      color: "#ABB3BF",
      fontSize: 12,
    },
    controlTopBar: {
      color: "#091540",
      // fontSize: 12,
      textTransform: "uppercase",
      fontWeight: 300,
    },
    publish: {
      fontSize: 12,
      margin: theme.spacing(1),
      backgroundColor: "#010A43",
      color: "#fff",
      textTransform: "none",
      borderRadius: 3,
    },
    edit: {
      fontSize: 12,
      margin: theme.spacing(1),
      color: "#424874",
      textTransform: "none",
      borderRadius: 3,
      borderColor: "#010A43",
    },
    preview: {
      fontSize: 12,
      margin: theme.spacing(1),
      backgroundColor: "#E7E9EE",
      color: "#424874",
      textTransform: "none",
      borderRadius: 3,
    },
    badge: {
      height: 16,
      minWidth: 12,
      fontSize: 8,
    },
    topBar: {
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2)",
      minHeight: 0,
      zIndex: 5,
    },
    nav: {
      minHeight: 0,
      borderTop: "solid 1px #ccc",
    },
    avatar: {
      backgroundColor: blue[100],
      color: blue[600],
    },
    size: {
      width: 469,
      height: 319,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: `url(../../../../../../images/dialog_bg.svg)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left bottom",
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMenuOpen = Boolean(mobileMoreAnchorEl);
  const [saveMessage, setSaveMessage] = useState("");
  const [undoDisabled, setUndoDisabled] = useState(false);
  const [redoDisabled, setRedoDisabled] = useState(false);
  const [networkOnline, setNetworkOnline] = useState(navigator.onLine);
  const [showPublishAppDialog, setShowPublishAppDialog] = useState("");
  const [showEditAppDialog, setShowEditAppDialog] = useState("");
  const [showClearCanvasDialog, setShowClearCanvasDialog] = useState(false);
  const [showPublishingLoading, setShowPublishingLoading] = useState(false);
  const [showPublishingFinished, setShowPublishingFinished] = useState(false);
  const [publishDialogType, setPublishDialogType] = useState(`info`);
  const [publishDialogText, setPublishDialogText] = useState(
    `Are you sure you want to publish this ${appType}?`
  );
  const [publishDialogFalseText, setPublishDialogFalseText] = useState(`No`);
  const history = useHistory();

  /**
   * @param data - The data returned from the server.
   */
  const onPublishSuccess = (data) => {
    if (!data?.data?.data?._meta?.success) {
      onPublishError({ error: "Network error" });
      return;
    }
    successToastify(`"${props.selectedApp?.name}" was published successfully`);
    !history.location.state
      ? history.push(mainNavigationUrls.APPS)
      : history.push(history.location.state.path);
  };
  const onPublishError = ({ error }) => {
    setShowPublishingFinished(true);
    setShowPublishingLoading(false);

    setPublishDialogType("error");
    const errorMessage = error?.response?.data?._meta?.error;
    const detailMessage = errorMessage?.messages
      ? `[ ${Object.keys(errorMessage?.messages)
          .map((fieldKey) => `"${fieldKey}"`)
          .join(", ")} ]`
      : "";

    const message = `${errorMessage?.message || JSON.stringify(error)} ${
      detailMessage ? ` : ${detailMessage}` : ""
    }`;
    setPublishDialogText(message);
    setPublishDialogFalseText("OK");

    dispatch(
      SetAppStatus({
        type: "error",
        msg: "Publishing failed",
      })
    );
    // errorToastify("Error occured while publishing")
  };

  /* Using the useCustomMutation hook to call the publishApp or publishTemplates function. */
  const { mutate: publishMutate } = useCustomMutation({
    apiFunc:
      props.appsControlMode === APPS_CONTROL_MODES.APP
        ? publishApp
        : props.appsControlMode === APPS_CONTROL_MODES.TEMPLATE &&
          //userManagementPermission(getUserRole()) &&
          publishTemplates,
    onSuccess: onPublishSuccess,
    onError: onPublishError,
    overrideNotification: true,
    retries: 0,
  });

  const { changesText } = props;

  useEffect(() => {
    /* Checking if the browser is online or offline. */
    window.addEventListener("online", () =>
      setNetworkOnline(!!navigator.onLine)
    );
    /* Checking if the browser is online or offline. */
    window.addEventListener("offline", () =>
      setNetworkOnline(!!navigator.onLine)
    );

    return () => {
      /* Removing the event listener for the online event. */
      window.removeEventListener("online", () =>
        setNetworkOnline(!!navigator.onLine)
      );

      /* Removing an event listener. */
      window.removeEventListener("offline", () =>
        setNetworkOnline(!!navigator.onLine)
      );
    };
  }, [setNetworkOnline]);

  useEffect(() => {
    setSaveMessage(changesText);
  }, [changesText]);

  useEffect(() => {
    /* Checking if the undoCursor is not equal to -1 and the activityHistory length is greater than 0.
   If it is, then it sets the undoDisabled to false. If it is not, then it sets the undoDisabled to
   true. */
    if (props?.undoCursor !== -1 && props?.activityHistory?.length > 0) {
      setUndoDisabled(false);
    } else {
      setUndoDisabled(true);
    }

    /* Checking if the undoCursor is less than the activityHistory.length - 1 and if the
   activityHistory.length is greater than 0. If both of these conditions are true, then it sets the
   redoDisabled to false. If either of these conditions are false, then it sets the redoDisabled to
   true. */
    if (
      props?.undoCursor < props?.activityHistory?.length - 1 &&
      props?.activityHistory?.length > 0
    ) {
      setRedoDisabled(false);
    } else {
      setRedoDisabled(true);
    }
  }, [props.undoCursor, props.activityHistory]);

  /**
   * When the user clicks on the menu icon, the menu will open.
   * @param event - SyntheticEvent&lt;any&gt;
   */
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    /* The above code is setting the anchorEl to null and closing the mobile menu. */
    setMobileMoreAnchorEl(null);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const handlePreview = () => {
    // Get the ID of the current screen
    const screenId = props.activeScreen?.id;
    //  now navigate
    setMobileMoreAnchorEl(null);
    window.open(`/editor/uieditor/preview/${screenId}`);
  };

  const handleEdit = () => {
    setMobileMoreAnchorEl(null);
    setShowEditAppDialog("edit_app");
    // }
  };

  /**
   * It sets the state of the showPublishAppDialog variable to the value of the string "publish_app".
   */
  const handlePublish = () => {
    // if (props.page === "uieditor" || props.page === "screens") {
    setMobileMoreAnchorEl(null);
    setShowPublishAppDialog("publish_app");
    // }
  };

  /**
   * If the user is in the APPS_CONTROL_MODES.APP tab, then publish the app. If the user is in the APPS_CONTROL_MODES.TEMPLATE tab
   * and the user is a PlugAdmin, then publish the app.
   * @returns Nothing.
   */
  const handlePublishAppTemp = () => {
    setShowPublishingLoading(true);
    setShowPublishingFinished(false);

    if (props.appsControlMode === APPS_CONTROL_MODES.APP) {
      dispatch(
        SetAppStatus({
          type: "loading",
          msg: `Publishing ${props.selectedApp?.name}...`,
        })
      );
      /* Publishing a mutation to the server. */
      publishMutate({
        id: props.selectedApp?.id,
        active: true,
      });
      // setShowPublishAppDialog(() => "");
      return;
    } else if (
      props.appsControlMode === APPS_CONTROL_MODES.TEMPLATE &&
      userManagementPermission(getUserRole())
    ) {
      dispatch(
        SetAppStatus({
          type: "loading",
          msg: `Publishing template ${props.selectedApp?.name}...`,
        })
      );
      publishMutate({
        id: props.selectedApp?.template,
        active: true,
      });
      // setShowPublishAppDialog(() => "");

      return;
    }
  };
  /**
   * If the response is true, then switch the use case and return the handlePublishAppTemp function.
   * @param use - the use case for the dialog
   * @param resp - true or false
   * @returns the value of the function handlePublishAppTemp()
   */
  const dialogResponse = (use, resp, clearDialog = true) => {
    if (resp === true) {
      switch (use) {
        case "publish_app":
          return handlePublishAppTemp();

        case "public": {
          // public({

          // })
          break;
        }
        default:
          break;
      }
    }

    if (clearDialog) {
      setShowEditAppDialog("");
      setShowPublishAppDialog("");
    }
  };

  /**
   * If the user confirms that they want to clear the canvas, then clear the canvas.
   */
  const _clearCanvas = () => {
    if (props.page === "workflows") {
      props.dispatch(clearWorkflowCanvas());
    } else if (props.page === "uieditor") {
      props.dispatch(clearUIEditorCanvas());
    }
  };

  const renderMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => setShowClearCanvasDialog(true)}>
        <DeleteForeverIcon style={{ fontSize: 18, marginRight: 5 }} /> Clear
        Canvas
      </MenuItem>
      <MenuItem onClick={handlePreview}>
        <PreviewIcon style={{ fontSize: 18, marginRight: 5 }} /> Preview
      </MenuItem>
      <MenuItem onClick={handlePublish}>
        <PublishIcon style={{ fontSize: 18, marginRight: 5 }} /> Publish
      </MenuItem>
    </Menu>
  );

  const initDialog = () => {
    setShowPublishAppDialog("");
    setShowPublishingFinished(false);
    setShowPublishingLoading(false);
    setPublishDialogType(`info`);
    setPublishDialogText(`Are you sure you want to publish this ${appType}?`);
    setPublishDialogFalseText(`No`);
  };

  return (
    <>
      <div className="app-editor-toolbar">
        <div className="editor-app-info">
          {props.appsControlMode === APPS_CONTROL_MODES.TEMPLATE && (
            <div className="template-nav-tag">Template</div>
          )}
          <Typography
            className={classes.controlTopBar}
            style={{ marginRight: 10, fontSize: 12 }}
          >
            {props.selectedApp?.name || "..."}
          </Typography>
          {(props.page === "uieditor" || props.page === "screens") && (
            <>
              <Typography style={{ fontWeight: "bold" }}>{`|`}</Typography>
              <Typography style={{ fontWeight: "bold", marginLeft: 10 }}>
                {props.activeScreen?.name || "..."}
              </Typography>
            </>
          )}
          {props.page === "workflows" && (
            <>
              <Typography style={{ fontWeight: "bold" }}>{`|`}</Typography>
              <Typography style={{ fontWeight: "bold", marginLeft: 10 }}>
                {props.activeWorkflow?.name || "..."}
              </Typography>
            </>
          )}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {/* <IconButton aria-label="More">
            <img src="../../../images/more.svg" alt="" />
          </IconButton> */}

            {/* <IconButton
            aria-label="Undo"
            disabled={undoDisabled}
            onClick={() => props.dispatch(canvasActivityUndoRedo(-1))}
          >
            <img
              src="../../../images/undo.svg"
              alt="undo"
              style={undoDisabled ? { filter: "invert(1)" } : {}}
            />
          </IconButton>

          <IconButton
            aria-label="Redo"
            disabled={redoDisabled}
            onClick={() => props.dispatch(canvasActivityUndoRedo(1))}
          >
            <img
              src="../../../images/redo.svg"
              alt="redo"
              style={redoDisabled ? { filter: "invert(1)" } : {}}
            />
          </IconButton> */}
          </div>
        </div>
        <div className="app-editor-toolbar-right">
          {/* <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              size="small"
            >
              <MoreIcon style={{ fontSize: 20 }} />
            </IconButton>
          </div>

          {renderMenu} */}
          {props.page !== "screens" && (
            <Tooltip title="Clear canvas">
              <div
                disabled={
                  (props.page === "uieditor" && !props?.canvasItems?.length) ||
                  (props.page === "workflows" &&
                    props?.workflowCanvas?.length < 3)
                }
                onClick={() => setShowClearCanvasDialog(true)}
              >
                <img src={clearEditorIcon} alt="clear" />
              </div>
            </Tooltip>
          )}
          {/* <Tooltip title="Edit app">
            <div onClick={handleEdit} disabled={props.appStatus === "draft"}>
              <img src={editEditorIcon} alt="edit" />
            </div>
          </Tooltip> */}
          {/* <Tooltip title="Preview app">
            <div onClick={handlePreview}>
              <img src={previewEditorIcon} alt="preview" />
            </div>
          </Tooltip> */}
          <Tooltip title="Click to save your app changes">
            <div className="_btn">
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#06188F",
                  textTransform: "capitalize",
                  // padding: "0 10px",
                  height: 26,
                  fontSize: 12,
                  color: "#FFFFFF",
                }}
                onClick={handlePublish}
                disabled={props.appStatus === "live"}
              >
                Publish
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>

      {/* <Dialog
        onClose={() => setShowPublishAppDialog("")}
        aria-labelledby="simple-dialog-title"
        open={!!showPublishAppDialog}
        classes={{
          paper: classes.size,
        }}
      >
        <IconButton
          size="small"
          style={{ position: "absolute", top: 10, right: 10 }}
          onClick={() => setShowPublishAppDialog("")}
        >
          <CancelIcon />
        </IconButton>
        <img
          src="../../../../../../images/dialog_img.svg"
          alt="Say Mind"
          width={104}
        />
        <div
          style={{
            color: "#010A43",
            fontSize: 14,
            marginTop: 22,
            textAlign: "center",
          }}
        >
          {`Are you sure you want to publish this ${appType}?`}
        </div>
        <div style={{ marginTop: 40 }}>
          <Button
            style={{
              width: 60,
              height: 30,
              textTransform: "none",
              border: "solid 1.5px #666666",
              marginRight: 20,
            }}
            onClick={() => setShowPublishAppDialog("")}
          >
            {"No"}
          </Button>
          <Button
            style={{
              width: 60,
              height: 30,
              textTransform: "none",
              border: "solid 1.5px #000000",
            }}
            variant="contained"
            onClick={() => dialogResponse(showPublishAppDialog, true)}
          >
            {"Yes"}
          </Button>
        </div>
      </Dialog> */}

      {showPublishAppDialog && (
        <CustomConfirmBox
          closeConfirmBox={() => initDialog()}
          text={publishDialogText}
          open={showPublishAppDialog}
          confirmAction={() =>
            dialogResponse(showPublishAppDialog, true, false)
          }
          type={publishDialogType}
          isAlertPrompt={showPublishingFinished}
          closeDialogOnAction={false}
          falseText={publishDialogFalseText}
          isLoadingDialog={showPublishingLoading}
          loadingDialogText="Publishing... please wait..."
        />
      )}

      {/* {(showPublishingLoading || showPublishingFinished) && (
        <CustomAlertBox
          open={showPublishingLoading || showPublishingFinished}
          closeConfirmBox={() => setShowPublishingFinished(false)}
          isLoading={showPublishingLoading}
          text={`Publishing...`}
        />
      )} */}

      {showEditAppDialog && (
        <CustomConfirmBox
          closeConfirmBox={() => setShowEditAppDialog("")}
          text={`Are you sure you want to take this ${appType} offline to edit?`}
          open={showEditAppDialog}
          confirmAction={() => dialogResponse(showEditAppDialog, true)}
        />
      )}
      {showClearCanvasDialog && (
        <CustomConfirmBox
          closeConfirmBox={() => setShowClearCanvasDialog(false)}
          text={`Are you sure you want to clear this ${props.page}?`}
          type="warning"
          open={showClearCanvasDialog}
          confirmAction={_clearCanvas}
        />
      )}
    </>
  );
};

export default connect((state) => {
  return {
    selectedApp: state.appsReducer.selectedApp,
    appStatus: state.appsReducer.appStatus,
    appsControlMode: state.appsReducer.appsControlMode,
    showDialog: state.reducers.showDialog,
    activeScreen: state.screens.activeScreen,
    canvasItems: state.uieditor.canvasItems,
    activityHistory: state.uieditor.activityHistory,
    undoCursor: state.uieditor.undoCursor,
    activeWorkflow: state.workflows.activeWorkflow,
    workflowCanvas: state.workflows.workflowCanvas,
  };
})(EditorNavBar);
