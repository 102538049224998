import React from "react";
import { useMsal } from "@azure/msal-react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useGoogleLogout } from "react-google-login";
import { logoutError, logoutUser, receiveLogout } from "../../store/actions";
import { Button, IconButton, MenuItem } from "@material-ui/core";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { logoutClearLocalStorage } from "../../helpers/helperFunctions";
import { useQueryClient } from "react-query";

const Logout = (props) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const onLogoutSuccess = (res) => {
    // infoToastify("Logged out Successfully");
    /* Clearing the local storage. */
    logoutClearLocalStorage(history, false);
    /* The above code is a reducer. A reducer is a function that receives state and an action, and returns
    a new state. */
    dispatch(receiveLogout());
  };

  const onFailure = () => {
    //console.log("handle failure cases");
    /* The above code is dispatching an action to the Redux store. The action is of type LOGOUT_ERROR and
    has no payload. */
    dispatch(logoutError());
  };

  /* The `useGoogleLogout` hook is a React custom hook that uses the Google Sign-Out API to sign out a
 user from your site.
 
 The `onLogoutSuccess` callback is called when the user successfully signs out.
 
 The `onFailure` callback is called when the user fails to sign out.
 
 The `clientId` prop is the Google Client ID that you can get from the Google Developer Console.
 
 The `signOut` function is the function that signs the user out. */
  const { signOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENTID,
    onLogoutSuccess,
    onFailure,
  });

  const handleSignout = (event) => {
    /* The above code is dispatching an action to the Redux store. The action is a logoutUser action, which
    is defined in the userActions.js file. The logoutUser action is then handled by the userReducer,
    which is defined in the userReducer.js file. */
    queryClient.removeQueries(["inAppNotificationBadgeCount"]); // clear cache notification badge count on logout
    dispatch(logoutUser({ signOut, instance }, history));

    /* The above code is preventing the default behavior of the submit button. */
    event.preventDefault();
  };

  return (
    <div
      onClick={handleSignout}
      ref={props?.logOutRef}
      style={{ marginTop: 20 }}
    >
      {props.children}
    </div>
  );
};

export default Logout;
