import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button, Grid, IconButton, TextField } from "@material-ui/core";
import { Menu, MenuItem } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Scrollbars } from "react-custom-scrollbars";

import SearchBar from "./SearchBar";
import ElementTile from "./ElementTiles";
import { allElements } from "../Pages/UIEditor/utils/elementsList";
import {
  SetAppStatus,
  manageAppLocalStorage,
} from "../../../helpers/helperFunctions";
import {
  rSetAppsControlMode,
  saveActiveScreen,
} from "../../../store/actions/properties";
import { registerApp } from "../../Apps/util/appLogics";
import { APPS_CONTROL_MODES } from "../Pages/Workflow/components/utils/constants";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} style={{ padding: 0 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "99%",
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  buttonTabs: {
    textTransform: "capitalize",
    background: "#424874",
    color: "#E7E9EE",
    minWidth: "fit-content",
  },
  selected: {
    background: "#010A43",
    color: "#fff",
  },
  panel: {
    position: "relative",
    // overflowY: "scroll",
    // overflowX: "clip",
    width: "100%",
    height: "100%",
    flex: "1 1 0%",
    // marginTop: 16,
    "& .MuiBox-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  elementButton: {
    borderRadius: 5,
    padding: "0px 0 0px 10px",
    border: "solid 1px #2457C1",
  },
  heading: {
    color: "#ABB3BF",
    fontSize: 14,
    maxWidth: "85%",
  },
  keepMargin: {
    margin: "12px 0 !important",
  },
  tileSpacing: {
    marginBottom: 10,
    borderRadius: 3,
    // background: "#010A43",
    "&::before": {
      position: "unset",
    },
    normalMargin: {
      marginTop: "0 !important",
      marginBottom: "10px !important",
    },
    cursor: "pointer",
    "&:hover": {
      background: "#0c7b93", //"#151c4b"
    },
    "&:hover span": {
      color: "#ffffff !important",
    },
  },
  normalHeight: {
    minHeight: "50px !important",
  },
  newEntity: {
    color: "#fff",
    background: "#010A43",
    fontSize: "0.8rem",
  },

  grid: {
    background: "#2457C1",
    width: "100%",
    // marginBottom: "26px",
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textTransform: "capitalize",
    fontSize: 10,
    fontWeight: 600,
  },
  select: {
    color: "#091540",
    fontSize: 12,
    padding: 10,
    // width: "100",
  },
}));

const EditorLayoutPanel = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // const [currentAppId, setCurrentAppId] = useState("");
  const [value, setValue] = useState(0);
  const [searchResults, setSearchResults] = useState([]);

  const [currentAppId, setCurrentAppId] = useState(null);
  const [entity, setEntity] = useState([]);
  const [entityName, setEntityName] = useState("");
  const [entityId, setEntityId] = useState("");
  const [entityIndex, setEntityIndex] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasUpdate, setHasUpdate] = useState(false);
  const [updateAction, setUpdateAction] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [isAppRegistered, setIsAppRegistered] = useState(false);
  const [search, setSearch] = useState("");
  const [screenTypeAnchorEl, setScreenTypeAnchorEl] = useState(null);
  const screenTypeOpen = Boolean(screenTypeAnchorEl);
  const nameref = useRef();
  const isMenuOpen = Boolean(anchorEl);
  const dispatchAction = useRef();
  const trackRerender = useRef(false);
  const namesArray = [];

  const buttonCallback = props.buttonCallback;
  const { activeScreen, screensList } = useSelector(({ screens }) => screens);
  const { activeWorkflow, workflowsList } = useSelector(
    ({ workflows }) => workflows
  );

  const _useDispatch = (dispatchAction.current = dispatch);

  useEffect(() => {
    const appId = location.pathname.split("/")[2];
    setCurrentAppId(appId);
  }, [location.pathname]);

  useEffect(() => {
    //if (props.panelType !== "list" || !isAppRegistered) return;
    if (props.panelType !== "list") return;

    if (props.page && !!currentAppId) {
      props.panelActions.fetchAllAndSetDefaultEntity(
        props.page,
        _useDispatch,
        currentAppId,
        hasUpdate,
        history
      );

      if (!trackRerender.current) {
        trackRerender.current = true;
        setHasUpdate((prev) => !prev);
      } else {
        trackRerender.current = false;
      }
    }
  }, [hasUpdate, isAppRegistered]);

  useEffect(() => {
    setHasUpdate(false);
  }, []);

  useEffect(() => {
    if (props.panelType !== "list" || !isAppRegistered) return;

    let subscribe = true;
    let timeout;

    props.panelActions.removeUpdateFlag({
      page: props.page,
      timeout,
      _useDispatch,
      // setApplicationStatus,
      subscribe,
      updateAction,
      setHasUpdate,
      hasUpdate,
    });

    return () => {
      subscribe = false;
      setUpdateAction(null);
      clearTimeout(timeout);
    };
  }, [
    hasUpdate,
    _useDispatch,
    updateAction,
    props.page,
    props.panelType,
    isAppRegistered,
  ]);

  const handleMenuOptionsOpen = (
    event,
    id,
    index,
    name,
    type,
    slug,
    namesArray
  ) => {
    event.stopPropagation();
    setEntityIndex(index);
    setEntityId(id);
    setEntityName(name);

    setActiveEntity({ id, name, type, slug });
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const handleEntityMutations = ({ value }) => {
    setUpdateAction(value);

    // _useDispatch(setAppStatus(`...`));

    switch (value) {
      case "rename":
        props.panelActions.handleRename({
          page: props.page,
          entityIndex,
          nameref,
          setShowOptions,
          namesArray,
          dispatch,
        });
        break;

      case "delete":
        // setAppStatus("info", `...`);
        props.panelActions.handleEntityDelete({
          page: props.page,
          entityId,
          setHasUpdate,
          dispatch,
        });
        break;

      case "edit":
        props.panelActions.handleEditEntity({
          page: props.page,
          history,
          appId: currentAppId,
          setShowOptions,
        });
        break;

      case "duplicate":
        // setAppStatus("info", `...`);
        props.panelActions.handleDuplicateEntity({
          page: props.page,
          entityId,
          entityIndex,
          entity,
          setEntity,
          setHasUpdate,
          setShowOptions,
          namesArray,
          dispatch,
          // setStatus,
        });
        break;

      default:
        break;
    }
  };

  const onSetSearch = (value) => {
    setSearch(value);
    setSearchResults(allElements(null, null, true, value));
  };

  const handleOpenScreenTypes = (type) => {
    setUpdateAction("create");
    dispatch(SetAppStatus({ type: "info", msg: `...` }));

    props.panelActions.handleNewEntity({
      page: props.page,
      screenType: type,
      appId: currentAppId,
      namesArray: screensList.map((screen) => screen.name),
      dispatch,
    });
  };

  const handleBtnClick = (event) => {
    switch (props.page) {
      case "screens":
        setScreenTypeAnchorEl(event.currentTarget);
        break;

      default:
        setScreenTypeAnchorEl(event.currentTarget);
        break;
    }
  };

  const handleScreensClose = () => {
    setScreenTypeAnchorEl(null);
  };

  const getAllList = () => {
    switch (props.page) {
      case "screens":
        return screensList;

      case "workflows":
        return workflowsList;

      default:
        return screensList;
    }
  };

  const getActiveEntity = () => {
    switch (props.page) {
      case "screens":
        return activeScreen;

      case "workflows":
        return activeWorkflow;

      default:
        return activeScreen;
    }
  };

  const setActiveEntity = ({ id, name, type, slug }) => {
    switch (props.page) {
      case "screens":
        dispatch(saveActiveScreen({ id, name, type, slug }));
        manageAppLocalStorage("set", currentAppId, "activeScreen", {
          id,
          name,
          type,
          slug,
        });
        break;

      case "workflows":
        dispatch(saveActiveScreen({ id, name, type, slug }));
        manageAppLocalStorage("set", currentAppId, "activeWorkflow", {
          id,
          name,
          type,
          slug,
        });
        break;

      default:
        dispatch(saveActiveScreen({ id, name, type, slug }));
        manageAppLocalStorage("set", currentAppId, "activeScreen", {
          id,
          name,
          type,
          slug,
        });
        break;
    }
  };

  const PanelTypeAccordion = () => (
    <div className={classes.root}>
      <div
        style={{
          color: "#000000",
          fontSize: 16,
          textAlign: "center",
          padding: "10px 0 30px",
          fontWeight: 500,
        }}
      >
        {/* {props.panelData.panelHeader} */}
        Components
      </div>
      {/* <SearchBar
        setSearch={onSetSearch}
        placeholder={props.panelData.searchBarText}
      /> */}

      {/* <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        indicatorColor={"primary"}
        variant="fullWidth"
        centered
      >
        <Tab
          label="Components"
          {...a11yProps(0)}
          style={{ borderRadius: "3px 0 0 3px", fontSize: 12 }}
          classes={{ selected: classes.selected, root: classes.buttonTabs }}
        />
        <Tab
          label=APPS_CONTROL_MODES.TEMPLATE
          {...a11yProps(1)}
          style={{ borderRadius: "0 3px 3px 0", fontSize: 12 }}
          classes={{ selected: classes.selected, root: classes.buttonTabs }}
        />
      </Tabs> */}

      <Scrollbars autoHide className="correction-side-panel">
        <TabPanel value={value} index={0} className={classes.panel}>
          <ElementTile
            panelData={props.panelData}
            buttonCallback={buttonCallback}
            searching={search}
            searchResults={searchResults}
          />
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.panel}></TabPanel>
      </Scrollbars>
    </div>
  );

  const PanelTypePlain = () => (
    <div className={classes.root}>
      <div
        style={{
          color: "#535353",
          fontSize: 14,
          textAlign: "center",
          padding: "10px 0 30px",
          fontWeight: 600,
        }}
      >
        {"Components" || props.panelData.panelHeader}
      </div>

      {props.page === "screens" && (
        <div>
          <SearchBar
            setSearch={setSearch}
            placeholder={props.panelData.searchBarText}
          />
        </div>
      )}

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          variant="contained"
          color="secondary"
          className={classes.grid}
          startIcon={<AddIcon />}
          disabled={props.page === "workflows"}
          id="basic-button"
          aria-describedby={screenTypeOpen ? "basic-menu" : undefined}
          aria-controls={"basic-menu"}
          aria-haspopup="true"
          aria-expanded={screenTypeOpen ? "true" : undefined}
          onClick={handleBtnClick}
        >
          {props.panelData.newText}
        </Button>
        <Grid container style={{ backgroundColor: "green", width: "100%" }}>
          <Menu
            id={screenTypeOpen ? "basic-menu" : undefined}
            anchorEl={screenTypeAnchorEl}
            open={screenTypeOpen}
            onClose={handleScreensClose}
            anchorReference={"anchorEl"}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleScreensClose} disabled>
              Select screen type
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleScreensClose();
                handleOpenScreenTypes("app");
              }}
            >
              Screen
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleScreensClose();
                handleOpenScreenTypes("document");
              }}
            >
              Document
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <Scrollbars
        style={{ width: "100%", flex: 1, marginTop: 16 }}
        className="correction-side-panel"
      >
        <div ref={nameref} className="correction">
          {props.panelActions.handleEntityFilter &&
            props.panelActions
              .handleEntityFilter({
                page: props.page,
                entities: getAllList(),
                search,
              })
              ?.map(({ id, name, type, slug }, index) => {
                namesArray.push(name);

                return (
                  <Grid
                    className={classes.tileSpacing}
                    classes={{ root: classes.normalMargin }}
                    key={`${id}`}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.elementButton}
                      style={{
                        // backgroundColor:
                        //   getActiveEntity().id === id ? "#0c7b93" : "#ffffff",
                        fontWeight:
                          getActiveEntity().id === id ? 700 : "inherit",
                      }}
                      classes={{
                        root: classes.normalHeight,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveEntity({ id, name, type, slug });
                      }}
                    >
                      <Typography
                        component="span"
                        className={classes.heading}
                        style={{
                          color: "#535353",
                          // getActiveEntity().id === id ? "#fff" : "#535353",
                          fontWeight: getActiveEntity().id === id ? 800 : 500,
                        }}
                        onKeyDown={(event) => {
                          if (event.key === "Enter")
                            props.panelActions.saveEntityNameChange({
                              page: props.page,
                              event,
                              id,
                              index,
                              setHasUpdate,
                              nameref,
                              namesArray,
                              dispatch,
                              // setStatus,
                            });
                        }}
                      >
                        {name}
                      </Typography>
                      <IconButton
                        size="small"
                        aria-controls="entity-menu"
                        style={{
                          color: "#535353",
                          // getActiveEntity().id === id ? "#fff" : "#959ba5",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMenuOptionsOpen(
                            e,
                            id,
                            index,
                            name,
                            type,
                            slug,
                            namesArray
                          );
                        }}
                      >
                        <MoreVertIcon style={{ fontSize: 18 }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
        </div>
      </Scrollbars>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={"entity-menu"}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {["Edit", "Rename", "Duplicate", "Delete"].map((value, idx) => (
          <MenuItem
            button
            key={idx}
            disabled={
              ((value === "Rename" || value === "Delete") &&
                entityName.toLowerCase() === "index") ||
              (props.page === "workflows" && value === "Duplicate")
            } //  cannot rename "Index" entity
            onClick={(e) => {
              e.stopPropagation();
              handleMenuClose();
              handleEntityMutations({ value: value.toLowerCase() });
            }}
            style={{ fontWeight: 300, lineHeight: "19px" }}
          >
            {value}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );

  return (
    // props.panelType === "accordion" ? <PanelTypeAccordion /> : <PanelTypePlain />
    props.panelType === "accordion" ? PanelTypeAccordion() : PanelTypePlain()
  );
};

export default EditorLayoutPanel;
