import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  Typography,
  Collapse,
  FormControlLabel,
  Switch,
  IconButton,
} from "@material-ui/core";
import { CheckCircleOutlineOutlined } from "@material-ui/icons";
import SelectOnSteroids from "../../EditorLayout/Pages/Workflow/components/RightSidebar/components/sidebarActions/common/SelectOnSteroids";
import {
  updateResourcePermissions,
  removeResourcePermissions,
  groupResourcePermissions,
} from "../../../components/Query/DataSheets/datasheetQuery";
//import { getUsersAPI } from "../../SettingsLayout/Pages/UserManagement/utils/usersAPIs";
import { useQueryClient } from "react-query";
import useCustomMutation from "../../../common/CustomMutation";
import { filterDuplicateObjects, compareByNameAndId } from "./index";
import { successToastify } from "../../../common/Toastify";
import PermissionsSidebar from "../../Dashboards/pages/DashboardsList/PermissionsSidebar";

const DatasheetSidebar = ({
  showMe, //= true,
  setShowMe,
  datasheetId,
  datasheetName,
  permissionToggle,
  //showSideBar,
  //setShowSideBar,
  usersLists,
  permsResource,
  setPermsResource,
  handleCloseSidebar,
  refetch,
}) => {
  const node = "screen";

  const [settingsComplete, setSettingsComplete] = useState(false);
  const [actionComplete, setActionComplete] = useState(false);

  const handleClose = () => {
    // dispatch({ type: SHOW_COLUMNBOX, payload: false });
    setShowMe(false);
  };

  return (
    <PermissionsSidebar
      showMe={showMe}
      handleClose={handleClose}
      componentName={datasheetName}
      permsResource={permsResource}
      resourceId={datasheetId}
      refetch={refetch}
      permissionToggle={permissionToggle}
      settingsComplete={settingsComplete}
      actionComplete={actionComplete}
      queryType={"getDatasheetPerms"}
      resourceType={"datasheet"}
      component={"datasheet"}
    />
  );
};

export default DatasheetSidebar;
