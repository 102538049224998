import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Draggable, Droppable } from "react-beautiful-dnd";
import ComponentTile from "./ComponentTile";

const useStyles = makeStyles((theme) => {
  return {
    elementButton: {
      background: "#010A43",
      borderRadius: "3px 3px 0 0",
    },
    root: ({ height }) => {
      return {
        display: "flex",
        width: 210,
        maxHeight: height,
        flexDirection: "row",
        flexWrap: "wrap",

        "& >*": {
          marginBottom: 5,
          marginLeft: 4,
          width: 66,
          height: 50.67,
          // padding: 10
        },
      };
    },
    heading: {
      color: "#ABB3BF",
    },
    tileSpacing: {
      marginBottom: 10,
    },
    tiles: {
      display: "flex",
    },
  };
});

const DraggableTile = (props) => {
  const style = useStyles({
    height: Math.ceil(Object.keys(props.elements)?.length / 3) * 60 - 9,
  });
  return (
    <>
      <div>
        <div className={style.root}>
          {props.elements.map((item, index) => (
            <div key={item.id} index={index}>
              <div key={index}>
                <ComponentTile
                  title={item.title}
                  icon={item.icon}
                  img={item.img}
                  type={item.type}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DraggableTile;
