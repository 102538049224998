import React from 'react'
import { makeStyles } from '@material-ui/styles';

import runLoading from '../../../assets/images/run_loading.svg';

const useStyles = makeStyles((theme) => ({
    loadingSquare: {
        position: "absolute",
        width: 200,
        height: 200,
        // backgroundColor: "pink",
        margin: "auto",
        left: 0,
        right: 0,
        marginTop: "15%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        border: "solid 1px #1d486a",
        alignItems: "center",
        borderRadius: 10,
        boxShadow: "4px 4px 10px #ddd",
    },
    loadingStatus: {
        fontSize: "0.7em",
        marginTop: 10,
    },
}))


const AppLoading = ({ loadingStatus, loading }) => {
    const classes = useStyles();
    if (!loading) return null;
    return (
        <div className={classes.loadingSquare}>
            <img className={classes.image} src={runLoading} alt="" style={{ marginBottom: 10 }} />
            <div style={{ color: "#bbb" }}>Loading...</div>
            <div className={classes.loadingStatus}>{loadingStatus}</div>
        </div>
    )
}

export default AppLoading
