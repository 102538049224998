import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { v4 } from "uuid";
import {
  toggleWorkflowPanel,
  rHideRightSideBar,
} from "../../../../../../store/actions/properties";
import { addWorkflowTask } from "../../utils/workflowHelpers";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "3px 0px",
    width: "100%",
    background: "#424874",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "1px",
    height: "100%",
  },
  imageIcon: {
    padding: 0,
  },
  iconRoot: {
    textAlign: "center",
  },
  tileText: {
    color: "#F4EEFF",
    fontSize: "7.5px !important",
  },
}));

export default function SimplePaper(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [clicking, setClicking] = useState(false);

  const newTaskId = v4();
  const blankTask = {
    id: newTaskId,
    name: "",
    description: "",
    type: props.type,
    triggerType: "default",
    useCustomTrigger: false,
    properties: {
      dataMatching: [{}],
    },
    new: true,
  };

  const _handleAddToWorkflow = () => {
    if (props.type === "approval") {
      const approvedId = v4();
      const deniedId = v4();
      blankTask.properties.approvalActions = [];
      blankTask.properties.approvedTasksId = approvedId;
      blankTask.properties.deniedTasksId = deniedId;
    }

    dispatch(addWorkflowTask(blankTask));
    dispatch(toggleWorkflowPanel(false));
    dispatch(rHideRightSideBar(false));
  };

  return (
    <Paper
      className={classes.root}
      elevation={3}
      align="center"
      onClick={_handleAddToWorkflow}
      onMouseDown={() => setClicking(true)}
      onMouseUp={() => setClicking(false)}
      style={{ cursor: "pointer", opacity: clicking ? 0.8 : 1 }}
    >
      <Icon classes={{ root: classes.iconRoot }}>
        {props.icon}
        {!props.icon && (
          <img
            className={classes.imageIcon}
            src={props.icon}
            draggable={false}
            alt=""
          />
        )}
      </Icon>
      <Typography className={classes.tileText}>{props.title}</Typography>
    </Paper>
  );
}
