import React from "react";
import {
  ViewQuilt,
  Email,
  AssignmentTurnedIn,
  Storage,
} from "@material-ui/icons";

export const allElements = (
  catg,
  callback,
  isSearch = false,
  searchText = "",
  customElements = []
) => {
  const elements = {
    taskElements: [
      {
        id: `form-${new Date().getTime()}`,
        type: "form",
        title: "Form",
        icon: <ViewQuilt style={{ color: "#cccccc" }} />,
        img: "../../../../../../../images/layout.svg",
        pos: { x1: null, y1: null },
        default: true,
        active: false,
        style: {},
        label: {},
        text: {},
        // function: handleInvoiceTableClick,
      },
      {
        id: `mail-${new Date().getTime()}`,
        type: "mail",
        title: "Mail",
        icon: <Email style={{ color: "#cccccc" }} />,
        img: "../../../../../../../images/layout.svg",
        pos: { x1: null, y1: null },
        default: true,
        active: false,
        style: {},
        label: {},
        text: {},
        // function: handleInvoiceTableClick,
      },
      {
        id: `data-${new Date().getTime()}`,
        type: "data",
        title: "Data",
        icon: <Storage style={{ color: "#cccccc" }} />,
        img: "../../../../../../../images/layout.svg",
        pos: { x1: null, y1: null },
        default: true,
        active: false,
        style: {},
        label: {},
        text: {},
        // function: handleInvoiceTableClick,
      },
      {
        id: `approval-${new Date().getTime()}`,
        type: "approval",
        title: "Approval",
        icon: <AssignmentTurnedIn style={{ color: "#cccccc" }} />,
        img: "../../../../../../../images/layout.svg",
        pos: { x1: null, y1: null },
        default: true,
        active: false,
        style: {},
        label: {},
        text: {},
        // function: handleInvoiceTableClick,
      },
    ],
  };

  function extractCustomElements(ce) {
    const obj = [];
    ce.forEach((c, i) => {
      const id = `custom-${new Date().getTime()}-${i}`;
      obj.push({
        id: id,
        type: "custom",
        title: ce.name,
        icon: "../../../../../../../images/icons/dropDown.svg",
        pos: { x1: null, y1: null },
        default: true,
        active: false,
        style: {},
        label: {},
        text: {},
        customId: id,
        customName: ce.name,
      });
    });

    return obj;
  }

  if (isSearch) {
    const searchResults = [];
    Object.keys(elements).forEach((c) => {
      Object.keys(elements[c]).forEach((e) => {
        if (
          elements[c][e]?.type
            ?.toLowerCase()
            ?.includes(searchText.toLowerCase())
        )
          searchResults.push(elements[c][e]);
      });
    });

    return searchResults;
  }
  return elements[catg];
};
