export const FILE_UPLOAD_LIMIT = {
  maxSize: 3,
  maxSizeUnit: "MB",
  fileTypes: [
    {
      name: "CSV",
      mimeType: "text/csv",
    },
  ],
};

export const SCREEN_REUSE_ATTRIBUTES = {
  EDITABLE: "editable",
  READONLY: "readonly",
  HIDDEN: "hidden",
};

export const APP_DESIGN_MODES = {
  LIVE: "live",
  PREVIEW: "preview",
  EDIT: "edit",
};

export const PERMS_DETAILS = {
  User: "user",
  UserGroup: "userGroup",
};

export const filterBy = [
  ["none", "none"],
  ["SystemNotification", "System Notification"],
  ["ApprovalNotification", "Approval Notification"],
  ["read", "Read"],
  ["unread", "Unread"],
];

export const sortBy = [
  ["none", "none"],
  ["updatedAt", "Last modified"],
];

export const DEBOUNCE_TIME = 500;

export const multiChartNames = [
  "multiplelinegraph",
  "clusteredbarchart",
  "clusteredcolumnchart",
];
