import { FormGroup, makeStyles, Typography } from "@material-ui/core";
import { useState, useEffect } from "react";
import Required from "../Required";
import { checkboxContent } from "../../../../../../../utils/dynamicContentReplace";
import { v4 } from "uuid";

const CheckBox = ({
  style,
  values,
  onChange,
  name,
  dynamicData,
  screenId,
  appDesignMode,
  disabled,
  returnedLookupObj,
  readOnly,
  val,
  ...props
}) => {
  const [selections, setSelections] = useState(val || []);
  const [renderKey, setRenderKey] = useState(v4());
  const [trackLookupVal, setTrackLookupVal] = useState([]);
  const dynamicContentObj = dynamicData?.[screenId];

  useEffect(() => {
    if (returnedLookupObj?.[props?.id]) {
      setTrackLookupVal([...trackLookupVal, returnedLookupObj?.[props?.id]]);

      const sels_ = returnSelection(selections, returnedLookupObj?.[props?.id]);

      setSelections([...sels_]);
      onChange([...sels_], props.id);

      setRenderKey(v4());
    }
  }, [returnedLookupObj, props?.id]);

  useEffect(() => {
    if (val) {
      onChange([...val], props.id);
    }
  }, [val]);

  const returnSelection = (select_, value) => {
    if (select_.includes(value)) {
      select_.splice(select_.indexOf(value), 1);
    } else select_.push(value);
    return select_;
  };
  const _onChange = (e) => {
    // THIS APPROACH ASSUMES THAT ALL CHECK OPTIONS HAVE UNIQUE VALUES/TEXTS

    const value = e.target.value;
    const sels_ = returnSelection(selections, value);

    setSelections([...sels_]);
    onChange([...sels_], props.id);
    // const uniqueID = e.target.value;
    // const val = e.target.checked;
  };

  const checkboxStyle = makeStyles((theme) => style);
  const classes = checkboxStyle();
  return (
    <div className={classes?.container} style={{ width: style?.field?.width }}>
      {!values?.labelHide && (
        <Typography gutterBottom className={classes?.label}>
          {values?.label}
          <Required required={values?.required} />
        </Typography>
      )}
      <FormGroup className={`${readOnly ? "read-only" : ""}`}>
        {checkboxContent(
          {
            values,
            name,
            appDesignMode,
            disabled,
            readOnly,
            reuseValue: val,
            _onChange,
          },
          dynamicContentObj,
          trackLookupVal
        )}
      </FormGroup>
    </div>
  );
};

export default CheckBox;
