import {
  saveActiveScreen,
  setScreensList,
} from "../../../../../store/actions/properties";
import {
  createScreen,
  deleteScreen,
  duplicateScreen,
  updateScreen,
} from "../screenAPIs";
import { defaultStyles } from "../../UIEditor/utils/defaultScreenStyles";
import {
  SetAppStatus,
  manageAppLocalStorage,
  wrapAPI,
} from "../../../../../helpers/helperFunctions";
import { getAllScreens } from "./screenUtilities";

export default class ScreensPanelActions {
  preAPIs = async (dispatch, func, msg, ...args) => {
    dispatch(SetAppStatus({ type: "info", msg: "..." }));
    const resp = await func(...args);
    if (resp.success) {
      dispatch(SetAppStatus({ type: "info", msg: msg }));
    } else {
      dispatch(SetAppStatus({ type: "error", msg: `${resp.data}` }));
    }
    return resp;
  };

  handleDefaultName = ({ name, namesArray }) => {
    const existingName = namesArray.includes(name);
    return existingName;
  };

  handleNewScreen =
    ({ screenType, appId, namesArray: screens }) =>
    async (dispatch) => {
      // let count = 1;
      let count =
        screens.filter((screen) => screen.type === screenType).length + 1;
      let computedName = `${
        screenType === "app" ? "Screen" : "Document"
      } ${count}`;

      while (
        this.handleDefaultName({ name: computedName, namesArray: screens })
      )
        computedName = `${
          screenType === "app" ? "Screen" : "Document"
        } ${count++}`;
      console.log(`a------`);
      const { success, data } = await this.preAPIs(
        dispatch,
        createScreen,
        "Screen created",
        {
          name: computedName,
          app: appId,
          type: screenType,
          style: { ...defaultStyles(), page: defaultStyles(screenType).page },
        }
      );
      console.log(`b------`);

      if (success) {
        console.log(`c------`);
        // dispatch(getAllScreens(appId, true));
        dispatch(setScreensList(data?.data?.screens));
      }
    };

  handleDisplayOption = async ({
    id,
    index,
    setScreenId,
    setScreenIndex,
    nameref: scrname,
  }) => {
    // setShowOptions((prev) => !prev);
    setScreenId(id);
    setScreenIndex(index);
    let current = scrname.current.children[index].children[0].children[0];
    current.contentEditable = false;
    //console.log(`{id, index}`, { id, index });
  };

  handleRename({
    setShowOptions,
    nameref: scrname,
    entityIndex: screenIndex,
    dispatch,
  }) {
    dispatch(
      SetAppStatus({ type: "info", msg: "HIT [ENTER] AFTER TYPING..." })
    );
    setShowOptions(() => false);
    const renameTypography =
      scrname.current.children[screenIndex].children[0].children[0];

    renameTypography.onclick = (e) => e.stopPropagation();

    renameTypography.contentEditable = true;

    renameTypography.style.padding = "5px 4px";
    renameTypography.style.width = "100%";
    this.selectElementContents(renameTypography);
    setTimeout(function () {
      renameTypography.focus();
    }, 0);
  }

  selectElementContents(el) {
    var range = document.createRange();
    range.selectNodeContents(el);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }
  saveScreenNameChange =
    ({ id, event, index, nameref: scrname, namesArray }) =>
    async (dispatch, getState) => {
      event.persist();
      const { activeScreen, screensList } = getState().screens;
      const {
        selectedApp: { id: appId },
      } = getState().appsReducer;

      if (event.key === "Enter") {
        let current = scrname.current.children[index].children[0].children[0];
        let currentText = current.innerText;

        let existingName = namesArray.find((names) =>
          names.includes(currentText.trim())
        );

        if (existingName) {
          dispatch(SetAppStatus({ type: "error", msg: "Name already exist" }));
          return false;
        }
        current.style.border = "none";
        current.style.width = "";
        current.style.padding = "";
        current.contentEditable = false;
        const { success, data } = await this.preAPIs(
          dispatch,
          updateScreen,
          "Screen updated",
          { id, name: currentText }
        );

        if (success) {
          const newScreensList = screensList?.map((screen) => {
            if (id === screen.id) screen.name = currentText;
            return screen;
          });
          dispatch(setScreensList(newScreensList));
          if (id === activeScreen?.id) {
            const newActiveScreen = {
              ...activeScreen,
              name: currentText,
            };
            dispatch(saveActiveScreen(newActiveScreen));
            manageAppLocalStorage(
              "set",
              appId,
              "activeScreen",
              newActiveScreen
            );
          }
        }
      }
    };

  handleScreenDelete = async ({
    entityId: screenId,
    setHasUpdate,
    dispatch,
  }) => {
    const conf = window?.confirm("Delete this Screen?");
    if (!conf) return;

    const { success } = await dispatch(
      wrapAPI(deleteScreen, "Screen deleted", screenId)
    );

    if (success) {
      setHasUpdate(() => true);
    } else {
      setHasUpdate(() => false);
      dispatch(SetAppStatus({ type: "error", msg: "Error deleting screen." }));
    }
  };

  handleEditScreen = ({ setShowOptions, appId, history }) => {
    setShowOptions(() => false);
    return history.push(`/editor/${appId}/uieditor`);
  };

  handleDuplicateScreen =
    ({
      setShowOptions,
      // entity: screens,
      // entityId: screenId,
      entityIndex: screenIndex,
      setHasUpdate,
      namesArray,
    }) =>
    async (dispatch, getState) => {
      const { screensList } = getState().screens;
      setShowOptions(() => false);
      let count = 1;
      let names;

      console.log(
        `* * * > ${JSON.stringify(
          screensList.length
        )} > screenIndex > ${screenIndex}`
      );
      const screen = screensList?.find((value, idx) => idx === screenIndex);
      names = `${screen.name} ${count}`;
      while (this.handleDefaultName({ names, namesArray }))
        names = `${screen.name} ${count++}`;

      if (screen) {
        const newScreen = {
          screen: screen.id,
          name: names,
        };

        const { success } = await this.preAPIs(
          dispatch,
          duplicateScreen,
          "Screen duplicated",
          newScreen
        );
        if (success) return setHasUpdate(true);

        dispatch(
          SetAppStatus({ type: "error", msg: "Error duplicating screen." })
        );
      }
    };

  handleScreensFilter = ({ search, entities: screens }) => {
    return screens?.filter(({ name }) =>
      name.toLowerCase().includes(search.toLowerCase())
    );
  };

  async fetchAllAndSetDefaultScreen(_useDispatch, appId, refresh, history) {
    _useDispatch(getAllScreens(appId, refresh, history));
  }

  removeUpdateFlag = ({
    hasUpdate,
    timeout,
    setHasUpdate,
    _useDispatch,
    updateAction,
    // SetAppStatus,
    subscribe,
  }) => {
    if (hasUpdate === true) {
      timeout = setTimeout(() => {
        subscribe && setHasUpdate(false);
      }, 3000);
      /* subscribe &&
        _useDispatch(
          SetAppStatus({ type: "success", msg: `Screen ${updateAction || ""} completed` })
        ); */
    }
  };
}
