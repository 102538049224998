import PermissionsSidebar from "../PermissionsSidebar";
import { useEffect, useState } from "react";
import {
  compareByNameAndId,
  filterDuplicateObjects,
} from "../../../../Datasheets/utils";
import { successToastify } from "../../../../../common/Toastify";
import { useQueryClient } from "react-query";
import {
  updateResourcePermissions,
  removeResourcePermissions,
  groupResourcePermissions,
} from "../../../../../components/Query/DataSheets/datasheetQuery";
import useCustomMutation from "../../../../../common/CustomMutation";

const DashboardPerms = (props) => {
  const {
    dashboardName,
    handleCloseSidebar,
    showMe,
    setShowMe,
    refetch,
    permsResource,
    setPermsResource,
    usersLists,
    permissionToggle,
    dashboardId,
  } = props;

  return (
    <PermissionsSidebar
      componentName={dashboardName}
      showMe={showMe}
      setShowMe={setShowMe}
      handleClose={() => setShowMe(false)}
      component={"dashboard"}
      resourceType={"report"}
      queryType={"getDashboardPerms"}
      refetch={refetch}
      permissionToggle={permissionToggle}
      permsResource={permsResource}
      resourceId={dashboardId}
      setPermsResource={setPermsResource}
    />
  );
};
export default DashboardPerms;
