import { v4 } from "uuid";

export const allElements = (
  catg,
  callback,
  isSearch = false,
  searchText = "",
  customElements = []
) => {
  const elements = {
    advancedElements: [
      {
        id: `displayTable-${new Date().getTime()}`,
        name: "",
        type: "displayTable",
        title: "Display Table",
        icon: "../../../../../../../images/layout.svg",
        default: true,
        dataType: "none",
        active: false,
        style: {},
        label: {},
        text: {},
        values: {},
        children: [],
        // function: handleInvoiceTableClick,
      },
      {
        id: `inputTable-${new Date().getTime()}`,
        name: "",
        type: "inputTable",
        title: "Input Table",
        icon: "../../../../../../../images/layout.svg",
        default: true,
        dataType: "none",
        active: false,
        style: {},
        label: {},
        text: {},
        values: {},
        children: [],
        // function: handleInvoiceTableClick,
      },
      /* {
        id: `inputTable-${new Date().getTime()}`,
        name: "",
        type: "inputTable",
        title: "Input Table",
        icon: "../../../../../../../images/grid-row.svg",
        default: true,
        dataType: "none",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
        // function: handlePricingTableClick,
      }, */
    ],
    basicElements: [
      // {
      //     id: `video-${new Date().getTime()}`,
      //     name: "",
      //     type: "video",
      //     title: "Video",
      //     icon: "../../../../../../images/icons/play.svg",
      //     default: true,
      //     dataType: "none",
      //     active: false,
      //     style: {},
      //     label: {},
      //     text: {},
      //     values: [],
      //     children: [],
      // },
      {
        id: `image-${new Date().getTime()}`,
        name: "",
        type: "image",
        title: "Image",
        icon: "../../../../../../images/icons/image.svg",
        default: true,
        dataType: "none",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `text-${new Date().getTime()}`,
        name: "",
        type: "text",
        title: "Text",
        icon: "../../../../../../images/icons/type.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `heading-${new Date().getTime()}`,
        name: "",
        type: "heading",
        title: "Heading Text",
        icon: "../../../../../../../images/icons/label-text.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },

      {
        id: `pageBreak-${new Date().getTime()}`,
        name: "",
        type: "pageBreak",
        title: "Page Break",
        icon: "../../../../../../images/icons/cut.svg",
        default: true,
        dataType: "none",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `form-${new Date().getTime()}`,
        name: "",
        type: "form",
        title: "Form",
        icon: "../../../../../../images/icons/bar-chart.svg",
        default: true,
        dataType: "submit",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
    ],

    buttonElements: [
      /* [`submit-${new Date().getTime()}`]: {
                name: "",
                type: "submit",
                title: "Submit",
                icon: "../../../../../../../images/icons/buttons.svg",
                default: true,
                dataType: "", 
                active: false,
                style: {},
                label: {},
                text: {},
                values:[],
                children: [],
            }, */
      {
        id: `actionButton-${new Date().getTime()}`,
        name: "",
        type: "actionButton",
        title: "Action Button",
        icon: "../../../../../../../images/icons/buttons.svg",
        default: true,
        dataType: "button",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `decisionButton-${new Date().getTime()}`,
        name: "",
        type: "decisionButton",
        title: "Decision Button",
        icon: "../../../../../../../images/icons/branch.svg",
        default: true,
        dataType: "button",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
    ],

    inputElements: [
      {
        id: `inputText-${new Date().getTime()}`,
        name: "",
        type: "inputText",
        title: "Input Text",
        icon: "../../../../../../../images/icons/type.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `textArea-${new Date().getTime()}`,
        name: "",
        type: "textArea",
        title: "Text Area",
        icon: "../../../../../../../images/icons/text-field.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      /* {
        id: `heading-${new Date().getTime()}`,
        name: "",
        type: "heading",
        title: "Label Text",
        icon: "../../../../../../../images/icons/label-text.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      }, */

      {
        id: `fileUpload-${new Date().getTime()}`,
        name: "",
        type: "fileUpload",
        title: "File Upload",
        icon: "../../../../../../images/icons/file-upload.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `phoneNumber-${new Date().getTime()}`,
        name: "",
        type: "phoneNumber",
        title: "Phone Number",
        icon: "../../../../../../../images/icons/mobile.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      // {
      //   id: `currency-${new Date().getTime()}`,
      //   name: "",
      //   type: "currency",
      //   title: "Currency",
      //   icon: "../../../../../../../images/icons/naira.svg",
      //   default: true,
      //   dataType: "text",
      //   active: false,
      //   style: {},
      //   label: {},
      //   text: {},
      //   values: [],
      //   children: [],
      // },
      {
        id: `dateTime-${new Date().getTime()}`,
        name: "",
        type: "dateTime",
        title: "Date Time",
        icon: "../../../../../../../images/icons/calendar.svg",
        default: true,
        dataType: "datetime",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      // {
      //     id: `dual-${new Date().getTime()}`,
      // name: "",
      //     type: "dual",
      //     title: "Dual",
      //     icon: "../../../../../../../images/icons/dual-text.svg",
      //     default: true,
      // dataType: "none",
      //     active: false,
      //     style: {},
      //     label: {},
      //     text: {},
      // values: [],
      // children: [],
      // },

      // {
      //     id: `address-${new Date().getTime()}`,
      //     name: "",
      //     type: "address",
      //     title: "Address",
      //     icon: "../../../../../../../images/icons/address.svg",
      //     default: true,
      //     dataType: "none",
      //     active: false,
      //     style: {},
      //     label: {},
      //     text: {},
      //     values: [],
      //     children: [],
      // },
      {
        id: `signature-${new Date().getTime()}`,
        name: "",
        type: "signature",
        title: "Signature",
        icon: "../../../../../../images/icons/edit.svg",
        default: true,
        dataType: "button",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `userPicker-${new Date().getTime()}`,
        name: "",
        type: "userPicker",
        title: "User Picker",
        icon: "../../../../../../images/icons/edit.svg",
        default: true,
        dataType: "user",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      // number: {
      //   title: "Number",
      //   icon: "../../../../../../../images/icons/number.svg",
      //   counter: {[`number-${new Date().getTime()}`] : { default: true,
      // dataType: "", active: false }},
      // name: "",
      // },

      // name: {
      //   title: "Name",
      //   icon: "../../../../../../../images/icons/user.svg",
      //   counter: {[`name-${new Date().getTime()}`] : { default: true,
      // dataType: "", active: false }},
      // name: "",
      // },
      // password: {
      //   title: "Password",
      //   icon: "../../../../../../../images/icons/lock.svg",
      //   counter: {[`password-${new Date().getTime()}`] : { default: true,
      // dataType: "", active: false }},
      // name: "",
      // },
    ],

    selectionElements: [
      {
        id: `dropdown-${new Date().getTime()}`,
        name: "",
        type: "dropdown",
        title: "Select Dropdown",
        icon: "../../../../../../../images/icons/dropDown.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `checkbox-${new Date().getTime()}`,
        name: "",
        title: "Checkbox",
        type: "checkbox",
        icon: "../../../../../../../images/icons/checkmark.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      // {
      //     id: `radio-${new Date().getTime()}`,
      //     name: "",
      //     title: "Radio",
      //     type: "radio",
      //     icon: "../../../../../../../images/icons/radio.svg",
      //     function: callback,
      //     default: true,
      //     dataType: "none",
      //     active: false,
      //     style: {},
      //     label: {},
      //     text: {},
      //     values: [],
      //     children: [],
      // },
      // {
      //     id: `toggle-${new Date().getTime()}`,
      //     name: "",
      //     title: "Toggle",
      //     type: "toggle",
      //     icon: "../../../../../../../images/icons/toggle.svg",
      //     function: callback,
      //     default: true,
      //     dataType: "none",
      //     active: false,
      //     style: {},
      //     label: {},
      //     text: {},
      //     values: [],
      //     children: [],
      // },
      // {
      //     id: `slider-${new Date().getTime()}`,
      //     name: "",
      //     title: "Slider",
      //     type: "slider",
      //     icon: "../../../../../../../images/icons/options.svg",
      //     function: callback,
      //     default: true,
      //     dataType: "none",
      //     active: false,
      //     style: {},
      //     label: {},
      //     text: {},
      //     values: [],
      //     children: [],
      // },
      // [`quantity-${new Date().getTime()}`]: {
      // name: "",
      //   title: "Quantity",
      //   type: "quantity",
      //   icon: "../../../../../../../images/icons/toggle.svg",
      //   function: handleClick,
      //   default: true,
      // dataType: "none",
      //   active: false,
      //   style: {},
      //   label: {},
      //   text: {},
      // values: [],
      // children: [],
      // },
    ],
    navigationElements: [
      {
        id: `header-${new Date().getTime()}`,
        name: "",
        type: "header",
        title: "Header",
        icon: "../../../../../../images/icons/header.svg",
        default: true,
        dataType: "none",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
    ],
    // customElements: extractCustomElements(customElements),
    searchedElements: [
      {
        id: `text-${new Date().getTime()}`,
        name: "",
        type: "text",
        title: "Text",
        icon: "../../../../../../images/icons/type.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `pageBreak-${new Date().getTime()}`,
        name: "",
        type: "pageBreak",
        title: "Page Break",
        icon: "../../../../../../images/icons/cut.svg",
        default: true,
        dataType: "none",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `form-${new Date().getTime()}`,
        name: "",
        type: "form",
        title: "Form",
        icon: "../../../../../../images/icons/bar-chart.svg",
        default: true,
        dataType: "submit",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `actionButton-${new Date().getTime()}`,
        name: "",
        type: "actionButton",
        title: "Action Button",
        icon: "../../../../../../../images/icons/buttons.svg",
        default: true,
        dataType: "button",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `decisionButton-${new Date().getTime()}`,
        name: "",
        type: "decisionButton",
        title: "Decision Button",
        icon: "../../../../../../../images/icons/branch.svg",
        default: true,
        dataType: "button",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `inputText-${new Date().getTime()}`,
        name: "",
        type: "inputText",
        title: "Input Text",
        icon: "../../../../../../../images/icons/type.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `userPicker-${new Date().getTime()}`,
        name: "",
        type: "userPicker",
        title: "User Picker",
        icon: "../../../../../../../images/icons/type.svg",
        default: true,
        dataType: "user",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `textArea-${new Date().getTime()}`,
        name: "",
        type: "textArea",
        title: "Text Area",
        icon: "../../../../../../../images/icons/text-field.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `heading-${new Date().getTime()}`,
        name: "",
        type: "heading",
        title: "Heading Text",
        icon: "../../../../../../../images/icons/label-text.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },

      {
        id: `fileUpload-${new Date().getTime()}`,
        name: "",
        type: "fileUpload",
        title: "File Upload",
        icon: "../../../../../../images/icons/file-upload.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `phoneNumber-${new Date().getTime()}`,
        name: "",
        type: "phoneNumber",
        title: "Phone Number",
        icon: "../../../../../../../images/icons/mobile.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `currency-${new Date().getTime()}`,
        name: "",
        type: "currency",
        title: "Currency",
        icon: "../../../../../../../images/icons/naira.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `dateTime-${new Date().getTime()}`,
        name: "",
        type: "dateTime",
        title: "Date Time",
        icon: "../../../../../../../images/icons/calendar.svg",
        default: true,
        dataType: "datetime",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `signature-${new Date().getTime()}`,
        name: "",
        type: "signature",
        title: "Signature",
        icon: "../../../../../../images/icons/edit.svg",
        default: true,
        dataType: "button",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
      {
        id: `header-${new Date().getTime()}`,
        name: "",
        type: "header",
        title: "Header",
        icon: "../../../../../../images/icons/header.svg",
        default: true,
        dataType: "none",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      },
    ],
  };

  function extractCustomElements(ce) {
    const obj = [];
    ce.forEach((c, i) => {
      const id = `custom-${new Date().getTime()}-${i}`;
      obj.push({
        id: id,
        name: "",
        type: "custom",
        title: ce.name,
        icon: "../../../../../../../images/icons/dropDown.svg",
        default: true,
        dataType: "none",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
        customId: id,
        customName: ce.name,
      });
    });

    return obj;
  }

  if (isSearch) {
    const searchResults = [];
    Object.keys(elements)
      .filter((element) => element !== "searchedElements")
      .forEach((c) => {
        Object.keys(elements[c]).forEach((e) => {
          if (
            elements[c][e]?.type
              ?.toLowerCase()
              .includes(searchText?.toLowerCase())
          )
            searchResults.push(elements[c][e]);
        });
      });

    return searchResults;
  }
  return elements[catg];
};
