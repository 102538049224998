import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { googleLogin } from "../../store/actions";
import { connect } from "react-redux";
import { useGoogleLogin } from "react-google-login";
import { refreshTokenSetUp } from "../refreshGoogleLoginToken";
import useCustomMutation from "../../common/CustomMutation";
import { socialAuthSignIn } from "../../components/Mutation/Registration/registrationMutation";
import { useHistory } from "react-router-dom";
import { errorToastify } from "../../common/Toastify";
import { mainNavigationUrls } from "../lists";

const GoogleLogin = ({ classes, ...props }) => {
  const { dispatch } = props;
  const btnMessage = props.btnMessage;

  const history = useHistory();

  const onLoginSuccess = ({ data }) => {
    localStorage.setItem("userId", data?.data?.id);
    localStorage.setItem("accountId", data?.data?.account?.id);
    localStorage.setItem("userInfo", JSON.stringify(data?.data));
    localStorage.setItem("accessToken", data?._meta?.accessToken);

    dispatch(googleLogin(data));
    history.push(mainNavigationUrls.APPS);
  };

  const { mutate: googleSignInMutate } = useCustomMutation({
    apiFunc: socialAuthSignIn,
    onSuccess: onLoginSuccess,
    retries: 0,
  });

  const onSuccess = async (res) => {
    const socialAuthId = res?.profileObj?.googleId;
    const accessToken = res?.tokenId;
    const socialAuthType = "google";
    const email = res?.profileObj?.email;

    // localStorage.setItem("userId", socialAuthId);
    localStorage.setItem("status", "old");

    await refreshTokenSetUp({
      res,
      googleSignInMutate,
      dispatch,
      googleLogin,
      data: {
        socialAuthId,
        accessToken,
        email,
        socialAuthType,
      },
    });
  };

  const onFailure = (res) => {
    let response =
      res?.error === "idpiframe_initialization_failed"
        ? null
        : res?.error?.details;
    errorToastify(response);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId: process.env.REACT_APP_GOOGLE_CLIENTID,
    isSignedIn: false,
    accessType: "offline",
    cookiePolicy: "single_host_origin",
  });

  return (
    <>
      <Button
        className={classes.signUpButton}
        style={{ backgroundColor: "#ffffff", color: "#464D72" }}
        size="large"
        type="submit"
        variant="outlined"
        onClick={signIn}
      >
        <img alt="G" className={classes.googleIcon} src="/images/google.png" />
        {btnMessage}
      </Button>
    </>
  );
};

GoogleLogin.propTypes = {
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    error: state.auth.error,
    isAuthenticated: state.auth.isAuthenticated,
  };
}
export default connect(mapStateToProps)(GoogleLogin);
