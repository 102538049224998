import { errorToastify } from "../../../../../common/Toastify";
import {
  manageAppLocalStorage,
  wrapAPI,
} from "../../../../../helpers/helperFunctions";
import {
  saveActiveScreen,
  setScreensList,
} from "../../../../../store/actions/properties";
import { getScreens } from "../screenAPIs";

export const getAllScreens =
  (appId, refresh, history) => async (dispatch, getState) => {
    const { screensList, activeScreen } = getState().screens;
    let returnScreensList = screensList;

    if (!screensList?.length || refresh) {
      //  get screens from backend
      const { success, data } = await dispatch(
        wrapAPI(getScreens, "Screens loaded", appId)
      );

      if (success) {
        returnScreensList = data?.data;
        //  load data into state
        dispatch(setScreensList(returnScreensList));
      } else {
        // errorToastify("Invalid URL. Kindly check and try again.");
        // history && history.push("/apps");
      }

      if (
        !activeScreen ||
        !returnScreensList.find((screen) => screen.id === activeScreen?.id)
      ) {
        const { id, name, slug, type } = returnScreensList?.[0] || {};
        dispatch(saveActiveScreen({ id, name, slug, type }));
        manageAppLocalStorage("set", appId, "activeScreen", {
          id,
          name,
          slug,
          type,
        });
      }
    }
    return returnScreensList || [];
  };
