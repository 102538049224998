import {
  createWorkflowAPI,
  updateWorkflowAPI,
  deleteWorkflowAPI,
  duplicateWorkflowAPI,
} from "../utils/workflowAPIs";
import {
  rSetupBackDrop,
  rHideRightSideBar,
  rSetActiveWorkflow,
} from "../../../../../store/actions/properties";
import {
  getAllWorkflowIntegrations,
  getAllWorkflows,
  setupActiveWorkflow,
} from "./workflowHelpers";
import { SetAppStatus } from "../../../../../helpers/helperFunctions";

// const explicitly = loadPreviewWorkflow;

export default class WorkflowPanelActions {
  preAPIs = async (dispatch, func, msg, ...args) => {
    dispatch(SetAppStatus({ type: "info", msg: "..." }));
    const resp = await func(...args);
    if (!!resp?._meta?.success) {
      dispatch(SetAppStatus({ type: "info", msg: msg }));
      return resp;
    } else {
      dispatch(
        SetAppStatus({
          type: "error",
          msg:
            resp?._meta?.message ||
            "An error occured. Check your connection and try again.", //resp.message,
        })
      );
    }
  };

  handleDefaultName = ({ names, namesArray }) => {
    const existingName = namesArray.includes(names);
    return existingName;
  };

  handleNewWorkflow =
    ({ setHasUpdate, namesArray }) =>
    async (dispatch, getState) => {
      let count = 1;
      const {
        selectedApp: { id: appId },
      } = getState().appsReducer;

      let name = `Workflow ${count}`;
      while (this.handleDefaultName({ name, namesArray }))
        name = `Workflow ${count++}`;

      const newWorkflow = await this.preAPIs(
        dispatch,
        createWorkflowAPI,
        "Workflow created",
        {
          app: appId,
          name,
        }
      );

      if (newWorkflow) setHasUpdate(() => true);
    };

  handleDisplayOption = async ({
    id,
    index,
    setWorkflowId,
    setWorkflowIndex,
    nameref: wfwname,
  }) => {
    // setShowOptions((prev) => !prev);
    setWorkflowId(id);
    setWorkflowIndex(index);
    let current = wfwname.current.children[index].children[0].children[0];
    current.contentEditable = false;
  };

  handleRename({ setShowOptions, nameref: scrname, entityIndex: screenIndex }) {
    setShowOptions(() => false);
    // alert(scrname)
    const renameTypography =
      scrname.current.children[screenIndex].children[0].children[0];

    renameTypography.onclick = (e) => e.stopPropagation();

    renameTypography.contentEditable = true;

    renameTypography.style.padding = "1px 4px";
    // renameTypography.focus();
    this.selectElementContents(renameTypography);
    setTimeout(function () {
      renameTypography.focus();
    }, 0);
  }

  selectElementContents(el) {
    var range = document.createRange();
    range.selectNodeContents(el);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }

  // var el = document.getElementById("foo");
  saveWorkflowNameChange = async ({
    id: workflowId,
    event,
    index,
    nameref: wfwname,
    setHasUpdate,
    namesArray,
    dispatch,
    // setStatus,
  }) => {
    event.persist();

    if (event.key === "Enter") {
      let current = wfwname.current.children[index].children[0].children[0];
      let currentText = current.innerText;

      let existingName = namesArray.filter((names) =>
        names.includes(currentText.trim())
      );

      if (existingName.length > 0) {
        dispatch(SetAppStatus({ type: "error", msg: "Name already exist" }));
        return false;
      }
      current.style.border = "none";
      current.contentEditable = false;

      const renamedWorkflow = await this.preAPIs(
        dispatch,
        updateWorkflowAPI,
        "Workflow updated",
        {
          id: workflowId,
          name: currentText,
        }
      );

      if (renamedWorkflow) setHasUpdate(() => true);
    }
  };

  handleWorkflowDelete = async ({
    entityId: workflowId,
    setShowOptions,
    entityIndex: workflowIndex,
    entity: workflows,
    setEntity: setWorkflows,
    setHasUpdate,
    dispatch,
  }) => {
    const deletedWorkflow = await this.preAPIs(
      dispatch,
      deleteWorkflowAPI,
      "Workflow deleted",
      {
        workflowId,
      }
    );

    if (deletedWorkflow) {
      const wfs = workflows.filter((wf) => wf.id !== workflowId);
      setWorkflows(wfs);

      setHasUpdate(() => true);
      setShowOptions(() => false);
    }
  };

  handleDuplicateWorkflow = async ({
    setShowOptions,
    entity: workflows,
    entityIndex: workflowIndex,
    setHasUpdate,
    dispatch,
  }) => {
    setShowOptions(() => false);
    let count = 1;

    const workflow = workflows && workflows[workflowIndex];
    const duplicatedWorkflow = await this.preAPIs(
      dispatch,
      duplicateWorkflowAPI,
      "Workflow duplicated",
      {
        workflow: workflow.id,
      }
    );

    if (duplicatedWorkflow) setHasUpdate(() => true);
  };

  handleWorkflowsFilter = ({ search, entities: workflows }) =>
    workflows?.filter(({ name }) =>
      name.toLowerCase().includes(search.toLowerCase())
    );

  handleEditWorkflow = ({ e, id, name, dispatch }) => {
    e.stopPropagation();

    dispatch(setupActiveWorkflow(id));
    dispatch(rHideRightSideBar(true));
    dispatch(
      rSetupBackDrop({
        show: false,
        clickToHideBackdrop: true,
      })
    );

    dispatch(rSetActiveWorkflow({ id, name }));
  };

  async fetchAllAndSetDefaultWorkflow(_useDispatch, appId, refresh, history) {
    try {
      // _useDispatch(getAllWorkflows(appId, refresh, history)); // this might still be required
      //  next: get all integrations
      _useDispatch(getAllWorkflowIntegrations(false));
    } catch (err) {
      // console.log(`E E E R R O R`);
    }
  }

  removeUpdateFlag = ({
    hasUpdate,
    timeout,
    setHasUpdate,
    _useDispatch,
    updateAction,
    // SetAppStatus,
    subscribe,
  }) => {
    if (hasUpdate === true) {
      timeout = setTimeout(() => {
        subscribe && setHasUpdate(false);
      }, 3000);
    }
  };
}
