import { useParams, useLocation, useHistory } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "./LoadingScreen";
import Error from "./Error";
import { makeStyles } from "@material-ui/core/styles";
import {
  getLiveData,
  getLiveScreen,
  runCurrentTask,
  setRunFirstScreen,
  uploadFile,
} from "../../helpers/LiveData";
import SignatureModal from "./SignatureModal";
import { jsPDF } from "jspdf";
import { Box, Button } from "@material-ui/core";
import PageWrapper from "../EditorLayout/Pages/UIEditor/components/PageWrapper";
import RealComponents from "../EditorLayout/Pages/UIEditor/components/ElementsComponent";
import ScreenButtons from "../EditorLayout/Pages/UIEditor/components/actualObjects/ScreenButtons";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { defaultStyles } from "../EditorLayout/Pages/UIEditor/utils/defaultScreenStyles";
import { APP_RUN_MAX_WIDTH } from "../EditorLayout/Pages/Workflow/components/utils/constants";
import {
  APP_DESIGN_MODES,
  SCREEN_REUSE_ATTRIBUTES,
} from "../../utils/constants";
import { WORKFLOWS_TASK_SCREEN } from "../EditorLayout/Pages/Workflow/components/utils/taskTypes";
import { manageLogicalOperators } from "./comparison";
import { CustomAxios } from "../../common/CustomAxios";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const myStyle = {
  root: {
    display: "flex",
    backgroundColor: "#F4F6F8",
    minHeight: "100vh",

    flexDirection: "column",
    alignItems: "center",
  },
  app: {
    margin: "10px auto 72px auto",
    border: "solid 0.5px #e8e8e8",
    borderRadius: 4,
    // padding: 5,
    // height: "100%",

    backgroundSize: "25px 25px",
    // backgroundColor: "#F4F6F8",
    // backgroundColor: "#FAFBFC",
    minHeight: "calc(100vh - 128px)",

    width: "100%",
    maxWidth: APP_RUN_MAX_WIDTH,
    minWidth: APP_RUN_MAX_WIDTH,
    // position: "absolute",
  },
  drop: {
    paddingBottom: 0,
    // marginBottom: 50,
  },
  container: {
    // margingTop: 50,
    // paddingBottom: 76,
    position: "relative",
    padding: 15,
    backgroundColor: "transparent",
  },
  documentBtn: {
    minWidth: "8rem",
    height: "36px",
    textTransform: "none",
    //marginRight: "4px",
    fontSize: "0.9rem",
    borderRadius: "0.5rem",
  },
};
const useStyles = makeStyles((theme) => ({
  ...myStyle,
  /* dropZone: {
    width: "100%",
    height: "inherit",
    minHeight: "100vh",
    background: "#FCFCFC",
    borderRadius: 5,
    padding: "0 10px",
    position: "relative",
  },
  container: {
    height: "inherit",
    display: "flex",
    justifyContent: "space-between",
  }, */
}));

const DEFAULT_LOOKUP_RETURN_TYPE = "list";

const DisplayScreen = () => {
  const SIGNATURE_FILE_NAME = "my_signature.png";
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const query = new URLSearchParams(useLocation().search);
  const { appSlug, screenSlug, accountSlug } = useParams();
  const [capturedData, setCapturedData] = useState({});
  const [noBack, setNoBack] = useState(false);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [signatureComponentID, setSignatureComponentID] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [uploadPDF, setUploadPDF] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [containerRef, setContainerRef] = useState(null);
  const [returnedLookup, setReturnedLookup] = useState(null);
  const [returnedLookupObj, setReturnedLookupObj] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [numPages, setNumPages] = useState(1);
  const {
    screen,
    runFirstScreen,
    screenLoading,
    screenError,
    error,
    workflowInstance,
    taskRunning,
    task,
    app,
    filesUploaded,
  } = useSelector(({ liveData }) => liveData);
  const { style: screenStyles } = screen; //useSelector(({ uieditor }) => uieditor);

  const taskId = query.get("taskId");
  const workflowInstanceId = query.get("workflowInstanceId");
  const isApproval = query.get("isApproval");
  const appId = query.get("appId");

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);

      const resp = await dispatch(
        getLiveData({
          appSlug,
          queryParam: `taskId=${taskId}&workflowInstanceId=${workflowInstanceId}`,
          relaunch: true,
          accountSlug,
        })
      );
      setIsFetching(false);

      if (resp?.type === "GET_LIVE_DATA_ERROR") {
        return;
      }

      //  checks state for first screen
      if (resp?.payload?.task?.type === WORKFLOWS_TASK_SCREEN) {
        dispatch(setRunFirstScreen(resp.payload.screensInfo.id));
      }
      if (!runFirstScreen) {
        setNoBack(true);
      } else if (resp.payload?.screensInfo?.id !== runFirstScreen) {
        setNoBack(false);
      }

      if (!isApproval) {
        dispatch(getLiveScreen(screenSlug, appId, accountSlug));
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSlug, taskId, dispatch]);

  useEffect(() => {
    if (screenLoading) {
      setLoadingMessage("Loading screen...");
    } else if (taskRunning) {
      setLoadingMessage("Processing...");
    } else if (isFetching) {
      setLoadingMessage("Setting environment...");
    } else {
      setLoadingMessage("");
    }
  }, [screenLoading, taskRunning, isFetching]);
  // }
  const goBack = () => {
    history.goBack();
  };

  const goNext = () => {
    const prepareData = {
      taskId,

      workflowInstanceId,
      data: capturedData || {},
    };
    dispatch(runCurrentTask({ payload: prepareData, history, accountSlug }));
  };

  const onFormSubmit = (e) => {
    e?.preventDefault();
    const prepareData = {
      taskId,
      workflowInstanceId,
      data: capturedData,
    };
    dispatch(runCurrentTask({ payload: prepareData, history, accountSlug }));
    setCapturedData({});
  };

  const captureData = (value, input, isGrouped = false) => {
    const isLookupTrigger = task?.properties?.lookupContents?.some(
      (contentObj) => contentObj?.lookupField === input
    );
    if (isLookupTrigger) {
      setReturnedLookup(async () => {
        if (input.toLowerCase().includes("checkbox")) {
          value = value?.[value?.length - 1];
        }
        if (input.toLowerCase().includes("userpicker")) {
          value = value?.[value?.length - 1]?.name;
        }
        const response = await CustomAxios().get(
          `${process.env.REACT_APP_ENDPOINT}/tasks/run/lookup?taskId=${task.id}&workflowInstanceId=${workflowInstance.id}&returnedDataType=${DEFAULT_LOOKUP_RETURN_TYPE}&lookupFieldId=${input}&lookupFieldValue=${value}`
        );
        return response;
      });
    }
    // dateTime has two different data source, reason for this check
    if (input?.includes("dateTime-")) {
      // this checks if time is selected and date was in state already
      if (value === capturedData[input]) {
        //  do nothing
      } else if (value?.includes(":") && capturedData[input]?.includes("-")) {
        // This checks if the selected value already has time selected
        if (capturedData?.[input]?.includes("T")) {
          return setCapturedData((prev) => ({
            ...prev,
            [input]: `${capturedData?.[input]?.replace(/T.*$/, `T${value}`)}`,
          }));
        }
        setCapturedData((prev) => ({
          ...prev,
          [input]: `${capturedData?.[input]}T${value}`,
        }));
      }
      // this checks if date is selected and time was in state already
      else if (value?.includes("-") && capturedData[input]?.includes(":")) {
        // This checks if the selected value already has date selected
        if (capturedData[input]?.includes("T")) {
          return setCapturedData((prev) => ({
            ...prev,
            [input]: `${value}${capturedData?.[input]?.replace(/[^T]*/, ``)}`,
          }));
        }

        setCapturedData((prev) => ({
          ...prev,
          [input]: `${value}T${capturedData?.[input]}`,
        }));
      }
      // this checks when nothing has been selected
      else {
        setCapturedData((prev) => ({ ...prev, [input]: value }));
      }
      return;
    }
    setCapturedData((prev) => ({
      ...prev,
      ...(isGrouped ? { ...value } : { [input]: value }),
    }));
  };

  useEffect(() => {
    const logger = async () => {
      const gottenVal = await returnedLookup;

      setReturnedLookupObj(gottenVal?.data?.data);
    };
    logger();
  }, [returnedLookup]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const processPDF = (content, type) => {
    const pdfID = `${Math.floor(Date.now() * Math.random())}PDF`;
    const bodyFormData = new FormData();
    const getPageSize = (node) => {
      const { width, height } = node.getBoundingClientRect();
      return {
        width: width * 2, // Convert px to pt (1px = 0.75pt)
        height: height * 2,
      };
    };
    const { width: contentWidth, height: contentHeight } = getPageSize(content);
    const pdfWidth = Math.max(contentWidth, 900.89);
    const pdfHeight = Math.max(contentHeight, 920.28);

    const doc = new jsPDF("l", "pt", [pdfWidth, pdfHeight]);
    //This document.scrollWidth does not work, but trying a
    // combination of the pdfWidth and hardcoding the windowWidth
    // works quite well.
    const screenWidth = document.documentElement.scrollWidth;
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();
    const properties = {
      pagesplit: true,
      width: width * 2,
      height: height * 2,
      windowWidth: 700,
      //windowWidth: 600,
      //windowWidth: screenWidth,
      margin: [40, 60, 40, 20],
      html2canvas: { scale: 1.2, unit: "pt", dpi: 300, useCORS: true },
    };
    const preview = () =>
      doc.html(content, {
        callback: async function (doc) {
          // output the document as a PDF with name of NewPdf
          const base64 = await doc.output("datauristring");
          setPdfUrl(base64);
        },
        ...properties,
      });
    const download = () =>
      doc.html(content, {
        callback: async function (doc) {
          // output the document as a PDF with name of NewPdf
          await doc.save("NewPdf");
        },
        ...properties,
      });
    const upload = () =>
      doc.html(content, {
        callback: async function (doc) {
          // output the document as a PDF with name of NewPdf.
          const results = await doc.output("blob");
          const type = results.type;
          const resultsFile = await new File([results], `NewPdf"${pdfID}`, {
            lastModified: new Date().getTime(),
            type,
          });
          bodyFormData.append("file", resultsFile);
          bodyFormData.append("taskId", taskId);
          bodyFormData.append("fileType", "pdf");
          bodyFormData.append("workflowInstanceId", workflowInstanceId);
          !filesUploaded.componentId &&
            taskId &&
            (await dispatch(uploadFile(pdfID, bodyFormData, null)));
        },
        ...properties,
      });
    if (type === "preview") {
      preview();
      uploadPDF && upload();
    } else if (type === "download") {
      download();
      uploadPDF && upload();
    }
  };
  const pdfContainerRef = useCallback((node) => {
    node && processPDF(node, "preview");
    node && setContainerRef(node);
    setUploadPDF(false);
  }, []);

  const determineStyle = (item) => {
    return {
      ...screen?.style,
      ...(item?.style?.overrideDefault ? item.style : {}),
    };
  };

  const getScreenReuseAttributes = () => {
    let fieldsAttributes = task?.properties?.screenReuse?.fieldsAttributes?.[0];

    if (!fieldsAttributes) return {};
    const elementIds = Object.keys(fieldsAttributes);

    const screenId = task.properties?.screen;
    const screenTaskIdsReuseOrder = workflowInstance.screenReuse?.[
      screenId
    ]?.reuseOrder?.map((reuse) => reuse.taskId);

    elementIds.forEach((elementId) => {
      for (let iter = 0; iter < screenTaskIdsReuseOrder?.length; iter++) {
        const taskId = screenTaskIdsReuseOrder[iter];

        const variableId = task.variables?.find(
          (variable) =>
            variable.matching?.valueSourceId === taskId &&
            variable.matching?.valueSourceInput === elementId
        )?.id;

        const fieldValue = workflowInstance.metadata?.[variableId];

        if (![null, undefined].includes(typeof fieldValue)) {
          fieldsAttributes = {
            ...fieldsAttributes,
            [elementId]: {
              ...fieldsAttributes?.[elementId],
              value: fieldValue,
            },
          };
          break;
        }
      }
    });

    return fieldsAttributes;
  };

  const screenReuseAttributes = getScreenReuseAttributes();

  return (
    <PageWrapper
      margin="54px auto 70px auto"
      minHeight="100vh"
      zoomLevel={100}
      myStyle={myStyle}
      screenStyles={screen.style}
    >
      <LoadingScreen
        loading={screenLoading || taskRunning || isFetching}
        message={loadingMessage}
      />
      {/* <ErrorRunningApp />
            <img src="../../assets/disconnected_app.svg" alt="Plug" /> */}
      {screen.type !== "document" && !loadingMessage && (
        <form onSubmit={onFormSubmit}>
          {screen.items?.map((item, index) => {
            if (
              manageLogicalOperators(
                item?.values?.conditionalSymbol,
                capturedData?.[item?.values?.conditionalElement],
                item?.values?.conditionalValue,
                item?.values?.conditionals,
                item?.type
              )
            )
              return (
                <div
                  key={item.id}
                  style={{
                    marginBottom: determineStyle(item)?.page?.lineSpacing,
                  }}
                >
                  <RealComponents
                    onChange={captureData}
                    onFormSubmit={onFormSubmit}
                    capturedData={capturedData}
                    metadata={workflowInstance.metadata}
                    // uploadFileChange={onUploadFile}
                    uploadFile={uploadFile}
                    dynamicData={workflowInstance?.dynamicContents}
                    dispatch={dispatch}
                    appSequence={screen?.screenSequence}
                    id={item.id}
                    screenId={item.screen}
                    taskId={task.id}
                    workflowInstanceId={workflowInstance.id}
                    name={item.name}
                    disabled={!!isApproval}
                    type={item.type}
                    returnedLookup={returnedLookup}
                    returnedLookupObj={returnedLookupObj}
                    returnedLookupObjValue={returnedLookupObj?.[item.id]}
                    parent={item.parent}
                    values={item.values}
                    children={item.children}
                    containerIndex={index}
                    style={determineStyle(item)}
                    screenStyles={screenStyles}
                    screenReuseAttributes={screenReuseAttributes}
                    {...(screenReuseAttributes?.[item.name]
                      ? {
                          readOnly:
                            screenReuseAttributes?.[item.name]?.attribute ===
                            SCREEN_REUSE_ATTRIBUTES.READONLY,
                        }
                      : {})}
                    customId={item.customId || null}
                    customName={item.customName || null}
                    appDesignMode={APP_DESIGN_MODES.LIVE}
                    setShowSignatureModal={setShowSignatureModal}
                    setSignatureComponentID={setSignatureComponentID}
                    hideScreenButton1={noBack}
                    screenButton1={goBack}
                    screenButton2={goNext}
                  />
                </div>
              );
          })}
        </form>
      )}
      {screen.type === "document" && !loadingMessage && (
        <div style={{ position: "relative" }}>
          <div>
            <Document
              style={{ height: "100vh" }}
              file={pdfUrl}
              loading={"Loading...."}
              noData={"Loading...."}
              onLoadSuccess={onDocumentLoadSuccess}
              //onLoadError={onLoadError}
              //onSourceError={onSourceError}
              height={"100"}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            >
              <Page pageNumber={currentPage} />
            </Document>
            {/* <p>
              Page {currentPage} of {numPages}
            </p> */}
          </div>
          <div style={{ display: "none" }}>
            <div ref={pdfContainerRef}>
              {screen.items.map((item, index) => (
                <div key={item.id} style={{ margin: "10px 0" }}>
                  <RealComponents
                    onChange={(val, name) => {}}
                    uploadFile={uploadFile}
                    dynamicData={workflowInstance?.dynamicContents}
                    dispatch={dispatch}
                    id={item.id}
                    screenId={item.screen}
                    name={item.name}
                    type={item.type}
                    actionBtn={true}
                    parent={item.parent}
                    values={item.values}
                    children={item.children}
                    containerIndex={index}
                    /* style={
                      item.style
                        ? { ...item.style }
                        : { ...defaultStyles()[item.type] }
                    } */
                    style={determineStyle(item)}
                    screenStyles={screenStyles}
                    customId={item.customId || null}
                    customName={item.customName || null}
                    appDesignMode={APP_DESIGN_MODES.LIVE}
                    hideScreenButton1={noBack}
                    screenButton1={goBack}
                    screenButton2={goNext}
                  />
                </div>
              ))}
            </div>
          </div>
          <Box
            style={{
              position: "fixed",
              bottom: 12,
              left: 20,
              //border: "2px solid red",
              zIndex: 11,
            }}
          >
            {task?.properties?.previewDownload && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flexStart",
                  //alignItems: "center",
                  //gap: "40px",
                }}
              >
                <Button
                  className={classes.documentBtn}
                  color="primary"
                  variant="contained"
                  onClick={() => processPDF(containerRef, "download")}
                >
                  Download PDF
                </Button>
              </Box>
            )}
          </Box>
          <ScreenButtons
            appDesignMode={APP_DESIGN_MODES.LIVE}
            hideScreenButton1={noBack}
            screenButton1={goBack}
            screenButton2={goNext}
            values={{
              button1Type: "back",
              button1Text: "Back",
              button2Type: "next",
              button2Text: "Next",
            }}
          />
        </div>
      )}
      {showSignatureModal && (
        <SignatureModal
          fileName={SIGNATURE_FILE_NAME}
          workflowInstanceID={workflowInstance?.id}
          signatureComponentID={signatureComponentID}
          setShowSignatureModal={setShowSignatureModal}
        />
      )}
      <Error
        status={screenError?.code || error?.code}
        message={screenError?.message || error?.message}
      />
    </PageWrapper>
  );
};

export default DisplayScreen;
