import { SetAppStatus } from "../../../../../helpers/helperFunctions";
import {
  loadCanvasItems,
  loadScreenStyle,
} from "../../../../../store/actions/properties";
import { getSingleScreenItems } from "../screenAPIs";

export const loadScreenProperties = () => async (dispatch, getState) => {
  let loadedStyle;
  const {
    activeScreen: { id: screenId, name: screenName },
    screensList,
  } = getState().screens;

  dispatch(SetAppStatus({ type: "info", msg: "..." }));

  //  get screen items
  if (!!screenId) {
    const currentScreenStyle = screensList.find(
      (screen) => screen.id === screenId
    )?.style;

    dispatch(loadScreenStyle(currentScreenStyle));

    const { success, data } = await getSingleScreenItems(screenId);

    if (success) {
      dispatch(loadCanvasItems(data));
      dispatch(
        SetAppStatus({
          type: "success",
          msg: `'${screenName}' screen components loaded`,
        })
      );
    }
  }

  return { loadedStyle };
};
