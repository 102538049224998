import { InputBase, MenuItem, Select, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { dropdownContent } from "../../../../../../../utils/dynamicContentReplace";
import Required from "../Required";
import { v4 } from "uuid";

const BootstrapInput = withStyles((theme) => ({
  // input: {
  //   borderRadius: 4,
  //   position: "relative",
  //   // backgroundColor: theme.palette.background.paper,
  //   backgroundColor: "whitesmoke",
  //   border: "1px solid #ced4da",
  //   fontSize: 16,
  //   padding: "10px 26px 10px 12px",
  //   transition: theme.transitions.create(["border-color", "box-shadow"]),
  //   "&:focus": {
  //     borderRadius: 4,
  //     borderColor: "#80bdff",
  //     boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  //   },
  // },
}))(InputBase);
const Dropdown = ({
  style,
  screenId,
  name,
  values,
  onChange,
  dynamicData,
  readOnly,
  returnedLookupObj,
  appDesignMode,
  val,
  ...props
}) => {
  const dropDownStyles = makeStyles((theme) => {
    return {
      ...style,
      select: {
        fontSize: style?.field?.fontSize,
        lineHeight: 1.5,
        //border: "1.2px solid grey",
        //borderRadius: "4px",
      },
    };
  });
  const classes = dropDownStyles();
  const [value, setValue] = useState("");
  const [renderKey, setRenderKey] = useState(v4());
  const [trackLookupVal, setTrackLookupVal] = useState([]);
  const dynamicContentObj = dynamicData?.[screenId];
  const returnedLookupObjValue = dynamicContentObj?.[props?.id];

  useEffect(() => {
    if (returnedLookupObj?.[props?.id]) {
      setTrackLookupVal([...trackLookupVal, returnedLookupObj?.[props?.id]]);

      onChange(returnedLookupObj?.[props?.id], props?.id);
      setValue(returnedLookupObj?.[props?.id]);
      setRenderKey(v4());
    }
  }, [returnedLookupObj, props?.id]);

  useEffect(() => {
    if (val) {
      setValue(val);
      onChange(val, props?.id);
    }
  }, [val]);

  const _onChange = (e) => {
    const val = e.target.value;
    setValue(val);
    onChange(val, props.id);
  };

  return (
    <div style={{ width: style?.field?.width }}>
      {!values?.labelHide && (
        <Typography gutterBottom className={classes?.label}>
          {values?.label}
          <Required required={values?.required} />
        </Typography>
      )}
      <Select
        key={renderKey}
        labelId="demo-customized-select-label"
        placeholder={values?.placeholder}
        value={value || values?.selectedValue || "choose"}
        className={`${classes?.field} ${readOnly ? "read-only" : ""}`}
        required={values?.required}
        classes={{
          select: classes?.select,
        }}
        style={{ paddingLeft: 10, color: style?.field?.color }}
        onChange={(e) => _onChange(e)}
        readOnly={readOnly}
        //disabled={props.appDesignMode === APP_DESIGN_MODES.EDIT}
        input={<BootstrapInput />}
        variant="filled"
        multiple={values?.selectType === "Multiple Select"}
      >
        {/* {!props?.data.length && <MenuItem value="">No Options</MenuItem>}
                {props?.data.map(({ value }) => <MenuItem value={value}>{value}</MenuItem>)} */}
        {!values?.options.length && !dynamicContentObj?.[name]?.length ? (
          <MenuItem value="">No options</MenuItem>
        ) : (
          <MenuItem value="choose" selected disabled>
            Select{" "}
            {values?.selectType === "Multiple Select" ? "options" : "one"}
          </MenuItem>
        )}
        {dropdownContent(
          { values, name, appDesignMode, trackLookupVal },
          dynamicContentObj
        )}
      </Select>
    </div>
  );
};

export default Dropdown;
