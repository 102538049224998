import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  Paper,
} from "@material-ui/core";
import CustomDropDown from "../../../helpers/DropDown/CustomDropDown";
import { useSelector } from "react-redux";
import { infoToastify } from "../../Toastify";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    width: "100%",
    // overflow: "scroll",
    // minHeight: "10em",
  },

  textWrapper: {
    maxWidth: "80%",
    // height: "36.12px",
    marginBottom: "1rem",
    padding: 10,
    margin: 10,
    backgroundColor: "#f5f5f5",
    border: "outset 1px #eee",
    "& > p": {
      fontStyle: "normal",
      fontWeight: 375,
      fontSize: "10px",
      lineHeight: "130%",
      textAlign: "center",
      color: "#424874",
    },

    "& > :last-child": {
      color: "red",
    },
  },

  dropDown: {
    width: "200px",
  },
}));

const VerifyUploadView = ({
  matchedData,
  fieldValue,
  updatedFileData,
  setIsVerifed,
}) => {
  // setting default states
  const classes = useStyles(makeStyles);
  const [values, setValues] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRow] = useState([]);
  const [plugList, setPlugList] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [duplicateValue, setDuplicateValue] = useState("");
  const listRef = useRef();

  /* It's setting the current value of the listRef to the values array. */
  listRef.current = values;

  /* It's getting the file data from the redux store. */
  const {
    upLoadFileReducer: { fileData },
  } = useSelector((state) => state);

  /**
   * When the user changes the value of the select element, update the matchedData object with the new
   * value.
   */
  const handleChange = (e, label, idx) => {
    if (e.target.value === "") return;

    /* It's checking if the value of the select element is in the matchedData object. If it is, it's
calling the infoToastify function and passing it a string as an argument. */
    if (e.target.value in matchedData.current) {
      return infoToastify(`Duplicate match found`);
    }

    /* It's setting the state of the ClickedIndex variable to the idx variable. */
    setSelectedValue(() => [...selectedValue, e.target.value]);

    /* It's cheing if the key of the matchedData object is not equal to the value of the select element
ck    and if the value of the key is equal to the value of the label variable. If it is, it's deleting the
    key from the matchedData object. */
    for (const key in matchedData.current) {
      if (key !== e.target.value && matchedData.current[key] === label) {
        delete matchedData.current[key];
        setDuplicateValue(() => key);
      }
    }

    /* It's setting the current value of the matchedData object to the label. */
    matchedData.current[e.target.value] = label;

    let newFieldValues = values.map((val, i) => {
      if (i === idx) {
        /* It's setting the value of the id key of the val object to the value of the label variable. */
        val.id = label;

        /* It's checking if the length of the fieldWatch array is greater than or equal to the length of the
values array minus two. If it is, it's checking if the firstName, lastName, and email keys of the
matchedData object are undefined. If they are, it's calling the infoToastify function and passing it
a string as an argument. If they aren't, it's setting the isVerified state to false. */
        if (
          matchedData.current["firstName"] !== undefined &&
          matchedData.current["lastName"] !== undefined &&
          matchedData.current["email"] !== undefined
        ) {
          setIsVerifed(() => false);
        }
      }

      return val;
    });

    /* It's setting the current value of the fieldValue object to the newFieldValues array. */
    fieldValue.current = newFieldValues;
  };

  useEffect(() => {
    let newUserData = [];
    /* It's removing duplicate objects from the fileData array. */
    let checkDuplicate = fileData;

    /* It's setting the current value of the updatedFileData to the checkDuplicate array. */
    updatedFileData.current = checkDuplicate.length > 0 && checkDuplicate;

    /**
     * HandleRows is a function that takes two arguments, row and input, and pushes an object with the
     * key label and the value row and the key input and the value input into the newUserData array,
     * and then sets the state of row to the newUserData array.
     */
    const handleRows = (row, input) => {
      newUserData.push({ label: row, input });
      setRow(() => newUserData);
    };

    /**
     * I'm trying to add a new column to the table and populate it with data from an array.
     */
    const addToState = () => {
      /* It's an array of arrays. - input lists */
      const plugLists = [
        ["", "match headers"],
        ["firstName", "First name *"],
        ["lastName", "Last name *"],
        ["email", "Email *"],
        ["mobile", "Phone"],
        ["employeeId", "Employee ID"],
        ["lineManager", "Line manager"],
        ["userGroups", "Group"],
      ];

      /* It's adding a new column to the table. */
      setColumns(() => [
        { label: "Your CSV column header", id: "label" },
        { label: "Match field", id: "input" },
      ]);

      /* It's getting the keys of the first object in the fileData array. */
      let fileDataKeys =
        checkDuplicate.length > 0 && Object.keys(checkDuplicate[0]);

      /* It's adding a new column to the table. */
      fileDataKeys.length > 0 &&
        fileDataKeys.forEach((data) => {
          handleRows(data, "input");
          if (listRef.current.length <= checkDuplicate.length) {
            setValues((prev) => [...prev, { id: "" }]);
          }
        });

      /* It's setting the input lists to the state. */
      setPlugList(() => plugLists);
    };
    addToState();
  }, [fileData, updatedFileData]);

  return (
    <Grid
      container
      className={classes.MainContainer}
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid className={classes.textWrapper}>
        <Typography variant="h3" component="p">
          Pair the column headers on your CSV file with the map fields on Plug
          on the right side before uploading.
        </Typography>
        {/* <Typography variant="h3" component="p">
          Note that system automatically uses the first column of the data sheet
          as the column heading on Plug, if no pairing is done.
        </Typography> */}
      </Grid>
      <Grid item>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.length > 0 &&
                    columns.map((column, idx) => (
                      <TableCell key={idx} style={{ width: 200 }}>
                        {column.label}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length > 0 &&
                  rows.map((row, idx) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                        {columns.length > 0 &&
                          columns.map((column, indx) => {
                            const value = row[column.id];
                            return column.id !== "input" ? (
                              <TableCell key={idx}>{value}</TableCell>
                            ) : (
                              <CustomDropDown
                                onChange={(e) =>
                                  handleChange(e, row.label, idx)
                                }
                                data={plugList}
                                name={value}
                                key={idx + 1}
                                selectedValue={selectedValue}
                                duplicateValue={duplicateValue}
                              />
                            );
                          })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default VerifyUploadView;
