import {
  Select,
  MenuItem,
  Typography,
  Button,
  TextField,
  Checkbox,
} from "@material-ui/core";
import useStyles from "./components/style";
import { useEffect, useState } from "react";
import { imageSourceDropdown } from "../../../../helpers/DropDown/util/utils";
import ColorPicker from "../../../EditorLayout/components/RightSidebar/components/ColorPicker";
import FileUpload from "../../../EditorLayout/Pages/UIEditor/components/actualObjects/FileUpload";
import { uploadFile } from "../../../../helpers/LiveData";
import { useSelector } from "react-redux";
import { getIntegrationDataAPI } from "../../../Integrations/utils/integrationsAPIs";
import { errorToastify, successToastify } from "../../../../common/Toastify";
import { updateUserAccount } from "../../../../components/Mutation/ProfileSetting/userMutations";
import useCustomMutation from "../../../../common/CustomMutation";
import useCustomQuery from "../../../../common/CustomQuery";
import { getAccountInfo } from "../SsoConfiguration/utils/ssoAccountsAPI";

const Customizations = () => {
  const classes = useStyles();
  const { user: userInfo } = useSelector(({ auth }) => auth); //to be uncommented

  const userAccountId = userInfo?.account?.id;

  const [orgLogo, setOrgLogo] = useState({});
  const [orgIntegrationId, setOrgIntegrationId] = useState({});

  const [integrationList, setIntegrationList] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loadingIntegration, setLoadingIintegration] = useState(false);
  const [appUpdating, setAppUpdating] = useState(false);

  const selectedChangeCustom = (e) => {
    e.persist();
    const { name, value } = e.target;
    setIsButtonDisabled(true);

    // Clear imageUrl state when imageSource is set to "None"
    const updatedLogo = {
      [name]: value,
      imageUrl: value === "NONE" ? "" : orgLogo?.logo?.imageUrl || "", // Clear imageUrl if imageSource is "None"
    };

    setOrgLogo({
      ...orgLogo,
      logo: updatedLogo,
    });
  };

  const selectedChangeIntegration = (e) => {
    const { name, value } = e.target;
    setIsButtonDisabled(true);

    setOrgIntegrationId({
      ...orgIntegrationId,
      defaultEmailSender: {
        usePlugDefault: value === "Plug", // Set usePlugDefault to false if value is not "Plug"
        [name]: value === "Plug" ? null : value,
      },
    });
  };

  const isSaveDisabled =
    !isButtonDisabled || // No input field is changed
    (orgLogo?.logo?.imageSource === "CDN" && !orgLogo?.logo?.imageUrl) || // CDN imageSource with empty imageUrl
    (orgLogo?.logo?.imageSource === "UPLOAD" && !orgLogo?.logo?.imageUrl); // Uploads imageSource with empty imageUrl

  // after user account has been updated
  const onAccountUpdateSuccess = () => {
    setAppUpdating(false);
    successToastify("Updated Successfully");
  };

  const accountInfoSuccess = async ({ data }) => {
    setLoadingIintegration(true);
    const userInfo = data?.data?.customisations;

    const { logo, defaultEmailSender } = userInfo;

    const options = {
      query: {
        active: true,
        group: "mail",
        per_page: 1000,
      },
    };
    const integrations = await getIntegrationDataAPI(options);

    if (integrations?._meta?.success) {
      const _list = integrations.data;

      // list of integrations, mapping only the name and id
      const orgEmailList = _list.map((item) => ({
        value: item.name,
        _id: item._id,
      }));
      setIntegrationList(orgEmailList);

      setOrgLogo({
        logo: logo,
      });

      setOrgIntegrationId({
        defaultEmailSender: {
          usePlugDefault: defaultEmailSender?.usePlugDefault,
          integrationId: defaultEmailSender?.integrationId,
        },
      });
      setLoadingIintegration(false);
    } else {
      setLoadingIintegration(false);
      errorToastify("Sorry an error occured while loading your Integrations");
    }
  };

  // update user account
  const { mutate: updateAccount } = useCustomMutation({
    apiFunc: updateUserAccount,
    onSuccess: onAccountUpdateSuccess,
    retries: 0,
  });

  //fetch account info
  const { isLoading, isFetching } = useCustomQuery({
    apiFunc: getAccountInfo,
    queryKey: ["accountInfo"],
    onSuccess: accountInfoSuccess,
  });

  const submitData = () => {
    const updatedData = { ...orgLogo, ...orgIntegrationId };

    setIsButtonDisabled(false);

    updateAccount({
      id: userAccountId,
      data: { customisations: updatedData },
    });
    setAppUpdating(true);
  };

  useEffect(() => {
    document.title = "Settings | Customizations";
  }, []);

  return isLoading || isFetching || loadingIntegration ? (
    <div className={classes.loading}>
      Loading... please wait...
      <img src="../../../images/loading-anim.svg" alt="Clone" width={20} />
    </div>
  ) : (
    <div className={classes.root}>
      <div className={classes.title}> Organisation logo</div>

      <form>
        <Typography className={classes.formLabels} gutterBottom>
          Image source
        </Typography>
        <Select
          id={""}
          disabled={""}
          size="small"
          name="imageSource"
          required
          value={orgLogo?.logo?.imageSource || ""}
          onChange={selectedChangeCustom}
          fullWidth
          error={""}
          type="text"
          variant="outlined"
          classes={{
            root: classes.selectPadding,
          }}
        >
          <MenuItem value={"NONE"}>None</MenuItem>
          {imageSourceDropdown.map((imageSourceOrg, i) => (
            <MenuItem value={imageSourceOrg[0]} key={i}>
              {imageSourceOrg[1]}
            </MenuItem>
          ))}
        </Select>

        {orgLogo?.logo?.imageSource === "CDN" && (
          <>
            <Typography className={classes.formLabels} gutterBottom>
              CDN URL
            </Typography>
            <div>
              <TextField
                id={"cdnLink"}
                className={classes.formTextField}
                name="imageUrl"
                required
                error={""}
                size="small"
                fullWidth
                placeholder="CDN URL"
                onChange={(e) => {
                  setIsButtonDisabled(true);
                  setOrgLogo({
                    ...orgLogo,
                    logo: {
                      ...orgLogo.logo,
                      imageUrl: e.target.value,
                    },
                  });
                }}
                type="text"
                inputMode="text"
                value={orgLogo?.logo?.imageUrl || ""}
                variant="outlined"
              />
            </div>
          </>
        )}
        {orgLogo?.logo?.imageSource === "UPLOAD" && (
          <>
            <Typography className={classes.formLabels} gutterBottom>
              Upload image
            </Typography>
            <div
              style={{
                border: "1px solid #C4C4C4",
                borderRadius: "4px",
                padding: "0px 12px 7px 12px",
              }}
            >
              <FileUpload
                values={{
                  fileType: "Image",
                  required: false,
                  numOfFiles: 1,
                  maxFileSize: 2,
                  buttonText: "Click to upload",
                }}
                onChange={(e) => {
                  setOrgLogo({
                    ...orgLogo,
                    logo: {
                      ...orgLogo.logo,
                      imageUrl: e.toString(),
                    },
                  });
                }}
                uploadFile={uploadFile}
                style={{
                  button: {
                    width: "100%",
                    fontSize: 12,
                    fontWeight: 300,
                    color: "#ffffff",
                    textAlign: "center",
                    textTransform: "none",

                    borderStyle: "solid",
                    borderWidth: 1,
                    borderRadius: 5,
                    borderColor: "#091540",
                    backgroundColor: "#091540",
                  },
                }}
              />
            </div>
          </>
        )}

        <div className={classes.title} style={{ marginTop: "2.5rem" }}>
          Default email sender
        </div>

        <Typography className={classes.formLabels} gutterBottom>
          Email sender
        </Typography>
        <Select
          id={""}
          disabled={""}
          size="small"
          name="integrationId"
          required
          fullWidth
          value={orgIntegrationId?.defaultEmailSender?.integrationId || "Plug"}
          onChange={selectedChangeIntegration}
          placeholder={`Enter email sender here`}
          error={""}
          type="text"
          variant="outlined"
          classes={{
            root: classes.selectPadding,
          }}
        >
          <MenuItem value="Plug">Plug</MenuItem>
          {integrationList.map((integrationOrg, index) => (
            <MenuItem value={integrationOrg._id} key={index}>
              {integrationOrg.value}
            </MenuItem>
          ))}
        </Select>

        {/* ================ plug Theme Section if later added =============== */}

        <Button
          color="primary"
          style={{ marginTop: 15 }}
          classes={{
            root: classes.customButton,
            label: classes.customButtonLabel,
            disabled: classes.disabled,
          }}
          onClick={submitData}
          disabled={isSaveDisabled}
          size="large"
          title="Save Configuration"
          variant="contained"
        >
          Save
          {appUpdating && <div className="activity-loader"></div>}
        </Button>
      </form>
    </div>
  );
};

export default Customizations;
