import { CustomAxios } from "../../../common/CustomAxios";

/**
 * It's a function that takes in an object with a queryKey property that is an array with two elements,
 * the first element is a string and the second element is an object with three properties:
 * appSortParams, selectedCategory,page and perPage.
 */
export const getAppsList = async ({
  queryKey: [{ appSortParams, selectedCategory, page, perPage }],
}) => {
  let stringifySortParams = JSON.stringify(appSortParams);
  let category =
    selectedCategory === "All" ? "" : `&category=${selectedCategory}`;
  const result = await CustomAxios().get(
    `${process.env.REACT_APP_ENDPOINT}/apps?population=["account","category"]&sort=${stringifySortParams}${category}&page=${page}&per_page=${perPage}`
  );

  return result;
};

/**
 * It makes a GET request to the endpoint /categories and returns the result.
 * @returns An object with a data property that contains the array of categories.
 */
export const getCategories = async () => {
  const result = await CustomAxios().get(
    `${process.env.REACT_APP_ENDPOINT}/categories`
  );

  return result;
};

export const sSORedirects = async () => {
  const result = await CustomAxios().get(
    `${process.env.REACT_APP_ENDPOINT}/auth/sso-authorize`,
    {
      withCredentials: false,
    }
  );

  return result;
};
