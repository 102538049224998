/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppLoading from "../../components/Loading";
import RealComponents from "../../Pages/UIEditor/components/ElementsComponent";
import PageWrapper from "../../Pages/UIEditor/components/PageWrapper";
import { useSelector, useDispatch } from "react-redux";

import Dialog from "../../../../utils/dialogue";

import { defaultStyles } from "../UIEditor/utils/defaultScreenStyles";
import { getSingleScreenItems } from "../Screens/screenAPIs";
import { showAppDialog } from "../../../../helpers/helperFunctions";
import { APP_DESIGN_MODES } from "../../../../utils/constants";

const Preview = (props) => {
  const { screenId } = useParams();
  const [canvasItems, setCanvasItems] = useState([]);
  const [screenState, setScreenState] = useState({
    loading: true,
    success: false,
    error: "",
  });

  const { showDialog } = useSelector((state) => state.reducers);

  const [capturedData, setCapturedData] = useState({});
  const [useDialog, setUseDialog] = useState("");
  const dispatch = useDispatch();

  const dialogResponse = (use, resp) => {
    dispatch(showAppDialog({ status: false }));
  };

  useEffect(() => {
    if (!screenId) return;

    const fetchData = async () => {
      setScreenState((prev) => ({ ...prev, loaded: true }));
      const { success, data } = await getSingleScreenItems(screenId);
      if (success) {
        setCanvasItems(data);
        return setScreenState((prev) => ({ ...prev, success, loading: false }));
      }
      setScreenState((prev) => ({
        ...screenState,
        error: data,
        success,
        loading: false,
      }));
    };

    fetchData();
  }, [screenId]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    dispatch(
      showAppDialog({
        status: true,
        message: "This information will not be sent to the server",
        messageNewLine: "Please publish and run the app and run",
        noButtons: true,
      })
    );
  };

  const captureData = (value, input) =>
    setCapturedData({ ...capturedData, [input]: value });
  return (
    <PageWrapper margin="54px auto 20px auto" minHeight="100vh" zoomLevel={100}>
      <AppLoading
        loadingStatus="fetching screen..."
        loading={screenState.loading}
      />
      <form onSubmit={onFormSubmit}>
        {screenState.success &&
          canvasItems.map((item, index) => {
            return (
              <div key={item.id}>
                <RealComponents
                  key={item.id}
                  id={item.id}
                  name={item?.name}
                  onFormSubmit={onFormSubmit}
                  uploadFileChange={(e) => {
                    console.log(e);
                  }}
                  type={item.type}
                  onChange={(val, name) => captureData(val, name)}
                  parent={item.parent}
                  values={item.values}
                  children={item.children}
                  containerIndex={index}
                  style={
                    item.style
                      ? { ...item.style }
                      : { ...defaultStyles()[item.type] }
                  }
                  customId={item.customId || null}
                  customName={item.customName || null}
                  appDesignMode={APP_DESIGN_MODES.PREVIEW}
                  screenButton1={() => console.log("dsl")}
                  screenButton2={() => console.log("dsl")}
                />
              </div>
            );
          })}
      </form>
      <Dialog
        dialogProps={showDialog}
        onClose={dialogResponse}
        use={useDialog}
      />
    </PageWrapper>
    // <div className={classes.root}>
    //   <div className={classes.app}>
    //     <div className={classes.container}>
    //       <div className={classes.dropZone}>

    //       </div>
    //     </div>

    //   </div>
    // </div>
  );
};

export default Preview;
