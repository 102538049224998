import { Typography, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateValues } from "../../../../Pages/UIEditor/utils/canvasHelpers";
import SidebarNameSection from "../components/SidebarNameSection";
import CustomStyleSection from "../components/CustomStyleSection";

export default function TextSidebar(props) {
  const {
    id,
    name,
    title,
    type: itemType,
    styles,
    stylesOverride,
    values,
    index,
    containingChildIndex,
    isInsideContainer,
  } = props;
  const dispatch = useDispatch();

  const onValuesChange = ({ value, property }) =>
    dispatch(
      updateValues({
        value,
        property,
        index,
        isInsideContainer,
        containingChildIndex,
        type: itemType,
      })
    );

  const onTextChange = (e) => {
    // if (e.target.value.includes(/,./)) return;
    onValuesChange({ value: e.target.value, property: "value" });
  };

  const MyTextInput = () => (
    <TextField
      variant="outlined"
      size="medium"
      fullWidth
      multiline
      minRows={9}
      defaultValue={values?.value}
      onBlur={onTextChange}
      InputProps={{
        style: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 12,
          color: "#091540!important",
          LineHeight: 15.6,
        },
      }}
    />
  );

  return (
    <div className="sidebar-container">
      <SidebarNameSection
        itemId={id}
        itemType={itemType}
        name={name}
        title={title}
        index={index}
        isInsideContainer={isInsideContainer}
        containingChildIndex={containingChildIndex}
      />

      <div className="sidebar-container-scroll">
        <div className="sidebar-section">
          <div
            className="sidebar-section-item _full"
            style={{ display: "block" }}
          >
            <Typography
              gutterBottom
              className="row-label _long"
              style={{ margin: "10px 0" }}
            >
              Text Content <span>(use @ to bind variables)</span>
            </Typography>
            <MyTextInput />
          </div>
        </div>
        <CustomStyleSection
          itemType={itemType}
          index={index}
          name={name}
          title={title}
          isInsideContainer={isInsideContainer}
          containingChildIndex={containingChildIndex}
          values={values}
          items={["text"]}
          styles={styles}
          stylesOverride={stylesOverride}
        />
      </div>
    </div>
  );
}
