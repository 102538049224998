import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Typography } from "@material-ui/core";
import moment from "moment";
import SidebarNameSection from "../components/SidebarNameSection";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  sideHeading: {
    color: "#091540",
    fontSize: 12,
    fontWeight: 300,
    paddingLeft: 3,
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
  },
  subTitle: {
    color: "#999999",
    fontWeight: 375,
    fontSize: 12,
  },
  subColor: {
    color: "#999999",
    fontWeight: 375,
    fontSize: 10,
    paddingBottom: 8,
  },
  subTitle2: {
    color: "#999999",
    fontWeight: 375,
    fontSize: 12,
  },
  user: {
    display: "flex",
    alignItems: "center",
  },
  bgcolor: {
    display: "flex",
  },
  userName: {
    color: "#091540",
    fontWeight: 375,
    fontSize: 12,
    textTransform: "capitalize",
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
  status: {
    background: "#808080",
    borderRadius: 4,
    color: "white",
    fontSize: 10,
    padding: "2px 20px",
    marginLeft: 10,
    display: "inline-flex",
    textTransform: "capitalize",
  },
  draft: {
    backgroundColor: "#f3f2ec",
    color: "#656776",
    border: "solid 0.5px #e7e6dc",
    padding: "2px 10px",
    fontSize: "0.85em",
    borderRadius: 4,
    letterSpacing: "0.8px",
    fontWeight: 300,
    display: "inline-block",
    textTransform: "uppercase",
  },
  live: {
    backgroundColor: "#3cc0dd",
    border: "solid 0.5px #3cc0dd",
    color: "#FFFFFF",
    padding: "2px 10px",
    fontSize: "0.85em",
    borderRadius: 4,
    letterSpacing: "0.8px",
    fontWeight: 300,
    display: "inline-block",
    textTransform: "uppercase",
    textShadow: "0.5px 0.5px 1px #888",
  },
  subHeading: {
    color: "#091540",
    fontWeight: 375,
    fontSize: 12,
  },
  subContent: {
    color: "#091540",
    fontWeight: 375,
    fontSize: 12,
    paddingBottom: 33,
  },
  subInfo: {
    color: "#999999",
    fontWeight: 375,
    fontSize: 12,
    paddingBottom: 8,
    paddingTop: "0px !important",
  },
  userChanges: {
    fontStyle: "italic",
    paddingTop: 8,
  },
  section: {
    marginBottom: 100,
  },
}));

export default function InfoSidebar(props) {
  const classes = useStyles();
  const {
    selectedApp: { createdAt, updatedAt, active, creator },
  } = useSelector(({ appsReducer }) => appsReducer);

  const editor_app_date = createdAt;
  const editor_app_last_update = updatedAt;

  return (
    <div className="sidebar-container">
      <SidebarNameSection
        itemId="info_side_bar"
        title="App Information Bar"
        itemType="App Information Bar"
        noName
      />

      <div className="sidebar-section">
        <Typography gutterBottom className={classes.subTitle}>
          Owner
        </Typography>
        <div className={classes.user}>
          <Avatar alt={creator?.toUpperCase()} src="/broken-image.jpg" />
          <Typography className={classes.userName}>{creator}</Typography>
        </div>
      </div>
      <div className="sidebar-section">
        <Typography gutterBottom className={classes.subTitle}>
          Status
        </Typography>
        {!active && <div className={classes.live}>Live</div>}
        {active && <div className={classes.draft}>Draft</div>}
      </div>
      <div className="sidebar-section">
        <Typography gutterBottom className={classes.subTitle}>
          Status
        </Typography>
        <Typography className={classes.subTitle2}>Created on</Typography>
        <Typography gutterBottom className={classes.subHeading}>
          {moment(editor_app_date).format("MMMM Do YYYY, h:mm:ss a")}
        </Typography>
        <Typography className={classes.subTitle2}>Last change made</Typography>
        <Typography className={classes.subHeading}>
          {moment(editor_app_last_update).format("MMMM Do YYYY, h:mm:ss a")}
        </Typography>
      </div>
    </div>
  );
}
