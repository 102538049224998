import { Divider, TextField, Typography } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import { useDispatch } from "react-redux";
import { updateValues } from "../../../../Pages/UIEditor/utils/canvasHelpers";
import titleCase from "../../../../../../helpers/titleCase";
import SidebarNameSection from "../components/SidebarNameSection";
import StyleOption from "../StyleOption";
import CustomStyleSection from "../components/CustomStyleSection";

const InputText = withStyles((theme) => ({
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 11,
    padding: "5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {},
  sideHeading: {
    color: "#091540",
    fontWeight: 600,
    fontSize: 13,
    padding: "10px 5px 0px 5px",
    paddingLeft: 10,
    display: "flex",
    justifyContent: "space-between",
    "& span": {
      textAlign: "right",
      "& input": {
        paddingLeft: 10,
      },
    },
  },
  section: {
    padding: 10,
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
  },
  sectionLabel: {
    color: "#999",
    fontSize: 10,
    marginRight: 12,
    marginTop: 6,
  },
  fullWidthText: {
    margin: "10px 0",
  },
  // input: {
  //   color: "#091540",
  //   fontSize: 10,
  // },
  center: {
    textAlign: "center",
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
  input: {
    "&::placeholder": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 12,
      color: "#091540!important",
      LineHeight: 15.6,
    },
  },
}));

export default function FileUploadSidebar(props) {
  const {
    id,
    type: itemType,
    title,
    index,
    styles,
    stylesOverride,
    values,
    isInsideContainer,
    containingChildIndex,
    name,
  } = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const onValuesChange = ({ value, property }) =>
    dispatch(
      updateValues({
        value,
        property,
        index,
        isInsideContainer,
        containingChildIndex,
        type: itemType,
      })
    );

  const onTextChange = (e) => {
    // if (e.target.value.includes(/,./)) return;
    onValuesChange({ value: e.target.value, property: "value" });
  };

  return (
    <div className="sidebar-container">
      <SidebarNameSection
        itemId={id}
        itemType={itemType}
        name={name}
        title={title}
        index={index}
        isInsideContainer={isInsideContainer}
        containingChildIndex={containingChildIndex}
      />

      <div className="sidebar-container-scroll">
        <div className="sidebar-section">
          <div
            className="sidebar-section-item _full"
            style={{ display: "block" }}
          >
            <Typography
              gutterBottom
              className="row-label _long"
              style={{ margin: "10px 0" }}
            >
              Text
            </Typography>
            <TextField
              variant="outlined"
              size="medium"
              fullWidth
              multiline
              minRows={9}
              value={values?.value}
              onChange={onTextChange}
              InputProps={{
                style: {
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: 12,
                  color: "#091540!important",
                  LineHeight: 15.6,
                },
              }}
            />
          </div>
        </div>
        <CustomStyleSection
          itemType={itemType}
          index={index}
          name={name}
          title={title}
          isInsideContainer={isInsideContainer}
          containingChildIndex={containingChildIndex}
          values={values}
          items={["text"]}
          styles={styles}
          stylesOverride={stylesOverride}
        />
      </div>
    </div>
  );
}
