import { CustomAxios } from "../../../../../common/CustomAxios";
import { composeQueryString } from "../../../../../common/composeQueryString";

//  get list of users
export const getUserGroupsAPI = async (options) => {
  const queryStr = composeQueryString(options);
  if (queryStr === false) return { data: [] };

  const result = await CustomAxios().get(
    `${process.env.REACT_APP_ENDPOINT}/user-groups${queryStr}`
  );

  return result.data;
};

// search user request
export const searchUserGroupsAPI = async (value) => {
  //console.log(value);
  const per_page = 20;
  const result = await CustomAxios().get(
    `${process.env.REACT_APP_ENDPOINT}/user-groups?search=${value}&selection=id+name`
  );
  return result.data;
};
// new usergroup request
export const newUserGroupAPI = async ({ data }) => {
  const result = await CustomAxios().post(
    `${process.env.REACT_APP_ENDPOINT}/user-groups`,
    data
  );
  return result.data;
};

// update a user request
export const updateUserGroupAPI = async ({ data: { id, ...data } }) => {
  const result = await CustomAxios().put(
    `${process.env.REACT_APP_ENDPOINT}/user-groups/${id}`,
    data
  );
  return result.data;
};

// delete a user request
export const removeUserGroupAPI = async ({ id }) => {
  const result = await CustomAxios().delete(
    `${process.env.REACT_APP_ENDPOINT}/user-groups/${id}`
  );
  return result.data;
};
