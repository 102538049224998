import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Typography,
  Select,
  MenuItem,
  Collapse,
} from "@material-ui/core";
import {
  ErrorOutlineOutlined,
  CheckCircleOutlineOutlined,
} from "@material-ui/icons";
import MyWindowsDimensions from "../../../../../utils/windowsDimension";
import MailActions from "./sidebarActions/MailActions";
import {
  doManageVariables,
  globalSetTaskInfo,
  isConnectedTo,
} from "../../utils/funcs";
import { NameDescription } from "./sidebarActions/common/NameDescription";
import {
  INVALID_FIELD_LABEL_STYLE,
  variableGroups,
} from "../../utils/constants";
import { useStyles } from "./Helpers/rightSidebarStyles";
import ExecutionVariables from "./Helpers/ExecutionVariables";
import { updateTaskVariables } from "../../../utils/workflowHelpers";

/* const useStyles = makeStyles((theme) => ({
  sideHeading: {
    color: "#091540",
    // fontWeight: 600,
    fontSize: 11,
    paddingLeft: 10,
    paddingTop: 10,
    textTransform: "capitalize",
    display: "inline-flex",
    alignItems: "center",
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
  section: {
    padding: 10,
    paddingBottom: 20,
    // marginBottom: 100,
  },
  container: {
    overflow: "hidden",
    height: "100%",
    "&:hover": {
      overflow: "overlay",
    },
  },
  actionsListItem: {
    width: "30%",
    height: 80,
    margin: 5,
    display: "inline-block",
    textAlign: "center",
    backgroundColor: "white",
    border: "solid 1px white",
    boxShadow: "1px 1px 3px #ccc",
    borderRadius: 10,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#fafafa",
      border: "solid 1px #eaeaea",
      boxShadow: "none",
      color: "#1846c8",
    },
  },
  closeIcon: {
    fontSize: 16,
    color: "#AAAAAA",
    marginRight: 10,
    cursor: "pointer",
    "&:hover": {
      color: "#091540",
    },
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
    marginBottom: 5,
  },
  input: {
    color: "#091540",
    fontSize: 12,
    marginBottom: 12,
  },
  input1: {
    color: "#091540",
    fontSize: 12,
  },
  select: {
    color: "#091540",
    fontSize: 12,
    padding: 10,
  },
  switchLabel: {
    width: "100%",
    justifyContent: "space-between",
    margin: 0,
  },
  matchingFields: {
    borderRadius: 5,
    border: "dashed 1.5px #999",
    padding: "10px 5px 10px 10px",
    backgroundColor: "#f8f8f8",
    marginTop: 7,
  },
  multiSelect: {
    display: "flex",
    flexWrap: "wrap",
  },
  selectedFields: {
    backgroundColor: "#f8f8f8",
    marginRight: 5,
    marginBottom: 5,
    padding: 5,
    border: "dashed 1px #999",
    borderRadius: 8,
    color: "#888",
  },
  sideHeadingBar: {
    backgroundColor: "#f8f8f8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    height: 29,
    "&:hover": {
      opacity: 0.7,
    },
  },
  sectionEntry: {
    marginBottom: 13,
  },
  rowAlignment: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
    alignItems: "center",
    "& > *": {
      width: "40%",
      marginBottom: 0,
      marginLeft: 5,
    },
    "& > *:first-child": {
      width: "25%",
      marginLeft: 0,
    },
  },
})); */

const MailTaskSidebar = ({
  activeTaskId,
  workflowTasks,
  workflowCanvas,
  allVariables,
  dispatch,
  activeTaskType,
  integrations,
}) => {
  const activeTask = workflowTasks[activeTaskId];
  const variables = allVariables[activeTaskId];

  const classes = useStyles(makeStyles);

  const [windowDimensions, setWindowDimensions] = useState({});
  const [showSection, setShowSection] = useState({
    settings: true,
    action: false,
  });
  const [taskUpdated, setTaskUpdated] = useState(false);
  const [isInfoConfigured, setIsInfoConfigured] = useState(false);
  const [isActionsConfigured, setIsActionsConfigured] = useState(false);
  const [nodeIsConnectedToAny, setNodeIsConnectedToAny] = useState(false);
  const [nodeIsConnectedToStart, setNodeIsConnectedToStart] = useState(false);

  const [selectedScreen, setSelectedScreen] = useState("");

  useEffect(() => {
    checkSetupStatus(activeTask);
  }, []);

  useEffect(() => {
    checkSetupStatus(activeTask);
  }, [activeTask]);

  useEffect(() => {
    const isConnectedToAnySource = isConnectedTo(
      activeTaskId,
      workflowCanvas,
      "anyNode"
    );
    const isConnectedToStartNode = isConnectedTo(
      activeTaskId,
      workflowCanvas,
      "startNode"
    );
    setNodeIsConnectedToAny(isConnectedToAnySource);
    setNodeIsConnectedToStart(isConnectedToStartNode);
  }, [workflowCanvas, activeTask]);

  const _toggleSection = (e, sect) => {
    e && e.stopPropagation();

    const makeTrue = !showSection[sect];
    const showSection_ = { ...showSection };
    Object.keys(showSection_).forEach((p) => (showSection_[p] = false));
    if (makeTrue) showSection_[sect] = true;
    setShowSection(showSection_);
  };

  const _setTaskInfo = (e, ppty, isGrouped) => {
    !!e.persist && e.persist();

    globalSetTaskInfo(
      dispatch,
      e,
      ppty,
      isGrouped,
      activeTask,
      setTaskUpdated,
      [],
      checkSetupStatus
    );
  };

  const preSetTaskInfo = async (e, ppty) => {
    let val;
    const value = e.target.value;
    if (value === "Plug") {
      val = {
        name: "Plug",
        integration: null,
      };
    } else {
      val = {
        name: integrations?.mail?.[value]?.type,
        integration: value,
      };
    }
    _setTaskInfo(val, ppty);
  };

  const joinExecutorVarsToVariables = (data, passedVariables, clear = null) => {
    const data_ = [...(data || [])];

    const validFields = data_.filter(
      (dataElement) =>
        !!dataElement.dataType &&
        dataElement.dataType !== "none" &&
        !!dataElement.name
    );

    const toVariablesLists = {
      task: validFields,
    };

    const validCurrentVars = doManageVariables(
      variableGroups.EXECUTOR,
      toVariablesLists,
      passedVariables || variables || [],
      activeTaskId,
      activeTaskType,
      clear
    );

    const directValue = {
      taskId: activeTaskId,
      taskVariables: validCurrentVars,
    };

    dispatch(updateTaskVariables({ directValue, saveToDB: true }));
  };
  const checkSetupStatus = (info) => {
    const infoStatus =
      !!info?.name &&
      (!!info?.triggeredByWebhook || !!info?.triggeredByWorkflow);

    const actionsStatus =
      (!!info?.properties?.emailServiceType?.name ||
        !!info?.properties?.emailServiceType?.integration) &&
      !!info?.properties?.emailTarget?.length &&
      !!info?.properties?.emailSubject &&
      !!info?.properties?.emailBody?.trim();

    const status = infoStatus && actionsStatus;

    const preStatus = isInfoConfigured && isActionsConfigured;

    setIsInfoConfigured(infoStatus);
    setIsActionsConfigured(actionsStatus);

    if (status === preStatus) return null;
    return status;
  };

  return (
    <div className={classes.container}>
      <MyWindowsDimensions setWinDim={setWindowDimensions} />
      <div
        className={classes.sideHeadingBar}
        onClick={(e) => _toggleSection(e, "settings")}
      >
        <Typography gutterBottom className={classes.sideHeading}>
          {activeTask?.type || ""} information
          {isInfoConfigured && (
            <CheckCircleOutlineOutlined
              style={{ fontSize: 16, marginLeft: 10, color: "green" }}
            />
          )}
          {!isInfoConfigured && (
            <ErrorOutlineOutlined
              style={{ fontSize: 16, marginLeft: 10, color: "red" }}
            />
          )}
        </Typography>
        {/* <Tooltip title="Close"><CancelIcon className={classes.closeIcon} onClick={cancelNewTask} /></Tooltip> */}
      </div>
      <Collapse in={!!showSection.settings}>
        <Divider style={{ marginBottom: 10 }} />
        <div className={classes.section}>
          <NameDescription
            activeTask={activeTask}
            _setTaskInfo={_setTaskInfo}
            nodeIsConnectedToAny={nodeIsConnectedToAny}
            nodeIsConnectedToStart={nodeIsConnectedToStart}
          />
        </div>
      </Collapse>
      <Divider />
      <div
        className={classes.sideHeadingBar}
        onClick={(e) => _toggleSection(e, "action")}
      >
        <Typography gutterBottom className={classes.sideHeading}>
          Action details
          {isActionsConfigured && (
            <CheckCircleOutlineOutlined
              style={{ fontSize: 16, marginLeft: 10, color: "green" }}
            />
          )}
          {!isActionsConfigured && (
            <ErrorOutlineOutlined
              style={{ fontSize: 16, marginLeft: 10, color: "red" }}
            />
          )}
        </Typography>
        {/* <Tooltip title="Close"><CancelIcon className={classes.closeIcon} onClick={cancelNewTask} /></Tooltip> */}
      </div>
      <Divider style={{ marginBottom: 10 }} />

      <Collapse in={!!showSection.action}>
        <div className={classes.section}>
          <div className={classes.sectionEntry}>
            <Typography
              gutterBottom
              className={classes.sectionTitle}
              style={
                !activeTask?.properties?.emailServiceType?.name &&
                !activeTask?.properties?.emailServiceType?.integration
                  ? INVALID_FIELD_LABEL_STYLE
                  : {}
              }
            >
              Mail type/Integration*
            </Typography>
            <Select
              key={
                activeTask?.properties?.emailServiceType?.name === "Plug"
                  ? "Plug"
                  : activeTask?.properties?.emailServiceType?.integration ||
                    "choose"
              }
              variant="outlined"
              size="small"
              fullWidth
              classes={{
                root: classes.select,
              }}
              // value={selectedActionDataSheet.id || "choose"}
              // onChange={(e) => setSelectedActionDataSheet(decoyDataSheets.find(dd => dd.id === e.target.value))}
              defaultValue={
                activeTask?.properties?.emailServiceType?.name === "Plug"
                  ? "Plug"
                  : activeTask?.properties?.emailServiceType?.integration ||
                    "choose"
              }
              onChange={(e) => preSetTaskInfo(e, "emailServiceType")}
            >
              <MenuItem value="choose" disabled>
                Select one
              </MenuItem>
              <MenuItem value="Plug" selected>
                Plug Mail
              </MenuItem>
              {(Object.keys(integrations?.mail || {}) || []).map((intg) => (
                <MenuItem value={intg} key={intg}>
                  {integrations?.mail?.[intg]?.type}{" "}
                  <b>: {integrations?.mail?.[intg]?.name}</b>
                </MenuItem>
              ))}
            </Select>
          </div>

          <MailActions
            show={true}
            activeTask={activeTask}
            setTaskInfo={_setTaskInfo}
            properties={activeTask?.properties}
            variables={variables}
            workflowTasks={workflowTasks}
          />
          <ExecutionVariables
            selectedScreen={selectedScreen}
            activeTask={activeTask}
            setTaskInfo={_setTaskInfo}
            activeTaskId={activeTaskId}
            variables={variables}
            properties={activeTask?.properties}
            classes={classes}
            joinExecutorVarsToVariables={joinExecutorVarsToVariables}
          />
        </div>
      </Collapse>
      {/* <Divider style={{ marginBottom: 100 }} /> */}
    </div>
  );
};

export default connect((state) => {
  return {
    activeTaskId: state.workflows.activeTask?.id,
    workflowTasks: state.workflows.workflowTasks,
    workflowCanvas: state.workflows.workflowCanvas,
    allVariables: state.workflows.variables,
    activeTaskType: state.workflows.activeTask?.type,
    integrations: state.workflows.workflowIntegrations,
  };
})(MailTaskSidebar);
