import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Droppable, Draggable } from "react-beautiful-dnd";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {
  canvasDeRegisterElement,
  duplicateCanvasItem,
  setActiveItem,
} from "../utils/canvasHelpers";

import ElementComponentWrapper from "./ElementComponentWrapper";
import RealComponents from "./ElementsComponent";

import EmptyCanvas from "./EmptyCanvas";
import Dialog from "../../../../../utils/dialogue";
import { showAppDialog } from "../../../../../helpers/helperFunctions";
import ErrorBoundary from "../../../../../components/ProtectedRoute/ErrorBoundary";
import GeneralError from "../../../../../components/ProtectedRoute/components/GeneralError";
import CustomConfirmBox from "../../../../../common/Helper/CustomConfirmBox";
import { APP_RUN_MAX_WIDTH } from "../../Workflow/components/utils/constants";
import { APP_DESIGN_MODES } from "../../../../../utils/constants";
// import "./canvasStyle.css";

const UIEditorCanvas = (props) => {
  const {
    activeScreen: { type: screenType },
    screenStyles,
  } = props;

  const useStyles = makeStyles((theme) => ({
    app: {
      // margin: `${props.uiEditorFullScreen ? "" : "20px"} auto`,
      // backgroundSize: "25px 25px",
      // width: "100%",
      // display: "flex",
      // justifyContent: "center"
      zoom: props.zoomLevel / 100,
      marginTop: props.uiEditorFullScreen ? 0 : 100,
      margin: `auto`,
      backgroundColor: screenStyles?.page?.backgroundColor || "#FCFCFC",

      ...(screenType === "document"
        ? {
            width:
              parseFloat(screenStyles?.page?.width) *
              (screenStyles?.page?.dimensionMeasure === "inch" ? 96 : 1),
            height:
              parseFloat(screenStyles?.page?.height) *
              (screenStyles?.page?.dimensionMeasure === "inch" ? 96 : 1),
          }
        : {
            maxWidth: APP_RUN_MAX_WIDTH,
            minWidth: APP_RUN_MAX_WIDTH,
            padding: 5,
            height: "100%",
          }),
    },
    dropZone: {
      // maxWidth: 890,
      width: "100%",
      minHeight: "75vh",
      background: "transparent",
    },
    drop: {
      width: "100%",
      height: "inherit",
      minHeight: "80vh",
      overflow: "hidden",
      position: "relative",
      boxShadow: "0 0 20px #eee",
      ...(screenType === "document"
        ? {
            paddingRight:
              parseFloat(screenStyles?.page?.horizontalMargin) *
              (screenStyles?.page?.dimensionMeasure === "inch" ? 96 : 1),
            paddingLeft:
              parseFloat(screenStyles?.page?.horizontalMargin) *
              (screenStyles?.page?.dimensionMeasure === "inch" ? 96 : 1),
            paddingTop:
              parseFloat(screenStyles?.page?.verticalMargin) *
              (screenStyles?.page?.dimensionMeasure === "inch" ? 96 : 1),
            paddingBottom:
              parseFloat(screenStyles?.verticalMargin) *
              (screenStyles?.page?.dimensionMeasure === "inch" ? 96 : 1),
          }
        : {
            padding: "10px 10px 60px 10px",
          }),
    },
    container: {
      height: "inherit",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "transparent",
    },
    elements_container: {
      width: "30%",
    },
    elements: {
      userSelect: "none",
      width: 100,
      display: "flex",
      flexDirection: "column",
      padding: 5,
    },
  }));

  const [useDialog, setUseDialog] = useState("");
  const [deletingItem, setDeletingItem] = useState(null);
  const [showDeleteItemDialog, setShowDeleteItemDialog] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { canvasItems } = props;

  const duplicateItem = (data) => {
    dispatch(duplicateCanvasItem(data));
  };
  const activeItem = (data) => {
    // console.log(`INDEX > ${data.index} --> ITEM: ${JSON.stringify(data.item)}`);
    dispatch(setActiveItem(data));
  };

  const dialogResponse = (use, resp) => {
    if (resp === true) {
      switch (use) {
        case "deleteTask":
          dispatch(canvasDeRegisterElement(deletingItem));
          setDeletingItem(null);
          break;
        default:
          break;
      }
    }

    dispatch(showAppDialog({ status: false }));
    return;
  };

  const deleteCanvasItem = () => {
    if (!deletingItem) return;
    dispatch(canvasDeRegisterElement(deletingItem));
    setShowDeleteItemDialog(false);
    setDeletingItem(null);
  };

  const determineStyle = (item) => {
    //  NOTE: form items don't reach here, just the for itself

    return {
      ...screenStyles,
      ...(item?.style?.overrideDefault ? item.style : {}),
    };
  };

  return (
    <ErrorBoundary render={() => <GeneralError section="editors" />}>
      <div className={classes?.app}>
        <div className={classes?.container}>
          <div className={classes?.dropZone}>
            <form>
              <Droppable droppableId={"app-container"}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} className={classes?.drop}>
                    {!canvasItems.length ||
                    (canvasItems.length === 1 && screenType === "app") ? (
                      <EmptyCanvas />
                    ) : (
                      canvasItems
                        ?.filter((item) => item?.type !== "ScreenButtons")
                        ?.map((item, index) => (
                          <Draggable
                            key={item?.id}
                            draggableId={item?.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  marginBottom:
                                    determineStyle(item)?.page?.lineSpacing,
                                  // || determineStyle(item)?.spacing,
                                }}
                              >
                                <ElementComponentWrapper
                                  name={
                                    item?.name || `Enter ${item?.type} Name`
                                  }
                                  title={item?.title}
                                  type={item?.type}
                                  appDesignMode={APP_DESIGN_MODES.EDIT}
                                  dragHandleProps={{
                                    ...provided.dragHandleProps,
                                  }}
                                  id={item?.id}
                                  onDelete={() => {
                                    setShowDeleteItemDialog(true);
                                    /* dispatch(showAppDialog({ status: true }));
                                    setUseDialog("deleteTask"); */
                                    setDeletingItem({
                                      container: item?.parent,
                                      parent: false,
                                      id: item?.id,
                                      index,
                                    });
                                  }}
                                  onDuplicate={() =>
                                    duplicateItem({
                                      container: item?.parent,
                                      parent: false,
                                      id: item?.id,
                                      index,
                                    })
                                  }
                                  onClick={(e) =>
                                    activeItem({
                                      item,
                                      index,
                                      parent: item?.parent,
                                    })
                                  }
                                >
                                  <RealComponents
                                    onChange={(val, name) => {}}
                                    id={item?.id}
                                    name={item?.name}
                                    type={item?.type}
                                    parent={item?.parent}
                                    values={item?.values}
                                    children={item?.children}
                                    containerIndex={index}
                                    style={determineStyle(item)}
                                    screenStyles={screenStyles}
                                    customId={item?.customId || null}
                                    customName={item?.customName || null}
                                    appDesignMode={APP_DESIGN_MODES.EDIT}
                                    index={index}
                                  />
                                </ElementComponentWrapper>
                              </div>
                            )}
                          </Draggable>
                        ))
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              {showDeleteItemDialog && (
                <CustomConfirmBox
                  closeConfirmBox={() => setShowDeleteItemDialog(false)}
                  text={`Are you sure you want to delete this item?
                This action cannot be undone.`}
                  open={showDeleteItemDialog}
                  confirmAction={deleteCanvasItem}
                />
              )}

              <Dialog
                dialogProps={props.showDialog}
                onClose={dialogResponse}
                use={useDialog}
              />
            </form>
          </div>
        </div>
      </div>
    </ErrorBoundary>
    // </div>
  );
};

export default connect((state) => {
  return {
    // activeData: state.activeCanvas.data,
    // workspace: state.activeCanvas.data,
    activeScreen: state.screens.activeScreen,
    canvasItems: state.uieditor.canvasItems,
    screenStyles: state.uieditor.screenStyles,
    droppedPos: state.uieditor.droppedPos,
    undoMode: state.uieditor.undoMode,
    zoomLevel: state.uieditor.zoomLevel,
    customElements: state.uieditor.customElements,
    showDialog: state.reducers.showDialog,
    uiEditorFullScreen: state.uieditor.uiEditorFullScreen,
  };
})(UIEditorCanvas);
