import { SET_DATA_PERMS } from "../../store/actions/dataAction";

// export const getLiveScreen = (slug, app, accountSlug) => async (dispatch) => {
//   dispatch({ type: GET_LIVE_SCREEN_START });
//   const { data, success } = await getScreenService(slug, app, accountSlug);
//   if (!success) return dispatch({ type: GET_LIVE_SCREEN_ERROR, payload: data });
//   dispatch({ type: GET_LIVE_SCREEN_SUCCESS, payload: data.data?.[0] || {} });
//   // //console.log(`GET_LIVE_SCREEN_START >>> ${ JSON.stringify(data) }`);
// };
export const setDataPermissions = (data) => async (dispatch) => {
  //console.log(`SET_DATA_PERMS > ${JSON.stringify(data)}`);
  dispatch({ type: SET_DATA_PERMS, payload: data });
};
