import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Divider, Typography, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import {
  deleteCustomElement,
  saveCustomElement,
  updateStylesAndValues,
} from "../../../../Pages/UIEditor/utils/canvasHelpers";
import { errorToastify } from "../../../../../../common/Toastify";

const InputText = withStyles((theme) => ({
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 10,
    paddingLeft: 2,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {},
  sideHeading: {
    color: "#091540",
    fontWeight: 600,
    fontSize: 13,
    padding: "10px 5px 0px 5px",
    display: "flex",
    justifyContent: "space-between",
    "& span": {
      textAlign: "right",
      "& input": {
        paddingLeft: 10,
      },
    },
  },
  section: {
    padding: 10,
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
  },
  sectionLabel: {
    color: "#999",
    fontSize: 10,
    marginRight: 5,
    marginTop: 5,
  },
  fullWidthText: {
    margin: "10px 0",
  },
  input: {
    color: "#091540",
    fontSize: 10,
  },
  center: {
    textAlign: "center",
  },
}));

export default function CustomSidebar(props) {
  const {
    style,
    id,
    type,
    canvasItems,
    componentsSelection,
    which,
    customInfo,
  } = props;
  const dispatch = useDispatch();
  const [newGroupName, setNewGroupName] = useState("");
  const onStyleChangeOrValues = (value, parent, property, changeType) => {
    dispatch(
      updateStylesAndValues({ value, parent, property, id, type, changeType })
    );
  };
  const classes = useStyles();

  const { fileTooltipText } = props;

  const doGroup = async () => {
    const canvasIt = { ...canvasItems };
    console.log(
      `${newGroupName}>> ${JSON.stringify(
        componentsSelection.activeComponents
      )}`
    );
    const groupName = newGroupName.trim();
    if (!groupName) {
      errorToastify("Please enter valid name");
      return;
    }

    const grp = componentsSelection.activeComponents;
    const fullGroup = { name: groupName, members: {} };

    let top = 0;
    let bottom = 0;
    let left = 0;
    let right = 0;

    Object.keys(grp).forEach((item, index) => {
      if (index === 0) {
        top = canvasIt[item].pos.y1;
        bottom = canvasIt[item].pos.y2;
        left = canvasIt[item].pos.x1;
        right = canvasIt[item].pos.x2;
      } else {
        top = Math.min(parseInt(canvasIt[item].pos.y1), top);
        bottom = Math.max(parseInt(canvasIt[item].pos.y2), bottom);
        left = Math.min(parseInt(canvasIt[item].pos.x1), left);
        right = Math.max(parseInt(canvasIt[item].pos.x2), right);
      }
    });

    const customWidth = right - left;
    const customHeight = bottom - top;

    Object.keys(grp).forEach((g) => {
      canvasIt[g].pos = {
        x1: canvasIt[g].pos.x1 - left,
        y1: canvasIt[g].pos.y1 - top,
        x2: canvasIt[g].pos.x2 - left,
        y2: canvasIt[g].pos.y2 - top,
      };
      fullGroup.members[g] = canvasIt[g];
    });

    const res = await dispatch(saveCustomElement(fullGroup));

    if (res?.error === "name-exists") alert("Name already used. Edit it.");
    console.log(`rrrrrrrrreeeeeeeeeesssssssssss >> ${res}`);
  };
  const delGroup = async () => {
    const res = await dispatch(deleteCustomElement(customInfo.customId));
  };

  console.log(
    `SIDEBAR >> ${JSON.stringify(componentsSelection.activeComponents)} >>> ${
      customInfo?.customId
    }`
  );
  return (
    <div>
      {which === "multi" && (
        <>
          <div
            style={{
              backgroundColor: "grey",
              color: "white",
              margin: 10,
              padding: "5px 10px",
              borderRadius: 3,
            }}
          >
            Selected components:{" "}
            {Object.keys(componentsSelection.activeComponents).length}
          </div>
          <div style={{ margin: 10 }}>Save as group:</div>
          <div style={{ margin: 10 }}>
            <TextField
              type={"text"}
              inputMode="text"
              placeholder="Enter unique name"
              onChange={(e) => setNewGroupName(e.target.value)}
            />
            <Button onClick={doGroup}>Save!</Button>
          </div>
        </>
      )}
      {which === "custom" && (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography gutterBottom className={classes.sideHeading}>
              Custom
            </Typography>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography gutterBottom className={classes.sideHeading}>
                Name:
              </Typography>
              <InputText
                variant="outlined"
                size="small"
                onChange={(e) =>
                  onStyleChangeOrValues(e.target.value, "values", "name")
                }
                value={customInfo.customName}
                placeholder="Name"
                style={{ width: "60%" }}
                inputProps={{
                  min: 0,
                  style: { textAlign: "center" },
                  className: classes.input,
                }}
              />
            </div>
          </div>
          <Divider />
          <Button
            fullWidth
            onClick={doGroup}
            style={{ color: "orange", fontSize: "0.95em" }}
          >
            Ungroup!
          </Button>
          <Divider />
          <Button
            fullWidth
            onClick={delGroup}
            style={{ color: "red", fontSize: "0.95em" }}
          >
            Delete!
          </Button>
          <Divider />
        </div>
      )}
    </div>
  );
}
