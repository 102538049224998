import React, { useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { InputBase, Tooltip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateComponentAttribute } from "../../../../Pages/UIEditor/utils/canvasHelpers";
import { saveDisplayTableColumn } from "../../../../../../store/actions/properties";
import { errorToastify } from "../../../../../../common/Toastify";
import { validateUIEInputs } from "../../../../../../helpers/validation";

const SidebarNameSection = React.memo(
  ({
    itemId,
    itemType,
    noName,
    title,
    name,
    index,
    isInsideContainer,
    setIsDynamic,
    isDynamic,
    containingChildIndex,
  }) => {
    const activeId = useRef();

    useEffect(() => {
      if (
        ["dropdown", "checkbox", "displayTable", "inputText"].includes(itemType)
      ) {
        if (activeId.current !== itemId) {
          activeId.current = itemId;
          return;
        }

        let newName;
        if (isDynamic) {
          newName = !name ? "" : name?.startsWith("@") ? name : "@" + name;
        } else {
          newName = !name
            ? ""
            : name?.startsWith("@")
            ? name.substring(1)
            : name;
        }

        if (name === newName) return;
        let target = { name: "", value: newName };
        onNameChange({ target });
      }
    }, [isDynamic, name, itemType]);

    const InputText = withStyles((theme) => ({
      input: {
        color: "#091540",
        borderRadius: 3,
        position: "relative",
        border: "1px solid #ABB3BF",
        fontSize: 11,
        padding: "5px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
      },
    }))(InputBase);

    const dispatch = useDispatch();

    const onNameChange = ({ target: { name: labelName, value } }) => {
      /* return if name value contains  { } or |  */
      const { status: isForbiddenChar, characterValue } =
        validateUIEInputs(value);

      if (isForbiddenChar) {
        return errorToastify(
          `"${characterValue}" character not allowed in name.`
        );
      }
      /* return if name value isn't changed onBlur */
      if (name === value) return;

      if (
        ["dropdown", "checkbox", "displayTable", "inputText"].includes(itemType)
      ) {
        if (value?.length > 1 && value.startsWith("@")) {
          dispatch(saveDisplayTableColumn(true));
          setIsDynamic(true);
        } else {
          setIsDynamic(false);
          dispatch(saveDisplayTableColumn(false));
        }
      }

      return dispatch(
        updateComponentAttribute({
          attrib: "name",
          value,
          index,
          isInsideContainer,
          containingChildIndex,
        })
      );
    };

    return (
      <div className="sidebar-section _header">
        {title}

        {!noName && (
          <Tooltip title={"Use alphanumeric characters without spaces"}>
            <InputText
              variant="outlined"
              size="small"
              onBlur={onNameChange}
              defaultValue={name}
              placeholder="Enter variable name..."
            />
          </Tooltip>
        )}
      </div>
    );
  }
);
export default SidebarNameSection;
